import React, {Component} from "react";
import {Affix, Button, Card, Checkbox, Col, Divider, Dropdown, Input, Menu, Row, Table, Tooltip} from "antd";
import PartnerDrawer from "../Components/PartnerDrawer";
import {connect} from "react-redux";
import API from "../Utils/API";
import SiteSelector from "../Components/SiteSelector";
import _ from "lodash";
import {saveAs} from 'file-saver';
import {LockOutlined} from "@ant-design/icons";
import ResponsibleSelector from "../Components/ResponsibleSelector";
import SubMenu from "antd/es/menu/SubMenu";

const {Column} = Table;

class SalesOrderList extends Component {

    componentDidMount() {
        this.getDocTypes();
        this.getUsers();
        this.getStatusesData();
    }

    state = {
        selectedRowKeys: [],
        selectedRows: [],
        tableLoading: false,
        visible: false,
        editId: null,
        search: "",
        users: [],
        selectedUser: null,
        salesOrder: [],
        status: ["BidRequest", "BidOffered", "BidAccepted", "Pending", "AwaitingPayment", "InPurchaseOrder", "OrderInStock", "Shipped", "CustomerNoticed", "Billable", "Closed", "Cancelled"],
        selectedStatus: ["BidRequest", "BidOffered", "BidAccepted", "Pending", "AwaitingPayment", "InPurchaseOrder", "OrderInStock", "Shipped", "CustomerNoticed", "Billable", "Closed", "Cancelled"],
        type: [],
        selectedType: [],
        site: "all",
        searchStr: "",
        sortedInfo: null,
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            current: 0,
            results: 1,
            total: 200,
            pageSize: 10
        },
        externalOrdersOnly: false,
        currentStatusMenuKey: 'all',
        currentTypeMenuKey: ['all'],
        statusesData: {
            allBid: 0,
            allPending: 0,
            inPurchaseOrder: 0,
            orderInStock: 0,
            shipped: 0,
            customerNoticed: 0,
            billable: 0,
            allClosed: 0
        },
        multiTypeSelect: false
    };
    searchCallback = _.debounce((text) => {
        this.getSalesOrders(text);
    }, 500);

    getUsers() {
        this.setState({users: JSON.parse(localStorage.getItem('users'))})
    };

    onClose = (e, reload) => {
        this.setState({
            visible: false,
            editId: ""
        });
        if (reload) {
            this.setState({tableLoading: true});
            this.getSalesOrders(this.state.search);
        }
    };

    editClick = (e, id) => {
        this.setState({
            visible: true,
            editId: id
        });
    };

    editSalesOrder = (id) => {
        this.props.history.push('/app/edit-salesOrder/' + id);
    };

    newSalesOrder = () => {
        this.props.history.push('/app/new-salesOrder');
    };

    newSales = () => {
        this.props.history.push('/app/new-sales');
    };

    newSalesOrderBid = () => {
        this.props.history.push('/app/new-bidOrder/');
    };

    getDocTypes() {
        const current = this;
        this.setState({
            type: JSON.parse(localStorage.getItem('docTypes')),
            selectedType: ["all"],
            tableLoading: true
        }, () => {
            setTimeout(function () {
                current.getSalesOrders(current.state.search);
            }, 200);
        });
    };

    getStatusesData = () => {
        console.log(this.state.site);
        API.get("salesOrder/getStatusesData/" + this.state.site)
            .then(res => {
                this.setState({statusesData: res.data})
                console.log(res.data);
            })
    }

    getSalesOrders(search) {
        this.setState({tableLoading: true, salesOrder: [], search: search});
        let data = {
            page: this.state.pagination.current,
            size: this.state.pagination.pageSize,
            siteId: this.state.site,
            status: this.state.selectedStatus,
            type: this.state.selectedType,
            search: search,
            userId: this.state.selectedUser,
            externalOrdersOnly: this.state.externalOrdersOnly
        }

        API.post("/salesOrder/getSalesOrders", data)
            .then(res => {
                let salesOrder = res.data.lines;
                console.log("salesorders: ", salesOrder);
                const pagination = {...this.state.pagination};
                pagination.total = res.data.totalElements;
                this.setState({salesOrder: salesOrder, pagination});
            }).finally(() => {
            this.setState({tableLoading: false});
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        console.log("pagination.pageSize:", pagination.pageSize);
        if (typeof pagination.pageSize !== "undefined")
            pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
            sortedInfo: sorter
        }, () => {
            this.getSalesOrders(this.state.search);
        });
    };

    changeSite = (e) => {
        this.setState({site: e, pagination: {...this.state.pagination, current: 0}}, () => {
            this.getSalesOrders(this.state.search);
            this.getStatusesData();
        });
    };

    filterByUser = (e) => {
        this.setState({selectedUser: e, pagination: {...this.state.pagination, current: 0}}, () => {
            this.getSalesOrders(this.state.search);
        });
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
        }
    };

    handleExternalOrdersOnlyChange = (e) => {
        this.setState({externalOrdersOnly: e.target.checked}, () => this.getSalesOrders(this.state.search));
    }

    handleStatusMenuClick = (e) => {
        let setPaggination = this.state.pagination;
        setPaggination.current = 1;
        this.setState({pagination: setPaggination});

        let status = [];
        switch (e.key) {
            case "all":
                this.state.status.forEach(d => status.push(d));
                break;
            case "allPending":
                status.push("Pending", "AwaitingPayment");
                break;
            case "allBid":
                status.push("BidRequest", "BidOffered", "BidAccepted");
                break;
            case "allClosed":
                status.push("Closed", "Cancelled");
                break;
            default:
                status.push(e.key);
                break;
        }
        this.setState({
            currentStatusMenuKey: e.key,
            selectedStatus: status
        }, () => this.getSalesOrders(this.state.search));
    }

    handleTypeMenuClick = (keys) => {
        if (this.state.multiTypeSelect) {
            let values = this.state.selectedType;
            if (values.includes(keys)) {
                values.splice(values.indexOf(keys), 1);
            } else {
                values.push(keys);
            }
            this.setState({
                currentTypeMenuKey: values,
                selectedType: values
            }, () => this.getSalesOrders(this.state.search))

        } else {
            this.setState({
                currentTypeMenuKey: keys,
                selectedType: [keys]
            }, () => this.getSalesOrders(this.state.search))
        }
    }

    onMultiChange = (e) => {
        this.setState({multiTypeSelect: e.target.checked, selectedType: ["all"]}, () => this.handleTypeMenuClick("all"))
    }

    download = (key) => {
        alert("Előkészítés..")
        API.get("/salesOrder/download/" + key, {responseType: 'blob'})
            .then(res => {
                const blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let date = new Date();
                let fileName = "salesOrders-" + date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + ".xls";
                saveAs(blob, fileName);
            }).catch(error => {
            console.log(error);
        });
    };

    render() {
        return (
            <div>
                <Card title="Értékesítés">
                    <Affix offsetTop={10}>
                        <div style={{backgroundColor: "white"}}>
                            <Col>
                                <Row>
                                    <Button onClick={e => this.newSalesOrderBid(e)} type="primary"
                                            style={{background: "black"}}>
                                        Új ajánlat
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button onClick={e => this.newSalesOrder(e)} type="danger">
                                        Új rendelés
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button onClick={e => this.newSales(e)} type="primary"
                                            style={{background: "green"}}>
                                        Új bolti eladás
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button onClick={this.handleTableChange} type="primary">
                                        Frissítés
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Dropdown overlay={(
                                        <Menu>
                                            <Menu.Item key={1} onClick={() => this.download(1)}>
                                                Utolsó 1 hónap
                                            </Menu.Item>
                                            <Menu.Item key={2} onClick={() => this.download(2)}>
                                                Utolsó 6 hónap
                                            </Menu.Item>
                                            <Menu.Item key={3} onClick={() => this.download(3)}>
                                                Utolsó 1 év
                                            </Menu.Item>
                                            <Menu.Item key={4} onClick={() => this.download(4)}>
                                                Összes
                                            </Menu.Item>
                                        </Menu>
                                    )}>
                                        <Button type="primary">Excel export</Button>
                                    </Dropdown>
                                    {/*<Divider type="vertical"/>
                                    <Button onClick={this.getWebshopOrders} type="primary">
                                        Webshop rendelések importálása
                                    </Button>*/}
                                </Row>
                                <Divider type="horizontal"/>
                            </Col>
                        </div>
                    </Affix>

                    <Menu defaultSelectedKeys={'all'} onClick={this.handleStatusMenuClick}
                          selectedKeys={[this.state.currentStatusMenuKey]} mode="horizontal">
                        <Menu.Item key="all"><b>Összes</b></Menu.Item>
                        <SubMenu key="allBid" onTitleClick={this.handleStatusMenuClick} title={
                            <span className="submenu-title-wrapper">
                                <b>Árajánlat </b>({this.state.statusesData.allBid})
                            </span>
                        }>
                            <Menu.ItemGroup>
                                <Menu.Item key="allBid">Összes</Menu.Item>
                                <Menu.Item key="BidRequest">Árajánlat kérés</Menu.Item>
                                <Menu.Item key="BidOffered">Árajánlat kiadva</Menu.Item>
                                <Menu.Item key="BidAccepted">Árajánlat elfogadva</Menu.Item>
                            </Menu.ItemGroup>
                        </SubMenu>

                        <SubMenu key="allPending" onTitleClick={this.handleStatusMenuClick} title={
                            <span className="submenu-title-wrapper">
                                <b>Függőben </b>({this.state.statusesData.allPending})
                            </span>
                        }>
                            <Menu.ItemGroup>
                                <Menu.Item key="allPending">Összes</Menu.Item>
                                <Menu.Item key="Pending">Függőben</Menu.Item>
                                <Menu.Item key="AwaitingPayment">Átutalásra vár</Menu.Item>

                            </Menu.ItemGroup>
                        </SubMenu>

                        <Menu.Item key="InPurchaseOrder"><b>Beszerzés
                            alatt </b>({this.state.statusesData.inPurchaseOrder})</Menu.Item>
                        <Menu.Item key="OrderInStock"><b>Rendelés készleten </b>({this.state.statusesData.orderInStock})</Menu.Item>
                        <Menu.Item key="Shipped"><b>Szállítás alatt </b>({this.state.statusesData.shipped})</Menu.Item>
                        <Menu.Item key="CustomerNoticed"><b>Ügyfél
                            értesítve </b>({this.state.statusesData.customerNoticed})</Menu.Item>
                        <Menu.Item key="Billable"><b>Számlázható </b>({this.state.statusesData.billable})</Menu.Item>
                        <SubMenu key="allClosed" onTitleClick={this.handleStatusMenuClick} title={
                            <span className="submenu-title-wrapper">
                                <b>Lezárt </b>({this.state.statusesData.allClosed})
                            </span>
                        }>
                            <Menu.ItemGroup>
                                <Menu.Item key="allClosed">Összes</Menu.Item>
                                <Menu.Item key="Closed">Lezárt</Menu.Item>
                                <Menu.Item key="Cancelled">Törölt</Menu.Item>
                            </Menu.ItemGroup>
                        </SubMenu>
                    </Menu>

                    <Row>
                        <span style={{float: 'left', marginTop: 10, marginLeft: 20}}><Checkbox
                            onChange={this.onMultiChange}/> Multi</span>
                        <Menu
                            onClick={e => this.handleTypeMenuClick(e.key)}
                            selectedKeys={this.state.multiTypeSelect ? this.state.currentTypeMenuKey : [this.state.currentTypeMenuKey]}
                            mode="horizontal"
                            multiple={this.state.multiTypeSelect}>
                            <Menu.Item disabled={this.state.multiTypeSelect} key="all"><b>Összes</b></Menu.Item>
                            <Menu.Item key="Bid"><b>Ajánlat</b></Menu.Item>
                            <Menu.Item key="Order"><b>Megrendelés</b></Menu.Item>
                            <Menu.Item key="CashRegisterSale"><b>Pénztárgépes eladás</b></Menu.Item>
                            <Menu.Item key="Invoice"><b>Számla</b></Menu.Item>
                            <Menu.Item key="DeliveryNote"><b>Szállító</b></Menu.Item>
                            <Menu.Item key="CancelledInvoice"><b>Sztornó számla</b></Menu.Item>
                        </Menu>

                    </Row>
                    {/*<Divider type="horizontal"/>*/}

                    <Row type="flex" justify="start" style={{marginTop: 10}}>
                        <Col offset={1}>
                            <Checkbox onChange={e => this.handleExternalOrdersOnlyChange(e)}>Csak webshopos
                                rendelések</Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5}/>
                        <Col span={3} offset={1}><b>Telephely</b></Col>
                        <Col span={4} offset={1}><b>Felelős személy</b></Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Input onChange={e => {
                                this.searchCallback(e.target.value)
                            }} placeholder="Keresés"/>
                        </Col>
                        <Col offset={1} span={3}>
                            <SiteSelector all={true} selected={"all"} onSelect={this.changeSite}/>
                        </Col>
                        <Col offset={1} span={4}>
                            <ResponsibleSelector users={this.state.users} filterByUser={e => this.filterByUser(e)}/>
                        </Col>
                    </Row>

                    <Divider type="horizontal"/>
                    <Col span={24}>
                        <Table onChange={this.handleTableChange} dataSource={this.state.salesOrder} bordered={true}
                               loading={this.state.tableLoading} rowKey={"id"} size={"small"}
                               pagination={this.state.pagination}
                               expandedRowRender={record => (<LinesTable record={record}/>)}>

                            {this.props.metaMode && <Column title="#" dataIndex="id" key="id"/>}
                            <Column title="Azonosító" dataIndex="salesOrderNo" key="salesOrderNo"
                                    render={(salesOrderNo, record) => (<span>
                                <span className="link" onDoubleClick={() => this.editSalesOrder(record.id)}>{salesOrderNo} {record.isLocked ?
                                    <Tooltip
                                        title={record.lockedBy + " felhasználó szerkeszti"}><LockOutlined/></Tooltip> : ""}</span>
                             </span>)}/>
                            <Column title="Típus" dataIndex="docTypeName" key="docTypeName"/>
                            <Column title="Státusz" dataIndex="salesOrderStatusName" key="salesOrderStatusName"/>
                            <Column title="Telephely" dataIndex="siteTitle" key="siteTitle"/>
                            <Column title="Partner" dataIndex="partnersName" key="partnersName"
                                    render={(partnerName, record) => (<span>
                                    <a href="#partner"
                                       onClick={e => this.editClick(e, record.partnersId)}>{partnerName}</a>
                                </span>)}/>
                            <Column title="Teljesítési Hat.Idő" dataIndex="dueDate" key="dueDate"/>
                            <Column title="Fiz.Mod" dataIndex="paymentTypeName" key="paymentTypeName"/>
                            <Column title="Szállítási mód" dataIndex="deliveryTypeName" key="deliveryTypeName"/>
                            <Column title="Ref." dataIndex="refNo" key="refNo"/>

                            {this.props.metaMode && <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>}
                            {this.props.metaMode &&
                            <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>}
                            {this.props.metaMode &&
                            <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>}

                        </Table>
                    </Col>
                </Card>
                <PartnerDrawer visible={this.state.visible} onClose={this.onClose} editId={this.state.editId}/>
            </div>
        );
    }
}

const LinesTable = (props) => {
    return (
        <Table dataSource={props.record.salesOrderLines} pagination={false} rowKey="productCode">
            <Column title="#" dataIndex="productCode" key="productCode"/>
            <Column title="Termék neve" dataIndex="name" key="name"/>
            <Column title="Mennyiség" dataIndex="qty" key="qty"/>
            <Column title="Beszerzési Ár (Nettó)" dataIndex="netPurchasePrice" key="netPurchasePrice" render={text => {
                return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '))
            }}/>
            <Column title="Értékesítési Ár (Bruttó)" dataIndex="grossSellPrice" key="grossSellPrice" render={text => {
                return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '))
            }}/>
        </Table>
    );
};

function mapStateToProps(state) {
    return {
        metaMode: state.metaMode,
        selectedSite: state.selectedSite,
    };
}

export default connect(mapStateToProps)(SalesOrderList);
