import React, {Component} from 'react';
import {Button, Card, Col, Descriptions, Form, Input, Modal, Row, Select, Table, Tag, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {EditOutlined} from "@ant-design/icons";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import {ShopPriceModal} from "../Components/ShopPriceModal";
import {VariantModal} from "../Components/VariantModal";

const {Option} = Select;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ProductDetails extends Component {

    state = {
        editObject: {id: null},
        edit: false,
        suppliers: [],
        previewVisible: false,
        previewImage: '',
        fileList: [],
        purchasePriceModalVisible: false,
        shopPriceModalVisible: false,
        selectedShopPrice: null,
        shopPriceIndex: 0,
        variantModalVisible: false,
        variantIndex: 0,
        selectedVariant: null,
        supplierCategories: [],
        newPurchasePrice: {},
        webShops: [],
        webShopTreeData: [],
        checkedCategories: []
    }

    componentDidMount() {
        this.getSuppliers();
        this.getWebShops();
        if (typeof this.props.location.state !== "undefined") {
            console.log("onDetails: ", this.props.location.state.detail);
            const product = this.props.location.state.detail;

            let images = this.state.fileList;
            if (product.imageLinks != null && Object.keys(product.imageLinks).length > 0) {
                product.imageLinks.forEach((imageLink, i) => {
                    if (imageLink.type === "LINK") {
                        let image = {
                            uid: '-' + i,
                            name: product.name + '-' + i,
                            status: 'done',
                            url: imageLink.value,
                            type: imageLink.type
                        }
                        images.push(image);
                    } else if (imageLink.type === "BASE64") {
                        let image = {
                            uid: '-' + i,
                            name: product.name + '-' + i,
                            status: 'done',
                            url: "data:image/jpeg;base64," + imageLink.value,
                            type: imageLink.type
                        }
                        images.push(image);
                    }
                })
            }
            this.setState({
                editObject: product,
                fileList: images,
                edit: true
            });
        }
    }

    getSuppliers() {
        API.get("/getSupplierList")
            .then(res => {
                let suppliers = res.data;
                this.setState({suppliers: suppliers});
            });
    };

    getSupplierCategories(e) {
        API.post("/getCategoriesBySupplier", [e])
            .then(res => {
                this.setState({supplierCategories: res.data})
            })
    }

    getWebShops() {
        API.get("productPrice/getWebshops")
            .then(res => {
                this.setState({webShops: res.data});
            });
    }

    getWebShopCategories = (selectedWebShop) => {
        API.get("productPrice/webshopCategoryTree/" + selectedWebShop)
            .then(res => {
                this.setState({webShopTreeData: res.data});
            });
    }

    handleCancel = () => this.setState({previewVisible: false});

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === "BASE64") {
            file.preview = file.url;
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({fileList}) => this.setState({fileList});

    handleShopPriceModal = (selectedShopPrice, index) => {
        console.log("called");
        if (selectedShopPrice.id === undefined) {
            selectedShopPrice = null;
        }
        this.setState({
            shopPriceIndex: index,
            selectedShopPrice
        }, () => this.setState({
            shopPriceModalVisible: !this.state.shopPriceModalVisible
        }))
    }

    handlePurchasePriceModal = () => {
        this.setState({purchasePriceModalVisible: !this.state.purchasePriceModalVisible})
    }

    handleVariantModal = (selectedVariant, index) => {
        if (selectedVariant.size === undefined) {
            selectedVariant = null;
        }
        const {form} = this.variantFormRef.props;
        form.resetFields();
        this.setState({
            variantIndex: index,
            selectedVariant
        }, () => this.setState({
            variantModalVisible: !this.state.variantModalVisible
        }))
    }

    handleCreateVariant = () => {
        const {form} = this.variantFormRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log(values);
            if (this.state.selectedVariant == null) {
                let editObject = this.state.editObject;
                let variants = editObject.variants ? editObject.variants : [];
                variants.push(values);
                editObject.variants = variants;
                this.setState({editObject});
            } else {
                let editObject = this.state.editObject;
                editObject.variants[this.state.variantIndex] = values;
                this.setState({editObject});
            }
            this.setState({
                variantIndex: null,
                selectedVariant: null,
                variantModalVisible: !this.state.variantModalVisible
            })
        })
    }

    handleDeleteVariant = () => {
        if (this.state.variantIndex != null) {
            let editObject = this.state.editObject;
            editObject.variants.splice(this.state.variantIndex, 1);
            this.setState({
                editObject,
                variantIndex: null,
                selectedVariant: null,
                variantModalVisible: !this.state.variantModalVisible
            })
        }
    }

    handleCreateShopPrice = (checkedCategories, priceRanges, grossSellPrice) => {
        const {form} = this.formRef.props;
        form.validateFields((err, values) => {
            if (Object.keys(checkedCategories).length < 1) {
                return;
            }
            if (err) {
                return;
            }

            //console.log('Received values of form: ', values);

            form.resetFields();
            this.setState({shopPriceModalVisible: false});

            let productCodes = [this.state.editObject.productCode];
            let grossSellPrices = [grossSellPrice];
            // for (let i = 0; i < this.state.selectedProducts.length; i++) {
            //     productCodes.push(this.state.selectedProducts[i].productCode);
            //     grossSellPrices.push(this.state.selectedProducts[i].grossSellPrice);
            // }

            if (this.state.priceCalcType !== 3) {
                let data = {
                    "productCodes": productCodes,
                    "grossSellPrices": grossSellPrices,
                    "selectedWebshop": [values.shopName],
                    "webshopCheckedKeys": checkedCategories,
                    "pricingMethod": values.pricingMethod,
                    "tax": values.tax,
                    "value": values.price
                }
                console.log("Save this:", data)

                API.post("productPrice/saveProduct/", data)
                    .then(res => {
                        NotificationUtil(res.data);
                        if (res.data.type === "success") {
                            let savedProduct = res.data.extraList[0];
                            let editObject = this.state.editObject;
                            editObject.shopPrices = savedProduct.shopPrices;
                            editObject.categories = savedProduct.categories;
                            this.setState({editObject: editObject});
                        }
                        // this.setState({
                        //     saveSpin: false,
                        //     //visibleTable: false,
                        //     selectedProducts: [],
                        //     alreadyAddedKeys: [],
                        //     canSave: false,
                        //     canAddPrice: false
                        // })
                        // //this.getTreeData();
                        // this.getProducts(this.state.priceWithOrWithout)
                    }).catch(() => {
                    NotificationUtil({msg: "Hiba történt!", type: "error"});
                })

            } else {
                let pricesFrom = [];
                let pricesTo = [];
                let pricesValue = [];
                for (let i = 0; i < priceRanges.length; i++) {
                    pricesFrom.push(priceRanges[i].from);
                    pricesTo.push(priceRanges[i].to);
                    pricesValue.push(priceRanges[i].value);
                }
                let data = {
                    "productCodes": productCodes,
                    "pricesFrom": pricesFrom,
                    "pricesTo": pricesTo,
                    "pricesValue": pricesValue,
                    "grossSellPrices": grossSellPrices,
                    "tax": values.tax,
                    "selectedWebshop": values.shopName,
                    "webshopCheckedKeys": checkedCategories
                }
                console.log("Save this:", data)

                // API.post("productPrice/saveBandPricedProduct/", data)
                //     .then(res => {
                //         NotificationUtil(res.data);
                //         this.setState({
                //             saveSpin: false,
                //             visibleTable: false,
                //             selectedProducts: [],
                //             alreadyAddedKeys: [],
                //             canSave: false,
                //             canAddPrice: false
                //         })
                //         this.getTreeData();
                //     }).catch(error => {
                //     NotificationUtil({msg: "Hiba történt!", type: "error"});
                // });
            }
        });
    };

    categoryChecked = data => {
        console.log(data);
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    saveVariantFormRef = formRef => {
        this.variantFormRef = formRef;
    };

    uploadFile = ({file}) => {
        let images = this.state.fileList;
        getBase64(file).then(res => {
            file.url = res;
            images.push(file);
            console.log("array:", images);
            this.setState({fileList: images});
        });
    }

    saveProduct = (redirect) => {
        const {form} = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            values.shopPrices = this.state.editObject.shopPrices;
            values.purchasePrices = this.state.editObject.purchasePrices;
            values.categories = this.state.editObject.categories;
            values.variants = this.state.editObject.variants;

            const images = this.state.fileList;
            let imageLinks = [];
            images.forEach(image => {
                let imageLink = {
                    type: image.type,
                    value: image.url
                }
                imageLinks.push(imageLink);
            })
            values.imageLinks = imageLinks;
            console.log("product to save: ", values);

            //TODO check productCode, alert if exists
            API.post("/updateProduct", values)
                .then(res => {
                    NotificationUtil(res.data);
                    if (redirect) {
                        this.props.history.push('/app/products-list')
                    } else {
                        //get product
                    }
                })
        });
    }

    resolvePricingMethod = (pricingMethod) => {
        switch (pricingMethod) {
            case 1:
                return "Egyszerű";
            case 2:
                return "Százalékos";
            case 3:
                return "Sávos";
            case 4:
                return "Bruttó";
            default:
                return pricingMethod;
        }
    }

    resolveTax = (taxValue) => {
        switch (taxValue) {
            case 1.27:
                return "27%";
            case 0:
                return "0%";
            default:
                return taxValue;
        }
    }

    resolveCategoriesByShopName = (shopName) => {
        let categories = this.state.editObject.categories.filter(category => category.shopName === shopName);
        return categories.map(category => {
            return <Tag>{category.name}</Tag>
        })
    }

    inputChange = (e) => {
        this.onChange(e.target.name, e.target.value);
    }

    selectChange = (name, value) => {
        this.onChange(name, value);
    }

    onChange = (name, value) => {
        let shopPrices = this.state.editObject.shopPrices;
        let shopPrice = shopPrices[this.state.shopPriceIndex];
        shopPrice[name] = value;
        this.setState({shopPrices})
    }

    removeCategory = (category) => {
        let array = [...this.state.editObject.categories];
        const index = array.indexOf(category);
        if (index !== -1) {
            array.splice(index, 1);
            let editObject = this.state.editObject;
            editObject.categories = array;
            this.setState({editObject});
        }
    }

    purchasePriceValueChange = (name, value) => {
        if (name === "supplier") {
            this.getSupplierCategories(value);
            value = this.state.suppliers.find(supplier => supplier.id = value).name;
        }
        let pp = this.state.newPurchasePrice;
        pp[name] = value;
        this.setState({newPurchasePrice: pp});
    }

    savePurchasePrice = () => {
        let newPP = this.state.newPurchasePrice;
        if (typeof newPP.supplier === "undefined" || newPP.supplier === "") {
            return;
        }
        if (typeof newPP.price === "undefined" || newPP.price === "") {
            return;
        }
        if (typeof newPP.supplierCategory === "undefined" || newPP.supplierCategory === "") {
            return;
        }
        if (typeof newPP.supplierProductCode === "undefined" || newPP.supplierProductCode === "") {
            return;
        }
        if (typeof newPP.internalStock === "undefined" || newPP.internalStock === "") {
            return;
        }
        let editObject = this.state.editObject;
        if(!editObject.purchasePrices){
            editObject.purchasePrices = [];
        }
        editObject.purchasePrices.push(newPP);
        this.setState({editObject: editObject, newPurchasePrice: {}});
        this.handlePurchasePriceModal();
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
        };
        const {previewVisible, previewImage} = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div className="ant-upload-text">Feltöltés</div>
            </div>
        );
        const title = this.state.editObject.productCode != null ? "Termék szerkesztése" : "Termék felvétele";
        const locale = {
            emptyText: "Nincs adat"
        }
        const newMode = this.state.editObject.id == null;
        return (
            <div>
                <h1>{title}</h1>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="product-form">
                    <Form.Item label="Gyártói azonosító:">
                        {getFieldDecorator('id', {
                            rules: [{required: true}],
                            initialValue: this.state.editObject.productCode != null ? this.state.editObject.productCode : ""
                        })(<Input disabled={this.state.editObject.productCode != null}/>)}
                    </Form.Item>

                    <Form.Item label="Webshop SKU:">
                        {getFieldDecorator('skuInShop', {
                            initialValue: this.state.editObject.skuInShop != null ? this.state.editObject.skuInShop : ""
                        })(<Input disabled={true}/>)}
                    </Form.Item>

                    <Form.Item label="Termék neve:">
                        {getFieldDecorator("productName", {
                            initialValue: this.state.editObject.name !== "" ? this.state.editObject.name : "",
                            rules: [{required: true}]
                        })(<Input/>)}
                    </Form.Item>

                    <Form.Item label="Vonalkód:">
                        {getFieldDecorator("barcode", {
                            initialValue: this.state.editObject.barcode !== "" ? this.state.editObject.barcode : ""
                        })(<Input/>)}
                    </Form.Item>

                    <Form.Item label="Gyártó:">
                        {getFieldDecorator("manufacturer", {
                            initialValue: this.state.editObject.manufacturer !== "" ? this.state.editObject.manufacturer : "",
                            rules: [{required: true}]
                        })(<Input/>)}
                    </Form.Item>

                    <Form.Item label="Garancia:">
                        {getFieldDecorator("warranty", {
                            initialValue: this.state.editObject.warranty !== "" ? this.state.editObject.warranty : ""
                        })(<Input/>)}
                    </Form.Item>

                    <Form.Item label="Rövid leírás:">
                        {getFieldDecorator("shortDescription", {
                            initialValue: this.state.editObject.shortDescription !== "" ? this.state.editObject.shortDescription : ""
                        })(<TextArea placeholder="Ha üres, akkor a leírás első 50 karaktere lesz."/>)}
                    </Form.Item>

                    <Form.Item label="Leírás:">
                        {getFieldDecorator("description", {
                            initialValue: this.state.editObject.description !== "" ? this.state.editObject.description : "",
                            rules: [{required: true}]
                        })(<TextArea/>)}
                    </Form.Item>

                    <Form.Item label="Youtube videó:">
                        {getFieldDecorator("youtubeLink", {
                            initialValue: this.state.editObject.youtubeLink !== "" ? this.state.editObject.youtubeLink : "",
                            rules: [{type: "url"}]
                        })(<Input type="url" placeholder="https://www.youtube.com/watch?v=g_wx9zM54os"/>)}
                    </Form.Item>

                    {this.state.editObject.name &&
                    <div className="clearfix">
                        <Upload
                            customRequest={this.uploadFile}
                            listType="picture-card"
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                        >
                            {uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>
                    }

                    {!this.state.editObject.variants &&
                    <Card title={"Beszerzési árak"}
                          extra={<Button type="primary" onClick={this.handlePurchasePriceModal}>Új
                              hozzáadása <PlusOutlined/></Button>}>
                        <Row gutter={24} type="flex" justify="start" align="top">
                            {this.state.editObject.purchasePrices &&
                            this.state.editObject.purchasePrices.map(purchasePrice =>
                                <Col span={8}>
                                    <Card title={purchasePrice.supplier}>
                                        <p>Be. nettó ára: {purchasePrice.price}</p>
                                        <p>Kategória: {purchasePrice.supplierCategory}</p>
                                        <p>Beszállítói azonosító: {purchasePrice.supplierProductCode}</p>
                                        <p>Belső készlet: {purchasePrice.internalStock}</p>
                                        <p>Külső készlet: {purchasePrice.externalStock}</p>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Card>
                    }

                    <PurchasePriceModal current={this} />

                    {!this.state.editObject.variants &&
                    <Card title={"Eladási árak"} extra={<Button type="primary" onClick={this.handleShopPriceModal}>Új
                        hozzáadása <PlusOutlined/></Button>}>
                        <Row gutter={48} type="flex" justify="center" align="top">
                            {this.state.editObject.shopPrices &&
                            this.state.editObject.shopPrices.map((shopPrice, index) =>
                                <ShopPriceCard current={this} shopPrice={shopPrice} index={index}/>
                            )}
                        </Row>
                        <ShopPriceModal wrappedComponentRef={this.saveFormRef}
                                        visible={this.state.shopPriceModalVisible}
                                        onCancel={this.handleShopPriceModal}
                                        onCreate={this.handleCreateShopPrice}
                                        onWebShopChange={this.getWebShopCategories}
                                        webShops={this.state.webShops}
                                        webShopCategories={this.state.webShopTreeData}
                                        categoryChecked={this.categoryChecked}
                                        editObject={this.state.editObject}
                                        selectedShopPrice={this.state.selectedShopPrice}/>
                    </Card>
                    }

                    {(this.state.editObject.variants || newMode) &&
                    <Card title={"Variánsok"}
                          extra={<Button type="primary" onClick={this.handleVariantModal}>Új hozzáadása <PlusOutlined/></Button>}>
                        <Row gutter={48} type="flex" justify="center" align="top">
                            {this.state.editObject.variants && this.state.editObject.variants.map((variant, index) =>
                                <Card title={"Variáns: " + variant.size} style={{width: "50%"}}
                                      extra={<Button type="primary" onClick={() =>
                                          this.handleVariantModal(variant, index)}><EditOutlined/></Button>}>
                                    <Descriptions>
                                        <Descriptions.Item label="Méret">{variant.size}</Descriptions.Item>
                                        <Descriptions.Item label="Súly">{variant.weight}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Extra méret">{variant.specialSize === "1" ? "Igen" : "Nem"}</Descriptions.Item>
                                    </Descriptions>
                                    <Card title={"Beszerzési árak"}>
                                        <Table locale={locale} rowKey="supplier" pagination={false} bordered={true}
                                               dataSource={variant.purchasePrices}>
                                            <Table.Column title="Beszállító" dataIndex="supplier"/>
                                            <Table.Column title="Ár" dataIndex="price"/>
                                            <Table.Column title="Készlet" dataIndex="internalStock"/>
                                            <Table.Column title="Kategória" dataIndex="supplierCategory"/>
                                        </Table>
                                    </Card>
                                    <Card title={"Eladási árak"}>
                                        <Table locale={locale} pagination={false} bordered={true} dataSource={variant.shopPrices}>
                                            <Table.Column title="Webshop" dataIndex="shopName"/>
                                            <Table.Column title="Ár" dataIndex="price"/>
                                            <Table.Column title="Kategória"
                                                          render={record => this.resolveCategoriesByShopName(record.shopName)}/>
                                        </Table>
                                    </Card>
                                </Card>
                            )}
                        </Row>
                        <VariantModal wrappedComponentRef={this.saveVariantFormRef}
                                      handleVariantModal={this.handleVariantModal}
                                      createVariant={this.handleCreateVariant}
                                      deleteVariant={this.handleDeleteVariant}
                                      current={this}
                                      state={this.state}/>
                    </Card>
                    }

                    {/*
                    <Table dataSource={this.state.editObject.purchasePrices} rowKey="supplier">
                        <Column title="Beszállító" dataIndex="supplier" key="supplier"
                                render={(text, record, index) => {
                                    return (
                                        <Form.Item>
                                            {getFieldDecorator(`purchasePrices[${(index)}].supplier`, {
                                                initialValue: record.supplier,
                                                rules: [{required: true}]
                                            })(
                                                <Select
                                                    style={{width: '200%'}}
                                                    placeholder="Beszállító">
                                                    {this.state.suppliers.map(d => (
                                                        <Option key={d.name} value={d.name}>{d.nickname}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>)
                                }}/>
                        <Column title="Ár" dataIndex="price" key="price" render={(text, record, index) => {
                            return (
                                <Form.Item>
                                    {getFieldDecorator(`purchasePrices[${(index)}].price`, {
                                        initialValue: record.price,
                                        rules: [{required: true}]
                                    })(<InputNumber style={{width: 80}}/>)}
                                </Form.Item>)
                        }}/>
                        <Column title="Kategória" dataIndex="supplierCategory" width={250} key="supplierCategory"
                                render={(text, record, index) => {
                                    return (
                                        <Form.Item>
                                            {getFieldDecorator(`purchasePrices[${(index)}].supplierCategory`, {
                                                initialValue: record.supplierCategory,
                                                rules: [{required: true}]
                                            })(<Input style={{width: 200}}/>)}
                                        </Form.Item>)
                                }}/>
                        <Column title="Beszállítói azonosító" dataIndex="supplierProductCode" key="supplierProductCode"
                                render={(text, record, index) => {
                                    return (
                                        <Form.Item>
                                            {getFieldDecorator(`purchasePrices[${(index)}].supplierProductCode`, {
                                                initialValue: record.supplierProductCode,
                                                rules: [{required: true}]
                                            })(<Input style={{width: 200}}/>)}
                                        </Form.Item>)
                                }}/>
                        <Column title="Belső készlet" dataIndex="internalStock" key="internalStock"
                                render={(text, record, index) => {
                                    return (
                                        <Form.Item>
                                            {getFieldDecorator(`purchasePrices[${(index)}].internalStock`, {
                                                initialValue: record.internalStock
                                            })(<Input style={{width: 100}}/>)}
                                        </Form.Item>)
                                }}/>
                        <Column title="Külső készlet" dataIndex="externalStock" key="externalStock"
                                render={(text, record, index) => {
                                    return (
                                        <Form.Item>
                                            {getFieldDecorator(`purchasePrices[${(index)}].externalStock`, {
                                                initialValue: record.externalStock
                                            })(<Input style={{width: 100}}/>)}
                                        </Form.Item>)
                                }}/>
                    </Table>*/}

                    <Form.Item style={{display: "none"}}>
                        {getFieldDecorator(`createdDate`, {
                            initialValue: this.state.editObject.createdDate != null ? this.state.editObject.createdDate : "",
                        })(
                            <Input hidden={true}/>
                        )}
                    </Form.Item>

                    <Row gutter={24} type="flex" justify="center" align="top">
                        <Button.Group size="large">
                            <Button type="primary" htmlType="submit" onClick={() => this.saveProduct(true)}>
                                Termék mentése
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={() => this.saveProduct(false)}>
                                Mentés és szerkeszt
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Mentés és új létrehozása
                            </Button>
                            <Button onClick={() => this.props.history.push('/app/products-list')}>
                                Mégsem
                            </Button>
                        </Button.Group>
                    </Row>
                </Form>
            </div>
        )
    }
}

const ShopPriceCard = (props) => {
    let shopPrice = props.shopPrice;
    let index = props.index;
    let current = props.current;
    return (
        <Col span={24}>
            <Card title={"Webshop: " + shopPrice.shopName}
                  extra={<Button type="primary"
                                 onClick={() => current.handleShopPriceModal(shopPrice, index)}><EditOutlined/></Button>}>
                <p>Eladási ára: {shopPrice.price}</p>
                <p>ÁFA: {current.resolveTax(shopPrice.tax)}</p>
                <p>Árazási mód: {current.resolvePricingMethod(shopPrice.pricingMethod)}</p>
                {shopPrice.bandPrices == null &&
                <p>Érték: {shopPrice.value}</p>
                }
                <p>Kategóriák: {current.resolveCategoriesByShopName(shopPrice.shopName)}</p>
            </Card>
        </Col>
    )
}

export const PurchasePriceModal = (props) => {
    let current = props.current;
    return (
        <Modal visible={current.state.purchasePriceModalVisible}
               title={"Beszerzési ár hozzáadása"}
               okText={"Hozzáadás"}
               closable={false}
               cancelText={"Mégsem"}
               onCancel={current.handlePurchasePriceModal}
               onOk={current.savePurchasePrice}>

            <Select name="supplier"
                    style={{width: '100%'}}
                    placeholder={"Beszállító"}
                    onChange={e => current.purchasePriceValueChange("supplier", e)}>
                {current.state.suppliers.map(d => (
                    <Option key={d.id} value={d.id}>{d.name}</Option>
                ))}
            </Select>
            <Input name="price" type="number" placeholder="Ár"
                   onChange={e => current.purchasePriceValueChange("price", e.target.value)}/>
            <Select name="supplierCategory" placeholder="Kategória"
                    onChange={e => current.purchasePriceValueChange("supplierCategory", e)}
                    style={{width: '100%'}}>
                {current.state.supplierCategories.map(d => (
                    <Option key={d.name} value={d.name}>{d.name}</Option>
                ))}
            </Select>
            <Input name="supplierProductCode" placeholder="Beszállítói azonosító"
                   onChange={e => current.purchasePriceValueChange("supplierProductCode", e.target.value)}/>
            <Input name="internalStock" placeholder="Belső készlet"
                   onChange={e => current.purchasePriceValueChange("internalStock", e.target.value)}/>
            <Input name="externalStock" placeholder="Külső készlet"
                   onChange={e => current.purchasePriceValueChange("externalStock", e.target.value)}/>

        </Modal>
    )
}

export default Form.create({
    name: 'product-form'
})(ProductDetails);