import {Form, Input, InputNumber, Table} from 'antd';
import React from 'react';

const {Column} = Table;

const PurchaseOrderExpandedTable = (props) => {
    let {purchaseOrderLines} = props;
    let newMode = props.newMode != null ? props.newMode : false;
    let metaMode = props.metaMode != null ? props.metaMode : false;
    return (
        <Table size={"small"} dataSource={purchaseOrderLines} rowSelection={props.rowSelection} bordered={true}
               pagination={false} rowKey="productCode">


            <Column title="#" dataIndex="productCode"/>


            <Column title="Megnevezés" dataIndex="name" render={(text, record, index) => {
                if (props.editing) {
                    return (
                        <span>
                    <Form.Item style={{display: "none"}}>
                        {props.getFieldDecorator(`purchaseOrderLines[${(index)}].productCode`, {
                            rules: [{required: false}],
                            initialValue: record.productCode != null ? record.productCode : ""
                        })(
                            <Input hidden={true}/>
                        )}
                    </Form.Item>
                    <Form.Item style={{display: "none"}}>
                    {props.getFieldDecorator(`purchaseOrderLines[${(index)}].id`, {
                        rules: [{required: false}],
                        initialValue: record.id != null ? record.id : "",
                    })(
                        <Input hidden={true}/>
                    )}
                    </Form.Item>
                    <Form.Item style={{marginBottom: "0px"}}>
                    {props.getFieldDecorator(`purchaseOrderLines[${(index)}].name`, {
                        rules: [{required: true, message: 'Töltse ki a megnevezést!'}],
                        initialValue: text !== "" ? text : ""
                    })(
                        <Input /*disabled={ record.productId != null}*/ placeholder="Megnevezés"/>
                    )}
                    </Form.Item>
                </span>);
                } else {
                    return (text);
                }
            }}/>

            <Column title="Nettó beszerzési ár" dataIndex="purchasePrice" width={200}
                    render={(text, record, index) => {
                        if (props.editing) {
                            return (
                                <Form.Item style={{marginBottom: "0px"}}>
                                    {props.getFieldDecorator(`purchaseOrderLines[${(index)}].purchasePrice`, {
                                        rules: [{type: "number", message: "Nem összeget adott meg!"}],
                                        initialValue: record.purchasePrice != null ? record.purchasePrice : ""
                                    })(
                                        <InputNumber step={0.1} formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                     style={{width: "100%"}}  /*disabled={ record.productId != null}*/ />
                                    )}
                                </Form.Item>);
                        } else {
                            return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '));
                        }
                    }}/>

            <Column title="Bruttó eladási ár" dataIndex="sellPrice" width={200}
                    render={(text, record, index) => {
                        if (props.editing) {
                            return (
                                <Form.Item style={{marginBottom: "0px"}}>
                                    {props.getFieldDecorator(`purchaseOrderLines[${(index)}].sellPrice`, {
                                        rules: [{type: "number", message: "Nem összeget adott meg!"}],
                                        initialValue: text !== "" ? text : ""
                                    })(
                                        <InputNumber step={0.1} formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                     style={{width: "100%"}}/>
                                    )}
                                </Form.Item>);
                        } else {
                            return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '));
                        }
                    }}/>

            <Column title="Mennyiség" dataIndex="qty" width={200} render={(text, record, index) => {
                if (props.editing) {
                    return (
                        <Form.Item style={{marginBottom: "0px"}}>
                            {props.getFieldDecorator(`purchaseOrderLines[${(index)}].qty`, {
                                rules: [{type: "number", message: "Nem mennyiséget adott meg!"}],
                                initialValue: text !== "" ? text : ""
                            })(
                                <InputNumber step={0.1} style={{width: "100%"}}/>
                            )}
                        </Form.Item>);
                } else {
                    return (text);
                }
            }}/>

            <Column title="Állapot" dataIndex="statusName" width={200}/>

            {/*<Column title="Foglalás" dataIndex="productId" key="productId" render={(text, record,index) => {
            if(record.productId != null){
                return (<Button disabled={true}>Foglalás</Button>);
            }
        }}/>*/}

            {(!newMode && metaMode) && [
                <Column title="Létrehozva" dataIndex="createdDate" key="createdDate"/>,
                <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>,
                <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>,
                <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>

            ]
            }

            {metaMode && [
                <Column title="Létrehozva" dataIndex="createdDate" key="createdDate"/>,
                <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>,
                <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>,
                <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>
            ]

            }
        </Table>);


};

export default PurchaseOrderExpandedTable;
