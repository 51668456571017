import {Table} from 'antd';
import React from 'react';
import moment from "moment";

const {Column} = Table;

const RepairListExpandedTable = (props) => {
    let {repairLines} = props;
    console.log("expanded props: ", repairLines);

    return (
        <Table size={"small"} rowKey="id" dataSource={repairLines} rowSelection={props.rowSelection} bordered={true} pagination={false} expandedRowRender={record => Notes(record)}>

            <Column title="Termék megnevezése" dataIndex="productName" render={(text) => {
                return (text);
            }}/>

            <Column title="Tartozékok" dataIndex="accessories" render={(text) => {
                return (text);
            }}/>

            <Column title="Termék állapota" dataIndex="productCondition" render={(text) => {
                return (text);
            }}/>

            <Column title="Hiba leírása" dataIndex="problemDescription" render={(text) => {
                return (text);
            }}/>

        </Table>);
};

const Notes = (props) => {
    let {notes} = props;
    return (
    <Table dataSource={notes} bordered={true} pagination={false} rowKey="id">
        <Column title="Megjegyzés" dataIndex="value" />
        <Column title="Dátum" dataIndex="createdDate" render={(text) => {
            return (
                <span>{moment(text).format('YYYY. MM. DD hh:mm:ss a')}</span>
            );
        }} />
        <Column title="Létrehozta" dataIndex="createdBy" />
    </Table>)
}

export default RepairListExpandedTable;
