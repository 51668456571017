import queryString from 'querystring'

class QueryParams {

    pathParams = "";
    queryParams = "";

    setPaginationParams(current,pagesize) {
        if(this.queryParams === ""){
            this.queryParams = "?"+queryString.stringify({page: current, size : pagesize});
        }else{
            this.queryParams = this.queryParams + "&"+queryString.stringify({page: current, size : pagesize});
        }

        return this;
    }

    setCustomParams(params) {
        if(this.queryParams === ""){
            this.queryParams = "?"+queryString.stringify(params);
        }else{
            this.queryParams = this.queryParams+"&"+queryString.stringify(params);
        }
        return this;
    }

    addPathParam(param) {
        if(this.pathParams === "") {
            this.pathParams = param;
        }else{
            this.pathParams = this.pathParams +"/"+param;
        }
        return this;
    }

    getQueryParam(){
        return this.pathParams+this.queryParams;
    }
}

export default QueryParams;