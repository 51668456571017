import {Form, Input, Select} from 'antd';
import React, {Component} from 'react';

export const WebshopForm = Form.create({name: 'normal_webshop'})(
    class extends Component {

        state = {};

        //TODO NameCheck?

        render() {
            const {getFieldDecorator} = this.props.form;
            let urlLabel = "Webshop URL: " +
                "Pl. Magento: https://magento2.test/index.php/rest/{StoreCode}/V1/ " +
                "Pl. Presta: http://localhost:8090/api/" +
                "Pl. Presta: http://vasalatdev.eagent.hu/api.php/"
            return (<Form onSubmit={this.handleSubmit} layout={'vertical'} className="webshop-form">

                <Form.Item label="Webshop neve">
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: 'Kérjük töltse ki a nevet!'}],
                        initialValue: this.props.name,
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <Form.Item label="Technikai felhasználó neve">
                    {getFieldDecorator('username', {
                        initialValue: this.props.username,
                    })(
                        <Input/>
                    )}
                </Form.Item>

                <Form.Item label="Technikai felhasználó jelszava">
                    {getFieldDecorator('password', {
                        initialValue: this.props.password,
                    })(
                        <Input/>
                    )}
                </Form.Item>

                <Form.Item label={urlLabel}>
                    {getFieldDecorator('url', {
                        rules: [{required: true, message: 'Kérjük töltse ki az URL-t!'}],
                        initialValue: this.props.url,
                    })(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item label="Típus">
                    {getFieldDecorator('type', {
                        rules: [{required: true, message: 'Kérjük válasszon!'}],
                        initialValue: this.props.type,
                    })(
                        <Select>
                            <Select.Option key="magento" value="magento">Magento</Select.Option>
                            <Select.Option key="presta" value="presta">Presta</Select.Option>
                            <Select.Option key="eagent" value="eagent">Eagent</Select.Option>
                        </Select>
                    )}
                </Form.Item>
            </Form>)
        }
    }
)