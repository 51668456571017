import React, {Component} from "react";
import {Divider, Form, Input, Modal, Row, Select, Spin, Tag, Tree} from "antd";
import PriceRangeList from "./PriceRangeList";
import {divide, multiply, sum} from "../Utils/CalculateUtils";

const {TreeNode} = Tree;

export const ShopPriceModal = Form.create({name: 'form_in_modal'})(
    class extends Component {
        state = {
            priceCalcType: 1,
            priceRanges: [],
            checkedCategories: [],
            tax: 1,
            price: 0,
            sellPrice: 0
        }

        updatePriceRanges = (e) => {
            this.setState({priceRanges: e}, () => this.setSellPrice());
        };

        onOk = () => {
            this.props.onCreate(this.state.checkedCategories, this.state.priceRanges, this.state.sellPrice);
            this.setState({priceCalcType: 1, priceRanges: [], checkedCategories: []});
        }

        setSellPrice = () => {
            let purchasePrice = typeof this.props.editObject.lowestPrice !== "undefined" ? this.props.editObject.lowestPrice : 0;
            let sellPrice = 0;
            switch (this.state.priceCalcType) {
                case 1: {
                    sellPrice = sum(multiply(this.state.tax, purchasePrice), this.state.price).toFixed(0);
                    break;
                }
                case 2: {
                    sellPrice = multiply(multiply(this.state.tax, purchasePrice), sum(1, divide(this.state.price, 100))).toFixed(0);
                    break;
                }

                case 3: {
                    console.log("setting with type 3")
                    let grossPrice = multiply(this.state.tax, purchasePrice);
                    this.state.priceRanges.forEach(priceRange => {
                        if (grossPrice > priceRange.from && grossPrice < priceRange.to) {
                            sellPrice = sum(grossPrice, priceRange.value);
                        }
                    });

                    if (sellPrice === 0 && typeof this.state.priceRanges[0] !== "undefined") {
                        sellPrice = sum(grossPrice, this.state.priceRanges[this.state.priceRanges.length - 1].value);
                    }
                    sellPrice = Number(sellPrice).toFixed(0);
                    break;
                }
                case 4: {
                    sellPrice = Number(this.state.price).toFixed(0);
                    break;
                }
                default:
                    break;
            }
            sellPrice = Math.round(sellPrice / 10) * 10;
            this.setState({sellPrice});
        };

        render() {
            const renderTreeNodes = data =>
                data.map(item => {
                    if (item.children) {
                        return (
                            <TreeNode title={item.title} key={item.key} dataRef={item}
                                      disableCheckbox={!item.selectable}>
                                {renderTreeNodes(item.children)}
                            </TreeNode>
                        );
                    }
                    return <TreeNode key={item.key} {...item} disableCheckbox={!item.selectable}/>;
                });
            const webShops = this.props.webShops != null ? this.props.webShops : [];
            const webShopCategories = this.props.webShopCategories != null ? this.props.webShopCategories : [];
            const {visible, onCancel, form} = this.props;
            const {getFieldDecorator} = form;
            return (
                <Modal
                    visible={visible}
                    title="Új eladási ár létrehozása"
                    okText="Létrehozás"
                    cancelText="Mégsem"
                    onCancel={onCancel}
                    onOk={this.onOk}
                >
                    <Form>
                        <Form.Item label={'Webshop'}>
                            {getFieldDecorator("shopName", {
                                rules: [{required: true, message: "Webshop megadása kötelező!"}],
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.shopName : ""
                            })(
                                <Select name="shopName" style={{width: '100%'}} onChange={this.props.onWebShopChange}>
                                    {webShops.map(d => (
                                        <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        {!this.props.selectedShopPrice &&
                        <div>
                            <Spin spinning={false} tip="Webshop kategóriák betöltése...">
                                Webshop kategóriák:
                                <Tree checkable onCheck={e => this.setState({checkedCategories: e})}>
                                    {renderTreeNodes(webShopCategories)}
                                </Tree>
                            </Spin>
                        </div>
                        }
                        {this.props.selectedShopPrice &&
                        <div>
                            <Row>Webshop kategóriák:</Row>
                            {this.props.editObject.categories.map(category => (
                                <Tag closable={true} key={category.name}>{category.name}</Tag>
                            ))}
                        </div>}

                        <Form.Item label={'ÁFA'}>
                            {getFieldDecorator("tax", {
                                rules: [{required: true, message: "ÁFA megadása kötelező!"}],
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.tax : 1.27
                            })(
                                <Select name="tax" placeholder="ÁFA" style={{width: '100%'}}
                                        onChange={e => this.setState({tax: e}, () => this.setSellPrice())}>
                                    <Select.Option key={1} value={1.27}>27%</Select.Option>
                                    <Select.Option key={2} value={1.25}>25%</Select.Option>
                                    <Select.Option key={3} value={1}>0%</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={'Árazási mód'}>
                            {getFieldDecorator("pricingMethod", {
                                rules: [{required: true, message: "Árazási mód megadása kötelező!"}],
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.pricingMethod : 1
                            })(
                                <Select placeholder="Árazási mód" style={{width: "100%"}}
                                        onChange={e => this.setState({priceCalcType: e}, () => this.setSellPrice())}>
                                    <Select.Option key={1} value={1}>Egyszerű mód</Select.Option>
                                    <Select.Option key={2} value={2}>Százalékos mód</Select.Option>
                                    <Select.Option key={3} value={3}>Sávos mód</Select.Option>
                                    <Select.Option key={4} value={4}>Fix mód ( Bruttó megadása )</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        {this.state.priceCalcType !== 3 &&
                        <Form.Item label={'Ár'}>
                            {getFieldDecorator("price", {
                                rules: [{required: true, message: "Ár megadása kötelező!"}],
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.value : ""
                            })(<Input type={'number'}
                                      onInput={e => this.setState({price: e.target.value}, () => this.setSellPrice())}/>)}
                        </Form.Item>
                        }

                        {this.state.priceCalcType === 3 &&
                        <div>
                            <Divider type="horizontal"/>
                            <PriceRangeList updateParentComponent={this.updatePriceRanges}
                                            initialValues={this.props.selectedShopPrice ? this.props.selectedShopPrice.priceRanges : undefined}/>
                        </div>
                        }

                        <Form.Item label={'Eladási Ár'}>
                            {getFieldDecorator("sellPrice", {
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.price : this.state.sellPrice
                            })(<Input type={'number'} disabled={true}/>)}
                        </Form.Item>

                        <Form.Item style={{hidden: true}}>
                            {getFieldDecorator('id', {
                                rules: [{required: false}],
                                initialValue: this.props.selectedShopPrice ? this.props.selectedShopPrice.id : "",
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            );
        }
    },
);