import API from "./API";

const SiteUtils = {
    getSiteTitleByKey: async function(value) {
        console.log(value);
        return API.get("getSiteByValue/"+value);
    },

    canIUseThisSite: async function(value) {
        return API.get("can-i-use-this-site/"+value);
    }
};

export default SiteUtils;