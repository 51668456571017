import {Form, Input, Select, TreeSelect} from 'antd';
import React, {Component} from 'react';
import API from "../Utils/API";

const { Option } = Select;

class CategoryForm extends Component {

    state = {
        parents : [],
        webshops: [],
    };

    componentDidMount() {
        API.get("productPrice/getWebshops").then(res => {
            this.setState({webshops: res.data})
        });
    }

    onChangeWebshop = (e) => {
        API.get("productPrice/webshopCategoryTree/"+e).then(res => {
            this.setState({parents: res.data})
            console.log("categoryTree: ",res.data)
        });
    };

    render() {
        const { getFieldDecorator} = this.props.form;
        return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="partner-form">

            <Form.Item label="Név" hasFeedback>
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Kérjük töltese ki a kategória nevet!' }],
                })(
                    <Input onBlur={this.checkName} />,
                )}
            </Form.Item>
            <Form.Item label="Webshop">
                {getFieldDecorator('webshop', {
                    rules: [{ required: true, message: 'Kérjük válasszon webshop-ot!' }],
                })(
                    <Select placeholder="Webshop" onChange={this.onChangeWebshop}>

                        {this.state.webshops.map(d => (
                            <Option key={d.id} value={d.id} disabled={d.type === "eagent"}>{d.name}</Option>
                        ))}

                    </Select>,
                )}
            </Form.Item>
            <Form.Item label="Szülő">
                {getFieldDecorator('parent', {
                    rules: [{ message: 'Kérjük válasszon szülőt!' }],
                })(

                    <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Kategória"
                        treeDefaultExpandAll
                        filterTreeNode={(input, option) =>
                            option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        treeData={this.state.parents}
                    >
                    </TreeSelect>

                )}
            </Form.Item>
        </Form>
        );
    }
}

export default Form.create({ name: 'category_form' ,  mapPropsToFields(props) {
            return {
            name: Form.createFormField({
            ...props.name,
            value: props.name,
            }),
            webshop: Form.createFormField({
                ...props.name,
                value: props.name,
            }),
            parent: Form.createFormField({
                ...props.name,
                value: props.name,
            })
        };
        },})(CategoryForm);

