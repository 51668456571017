import {Form, Select} from "antd";
import React, {Component} from "react";

const {Option} = Select;

class SiteSelector extends Component {

    state = {
        selectedId: "",
        sites: []
    };

    componentDidMount() {
        this.setState({sites: JSON.parse(localStorage.getItem('sites'))});
    }

    render() {
        let component;
        if (this.props.form) {
            const {getFieldDecorator} = this.props.form;
            component = <Form.Item label="Hova:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                {getFieldDecorator('sites', {
                    initialValue: this.props.selectedValue,
                    rules: [
                        {required: true, message: 'Válasszon telephelyet!'},
                    ],
                })(
                    <Select style={{width: "80%"}} placeholder="Telephely" onChange={this.props.changeSite}>
                        {this.state.sites.map(d => (
                            <Option key={d.nameKey} value={d.nameKey}>{d.title}</Option>
                        ))}
                    </Select>
                )}
            </Form.Item>;
        } else {
            component = <Select placeholder="Telephely" defaultValue={this.props.selected} style={{width: "100%"}}
                                onSelect={this.props.onSelect} onChange={this.props.changeSite}>

                {this.state.sites.map(d => (
                    <Option key={d.id} value={d.id}>{d.title}</Option>
                ))}

                {this.props.all &&
                <Option value={"all"}>Összes</Option>
                }

            </Select>;
        }

        return component;

    }
}

export default SiteSelector;


