import React, {Component} from 'react';
import API from "../../Utils/API";
import {Table, Button, Col, Divider, Popconfirm, Icon, Row, Card} from "antd";
import Column from "antd/es/table/Column";
import Tag from "antd/es/tag";
import UserDrawer from "./UserDrawer";

class UserSettings extends Component {

    componentDidMount() {
        this.getUsers();
    }

    availableTags = [];

    state = {
        showUserDrawer: false,
        selectedRowKeys: [],
        selectedRows: [],
        tableLoading: false,
        editId: "",
        users: []
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
        },
        type: 'radio'
    };

    getUsers() {
        this.setState({tableLoading: true});
        API.get("settings/getUsers")
            .then(res => {
                this.setState({users: res.data.lines});
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    handleTableChange() {
        this.getUsers();
        // TODO Set radio button value to null
    }

    addUser = () => {
        this.setState({showUserDrawer: true, editId: ""})
    };

    onCloseUser = () => {
        this.setState({showUserDrawer: false});
        this.handleTableChange()
    };

    editUser = () => {
        let {selectedRows} = this.state;
        let selectedId = selectedRows.map(row => {
            return row.id;
        });
        this.setState({showUserDrawer: true, editId: selectedId})
    };

    inactivateUser = () => {
        let {selectedRows} = this.state;
        let selectedId = selectedRows.map(row => {
            return row.id;
        });
        API.post("settings/inactivateUser/" + selectedId)
            .then(() => {
                this.handleTableChange();
            })
    };

    render() {
        const {selectedRowKeys} = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Card title="Felhasználók">
                    <Row>
                        <Col offset={1}>
                            <Button onClick={this.addUser} type="primary">
                                Új felvétel
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={this.editUser} disabled={!hasSelected} type="primary">
                                Felhasználó szerkesztése
                            </Button>
                            <Divider type="vertical"/>
                            <Popconfirm
                                title={"Biztos a letiltásban? A felhasználó nem fog tudni bejelentkezni!"}
                                icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
                                onConfirm={this.inactivateUser}
                                okText="Igen"
                                cancelText="Nem"
                                disabled={!hasSelected}
                            >
                                <Button disabled={!hasSelected} type="danger">Felhasználó letiltása</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.users}
                        pagination={false}
                        bordered={true}
                        rowSelection={this.rowSelection}
                        rowKey={"id"}
                    >
                        <Column title="ID" dataIndex="id" key="id"/>
                        <Column title="Teljes név" dataIndex="fullName" key="fullName"/>
                        <Column title="Felhasználónév" dataIndex="username" key="username"/>
                        <Column
                            title="Jogosultságok"
                            dataIndex="roles"
                            key="roles"
                            render={tags => (
                                <span>
                                  {tags.map(tag => (
                                      <Tag color="blue" key={tag.id}>
                                          {tag.name}
                                      </Tag>
                                  ))}
                                </span>)}
                        />
                    </Table>
                </Card>
                <UserDrawer visible={this.state.showUserDrawer} onClose={this.onCloseUser} editId={this.state.editId}/>
            </div>
        )
    }
}

export default UserSettings;