import {
    SET_META_MODE,
    SET_NEWLY_CREATED_PARTNER,
    SET_SELECTED_SITE,
    SET_USER,
    UNSET_NEWLY_CREATED_PARTNER,
    SET_PRODUCTS_FOR_SALE,
    SET_DOC_NUMBERS,
    SET_DELIVERY_TYPES,
    SET_PAYMENT_TYPES,
    SET_TAX_VALUES,
    SET_DOC_TYPES,
    SET_USERS, SET_SITES
} from "../ActionTypes";

export function setSelectedSite(payload) {
    return {type: SET_SELECTED_SITE, payload}
}

export function setNewlyCreatedPartner(payload) {
    return {type: SET_NEWLY_CREATED_PARTNER, payload}
}

export function unsetNewlyCreatedPartner() {
    return {type: UNSET_NEWLY_CREATED_PARTNER}
}

export function setMetaMode(payload) {
    return {type: SET_META_MODE, payload}
}

export function setUser(payload) {
    return {type: SET_USER, payload}
}

export function setProductsForSale(payload) {
    return {type: SET_PRODUCTS_FOR_SALE, payload}
}

export function setDocNumbers(payload) {
    return {type: SET_DOC_NUMBERS, payload}
}

export function setDeliveryTypes(payload) {
    return {type: SET_DELIVERY_TYPES, payload}
}

export function setPaymentTypes(payload) {
    return {type: SET_PAYMENT_TYPES, payload}
}

export function setTaxValues(payload) {
    return {type: SET_TAX_VALUES, payload}
}

export function setDocTypes(payload) {
    return {type: SET_DOC_TYPES, payload}
}

export function setUsers(payload) {
    return {type: SET_USERS, payload}
}

export function setSites(payload) {
    return {type: SET_SITES, payload}
}