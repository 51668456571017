export function CalculateUtils(e, type, index, form, orderLines, taxes, source) {
    let productLines = [...orderLines];
    let taxList = [...taxes];

    let line = {...productLines[index]};

    let productCode = form.getFieldValue(source + "[" + index + "].productCode");
    let name = form.getFieldValue(source + "[" + index + "].name");
    let isPoRequired = form.getFieldValue(e + "[" + index + "].isPoRequired");

    let qty = Math.abs(orderLines[index].qty);
    let grossSellPrice = form.getFieldValue(source + "[" + index + "].grossSellPrice");
    let netSellPrice = form.getFieldValue(source + "[" + index + "].netSellPrice");
    let netPurchasePrice = form.getFieldValue(source + "[" + index + "].netPurchasePrice");
    let taxId = form.getFieldValue(source + "[" + index + "].taxId");

    if (typeof taxId === "undefined") {
        taxId = 1;
    }
    let taxValue = taxList.find(tax => (tax.id === taxId)).value;
    let toParse;

    switch (type) {
        case "productCode":
            productCode = e.target.value;
            break;
        case "name":
            name = e.target.value;
            break;
        case "isPoRequired":
            isPoRequired = e;
            console.log(e)
            break;
        case "tax":
            taxId = e;
            taxValue = taxList.find(tax => (tax.id === taxId)).value;
            break;
        case "netPurchasePrice":
            netPurchasePrice = e;
            break;
        case "qty":
            qty = e;
            break;
        case "grossSellPrice":
            grossSellPrice = e;
            toParse = grossSellPrice / taxValue;
            netSellPrice = parseFloat(toParse.toString()).toFixed(2);
            break;
        case "netSellPrice":
            netSellPrice = e;
            toParse = netSellPrice * taxValue;
            grossSellPrice = parseFloat(toParse.toString()).toFixed(2);
            break;
        default:
            break;
    }

    orderLines[index].productCode = productCode;
    orderLines[index].name = name;
    orderLines[index].isPoRequired = isPoRequired;
    orderLines[index].qty = qty;
    orderLines[index].netSellPrice = isNaN(parseFloat(netSellPrice)) ? 0 : parseFloat(netSellPrice);
    orderLines[index].grossSellPrice = isNaN(parseFloat(grossSellPrice)) ? 0 : parseFloat(grossSellPrice);
    orderLines[index].netPurchasePrice = isNaN(parseFloat(netPurchasePrice)) ? 0 : parseFloat(netPurchasePrice);

    let sumGrossPrice = grossSellPrice * qty;
    let sumNetPrice = netSellPrice * qty;
    let profit = (netSellPrice - netPurchasePrice) * qty;
    let netProfitPerUnit = netSellPrice - netPurchasePrice;
    let netProfit = netProfitPerUnit * qty;
    let grossProfitPerUnit = grossSellPrice - (netPurchasePrice * 1.27);
    let grossProfit = grossProfitPerUnit * qty;

    orderLines[index].productCode = productCode;
    orderLines[index].name = name;
    orderLines[index].sumGrossPrice = isNaN(parseFloat(sumGrossPrice.toString())) ? 0 : parseFloat(sumGrossPrice.toString()).toFixed(2);
    orderLines[index].sumNetPrice = isNaN(sumNetPrice) ? 0 : parseFloat(sumNetPrice.toString()).toFixed(2);
    orderLines[index].profit = isNaN(parseFloat(profit.toString())) ? 0 : parseFloat(profit.toString()).toFixed(2);
    orderLines[index].netProfitPerUnit = isNaN(parseFloat(netProfitPerUnit.toString())) ? 0 : parseFloat(netProfitPerUnit.toString()).toFixed(2);
    orderLines[index].netProfit = isNaN(parseFloat(netProfit.toString())) ? 0 : parseFloat(netProfit.toString()).toFixed(2);
    orderLines[index].grossProfitPerUnit = isNaN(parseFloat(grossProfitPerUnit.toString())) ? 0 : parseFloat(grossProfitPerUnit.toString()).toFixed(2);
    orderLines[index].grossProfit = isNaN(parseFloat(grossProfit.toString())) ? 0 : parseFloat(grossProfit.toString()).toFixed(2);

    line.productCode = productCode;
    line.name = name;
    line.isPoRequired = isPoRequired;
    line.qty = qty;
    line.netSellPrice = isNaN(parseFloat(netSellPrice)) ? 0 : parseFloat(netSellPrice);
    line.grossSellPrice = isNaN(parseFloat(grossSellPrice)) ? 0 : parseFloat(grossSellPrice);
    line.netPurchasePrice = isNaN(parseFloat(netPurchasePrice)) ? 0 : parseFloat(netPurchasePrice);
    line.sumNetPrice = isNaN(sumNetPrice) ? 0 : parseFloat(sumNetPrice.toString()).toFixed(2);
    line.profit = isNaN(parseFloat(profit.toString())) ? 0 : parseFloat(profit.toString()).toFixed(2);
    line.netProfitPerUnit = isNaN(parseFloat(netProfitPerUnit.toString())) ? 0 : parseFloat(netProfitPerUnit.toString()).toFixed(2);
    line.netProfit = isNaN(parseFloat(netProfit.toString())) ? 0 : parseFloat(netProfit.toString()).toFixed(2);
    line.grossProfitPerUnit = isNaN(parseFloat(grossProfitPerUnit.toString())) ? 0 : parseFloat(grossProfitPerUnit.toString()).toFixed(2);
    line.grossProfit = isNaN(parseFloat(grossProfit.toString())) ? 0 : parseFloat(grossProfit.toString()).toFixed(2);
    line.sumGrossPrice = isNaN(parseFloat(sumGrossPrice.toString())) ? 0 : parseFloat(sumGrossPrice.toString()).toFixed(2);

    productLines[index] = line;

}

const sumFunction = new Function('x', 'y', 'return Number(x) + Number(y)');

export function sum(x, y) {
    return sumFunction(x, y)
}

const subtractFunction = new Function('x', 'y', 'return Number(x) - Number(y)');

export function subtract(x, y) {
    return subtractFunction(x, y)
}

const multiplyFunction = new Function('x', 'y', 'return Number(x) * Number(y)');

export function multiply(x, y) {
    return multiplyFunction(x, y)
}

const divideFunction = new Function('x', 'y', 'return Number(x) / Number(y)');

export function divide(x, y) {
    return divideFunction(x, y)
}
