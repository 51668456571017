import {Button, Divider, Form, Input, Modal, Popconfirm, Table} from 'antd';
import React, {useState} from 'react';
import TextArea from "antd/es/input/TextArea";
import {connect} from "react-redux";
import moment from "moment";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import {QuestionCircleOutlined} from "@ant-design/icons";

const {Column} = Table;

const RepairLineTable = (props) => {
    const [selectedNote, setSelectedNote] = useState(null);
    const [modalOpened, setModal] = useState(false);
    let {repairLines} = props;
    let {edit} = props;
    let {rowSelection} = props;

    const Notes = (props) => {
        const {record} = props;
        let metaMode = props.props.metaMode != null ? props.props.metaMode : false;
        return (
            <>
                {Object.keys(record.notes).length > 0 &&
                <>
                    <Table dataSource={record.notes} pagination={false} rowKey={record => record.id}>
                        {metaMode &&
                        <Column title="#" dataIndex="id" width={100} key="id" render={(text) => {
                            return (
                                <span>{text}</span>
                            );
                        }}/>}
                        <Column title="Megjegyzés" dataIndex="value" key="value" render={(text) => {
                            return (
                                <span>{text}</span>
                            );
                        }}/>
                        <Column title="Dátum" dataIndex="createdDate" width={130} key="createdDate"
                                render={(text) => {
                                    return (
                                        <span>{moment(text).format('YYYY. MM. DD hh:mm:ss a')}</span>
                                    );
                                }}/>
                        <Column title="Létrehozta" dataIndex="createdBy" width={120} key="createdBy"
                                render={(text) => {
                                    return (
                                        <span>{text}</span>
                                    );
                                }}/>
                        {metaMode && [
                            <Column title="Módosítva" dataIndex="modifiedDate" width={100} key="modifiedDate"
                                    render={(text) => {
                                        return (
                                            <span>{text}</span>
                                        );
                                    }}/>,
                            <Column title="Módosította" dataIndex="modifiedBy" width={100} key="modifiedBy"
                                    render={(text) => {
                                        return (
                                            <span>{text}</span>
                                        );
                                    }}/>]}
                        <Column title="Szerk/Törlés" key="operation" width={150} render={(text, record, index) => {
                            return (
                                <>
                                    <span>
                                        <span className="link" key={`[${(index)}].edit`}>
                                            <EditOutlined style={{fontSize: 25}}
                                                          onClick={() => {
                                                              setSelectedNote(record)
                                                              setModal(true)
                                                          }}/>
                                        </span>
                                        <Divider type="vertical"/>
                                        <span className="link" key={`[${(index)}].delete`}>
                                            <DeleteOutlined style={{fontSize: 25, color: "darkred"}}
                                                            onClick={() => {
                                                                setSelectedNote(record)
                                                                deleteNote(record.id)
                                                            }}/>
                                        </span>
                                    </span>
                                </>);
                        }}/>
                    </Table>
                    <NoteModal props={props}/>
                </>}
            </>)
    }

const NoteModal = (props) =>
    {
        console.log("NoteModal rendered", props)
        return (
            <Modal
                title={selectedNote != null && selectedNote.id != null ? "Megjegyzés szerkesztése" : "Megjegyzés felvétele"}
                visible={modalOpened === true}
                cancelText={"Mégsem"}
                onCancel={() => {
                    setModal(false)
                    setSelectedNote(null)
                }}
                okText={"Mentés"}
                onOk={() => saveNote(props)}
            >
                {selectedNote != null && [
                    <TextArea defaultValue={selectedNote.value} key={`note_modal_${selectedNote.id}`}
                              onChange={e => selectedNote.value = e.target.value}
                              rows={6}/>
                ]}
                {selectedNote == null &&
                <TextArea onChange={e => selectedNote.value = e.target.value}
                          rows={6} key={"note_modal"}/>
                }
            </Modal>)
    }

const saveNote = () =>
    {
        API.post("repair/saveLineNote", selectedNote).then(res => {
            props.reloadTable(props.repairLines[0].repairId);
            setSelectedNote(null);
            setModal(false);
            NotificationUtil(res.data);
        }).catch(() => {
            setSelectedNote(null);
            setModal(false);
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    }

const deleteNote = (id) =>
    {
        Modal.confirm({
            title: 'Biztosan törölni szeretné a kijelölt megjegyzést?',
            content: 'A törlés nem visszavonható!',
            onOk() {
                API.post("repair/deleteLineNote/" + id).then(res => {
                    setSelectedNote(null);
                    setModal(false);
                    NotificationUtil(res.data);
                    window.location.reload(false);
                }).catch(() => {
                    setSelectedNote(null);
                    setModal(false);
                    NotificationUtil({msg: "Hiba történt!", type: "error"});
                });
            }
        });
    }

    return (
        <>
            <Table size={"small"} dataSource={repairLines} rowKey={record => record.id} bordered={true} pagination={false} rowSelection={rowSelection}
                   expandedRowRender={record => edit ? (<Notes record={record} props={props}/>) : undefined}>

                <Column title="Termék megnevezése" dataIndex="productName" key="productName"
                        render={(text, record, index) => {
                            if (edit) {
                                return (
                                    <span>{record.productName}</span>)
                            } else {
                                return (
                                    <span>
                                    <Form.Item style={{marginBottom: "0px"}}>
                                    {props.getFieldDecorator(`repairLines[${(index)}].productName`, {
                                        rules: [{required: true, message: 'Töltse ki a megnevezést!'}],
                                        initialValue: text !== "" ? text : ""
                                    })(
                                        <TextArea onChange={e => props.onChange(e, index, "productName")}
                                                  autoSize={{maxRows: 6}}/>
                                    )}
                                    </Form.Item>
                                    <Form.Item style={{display: "none"}}>
                                    {props.getFieldDecorator(`repairLines[${(index)}].id`, {
                                        rules: [{required: false}],
                                        initialValue: record.id != null ? record.id : "",
                                    })(
                                        <Input hidden={true}/>
                                    )}
                                    </Form.Item>
                                </span>
                                )
                            }
                        }}/>

                <Column title="Tartozékok" dataIndex="accessories" key="accessories" render={(text, record, index) => {
                    if (edit) {
                        return (
                            <span>{record.accessories}</span>)
                    } else {
                        return (
                            <Form.Item style={{marginBottom: "0px"}}>
                                {props.getFieldDecorator(`repairLines[${(index)}].accessories`, {
                                    initialValue: text !== "" ? text : ""
                                })(
                                    <TextArea onChange={e => props.onChange(e, index, "accessories")}
                                              autoSize={{maxRows: 6}}/>,
                                )}
                            </Form.Item>)
                    }
                }}/>

                <Column title="Termék állapota" dataIndex="productCondition" key="productCondition"
                        render={(text, record, index) => {
                            if (edit) {
                                return (
                                    <span>{record.productCondition}</span>)
                            } else {
                                return (
                                    <Form.Item style={{marginBottom: "0px"}}>
                                        {props.getFieldDecorator(`repairLines[${(index)}].productCondition`, {
                                            initialValue: text !== "" ? text : ""
                                        })(
                                            <TextArea onChange={e => props.onChange(e, index, "productCondition")}
                                                      autoSize={{maxRows: 6}}/>,
                                        )}
                                    </Form.Item>)
                            }
                        }}/>

                <Column title="Hiba leírása" dataIndex="problemDescription" key="problemDescription"
                        render={(text, record, index) => {
                            if (edit) {
                                return (
                                    <span>{record.problemDescription}</span>)
                            } else {
                                return (
                                    <Form.Item style={{marginBottom: "0px"}}>
                                        {props.getFieldDecorator(`repairLines[${(index)}].problemDescription`, {
                                            rules: [{required: true, message: 'Adja meg a hiba leírását!'}],
                                            initialValue: text !== "" ? text : ""
                                        })(
                                            <TextArea onChange={e => props.onChange(e, index, "problemDescription")}
                                                      autoSize={{maxRows: 6}}/>,
                                        )}
                                    </Form.Item>)
                            }
                        }}/>

                <Column title="Megjegyzés" dataIndex="note" key="note" render={(text, record, index) => {
                    if (edit) {
                        return (
                            <Button onClick={() => {
                                setModal(true);
                                setSelectedNote({type: "NOTE", repairLineId: record.id})
                            }
                            }>
                                <PlusOutlined/> Megjegyzés felvétele</Button>)
                    } else {
                        return (
                            <Form.Item style={{marginBottom: "0px"}}>
                                {props.getFieldDecorator(`repairLines[${(index)}].note`, {
                                    initialValue: text !== "" ? text : ""
                                })(
                                    <TextArea onChange={e => props.onChange(e, index, "note")} autoSize={{maxRows: 6}}/>
                                )}
                            </Form.Item>)
                    }
                }}/>
                {(!props.edit && props.repairLines.length !==  1) &&
                    <Column title="Törlés" key="operation" width={150} render={(text, record, index) => {
                        return (
                            <span className="link" key={`[${(index)}].delete`}>
                                <Popconfirm
                                    title="Biztos a törlésben?"
                                    icon={<QuestionCircleOutlined color={"red"}/>}
                                    onConfirm={() => props.removeLine(record,
                                        index,
                                        [`repairLines[${(index)}].productName`,
                                            `repairLines[${(index)}].accessories`,
                                            `repairLines[${(index)}].productCondition`,
                                            `repairLines[${(index)}].problemDescription`,
                                            `repairLines[${(index)}].note`])}
                                    okText="Igen"
                                    cancelText="Nem"
                                >
                                    <DeleteOutlined style={{fontSize: 25}}/>
                                </Popconfirm>
                            </span>
                        );
                    }}/>}
            </Table>
            <NoteModal props={props}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        metaMode: state.metaMode
    };
}

export default connect(mapStateToProps, null)(RepairLineTable);
