import {
    SET_META_MODE,
    SET_NEWLY_CREATED_PARTNER,
    SET_PRODUCTS_FOR_SALE,
    SET_SELECTED_SITE,
    SET_USER,
    UNSET_NEWLY_CREATED_PARTNER,
    SET_DOC_NUMBERS,
    SET_DELIVERY_TYPES, SET_PAYMENT_TYPES, SET_TAX_VALUES, SET_DOC_TYPES, SET_USERS, SET_SITES
} from "../ActionTypes";
import {localstorageToBool} from "../Utils/Utils";

let initialState = {
    selectedSite: {
        title: localStorage.getItem('site') ? JSON.parse(localStorage.getItem('site')).title : "",
        value: localStorage.getItem('site') ? JSON.parse(localStorage.getItem('site')).value : "",
    },
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "",
    newlyCreatedPartner: {},
    metaMode: localStorage.getItem('metaMode') !== null ? localstorageToBool('metaMode') : false,
    productsForSale: localStorage.getItem('productsForSale') ? JSON.parse(localStorage.getItem('productsForSale')) : [],
    docNumbers: localStorage.getItem('docNumbers') ? JSON.parse(localStorage.getItem('docNumbers')) : [],
    deliveryTypes: localStorage.getItem('deliveryTypes') ? JSON.parse(localStorage.getItem('deliveryTypes')) : [],
    paymentTypes: localStorage.getItem('paymentTypes') ? JSON.parse(localStorage.getItem('paymentTypes')) : [],
    taxValues: localStorage.getItem('taxValues') ? JSON.parse(localStorage.getItem('taxValues')) : [],
    docTypes: localStorage.getItem('docTypes') ? JSON.parse(localStorage.getItem('docTypes')) : [],
    users: localStorage.getItem('users') ? JSON.parse(localStorage.getItem('users')) : [],
    sites: localStorage.getItem('sites') ? JSON.parse(localStorage.getItem('sites')) : []
};

function rootReducer(state = initialState, action) {
    if (action.type === SET_SELECTED_SITE) {
        localStorage.setItem("site", JSON.stringify(action.payload));
        return Object.assign({}, state, {
            selectedSite: action.payload
        });
    }

    if (action.type === SET_NEWLY_CREATED_PARTNER) {
        return Object.assign({}, state, {
            newlyCreatedPartner: action.payload
        });
    }

    if (action.type === SET_META_MODE) {
        localStorage.setItem("metaMode", action.payload);
        return Object.assign({}, state, {
            metaMode: action.payload
        });
    }

    if (action.type === UNSET_NEWLY_CREATED_PARTNER) {
        return Object.assign({}, state, {
            newlyCreatedPartner: ""
        });
    }

    if (action.type === SET_USER) {
        localStorage.setItem("user", JSON.stringify(action.payload));
        return Object.assign({}, state, {
            user: action.payload
        });
    }

    if (action.type === SET_PRODUCTS_FOR_SALE) {
        localStorage.setItem('productsForSale', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            productsForSale: action.payload
        });
    }

    if (action.type === SET_DOC_NUMBERS) {
        localStorage.setItem('docNumbers', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            docNumbers: action.payload
        });
    }

    if (action.type === SET_DELIVERY_TYPES) {
        localStorage.setItem('deliveryTypes', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            deliveryTypes: action.payload
        });
    }

    if (action.type === SET_PAYMENT_TYPES) {
        localStorage.setItem('paymentTypes', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            paymentTypes: action.payload
        });
    }

    if (action.type === SET_TAX_VALUES) {
        localStorage.setItem('taxValues', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            taxValues: action.payload
        });
    }

    if (action.type === SET_DOC_TYPES) {
        localStorage.setItem('docTypes', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            docTypes: action.payload
        });
    }

    if (action.type === SET_USERS) {
        localStorage.setItem('users', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            users: action.payload
        });
    }

    if (action.type === SET_SITES) {
        localStorage.setItem('sites', JSON.stringify(action.payload));
        return Object.assign({}, state, {
            sites: action.payload
        });
    }

    return state;
}

export default rootReducer;