import React, {Component} from "react";
import {Menu} from "antd";
import {AppstoreOutlined, MailOutlined} from "@ant-design/icons";
import DocNumberList from "../../Components/DocNumberList";
import NavSettings from "../../Components/NavSettings";
import NavExport from "../../Components/NavExport";

class InvoiceSettings extends Component {

    state = {
        current: 'docNumbers'
    }

    handleClick = e => {
        this.setState({current: e.key});
    }

    render() {
        const {current} = this.state;
        return (
            <div>
                <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
                    <Menu.Item key="docNumbers" icon={<AppstoreOutlined/>} style={{width: "33%"}}>
                        Számlatömbök kezelése
                    </Menu.Item>
                    <Menu.Item key="navSettings" icon={<MailOutlined/>} style={{width: "33%"}}>
                        NAV Beállítások
                    </Menu.Item>
                    <Menu.Item key="navExport" icon={<MailOutlined/>} style={{width: "33%"}}>
                        NAV Export
                    </Menu.Item>
                </Menu>
                {current === "navSettings" &&
                <NavSettings history={this.props.history}/>
                }
                {current === "docNumbers" &&
                <DocNumberList/>
                }
                {current === "navExport" &&
                <NavExport/>
                }
            </div>
        )
    }


}
export default InvoiceSettings;