class AddressUtil {

    addressLine = "";

    getAddressLine(partner) {
        if (partner != null) {
            this.addressLine = partner.zipCode + " " +
                partner.city + ", " +
                partner.address + " ";
            if (partner.addressType != null)
                this.addressLine += partner.addressType + " ";
            if (partner.addressNumber != null)
                this.addressLine += partner.addressNumber + " ";
            if (partner.addressBuilding != null)
                this.addressLine += partner.addressBuilding + " ";
            if (partner.addressStaircase != null)
                this.addressLine += partner.addressStaircase + " ";
            if (partner.addressFloor != null)
                this.addressLine += partner.addressFloor + " ";
            if (partner.addressDoor != null)
                this.addressLine += partner.addressDoor + " ";
            if (partner.addressLotNumber != null) {
                this.addressLine += ", hrsz: " + partner.addressLotNumber
            }
            return this.addressLine;
        } else {
            return "";
        }
    }

    getPostalAddressLine(partner) {
        if (partner != null) {
            this.addressLine = partner.postalCode + " " +
                partner.postalCity + ", " +
                partner.postalAddress + " ";
            if (partner.postalAddressType)
                this.addressLine += partner.postalAddressType + " ";
            if (partner.postalNumber != null)
                this.addressLine += partner.postalNumber + " ";
            if (partner.postalBuilding != null)
                this.addressLine += partner.postalBuilding + " ";
            if (partner.postalStaircase != null)
                this.addressLine += partner.postalStaircase + " ";
            if (partner.postalFloor != null)
                this.addressLine += partner.postalFloor + " ";
            if (partner.postalDoor != null)
                this.addressLine += partner.postalDoor + " ";
            if (partner.postalLotNumber != null) {
                this.addressLine += ", hrsz: " + partner.postalLotNumber
            }
            return this.addressLine;
        } else {
            return "";
        }
    }
}

export default AddressUtil;