import React, {Component} from "react";
import {Form, Input, DatePicker, Select} from "antd";
const { TextArea } = Input;
const { RangePicker } = DatePicker
const { Option } = Select

export const LeaveItemForm = Form.create({name: 'leave_create'})(
    class extends Component {
        state = {
            businessDayCount: 0,
        }

        componentDidMount() {
            this.setBusinessDayCount(this.props.date)
        }

        componentDidUpdate(prevProps) {
            if (prevProps.date !== this.props.date) {
                this.setBusinessDayCount(this.props.date)
            }
        }

        setBusinessDayCount = (dateArray) => {
            const fromDate = dateArray[0].format('YYYY-MM-DD')
            const toDate = dateArray[1].format('YYYY-MM-DD')
            this.setState({
                businessDayCount: this.props.getBusinessDayCount(fromDate, toDate),
            })
        }

        validateHalfDays = (rule, value, callback) => {
            if (value === 3 && this.state.businessDayCount !== 1) {
                callback('Félnapot csak egy napos intervallumra lehet kiadni!')
            } else {
                callback()
            }
        }

        render() {
            const { getFieldDecorator } = this.props.form
            return (
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label="Intervallum">
                        {getFieldDecorator('date', {
                            initialValue: this.props.date,
                        })(
                            <RangePicker disabled={false} onChange={this.setBusinessDayCount} />
                        )}
                        <span> {this.props.form.getFieldValue('type') === 3 ? '0.5' : this.state.businessDayCount} munkanap</span>
                    </Form.Item>
                    <Form.Item label="Név">
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Kérjük adja meg a nevet!' }],
                            initialValue: this.props.name,
                        })(
                            <Select showSearch={true} filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                {this.props.employees.map((employee) => (
                                    <Option key={employee.id} value={employee.id}>{employee.fullName}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Típus">
                        {getFieldDecorator('type', {
                            rules: [
                                { required: true, message: 'Kérjük adja meg a szabadság típusát!' },
                                { validator: this.validateHalfDays }
                            ],
                            initialValue: this.props.type,
                        })(
                            <Select>
                                {this.props.types.map((type) => (
                                    <Option key={type.id} value={type.id}>{type.name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Megjegyzés">
                        {getFieldDecorator('notes', {
                            rules: [{ required: false }],
                            initialValue: this.props.notes,
                        })(
                            <TextArea rows={4} />,
                        )}
                    </Form.Item>
                </Form>
            )
        }
    }
)