import React, {Component} from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Icon,
    Input,
    InputNumber, Menu,
    Modal, Popconfirm,
    Row,
    Select,
    Spin,
    Table,
    Tree
} from "antd";
import PriceRangeList from "../Components/PriceRangeList";
import CategoryDrawer from "../Components/CategoryDrawer";
import NotificationUtil from "../Utils/NotificationUtil";
import API from "../Utils/API";
import PricingSelector from "../Components/PricingSelector";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {divide, multiply, sum} from "../Utils/CalculateUtils";

const {Column} = Table;

const {Option} = Select;
const {TreeNode} = Tree;

class ProductPricing extends Component {

    componentDidMount() {
        this.getWebshops();
        this.getTreeData();
    }

    state = {
        deleteShopPricesModalVisibility: false,
        products: [],
        tableLoading: true,
        selectedProducts: [],
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40', '100', '500', '1000', '2000'],
            showSizeChanger: true,
            position: "both",
            current: 0,
            results: 0,
            total: 0,
            pageSize: 2000
        },
        selectedPagination: {
            pageSizeOptions: ['10', '20', '30', '40', '100', '500', '1000', '2000'],
            showSizeChanger: true,
            position: "both",
            current: 0,
            results: 0,
            total: 0,
            pageSize: 2000
        },
        priceCalcType: 0,
        priceRanges: [],
        pricePlaceHolder: "",
        selectedRows: [],
        autoExpandParent: true,
        selectedKeys: [],
        treeData: [],
        visibleTable: false,
        visibleTree: false,
        tax: 0,
        categoryLoading: true,
        priceWithOrWithout: false,
        priceWithOrWithoutDisabled: true,
        checkedKeys: [],
        price: null,
        alreadyAddedKeys: [],
        webshopLoading: true,
        webshopCategoryLoading: false,
        webshopTreeData: [],
        webshops: [],
        autoExpandParentWebshop: true,
        webshopCheckedKeys: [],
        categoryDrawerVisible: false,
        selectedWebshop: "",
        canAddPrice: false,
        canSave: false,
        saveSpin: false,
        search: "",
        filteredInfo: null,
        expandedKeys: [],
        pricedTreeData: [],
        selectedCBKeys: new Set(),
    };

    toggleClearShopPricesModal = () => {
        this.setState({deleteShopPricesModalVisibility: true})
    }

    refreshCategories = () => {
        if (this.state.selectedWebshop) {
            console.log(this.state.selectedWebshop);
            API.post("productPrice/refreshCategories", this.state.selectedWebshop)
                .then(res => {
                    NotificationUtil(res.data);
                })
        }
    }

    clearShopPrices = (skuInShop, webshopName) => {
        console.log(webshopName)
        console.log(skuInShop)
        let clearShopPriceDTO = {
            productIdList: [
                skuInShop
            ],
            webshopList: [webshopName]
        }
        API.post("productPrice/clearShopPrice", clearShopPriceDTO)
            .then(res => {
                NotificationUtil(res.data);
            })
        this.setState({deleteShopPricesModalVisibility: false});
    }

    forceUpload = () => {
        alert("Feltöltés elindítva!");
        API.get("productPrice/forceUpload")
            .then(res => {
                NotificationUtil(res.data);
            })
    }

    handleTableChange = (pagination, filters) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
            filteredInfo: filters
        }, () => this.getProducts(this.state.priceWithOrWithout));
    };

    handleSelectedChange = (pagination) => {
        const pager = {...this.state.selectedPagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            selectedPagination: pager
        });
    };

    categoryDrawerOnClose = () => {
        this.setState({
            categoryDrawerVisible: false,
        });
        if (this.state.selectedWebshop !== "") {
            this.getWebshopTreeData();
        }
    };

    handlePriceCalcTypeChange = (e) => {
        let text = "";
        switch (e) {
            case 1: {
                text = "Nettó ár * ÁFA + ez az összeg";
                break;
            }
            case 2: {
                text = "Nettó ár * ÁFA * ez a százalék";
                break;
            }
            case 4: {
                text = "Bruttó ár";
                break;
            }
            default:
                break;
        }
        this.setState({priceCalcType: e, pricePlaceHolder: text}, () => {
            this.checkCanAddPrice();
        });
    };

    handleChangeTax = (e) => {
        this.setState({tax: e}, () => {
            this.checkCanAddPrice();
        });
    };

    handleDestWebshopChange = (e) => {
        this.getWebshopTreeData(e);
        this.setState({selectedWebshop: e});
    };

    saveProduct = () => {
        this.setState({saveSpin: true})
        let productCodes = [];
        let grossSellPrices = [];
        for (let i = 0; i < this.state.selectedProducts.length; i++) {
            productCodes.push(this.state.selectedProducts[i].productCode);
            grossSellPrices.push(this.state.selectedProducts[i].grossSellPrice);
        }

        if (this.state.priceCalcType !== 3) {
            let data = {
                "productCodes": productCodes,
                "grossSellPrices": grossSellPrices,
                "selectedWebshop": this.state.selectedWebshop,
                "webshopCheckedKeys": this.state.webshopCheckedKeys,
                "pricingMethod": this.state.priceCalcType,
                "tax": this.state.tax,
                "value": this.state.price
            }

            API.post("productPrice/saveProduct/", data)
                .then(res => {
                    NotificationUtil(res.data);
                    this.setState({
                        saveSpin: false,
                        //visibleTable: false,
                        selectedProducts: [],
                        alreadyAddedKeys: [],
                        canSave: false,
                        canAddPrice: false
                    })
                    //this.getTreeData();
                    this.getProducts(this.state.priceWithOrWithout)
                }).catch(() => {
                NotificationUtil({msg: "Hiba történt!", type: "error"});
            })

        } else {
            let pricesFrom = [];
            let pricesTo = [];
            let pricesValue = [];
            for (let i = 0; i < this.state.priceRanges.length; i++) {
                pricesFrom.push(this.state.priceRanges[i].from);
                pricesTo.push(this.state.priceRanges[i].to);
                pricesValue.push(this.state.priceRanges[i].value);
            }
            let data = {
                "productCodes": productCodes,
                "pricesFrom": pricesFrom,
                "pricesTo": pricesTo,
                "pricesValue": pricesValue,
                "grossSellPrices": grossSellPrices,
                "tax": this.state.tax,
                "selectedWebshop": this.state.selectedWebshop,
                "webshopCheckedKeys": this.state.webshopCheckedKeys
            }
            API.post("productPrice/saveBandPricedProduct/", data)
                .then(res => {
                    NotificationUtil(res.data);
                    this.setState({
                        saveSpin: false,
                        visibleTable: false,
                        selectedProducts: [],
                        alreadyAddedKeys: [],
                        canSave: false,
                        canAddPrice: false
                    })
                    this.getTreeData();
                }).catch(() => {
                NotificationUtil({msg: "Hiba történt!", type: "error"});
            });
        }
    }

    getWebshops() {
        API.get("productPrice/getWebshops")
            .then(res => {
                this.setState({webshops: res.data});
            }).finally(() => {
            this.setState({webshopLoading: false});
        });
    }

    getProducts = (e) => {
        let data = {
            "hasPrice": e,
            "supplierId": Object.keys(this.state.expandedKeys).length > 0 ? this.state.expandedKeys[0] : null,
            "categories": this.state.checkedKeys,
            "search": this.state.search,
        };

        this.setState({tableLoading: true});
        API.post("productPrice/getProducts" +
            "?page=" + this.state.pagination.current +
            "&size=" + this.state.pagination.pageSize, data)
            .then(res => {
                let products = res.data.lines;
                const pagination = {...this.state.pagination};
                pagination.total = res.data.totalElements;

                let manufacturers = [];
                for (let i = 0; i < res.data.lines.length; i++) {
                    if (res.data.lines[i].manufacturer)
                        if (!manufacturers.includes(res.data.lines[i].manufacturer)) {
                            manufacturers.push(res.data.lines[i].manufacturer);
                        }
                }

                products.forEach(p=>{
                    p.variantHandled = p.children && p.children.length>0;
                    p.checked = true;
                });

                this.setState({
                    products: products,
                    priceWithOrWithout: e,
                    manufacturers: manufacturers,
                    selectedRows: [],
                    visibleTable: true,
                    visibleTree: false,
                    pagination
                })
            }).finally(() => {
            this.setState({tableLoading: false, categoryLoading: false});

        });
    }

    searchProducts = (text) => {
        this.setState({search: text, categoryLoading: true}, () => this.getProducts(this.state.priceWithOrWithout))
    }

    getTreeData() {
        this.setState({categoryLoading: true});
        API.get("productPrice/getSupplierCategories")
            .then(res => {
                console.log(res.data)
                this.setState({
                    categories: res.data,
                    treeData: res.data
                })
            }).finally(() => {
            this.setState({visibleTree: true, categoryLoading: false})
        });
    }

    getPricedTreeData() {
        this.setState({categoryLoading: true});
        //API.get("productPrice/getPricedTreeData")
        API.get("productPrice/getWebshopCategoryTree")
            .then(res => {
                console.log('rootCategoriesByWebshops: ', res.data);
                this.setState({treeData: res.data})
            }).finally(() => {
            this.setState({categoryLoading: false})
        })
    }

    getWebshopTreeData(e) {
        this.setState({webshopCategoryLoading: true});
        let selectedWebShop;
        if (typeof e === 'undefined') {
            selectedWebShop = this.state.selectedWebshop;
        } else {
            selectedWebShop = e;
        }

        if (selectedWebShop.length > 0) {
            API.get("productPrice/webshopCategoryTree/" + selectedWebShop)
                .then(res => {
                    this.setState({webshopTreeData: res.data});
                }).finally(() => {
                this.setState({webshopCategoryLoading: false});
            })
        } else {
            this.setState({webshopCategoryLoading: false, webshopTreeData: []});
        }
    }

    setPrice = () => {
        let rows = this.state.selectedRows;
        rows.forEach((row) => {

            switch (this.state.priceCalcType) {
                case 1: {
                    //row.grossSellPrice = Number(eval(this.state.tax * row.purchasePrice) + eval(this.state.price)).toFixed(0);
                    row.grossSellPrice = sum(multiply(this.state.tax, row.purchasePrice), this.state.price).toFixed(0);
                    break;
                }
                case 2: {
                    //row.grossSellPrice = Number(eval(this.state.tax * row.purchasePrice * (1 + this.state.price / 100))).toFixed(0);
                    row.grossSellPrice = multiply(multiply(this.state.tax, row.purchasePrice), (sum(1, divide(this.state.price, 100)))).toFixed(0);
                    break;
                }

                case 3: {
                    //let grossPrice = eval(this.state.tax * row.purchasePrice);
                    let grossPrice = multiply(this.state.tax, row.purchasePrice);
                    this.state.priceRanges.forEach(priceRange => {
                        if (grossPrice > priceRange.from && grossPrice < priceRange.to) {
                            //row.grossSellPrice = grossPrice + eval(priceRange.value);
                            row.grossSellPrice = sum(grossPrice, priceRange.value);
                        }
                    });
                    if (row.grossSellPrice === "" || row.grossSellPrice === 0) {
                        //row.grossSellPrice = grossPrice + eval(this.state.priceRanges[this.state.priceRanges.length - 1].value);
                        row.grossSellPrice = sum(grossPrice, this.state.priceRanges[this.state.priceRanges.length - 1].value);
                    }
                    row.grossSellPrice = Number(row.grossSellPrice).toFixed(0);
                    break;
                }
                case 4: {
                    row.grossSellPrice = Number(this.state.price).toFixed(0);
                    break;
                }
                default:
                    break;
            }
            row.grossSellPrice = Math.round(row.grossSellPrice / 10) * 10;
        });

        let selectedPagination = this.state.selectedPagination;
        selectedPagination.total = rows.length;

        this.setState({selectedProducts: this.state.selectedProducts.concat(rows), selectedPagination}, () => {
            let ids = rows.map(row => row.id);
            this.setState({alreadyAddedKeys: this.state.alreadyAddedKeys.concat(ids)});
            this.setState({selectedRows: [], canSave: true});

        });
    };

    changePrice = (e) => {
        console.log(e.target.value);
        this.setState({price: e.target.value}, () => {
            this.checkCanAddPrice();
        });
    };

    updatePriceRanges = (e) => {
        this.checkCanAddPrice();
        e.forEach(row => {
            if (row.fromStatus !== "success" || row.toStatus !== "success") {
                this.setState({canAddPrice: false});
            }
        });
        this.setState({priceRanges: e});
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({selectedRows: selectedRows}, () => this.checkCanAddPrice());
        },
        /*(record.children && Object.keys(record.children).length > 0)*/
        getCheckboxProps: record => ({
            name: record.name,
            disabled: console.log('record : ',record) && this.state.alreadyAddedKeys.includes(record.id) || record.variantHandled
        }),
    };

    openCategoryDrawer = () => {
        this.setState({categoryDrawerVisible: true});
    };

    modifyCategory = () => {
        this.setState({visibleTable: false, visibleTree: true, products: [], checkedKeys: []});
    };

    setPriceWithOrWithout = (e) => {
        this.getProducts(e);
        //this.setState({priceWithOrWithout: e});
    };


    onExpand = expandedKeys => {
        console.log("onExpand called ", expandedKeys);

        //API.get("productPrice/getWebshopCategoryTree")

        this.setState({
            expandedKeys,
            autoExpandParent: true,
        });
    };


    onCheckWebshopCategory = checkedKeys => {
        this.setState({webshopCheckedKeys: checkedKeys}, () => {
            this.checkCanAddPrice();
        });
    };

    checkCanAddPrice() {
        console.log("Checking...");
        if (this.state.priceCalcType !== 3) {
            if (this.state.webshopCheckedKeys.length > 0
                && this.state.tax !== 0
                && this.state.price !== null
                && this.state.selectedRows.length > 0) {
                console.log("TRUE")
                this.setState({canAddPrice: true})
            } else {
                console.log("FALSE")
                console.log("webshopCheckedKeys.length: " + this.state.webshopCheckedKeys.length
                    + "\ntax: " + this.state.tax
                    + "\nprice: " + this.state.price
                    + "\nselectedRows.length: " + this.state.selectedRows.length);
                this.setState({canAddPrice: false})
            }
        } else {
            if (this.state.webshopCheckedKeys.length > 0 && this.state.tax !== 0) {
                this.setState({canAddPrice: true})
            } else {
                this.setState({canAddPrice: false})
            }
        }
    }

    onCheck = checkedKeys => {
        console.log("onCheckCalled", checkedKeys);
        this.setState({checkedKeys: checkedKeys}, () => {
            if (checkedKeys.length > 0) {
                this.getProducts(this.state.priceWithOrWithout);
                this.setState({visibleTable: true, visibleTree: false, priceWithOrWithoutDisabled: false});
            } else {
                this.setState({visibleTable: false, visibleTree: true, products: [], priceWithOrWithoutDisabled: true});
            }
        });
    };

    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item} disableCheckbox={!item.selectable}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} disableCheckbox={!item.selectable}/>;
        });

    handleMenuClick = (e) => {
        let priceWithOrWithout = e.key === "true";

        this.setState({
            priceWithOrWithout, visibleTable: false, visibleTree: true, checkedKeys: [], expandedKeys: []

        }, () => {
            if (priceWithOrWithout) {
                this.getPricedTreeData();
            } else {
                this.getTreeData();
            }
        })
    }

    removeFromPriced = (record) => {
        let {selectedProducts, canSave, alreadyAddedKeys, canAddPrice} = this.state;
        console.log(selectedProducts);
        let newArray = selectedProducts.filter(item => item !== record);
        if (newArray.length === 0) {
            canSave = false;
            alreadyAddedKeys = [];
            canAddPrice = false;
        }
        this.setState({selectedProducts: newArray, canSave, alreadyAddedKeys, canAddPrice});
    }

    render() {
        return (
            <div style={{textAlign: "left"}}>
                <Spin tip={"Mentés folyamatban..."} spinning={this.state.saveSpin}>
                    <Row gutter={16} type="flex" justify="start" align="top">
                        <Col span={12/*8*/}>
                            <Card title="Szűrés">
                                <Row>
                                    <Button onClick={this.forceUpload} type="primary" style={{background: "green"}}>
                                        Feltöltés most!
                                    </Button>
                                    <Button onClick={this.toggleClearShopPricesModal} type="danger"
                                            style={{marginLeft: 100}}>Ár törlése</Button>
                                </Row>

                                <Divider type="horizontal"/>

                                <Menu defaultSelectedKeys={"false"} onClick={this.handleMenuClick} mode="horizontal">
                                    <Menu.Item style={{width: "50%"}} key={"false"}>Új termékek</Menu.Item>
                                    <Menu.Item style={{width: "50%"}} key={"true"}>Árazott termékek</Menu.Item>
                                </Menu>

                                <PricingSelector state={this.state}
                                                 handleTableChange={this.handleTableChange}
                                                 modifyCategory={this.modifyCategory}
                                                 searchProducts={this.searchProducts}
                                                 onExpand={this.onExpand}
                                                 onCheck={this.onCheck}
                                                 rowSelection={this.rowSelection}
                                                 renderTreeNodes={this.renderTreeNodes}/>

                            </Card>
                        </Col>
                        <Col span={12/*6*/}>
                            <Card title="Beállítások" bordered={true}>

                                <Select placeholder="Árazási mód" style={{width: "100%"}}
                                        onChange={this.handlePriceCalcTypeChange}>
                                    <Option key={1} value={1}>Egyszerű mód</Option>
                                    <Option key={2} value={2}>Százalékos mód</Option>
                                    <Option key={3} value={3}>Sávos mód</Option>
                                    <Option key={4} value={4}>Fix mód ( Bruttó megadása )</Option>
                                </Select>

                                <Divider type="horizontal"/>

                                <Select placeholder="Áfa" style={{width: "100%"}} onChange={this.handleChangeTax}>
                                    <Option key={1} value={1.27}>27%</Option>
                                    <Option key={2} value={1.25}>25%</Option>
                                    <Option key={3} value={1}>0%</Option>
                                </Select>

                                <Divider type="horizontal"/>
                                {!this.state.webshopLoading &&
                                <Select placeholder="Cél webshop" mode="multiple"
                                        onChange={this.handleDestWebshopChange}
                                        style={{width: "100%"}}>

                                    {this.state.webshops.map(d => (
                                        <Option key={d.id} value={d.id}>{d.name}</Option>
                                    ))
                                    }

                                </Select>
                                }

                                {(this.state.priceCalcType !== 3 && this.state.priceCalcType !== 0) &&
                                <div>
                                    <Divider type="horizontal"/>
                                    <Input placeholder={this.state.pricePlaceHolder} onChange={this.changePrice}/>
                                </div>
                                }

                                {this.state.priceCalcType === 3 &&
                                <div>
                                    <Divider type="horizontal"/>
                                    <PriceRangeList updateParentComponent={this.updatePriceRanges}/>
                                </div>
                                }

                                <Row>
                                    <Col span={12}>
                                        <Button block disabled={!this.state.canSave} type={"primary"}
                                                onClick={this.saveProduct}> Mentés <Icon type="save"/></Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={this.setPrice} disabled={!this.state.canAddPrice}
                                                style={{backgroundColor: "#a0d911"}} block>Hozzáadás <Icon
                                            type="vertical-left"/> </Button>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>

                        <Col span={24/*10*/}>
                            <Card title="Beárazott termékek" bordered={true}>

                                <Button onClick={this.openCategoryDrawer}
                                        style={{top: '5px', position: 'relative', marginBottom: "20px"}}>
                                    Webshop kategória <Icon type="plus"/>
                                </Button>
                                <Button onClick={this.refreshCategories} type="danger"
                                        style={{
                                            top: '5px',
                                            position: 'relative',
                                            marginBottom: "20px",
                                            marginLeft: "40px"
                                        }}>
                                    Kategóriák frissítése
                                </Button>

                                <div style={{overflowY: "auto", height: "auto", maxHeight: "200px"}}>
                                    <Spin spinning={this.state.webshopCategoryLoading}
                                          tip="Webshop kategóriák betöltése...">
                                        Webshop kategóriák:
                                        <Tree
                                            checkable
                                            onCheck={this.onCheckWebshopCategory}
                                            checkedKeys={this.state.webshopCheckedKeys}
                                        >
                                            {this.renderTreeNodes(this.state.webshopTreeData)}
                                        </Tree>
                                    </Spin>
                                </div>
                                <Divider type="horizontal"/>
                                <Table dataSource={this.state.selectedProducts} onChange={this.handleSelectedChange}
                                       pagination={this.state.selectedPagination} rowKey={"productCode"}>

                                    <Column title="Termék Név" dataIndex="name" key="name"/>

                                    <Column title="Besz. Ár (Nettó)" dataIndex="purchasePrice" key="purchasePrice"
                                            render={(text) => {
                                                return (
                                                    <InputNumber
                                                        formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                        value={text} style={{width: "100%"}}/>
                                                );
                                            }}
                                    />

                                    <Column title="Eladási Ár (Bruttó)" dataIndex="grossSellPrice" key="grossSellPrice"
                                            render={(text) => {
                                                return (
                                                    <InputNumber
                                                        formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                        value={text} style={{width: "100%"}}/>
                                                );
                                            }}
                                    />

                                    <Column title="Törlés" key="del" render={(text, record, index) => {
                                        return (
                                            <div key={`[${(index)}].delete`}>
                                                <Popconfirm
                                                    title="Biztosan törölni szeretné?"
                                                    icon={<QuestionCircleOutlined color={"red"}/>}
                                                    onConfirm={() => this.removeFromPriced(record)}
                                                    okText="Igen"
                                                    cancelText="Nem">
                                                    <DeleteOutlined style={{fontSize: 25, color: "#1890ff"}}/>
                                                </Popconfirm>
                                            </div>)
                                    }}/>

                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    <Modal visible={this.state.deleteShopPricesModalVisibility} title="Termék árainak törlése"
                           okText="Törlés"
                           cancelText="Mégsem"
                           onOk={() => {
                               this.clearShopPrices(document.getElementById("inputToDelete").value,
                                   document.getElementById("webshopToDeleteFrom").value);
                           }}
                           onCancel={() => this.setState({deleteShopPricesModalVisibility: false})}>
                        <Input id="inputToDelete" placeholder="Cikkszám a webshopban"/>
                        <select id="webshopToDeleteFrom" style={{width: "100%", marginTop: 10}}>
                            {this.state.webshops.map(d => (
                                <option key={d.id} value={d.name}>{d.name}</option>
                            ))
                            }
                        </select>
                    </Modal>
                    <CategoryDrawer visible={this.state.categoryDrawerVisible} onClose={this.categoryDrawerOnClose}/>
                </Spin>
            </div>
        );
    }
}

export default ProductPricing;