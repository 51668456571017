import React, {Component} from "react";
import {Button, Col, Divider, Spin, Table, Tree} from "antd";
import Search from "antd/es/input/Search";
import Tag from "antd/es/tag";

const {Column} = Table;


class PricingSelector extends Component {

    countManufacturers() {
        const countTypes = this.props.state.products.filter(product => product.manufacturer !== null);
        return countTypes.length;
    }

    render() {
        let {filteredInfo, manufacturers} = this.props.state;
        filteredInfo = filteredInfo || [];
        manufacturers = manufacturers || [];
        let filters = manufacturers.map(manufacturer => (
            {text: manufacturer, value: manufacturer}
        ))
        return (
            <>
                <Col span={20} style={{paddingTop: 20, paddingBottom: 20}}>
                    <Search placeholder="Keresés" onSearch={value => this.props.searchProducts(value)} allowClear/>
                </Col>

                {!this.props.state.visibleTree &&
                <div style={{paddingTop: 20, paddingBottom: 20}}>
                    <Divider type="horizontal"/>
                    <h4>Válaszott
                        kategória: <b>{this.props.state.checkedKeys}</b> ({this.props.state.pagination.total})</h4>
                    <Button onClick={this.props.modifyCategory}>Kategória módosítás</Button>
                </div>
                }
                <Divider type="horizontal"/>

                <Spin spinning={this.props.state.categoryLoading} tip="Kategóriák betöltése...">
                    {this.props.state.visibleTree &&
                    <div>
                        {this.props.state.treeData.length > 0 &&
                        <div style={{overflowY: "auto", height: "auto", maxHeight: "400px"}}>
                            Kategóriák:
                            <Tree
                                checkable
                                onExpand={this.props.onExpand}
                                onCheck={this.props.onCheck}
                                checkedKeys={this.props.state.checkedKeys}
                            >
                                {this.props.renderTreeNodes(this.props.state.treeData)}
                            </Tree>
                        </div>
                        }
                        {this.props.state.treeData.length === 0 && <h4>Nincs találat!</h4>}
                    </div>
                    }
                </Spin>

                {this.props.state.visibleTable &&
                <div>
                    <h3>Termék választó</h3>
                    {<h4>Kiválasztva {this.props.state.selectedRows.length} termék,
                        összesen {this.props.state.pagination.total}</h4>}
                    <Table size={""} onChange={this.props.handleTableChange}
                           dataSource={this.props.state.products}
                           loading={this.props.state.tableLoading} rowKey={"productCode"}
                           indentSize={1}
                           rowClassName={record => {
                               if (record.variantHandled) {
                                   return (record.children && Object.keys(record.children).length > 0) ? "warning" : "success"
                               } else {
                                   return ""
                               }
                           }}
                           pagination={this.props.state.pagination} rowSelection={this.props.rowSelection}
                        //expandIcon={p => <CustomExpandIcon {...p} />}
                        //expandedRowRender={item => null}
                    >
                        <Column title="Termék neve" dataIndex="name" key="name"/>

                        {this.countManufacturers() > 0 &&
                        <Column title="Gyártó" dataIndex="manufacturer"
                                key="manufacturer"
                                filters={filters}
                                filteredValue={filteredInfo.manufacturer || null}
                                onFilter={(value, record) => (
                                    record.manufacturer.includes(value)
                                )}/>
                        }
                        <Column
                            title="Besz. ár"
                            dataIndex="purchasePrice"
                            key="purchasePrice"
                            sorter={(a, b) => a.purchasePrice - b.purchasePrice}
                            render={(text, record) => {
                                return ((record.children && Object.keys(record.children).length > 0) ? "" : text)
                            }}
                        />

                        {this.props.state.priceWithOrWithout &&
                        <Column
                            title="Elad. ár"
                            dataIndex="shopPrices"
                            key="shopPrices"
                            render={shopPrices => (
                                <span>
                                    {shopPrices && shopPrices.map(shopPrice => (
                                        <Tag color="blue" key={shopPrice.price}>
                                            {shopPrice.shopName}:{shopPrice.price}
                                        </Tag>
                                    ))}
                                </span>
                            )}
                        />
                        }
                    </Table>
                </div>
                }
            </>
        )
    }
}

//Úgy tűnik, hogy nincs használatban...
// const CustomExpandIcon = props => {
//     if (!props.record.children || Object.keys(props.record.children).length === 0) return null; //nothing to expand
//     if (props.expanded) {
//         return (
//             <a onClick={e => props.onExpand(props.record, e)}>
//                 <Icon type="minus"/>
//             </a>
//         );
//     } else {
//         return (
//             <a onClick={e => props.onExpand(props.record, e)}>
//                 <Icon type="plus"/>
//             </a>
//         );
//     }
// };

export default PricingSelector;