import React, {Component} from 'react';
import API from "../../Utils/API";
import {Button, Card, Col, Divider, Row, Table} from "antd";
import Column from "antd/es/table/Column";
import SiteDrawer from "./SiteDrawer";

class SitesSettings extends Component {

    componentDidMount() {
        this.getSites();
    }

    state = {
        showSiteDrawer : false,
        selectedRowKeys : [],
        selectedRows: [],
        tableLoading: false,
        sites: []
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
        },
        type: 'radio'
    };

    getSites(){
        this.setState({tableLoading: true});
        API.get("settings/getSites")
            .then(res => {
                this.setState({ sites : res.data.lines},()=>{console.log(this.state.sites)});
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    handleTableChange(){
        this.getSites();
        // TODO Set radio button value to null
    }

    addSite=()=> {  this.setState({showSiteDrawer:true, editId: ""}) };

    onCloseSite=()=> { this.setState({showSiteDrawer:false}); this.handleTableChange()};

    editSite = () => {
        let {selectedRows} = this.state;
        let selectedId = selectedRows.map(row => {
            return row.id;
        });
        this.setState({showSiteDrawer:true, editId: selectedId})
    };

    render() {
        const {selectedRowKeys} = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Card title="Telephelyek">
                    <Row>
                        <Col offset={1}>
                            <Button onClick={e=>this.addSite(e)} type="primary">
                                Új felvétel
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={this.editSite} disabled={!hasSelected} type="primary">
                                Telephely szerkesztése
                            </Button>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.sites}
                        pagination={false}
                        bordered={true}
                        rowSelection={this.rowSelection}
                        rowKey={"id"}

                    >
                        <Column title="ID" dataIndex="id" key="id"/>
                        <Column title="Megnevezés" dataIndex="title" key="title"/>
                        <Column title="Cég" dataIndex="companyName" key="companyName"/>
                        <Column title="Prefix" dataIndex="prefix" key="prefix"/>
                    </Table>
                </Card>
                <SiteDrawer visible={this.state.showSiteDrawer} onClose={this.onCloseSite} editId={this.state.editId}  />
            </div>
        )
    }
}
export default SitesSettings;