import {Form, Input} from 'antd';
import React, {Component} from 'react';

export const SiteForm = Form.create({name: 'normal_site'})(
    class extends Component {

        state = {
            showPostalData : false,
            nameChecking : "",
            nameCheckingText: ""
        };

        render() {
            const { getFieldDecorator} = this.props.form;
            return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="site-form">

                <Form.Item label="Telephely neve">
                    {getFieldDecorator('title', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a nevet!'}],
                        initialValue: this.props.title,
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <Form.Item label="Számla prefix">
                    {getFieldDecorator('prefix', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a prefixet!' }],
                        initialValue: this.props.prefix,
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <Form.Item label="NameKey (telephely neve kisbetűvel, ékezetek nélkül!)">
                    {getFieldDecorator('nameKey', {
                        rules: [{ required: true, message: 'Kérjük adja meg a telephely nevét kisbetűvel, ékezetek nélkül!' }],
                        initialValue: this.props.nameKey,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Számlázói cég azonosítója (tenantId)">
                    {getFieldDecorator('tenantId', {
                        rules: [{ required: true, message: 'Kérjük töltse ki az azonosítót!' }],
                        initialValue: this.props.tenantId,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Cég neve">
                    {getFieldDecorator('companyName', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a cég nevét!' }],
                        initialValue: this.props.companyName,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Cég adószáma">
                    {getFieldDecorator('companyTaxNumber', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a cég adószámát!' }],
                        initialValue: this.props.companyTaxNumber,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Cím">
                    {getFieldDecorator('companyAddress', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a cég címét!' }],
                        initialValue: this.props.companyAddress,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <h4>Árajánlaton / szerviz munkalapon szereplő adatok</h4>
                <Form.Item label="Cégjegyzékszám">
                    {getFieldDecorator('companyRegistrationNumber', {
                        initialValue: this.props.companyRegistrationNumber,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Telephely telefonszáma">
                    {getFieldDecorator('companyPhone', {
                        initialValue: this.props.companyPhone,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item label="Telephely email címe">
                    {getFieldDecorator('companyEmail', {
                        initialValue: this.props.companyEmail,
                    })(
                        <Input/>,
                    )}
                </Form.Item>
                <Form.Item style={{display: "none"}}>
                    {getFieldDecorator('id', {
                        rules: [{required: false}],
                        initialValue: this.props.id,
                    })(
                        <Input hidden={true} />
                    )}
                </Form.Item>
            </Form>)
        }
    }
)