import {Form, Input, Modal, Select} from "antd";
import React, {Component} from "react";
import API from "../Utils/API";
import SiteSelector from "./SiteSelector";
import PartnerSelect from "./PartnerSelect";
import TextArea from "antd/es/input/TextArea";
import PartnerDrawer from "./PartnerDrawer";
import ResponsibleSelector from "./ResponsibleSelector";

const {Option} = Select;

class ToDoModal extends Component {

    state = {
        partnerDrawerVisible: false,
        editId: "",
        selectedPartner: {},
        partner: {}
    }

    save = (e) => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.props.onOk(e, values);
            form.resetFields();
        });
    };

    newPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: ""
        });
    };

    editPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: this.state.selectedPartner.id
        });
    };

    partnerDrawerOnClose = () => {
        this.setState({
            partnerDrawerVisible: false,
            editId: ""
        });
    };
    getPartnerInfo = (e, data) => {
        this.setState({selectedPartner: data}, () => this.partnerInfo(data.id));
    };

    partnerInfo = (id) => {
        if (id !== "null" && id !== "undefined"
            && id !== null && id !== undefined) {
            API.get("/getPartner/" + id)
                .then(res => {
                    let partnerDetails = res.data;
                    this.setState({
                        partner: partnerDetails
                    })
                });
        }
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const title = this.props.title;
        return (
            <div>
                <Modal
                    visible={this.props.visible}
                    onOk={this.save}
                    okText="Mentés"
                    cancelText="Mégse"
                    onCancel={this.props.onCancel}
                    maskClosable={false}
                    title={title}
                >
                    <Form onSubmit={this.handleSubmit} layout={'vertical'} className="todo_form">

                        <Form.Item style={{display: "none"}}>
                            {getFieldDecorator('id', {
                                rules: [{required: false}],
                                initialValue: this.props.item.id != null ? this.props.item.id : "",
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>

                        <Form.Item label="Leírás">
                            {getFieldDecorator("toDoDescription", {
                                rules: [{required: true, message: 'Kérjük töltse ki a leírást!'}],
                                initialValue: this.props.item.toDoDescription
                            })(
                                <TextArea autoFocus={true} rows={3}/>
                            )}
                        </Form.Item>

                        <PartnerSelect toDo={true} openDrawer={this.newPartner} editPartner={this.editPartner}
                                       selectedPartnerId={this.props.item.partnersId}
                                       form={this.props.form} onSelectedPartner={this.getPartnerInfo}/>

                        <SiteSelector form={this.props.form} changeSite={null}
                                      selectedValue={this.props.item.siteNameKey != null ? this.props.item.siteNameKey : ""}/>

                        <Form.Item label="Státusz: " labelCol={{span: 3}} wrapperCol={{span: 15}}>
                            {getFieldDecorator("toDoStatus", {
                                initialValue: this.props.item.toDoStatusId
                            })(
                                <Select style={{width: "80%"}} allowClear placeholder="Függőben">
                                    {this.props.statuses.map(d => (
                                        <Option key={d.id} value={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <ResponsibleSelector users={this.props.users} form={this.props.form} responsible={this.props.item.responsible} userSelect={null}/>

                    </Form>
                </Modal>
                <PartnerDrawer visible={this.state.partnerDrawerVisible} onClose={this.partnerDrawerOnClose}
                               editId={this.state.editId}/>
            </div>
        )
    }
}


export default Form.create()(ToDoModal);