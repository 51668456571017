import {Form, Input, Radio} from 'antd';
import React, {Component} from 'react';
import SiteSelector from "../../Components/SiteSelector";
import TextArea from "antd/es/input/TextArea";

export const CashRegisterForm = Form.create({name: 'normal_cashRegister'})(
    class extends Component {

        state = {
            showPostalData : false,
            nameChecking : "",
            nameCheckingText: ""
        };

        //TODO NameCheck?

        render() {
            const { getFieldDecorator} = this.props.form;
            return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="cashRegister-form">

                <Form.Item label="Kassza neve">
                    {getFieldDecorator('name', {
                        initialValue: this.props.cashRegister.name,
                        rules: [{ required: true, message: 'Kérjük töltse ki a nevet!'}],
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <SiteSelector form={this.props.form} all={true} selectedValue={this.props.cashRegister.siteName} onSelect={this.changeSite} />

                <Form.Item label="Kassza típusa" style={{display: this.props.isEdit ? "none" : ""}}>
                    {getFieldDecorator('type', {
                        initialValue: this.props.cashRegister.type
                    })(
                        <Radio.Group>
                            <Radio.Button value="normal">Bolti</Radio.Button>
                            <Radio.Button value="office">Irodai</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>


                <Form.Item label="Megjegyzés">
                    {getFieldDecorator('note', {
                        initialValue: this.props.cashRegister.note
                    })(
                        <TextArea autoSize/>
                    )}
                </Form.Item>
            </Form>)
        }
    }
)