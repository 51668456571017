import React, {Component} from 'react';
import API from "../../Utils/API";
import {Button, Card, Col, Divider, Modal, Row, Table} from "antd";
import Column from "antd/es/table/Column";
import WebshopDrawer from "./WebshopDrawer";

class WebshopSettings extends Component {

    componentDidMount() {
        this.getWebshops();
    }

    state = {
        showWebshopDrawer : false,
        selectedRowKeys : [],
        selectedRows: [],
        tableLoading: false,
        webshops: []
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
        },
        type: 'radio'
    };

    getWebshops(){
        this.setState({tableLoading: true});
        API.get("settings/getWebshops")
            .then(res => {
                this.setState({ webshops : res.data.lines},()=>{console.log(this.state.webshops)});
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    handleTableChange(){
        this.getWebshops();
        // TODO Set radio button value to null
    }

    addWebshop=()=> {  this.setState({showWebshopDrawer:true, editId: ""}) };

    onCloseWebshop=()=> { this.setState({showWebshopDrawer:false}); this.handleTableChange()};

    editWebshop = () => {
        let {selectedRows} = this.state;
        let selectedId = selectedRows.map(row => {
            return row.id;
        });
        this.setState({showWebshopDrawer:true, editId: selectedId})
    };
    deleteWebshop = () => {
        const current = this;
        Modal.confirm({
            title: "Biztosan törölni szeretné?",
            content: "A törlés nem visszavonható!",
            okText: "Törlés",
            cancelText: "Mégsem",
            onOk: () => {
                current.setState({tableLoading: true});
                let {selectedRows} = current.state;
                let selectedId = selectedRows.map(row => {
                    return row.id;
                });
                API.post("settings/deleteWebshop/" + selectedId)
                    .then(() => {
                        current.getWebshops();
                    });
            }
        })
    }

    render() {
        const {selectedRowKeys} = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Card title="Webshopok">
                    <Row>
                        <Col offset={1}>
                            <Button onClick={e=>this.addWebshop(e)} type="primary">
                                Új felvétel
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={this.editWebshop} disabled={!hasSelected} type="primary">
                                Webshop szerkesztése
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={this.deleteWebshop} disabled={!hasSelected} type="danger">
                                Webshop törlése
                            </Button>
                            <Divider type="vertical"/>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.webshops}
                        pagination={false}
                        bordered={true}
                        rowSelection={this.rowSelection}
                        rowKey={"id"}

                    >
                        <Column title="ID" dataIndex="id" key="id"/>
                        <Column title="Megnevezés" dataIndex="name" key="name"/>
                        <Column title="URL" dataIndex="url" key="url"/>
                        <Column title="Felhasználó" dataIndex="username" key="username"/>
                        <Column title="Típus" dataIndex="type" key="type"/>
                    </Table>
                </Card>
                <WebshopDrawer visible={this.state.showWebshopDrawer} onClose={this.onCloseWebshop} editId={this.state.editId}  />
            </div>
        )
    }
}
export default WebshopSettings;