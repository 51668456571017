import React, {Component} from 'react';
import API from "../../Utils/API";
import {Card, Form, Table} from "antd";
import Column from "antd/es/table/Column";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import {EmailTemplateModal} from "./EmailTemplateModal";
import NotificationUtil from "../../Utils/NotificationUtil";

class EmailTemplateSettings extends Component {

    componentDidMount() {
        this.getTemplates();
    }

    state = {
        tableLoading: false,
        templates: [],
        editModalVisibility: false,
        selectedTemplate: {}
    };

    getTemplates() {
        this.setState({tableLoading: true});
        API.get("settings/getTemplates")
            .then(res => {
                this.setState({templates: res.data}, () => {
                    console.log(this.state.templates)
                });
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    saveTemplate = () => {
        const {form} = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log(values);
            API.post("settings/updateTemplate", values)
                .then(res => {
                    NotificationUtil(res.data);
                    this.handleTableChange();
                })
        });
    }

    handleTableChange() {
        this.getTemplates();
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    editTemplate = (record) => {
        this.setState({selectedTemplate: record, editModalVisibility: true});
    };

    render() {
        return (
            <>
                <Card title="Email formátumok">
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.templates}
                        pagination={false}
                        bordered={true}
                        rowKey={"id"}>
                        <Column title="ID" dataIndex="id" key="id"/>
                        <Column title="Művelet" dataIndex="event" key="event" render={text => {
                            if (text === "SALES_ORDER_PROCESSING") return "Feldolgozás alatt"
                            if (text === "SALES_ORDER_WAITING_FOR_TRANSFER") return "Átutalásra vár"
                            if (text === "SALES_ORDER_SHIPPED") return "Szállítás alatt"
                            if (text === "SALES_ORDER_DELAYED") return "Beszerzés alatt"
                        }}/>
                        <Column title="Tárgy" dataIndex="subject" key="subject"/>
                        <Column title="Tartalom" dataIndex="body" key="body"/>
                        <Column title="Szerk." key="operation" width={150} render={(text, record, index) => {
                            return (
                                <span className="link" key={`[${(index)}].edit`}>
                                    <EditOutlined style={{fontSize: 25}} onClick={() => this.editTemplate(record)}/>
                                </span>
                            );
                        }}/>
                    </Table>
                </Card>
                <EmailTemplateModal wrappedComponentRef={this.saveFormRef}
                                visible={this.state.editModalVisibility}
                                onCancel={() => this.setState({editModalVisibility: false})}
                                onSave={this.saveTemplate}
                                selectedTemplate={this.state.selectedTemplate} />
            </>
        )
    }
}

export default Form.create({
    name: 'email-form'
})(EmailTemplateSettings);