import {Button, Modal, notification, Table} from 'antd';
import React from "react";

export default (jsonResponse) => {
    notification[jsonResponse.type]({
        message: jsonResponse.msg,
        description: jsonResponse.extraList ?
            (<Button type={"href"} onClick={async () => showOtherInformation(jsonResponse.extraList)}>Több infó
            </Button>) : null,
        style: {
            width: 600,
            marginLeft: 335 - 600,
        },
    });
};

async function showOtherInformation(content) {
    let columns = [];
    Object.keys(content[0]).forEach((d, index) => {
        if (d !== "children" && d !== "imageLinks") {
            columns.push({
                title: d,
                dataIndex: d,
                key: index,
                render: (record) => (
                    JSON.stringify(record)
                )
            })
        }
    })
    Modal.info({
        title: "Módosított elemek: (" + content.length + ")",
        width: "80%",
        content: (
            <Table
                rowKey={columns[0]}
                columns={columns}
                dataSource={content}
                expandedRowRender={false}
                bordered={true}
                pagination={false}
            />
        ),
    });
}

