import React, {Component} from "react";
import API from "../Utils/API";
import QueryParams from "../Utils/QueryParams";
import Card from "antd/es/card";
import {Button, DatePicker, Col, Row, Select, Switch} from "antd";
import moment from "moment";
import {saveAs} from 'file-saver';
import locale from "antd/es/date-picker/locale/hu_HU";

const {Option} = Select;
const {RangePicker} = DatePicker;
let numbers = [];

class NavExport extends Component {

    state = {
        docNumbers: [],
        lastDocNumber: 0,
        from: 0,
        to: 0,
        selectedDocNumber: "",
        exportDisabled: true,
        selectedDates: [],
        exportType: false
    }

    componentDidMount() {
        this.getDocNumbers();
    }

    onTypeChange = (e) => {
        this.setState({exportType: e})
    }

    downloadExport = () => {
        let from = this.state.from;
        let to = this.state.to;
        let prefix = this.state.selectedDocNumber;
        if (this.state.exportType) {
            console.log("Export by serialNumbers");
            API.get("invoice/nav/serialnumber/" + prefix + "/" + from + "/" + to, {responseType: 'blob'})
                .then(res => {
                    this.saveFile(res);
                }).catch(error => {
                console.log(error);
            })
        } else {
            console.log("Export by dates");
            API.get("invoice/nav/date/" + this.state.selectedDates[0] + "/" + this.state.selectedDates[1], {responseType: 'blob'})
                .then(res => {
                    this.saveFile(res);
                }).catch(error => {
                console.log(error);
            })
        }
    }

    saveFile(res) {
        console.log(res);
        let blob = new Blob([res.data], {type: 'application/application/octet-stream'});
        let date = new Date();
        let fileName = "export-" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + ".zip";
        saveAs(blob, fileName);
    }

    getLastDocNumber(prefix) {
        API.get("invoice/get-last-number" + prefix)
            .then(res => {
                this.setState({lastDocNumber: res.data.extraList[0]})
            })
    }

    onFromChange = (e) => {
        this.setState({from: e});
        if (e && this.state.to) {
            this.setState({exportDisabled: false});
        } else {
            this.setState({exportDisabled: true});
        }
    }

    onToChange = (e) => {
        this.setState({to: e});
        if (this.state.from && e) {
            this.setState({exportDisabled: false});
        } else {
            this.setState({exportDisabled: true});
        }
    }

    getDocNumbers() {
        let qp = new QueryParams();
        qp.setCustomParams({billable: true});
        API.get("invoice/getDocNumbers")
            .then(res => {
                this.setState({docNumbers: res.data.extraList}, () => console.log(this.state.docNumbers))
            })
    }

    onDocNumberSelected = (e) => {
        this.setState({selectedDocNumber: e});
        numbers = [];
        if (e) {
            this.getLastDocNumber(e);
        }
    }

    disabledDate = (current) => {
        let customDate = new Date();
        customDate.setFullYear(2020);
        customDate.setMonth(6);
        customDate.setDate(0);
        return current && current < moment(customDate, "YYYY-mm-DD");
    };

    onDateChange = (value, dateString) => {
        this.setState({selectedDates: dateString}, () => console.log(this.state.selectedDates))
        if (this.state.selectedDates !== null) {
            if (dateString[0] === "" && dateString[1] === "") {
                this.setState({exportDisabled: true})
            } else {
                this.setState({exportDisabled: false})
            }
        } else {
            this.setState({exportDisabled: true})
        }
    };


    render() {
        for (let i = 1; i < this.state.lastDocNumber; i++) {
            numbers[i] = i;
        }
        return (
            <Card title="NAV Adatexport">
                <Row style={{paddingBottom: "10px"}} type="flex" justify="center" align="middle">
                    <h1>Dátum szerint&nbsp;</h1><Switch onChange={e => this.onTypeChange(e)}/>&nbsp;<h1>Sorszám
                    szerint</h1>
                </Row>
                {this.state.exportType &&
                <Row type="flex" justify="center" align="middle">
                    <Col span={4}>
                        <h1>Számlatömb</h1>
                    </Col>
                    <Col span={4}>
                        <h1>Sorszámtól</h1>
                    </Col>
                    <Col>
                        <h1>-</h1>
                    </Col>
                    <Col span={3}>
                        <h1>Sorszámig</h1>
                    </Col>
                </Row>}

                {this.state.exportType &&
                <Row type="flex" justify="center" align="middle">
                    <Col span={4}>
                        {(this.state.docNumbers == null) ?
                            <Select disabled style={{width: '80%'}} allowClear onSelect={this.onDocNumberSelected}/>
                            :
                            <Select style={{width: '80%'}} allowClear onChange={this.onDocNumberSelected}>
                                {this.state.docNumbers.map(d => (
                                    <Option key={d.id} value={d.prefix}>{d.prefix}</Option>
                                ))}
                            </Select>
                        }
                    </Col>
                    <Col span={4}>
                        <Select style={{width: '80%'}} allowClear showSearch onChange={this.onFromChange}>
                            {numbers.map(d => (
                                (this.state.to > 0 && this.state.to < d) ?
                                    <Option disabled={true} key={d} value={d}>{d}</Option> :
                                    <Option key={d} value={d}>{d}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select style={{width: '80%'}} allowClear showSearch onChange={this.onToChange}>
                            {numbers.map(d => (
                                (this.state.from > d) ?
                                    <Option disabled={true} key={d} value={d}>{d}</Option> :
                                    <Option key={d} value={d}>{d}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={this.downloadExport}
                                disabled={this.state.exportDisabled}>Export</Button>
                    </Col>
                </Row>
                }
                {!this.state.exportType &&
                <Row type="flex" justify="center" align="middle">
                    <Col span={6}>
                        <RangePicker style={{width: '100%'}} locale={locale} disabledDate={this.disabledDate}
                                     onChange={this.onDateChange}/>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" onClick={this.downloadExport}
                                disabled={this.state.exportDisabled}>Export</Button>
                    </Col>
                </Row>
                }
            </Card>
        )
    }
}

export default NavExport;