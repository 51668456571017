import React, {Component} from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Icon,
    InputNumber,
    Popconfirm,
    Table, Spin, Select
} from "antd";
import {connect} from "react-redux";
import PurchaseOrderExpandedTable from "../Components/PurchaseOrderExpandedTable";
import PartnerSelect from "../Components/PartnerSelect";
import PartnerDrawer from "../Components/PartnerDrawer";
import API from "../Utils/API";
import {unsetNewlyCreatedPartner} from "../actions";
import NotificationUtil from "../Utils/NotificationUtil";
import moment from "moment";
import SiteSelector from "../Components/SiteSelector";
import ProductSelectorModal from "../Components/ProductSelectorModal";
import Column from "antd/es/table/Column";
import QueryParams from "../Utils/QueryParams";
import {resolvePurchaseOrderStatus} from "../Utils/Utils";

const {TextArea} = Input;
const {Option} = Select;

class PurchaseOrder extends Component {

    state = {
        salesOrderReferences: null,
        purchaseOrderLines: [{
            name: "", purchasePrice: "", qty: "", status: "", created: "",
            updated: "", user: "", userUpdated: "", productId: ""
        }],
        selectedStatus: "",
        currentUser: null,
        lock: false,
        newPurchaseOrderId: "",
        selectedSite: "",
        partnerDrawerVisible: false,
        editId: "",
        selectedPartner: "",
        productChooserVisible: false,
        selectedRowKeys: [],
        newlyCreatedPartner: "",
        haveOffset: 8,
        loading: true,
        editObject: {
            purchaseOrderNo: ""
        },
        suppliers: [],
        selectedSupplier: "",
        status: []
    };

    constructor(props) {
        super(props);
        this.getStatuses();
        const {match: {params}} = props;
        if (params.id != null) {
            this.fetchData(params.id);
            this.getRefs(params.id);
        } else {
            this.getLastPurchaseOrderNo(props.selectedSite.value);
        }
    }

    componentWillUnmount() {
        API.post("purchaseOrder/unlock", {id: this.state.editObject.id, username: this.state.currentUser});
        if (typeof this.props.newlyCreatedPartner.partnerId != "undefined") {
            this.props.unsetNewlyCreatedPartner();
        }
    }

    getStatuses = () => {
        API.get("purchaseOrder/getAllStatus")
            .then(res => {
                this.setState({status: res.data})
            })
    }

    fetchData(id) {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "";
        API.get("purchaseOrder/getPurchaseOrder/" + id).then(res => {
            let {data} = res;
            console.log(res.data);
            this.setState({
                editObject: data,
                purchaseOrderLines: data.purchaseOrderLines,
                loading: false,
                currentUser: user.username
            });
            if (this.state.editObject.isLocked && this.state.currentUser !== this.state.editObject.lockedBy) {
                this.setState({lock: true});
                NotificationUtil({msg: "Dokumentum zárolva, csak megtekinthető!", type: "error"});
            }
        });
        API.post("/purchaseOrder/lock", {id: id, username: user.username});
        this.getSuppliers();
    };

    getRefs(id) {
        console.log("id: " + id);
        API.get("recon/getRefs/" + id).then(res => {
            this.setState({
                salesOrderReferences: res.data,
            }, () => {
                console.log("refs: ", this.state.salesOrderReferences)
                if (this.state.salesOrderReferences != null) {
                    this.setState({haveOffset: 0});
                }
            })
        });
    };

    selectProduct = (e) => {
        this.setState({
            productChooserVisible: false,
        });
        let qp = new QueryParams();
        qp.setCustomParams({id: e})
        API.get("/product-by-productId/" + qp.getQueryParam()).then(res => {
            let {data} = res;
            console.log(data);
            let line = {
                productCode: data.productCode, name: data.name, qty: 1, purchasePrice: data.netPurchasePrice,
                status: "", created: "", updated: "", user: "", userUpdated: "", sellPrice: data.grossSellPrice
            };
            this.setState(prevState => ({
                purchaseOrderLines: [...prevState.purchaseOrderLines, line]
            }));
            console.log(data);
        });
    };

    getLastPurchaseOrderNo(sitePrefix) {
        API.get("purchaseOrder/getLastPurchaseOrderNo/" + sitePrefix).then(res => {
            let {data} = res;
            this.setState({newPurchaseOrderId: data.purchaseOrderNo, loading: false});
            console.log(data);
        });
    }

    changeSite = (e) => {
        this.getLastPurchaseOrderNo(e);
    };

    changeStatus = (e) => {
        this.setState({selectedStatus: e});
    };

    closeProductChooser = () => {
        this.setState({productChooserVisible: false,});
    };

    onRowChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };


    getCheckboxProps = (record) => {
        return {
            disabled: record.index === 0,
            name: record.name,
        }
    };

    newPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: ""
        });
    };

    editPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: this.state.selectedPartner.id
        });
    };

    partnerDrawerOnClose = () => {
        this.setState({
            partnerDrawerVisible: false,
            editId: ""
        });
    };

    getPartnerInfo = (e, data) => {
        console.log("called?", data);
        this.setState({selectedPartner: data});
    };

    newProductLine = () => {
        this.setState({productChooserVisible: true,});
    };

    save = () => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            API.post("purchaseOrder/save", values).then(res => {
                form.resetFields();
                NotificationUtil(res.data);
                this.props.history.push('/app/purchaseOrders');
            }).catch(() => {
                NotificationUtil({msg: "Hiba történt!", type: "error"});
            });
        });
    };

    saveLineStatus = () => {
        let {selectedRowKeys, purchaseOrderLines} = this.state;
        let lines = selectedRowKeys.filter(line => purchaseOrderLines[line].id !== "");

        API.post("purchaseOrder/updateLinesStatus", {lines: lines, status: this.state.selectedStatus}).then(res => {
            NotificationUtil(res.data);
            const {match: {params}} = this.props;
            this.fetchData(params.id);
        }).catch(() => {
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    };

    removeLine = () => {
        let {selectedRowKeys, purchaseOrderLines} = this.state;
        let removeLines = selectedRowKeys.filter(line => typeof purchaseOrderLines[line].id !== "undefined").map(line => {
            return purchaseOrderLines[line].id;
        });

        if (removeLines.length > 0) {
            API.post("purchaseOrder/removeLine", {line: removeLines}).then(res => {
                NotificationUtil(res.data);
            }).catch(() => {
                NotificationUtil({msg: "Hiba történt!", type: "error"});
            });
        }

        selectedRowKeys.forEach(selected => {
            purchaseOrderLines.splice(selected, 1);
        });
        this.setState({selectedRowKeys: []});
        this.setState({purchaseOrderLines: purchaseOrderLines});
    };

    addLine = () => {
        let line = {
            name: "", price: "", qty: "", status: "",
            created: "", updated: "", user: "", userUpdated: ""
        };
        this.setState(prevState => ({
            purchaseOrderLines: [...prevState.purchaseOrderLines, line]
        }));
    };

    getSalesOrder(id) {
        this.props.history.push('/app/edit-salesOrder/' + id);
    }

    getSuppliers() {
        API.get("purchaseOrder/getSuppliers")
            .then(res => {
                this.setState({suppliers: res.data}, () => console.log(this.state.suppliers));
            })
    }

    selectSupplier = (e) => {
        this.setState({selectedSupplier: e})
    }

    saveLineSupplier = () => {
        let {selectedRowKeys, purchaseOrderLines} = this.state;
        let lines = selectedRowKeys.filter(line => purchaseOrderLines[line].id !== "");
        API.post("purchaseOrder/saveLineSupplier/" + this.state.selectedSupplier.toString() + "/" + lines.toString() + "/" + this.props.match.params.id.toString())
            .then(res => {
                NotificationUtil(res.data);
                const {match: {params}} = this.props;
                this.fetchData(params.id);
            }).catch(() => {
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    }

    render() {
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onRowChange,
            getCheckboxProps: this.getCheckboxProps
        };

        const {getFieldDecorator} = this.props.form;
        const hasSelected = selectedRowKeys.length > 0;
        const lineStatusUpdatable = (!(this.state.selectedStatus !== "" && selectedRowKeys.length > 0));
        const lineSavable = !(this.state.selectedSupplier !== "" && selectedRowKeys.length > 0);
        let newMode = true;
        if (this.state.editObject.purchaseOrderNo !== "") {
            newMode = false;
        }
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Form onSubmit={this.handleSubmit} className="partner-form" layout="vertical">
                        <Row gutter={24}>
                            {(this.state.lock && this.state.currentUser !== this.state.editObject.lockedBy) &&
                            <h2 style={{color: "red"}}>Dokumentum zárolva, {this.state.editObject.lockedBy} felhasználó
                                épp szerkeszti!</h2>
                            }
                            <Col span={12}>
                                <Card title="Rendelés" bordered={true}>
                                    <Form.Item>
                                        {getFieldDecorator('id', {
                                            rules: [{required: false}],
                                            initialValue: this.state.editObject.id != null ? this.state.editObject.id : ""
                                        })(
                                            <Input hidden={true}/>
                                            ,
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Rendelési szám:" labelCol={{span: 6}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("purchaseOrderNo", {
                                            rules: [],
                                            initialValue: this.state.editObject.purchaseOrderNo !== "" ? this.state.editObject.purchaseOrderNo : this.state.newPurchaseOrderId
                                        })(
                                            <Input style={{width: "80%"}} disabled={true}/>
                                        )}
                                    </Form.Item>

                                    <PartnerSelect openDrawer={this.newPartner} editPartner={this.editPartner}
                                                   selectedPartnerId={this.state.editObject.partnersId}
                                                   form={this.props.form} onSelectedPartner={this.getPartnerInfo}/>

                                    <Divider type="horizontal"/>

                                    <Form.Item label="Státusz:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("purchaseOrderStatus", {
                                            rules: [],
                                            initialValue: this.state.editObject.purchaseOrderStatus != null ? this.state.editObject.purchaseOrderStatus : "Pending"
                                        })(
                                            <Select style={{width: "80%"}}>
                                                {this.state.status.map(d => (
                                                    <Option key={d} value={d}>{resolvePurchaseOrderStatus(d)}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Határidő:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("dueDate", {
                                            rules: [],
                                            initialValue: this.state.editObject.dueDate != null ? moment(this.state.editObject.dueDate) : null
                                        })(
                                            <DatePicker placeholder="Határidő" style={{width: "80%"}}/>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Várható beérkezés:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("expectedArrival", {
                                            rules: [],
                                            initialValue: this.state.editObject.expectedArrival != null ? moment(this.state.editObject.expectedArrival) : null
                                        })(
                                            <DatePicker placeholder="Várható beérkezés" style={{width: "80%"}}/>
                                        )}
                                    </Form.Item>

                                    <SiteSelector form={this.props.form} changeSite={this.changeSite}
                                                  selectedValue={this.state.editObject.siteTitle != null ? this.state.editObject.siteTitle : this.props.selectedSite.value}/>

                                    <Form.Item label="Előleg:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("deposit", {
                                            rules: [{
                                                required: true,
                                                type: "integer",
                                                message: "Nem adott meg előleget!"
                                            }],
                                            initialValue: this.state.editObject.deposit != null ? this.state.editObject.deposit : 0,
                                        })(
                                            <InputNumber style={{width: "80%"}}/>,
                                        )}
                                    </Form.Item>
                                </Card>

                            </Col>
                            <Col span={12}>
                                <Card title="Referenciák" bordered={true}>
                                    <Table dataSource={this.state.salesOrderReferences} pagination={false}>
                                        <Column title="Referencia azonosító" dataIndex="targetOrderNo" key="targetOrderNo"
                                                render={(text, record) => (<span>
                                <a href="#salesOrderNo" onDoubleClick={() => this.getSalesOrder(record.targetId)}>{text}</a>
                            </span>)}/>
                                        <Column title="Termék" dataIndex="targetOrderLineName" key="targetOrderLineName"
                                                render={(text, record) => (
                                                    record.targetOrderLineName.map(name =>
                                                        <Row>{name}</Row>)
                                                )}/>
                                    </Table>
                                </Card>
                            </Col>

                            <Col span={12}>
                                <Card title="Megjegyzés" bordered={true}>
                                    <Form.Item>
                                        {getFieldDecorator("note", {
                                            rules: [{required: false}],
                                            initialValue: this.state.editObject.note != null && this.state.editObject.note,
                                        })(
                                            <TextArea rows={4} placeholder="Megjegyzés"/>
                                        )}
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>

                        {!this.state.lock &&
                        <Row gutter={24}>
                            <Col span={24}>
                                <Card title="Műveletek" bordered={true}>
                                    <Row>
                                        <Button onClick={this.save} type="primary">Mentés</Button>
                                        {/*<Button type="danger">Törlés</Button>*/}
                                        {/*<Button type="primary">Címke nyomtatás</Button>*/}
                                        <Divider type="vertical"/>
                                        <Button type="dashed" onClick={() => {
                                            this.props.history.push('/app/purchaseOrders')
                                        }}>Vissza</Button>
                                    </Row>
                                    <Divider type="horizontal"/>
                                    {!newMode &&
                                    <Row>
                                        <Col offset={3} span={6}>
                                            <h3>Tétel státuszának módosítása</h3>
                                            <Select style={{width: "80%"}} onChange={this.changeStatus}>
                                                {this.state.status.map(d => (
                                                    <Option key={d} value={d}>{resolvePurchaseOrderStatus(d)}</Option>
                                                ))}
                                            </Select>
                                            <Button type="primary" onClick={this.saveLineStatus}
                                                    disabled={lineStatusUpdatable}> Kijelölt(ek) módosítása </Button>
                                        </Col>
                                        <Col offset={6} span={6}>
                                            <h3>Tétel áthelyezése másik beszállítóhoz</h3>
                                            <Select placeholder="Tétel áthelyezése" style={{width: "100%"}}
                                                    onChange={this.selectSupplier}>
                                                {this.state.suppliers.map(d => (
                                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                                ))}
                                            </Select>
                                            <Button type="primary" onClick={this.saveLineSupplier}
                                                    disabled={lineSavable}> Kijelölt(ek) módosítása </Button>
                                        </Col>
                                    </Row>
                                    }
                                </Card>
                            </Col>
                        </Row>
                        }
                        <Divider type="horizontal"/>

                        <Row>
                            <Col span={24}>
                                <PurchaseOrderExpandedTable rowSelection={rowSelection}
                                                            newMode={this.state.editObject.purchaseOrderNo === ""}
                                                            purchaseOrderLines={this.state.purchaseOrderLines}
                                                            getFieldDecorator={getFieldDecorator} editing={true}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" onClick={this.newProductLine}> <Icon type="plus-circle"/> Új
                                    termék</Button>
                                <Divider type="vertical"/>
                                <Button type="primary" onClick={this.addLine}> <Icon type="plus-circle"/> Új
                                    sor</Button>
                                <Divider type="vertical"/>
                                <Popconfirm
                                    title="Biztos a törlésben? A törlés nem visszavonható!"
                                    icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
                                    onConfirm={this.removeLine}
                                    okText="Igen"
                                    cancelText="Nem"
                                    disabled={!hasSelected}
                                >
                                    <Button type="danger" disabled={!hasSelected}> <Icon type="delete"/> Kijelölt(ek)
                                        törlése</Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                <ProductSelectorModal onSelectClick={this.selectProduct} visible={this.state.productChooserVisible}
                                      close={this.closeProductChooser}/>
                <PartnerDrawer visible={this.state.partnerDrawerVisible} onClose={this.partnerDrawerOnClose}
                               editId={this.state.editId}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
        newlyCreatedPartner: state.newlyCreatedPartner
    };
}

function mapDispatchToProps(dispatch) {
    return {
        unsetNewlyCreatedPartner: () => dispatch(unsetNewlyCreatedPartner())
    };
}

const connectedPurchaseOrders = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);

export default Form.create({name: 'purchaseOrder_form'})(connectedPurchaseOrders);




