import React, {Component} from "react";

class Inventory extends Component {

    render() {
        return (
            <h1>Itt lesz majd a készletkezelő</h1>
        );
    }
}

export default Inventory;