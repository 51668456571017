import React, {Component} from 'react';
import {Button} from "antd";
import SiteUtils from "../Utils/SiteUtils";

class SiteSelector extends Component {

    selectSite = (e) => {
        SiteUtils.getSiteTitleByKey(e.target.value).then(res => {

            let newSelectedSite = {
                id: res.data.id,
                title: res.data.title,
                value: res.data.nameKey
            };
            this.props.setSelectedSite(newSelectedSite);
            this.props.history.push('/app/index');
        });
    };

    render() {
        return (
            <div>
                <h1>Kérem válasszon telephelyet!</h1>
                <Button.Group size="large">
                    {this.props.sites.map(d => (
                        <Button size="large" type="primary" onClick={this.selectSite} key={d.nameKey}
                                value={d.nameKey}>{d.title}</Button>
                    ))}
                </Button.Group>
            </div>
        );
    }
}

export default SiteSelector;
