import {Select} from "antd";
import React, {Component} from "react";
import {resolvePurchaseOrderStatus} from "../Utils/Utils";

const { Option } = Select;

class PurchaseOrderStateSelector extends Component {

    state = {
        selectedId : "",
        status: ["Pending", "Ordered", "InStock", "Done"]
    };

    render() {

        return(
            <Select placeholder="Státusz" style={{width: "100%"}} onChange={this.props.changeStatus}>
                {this.state.status.map(d => (
                    <Option key={d} value={d}>{resolvePurchaseOrderStatus(d)}</Option>
                ))}
            </Select>);
    }
}

export default PurchaseOrderStateSelector;
