import {Col, InputNumber, Modal, Row} from "antd";
import React, {Component} from "react";
import {connect} from "react-redux";

class CashRegisterModal extends Component {

    constructor(props) {
        super(props);
        this.focus[10] = React.createRef();
        this.focus[20] = React.createRef();
        this.focus[50] = React.createRef();
        this.focus[100] = React.createRef();
        this.focus[200] = React.createRef();
        this.focus[500] = React.createRef();
        this.focus[1000] = React.createRef();
        this.focus[2000] = React.createRef();
        this.focus[5000] = React.createRef();
        this.focus[10000] = React.createRef();
        this.focus[20000] = React.createRef();
    }

    change = (value,type) => {
        let cashArr = this.state.cashArr;
        cashArr[type] = (value*type);
        this.setState({cashArr :cashArr},() =>{
            let sum = 0;
            for (const row in cashArr) {
               sum = sum + cashArr[row];
               this.setState({
                   difference: -(((this.props.sumOpen)-sum) + this.props.itemsSum)
               });
            }
            this.setState({sum : sum});
        });
    };

    state = {
        sum : 0,
        difference : 0,
        cashArr: {
            "5" : 0,
            "10": 0,
            "20": 0,
            "50": 0,
            "100": 0,
            "200": 0,
            "500": 0,
            "1000": 0,
            "2000": 0,
            "5000": 0,
            "10000": 0,
            "20000": 0,
        }
    };

    focus = (e,number) =>{ this.focus[number].current.focus(); };

    open = (e) => {
        this.props.onOk(e,this.state.sum);
    };

    render() {
        return (
            <Modal
                visible={this.props.visibleCR}
                onOk={this.open}
                okText="Mentés"
                cancelText="Mégse"
                onCancel={this.props.onCancel}
                closable={false}
                maskClosable={false}
            >

                <br/>
                <br/>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>5</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber autoFocus={true} onPressEnter={(e) => this.focus(e,10)} defaultValue={0} min={0} onChange={(e) => this.change(e, '5')}/>
                    </Col>
                    <Col span={3}>
                        <p>500</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['500']} onPressEnter={(e) => this.focus(e,1000)} defaultValue={0} min={0} onChange={(e) => this.change(e, '500')}/>
                    </Col>
                </Row>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>10</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['10']} onPressEnter={(e) => this.focus(e,20)} defaultValue={0} min={0} onChange={(e) => this.change(e, '10')} />
                    </Col>
                    <Col span={3}>
                        <p>1000</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['1000']} onPressEnter={(e) => this.focus(e,2000)} defaultValue={0} min={0} onChange={(e) => this.change(e, '1000')}/>
                    </Col>
                </Row>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>20</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['20']} onPressEnter={(e) => this.focus(e,50)} defaultValue={0} min={0} onChange={(e) => this.change(e, '20')}/>
                    </Col>
                    <Col span={3}>
                        <p>2000</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['2000']} onPressEnter={(e) => this.focus(e,5000)} defaultValue={0} min={0} onChange={(e) => this.change(e, '2000')}/>
                    </Col>
                </Row>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>50</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['50']} onPressEnter={(e) => this.focus(e,100)} defaultValue={0} min={0} onChange={(e) => this.change(e, '50')}/>
                    </Col>
                    <Col span={3}>
                        <p>5000</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['5000']} onPressEnter={(e) => this.focus(e,10000)} defaultValue={0} min={0} onChange={(e) => this.change(e, '5000')}/>
                    </Col>
                </Row>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>100</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['100']} onPressEnter={(e) => this.focus(e,200)} defaultValue={0} min={0} onChange={(e) => this.change(e, '100')}/>
                    </Col>
                    <Col span={3}>
                        <p>10000</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['10000']} onPressEnter={(e) => this.focus(e,20000)} defaultValue={0} min={0} onChange={(e) => this.change(e, '10000')}/>
                    </Col>
                </Row>
                <Row gutter={12} type="flex" justify="space-around">
                    <Col span={3}>
                        <p>200</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['200']} onPressEnter={(e) => this.focus(e,500)} defaultValue={0} min={0} onChange={(e) => this.change(e, '200')}/>
                    </Col>
                    <Col span={3}>
                        <p>20000</p>
                    </Col>
                    <Col span={3}>
                        <InputNumber ref={this.focus['20000']} defaultValue={0} min={0} onChange={(e) => this.change(e, '20000')}/>
                    </Col>
                </Row>

                <h1>Összesen: {this.state.sum}</h1>

                {this.state.difference!==0 && this.props.sumOpen!==undefined &&
                    <h2>Eltérés: {this.state.difference}</h2>
                }

            </Modal>
        )
    }
}
function mapStateToProps(state) {
    return {
        difference: state.difference
    };
}

export default connect(mapStateToProps)(CashRegisterModal);