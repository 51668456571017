import React, {Component} from "react";
import {Button, Divider, Modal, Table} from "antd";
import Descriptions from "antd/es/descriptions";
import EmailField from "./EmailField";
import AddressUtil from "../Utils/AddressUtil";

const {Column} = Table;
class PartnerInfoModal extends Component{

    render() {
        let addressUtil = new AddressUtil();
        let addressLine = addressUtil.getAddressLine(this.props.partner);
        let postalAddressLine = addressUtil.getPostalAddressLine(this.props.partner);
        return(
            <Modal
                title="Partner infó"
                visible={this.props.visibleInfoModal}
                onOk={this.props.onClose}
                onCancel={this.props.onClose}
                width={"70%"}
                footer={[
                    <Button key="partner_info_modal_close_button" type="primary" onClick={this.props.onClose}>Bezárás</Button>
                ]}
            >
                <h2>Partner adatai: </h2>
                <Descriptions bordered layout="vertical" border size="default">
                    <Descriptions.Item label="Név">{this.props.partner.name}</Descriptions.Item>
                    <Descriptions.Item
                        label="Cím">{addressLine}</Descriptions.Item>
                    <Descriptions.Item label="Email cím"><EmailField
                        email={this.props.partner.email}/> </Descriptions.Item>
                    <Descriptions.Item label="Adószám">{this.props.partner.vatNumber}</Descriptions.Item>
                    <Descriptions.Item label="Telefonszám">{this.props.partner.phone}</Descriptions.Item>
                    <Descriptions.Item label="Megjegyzés">{this.props.partner.note}</Descriptions.Item>
                </Descriptions>

                {this.props.partner.postalCode != null &&
                <div>
                <h2>Szállítási adatok: </h2>
                <Descriptions bordered layout="vertical" border size="default">
                    <Descriptions.Item label="Név">{this.props.partner.postalName}</Descriptions.Item>
                    <Descriptions.Item
                        label="Cím">{postalAddressLine}</Descriptions.Item>
                    <Descriptions.Item label="Email cím"><EmailField
                        email={this.props.partner.email}/> </Descriptions.Item>
                    <Descriptions.Item label="Adószám">{this.props.partner.vatNumber}</Descriptions.Item>
                    <Descriptions.Item label="Telefonszám">{this.props.partner.phone}</Descriptions.Item>
                    <Descriptions.Item label="Megjegyzés">{this.props.partner.note}</Descriptions.Item>
                </Descriptions>
                </div>
                }

                <Divider type="horizontal"/>

                <h2>Korábbi vásárlások: </h2>
                <Table dataSource={this.props.orders} expandedRowRender={record => (<OrderLines record={record}/>)} pagination={false} rowKey="id">
                    <Column title="ID" dataIndex="id" key="id"/>
                    <Column title="Rendelési szám" dataIndex="salesOrderNo" key="salesOrderNo"/>
                    <Column title="Teljesítve" dataIndex="dueDate" key="dueDate"/>
                    <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>
                    <Column title="Megjegyzés" dataIndex="note" key="note"/>
                </Table>
            </Modal>
        );
    }

}

const OrderLines = (props) => {
    console.log("props: ",props)
    return (
        <Table dataSource={props.record.salesOrderLines} pagination={false} rowKey="id">
            <Column title="Megnevezés" dataIndex="name" key="name"/>
            <Column title="Mennyiség" dataIndex="qty" key="qty"/>
            <Column title="Egység" dataIndex="uom" key="uom"/>
            <Column title="Áfa" dataIndex="tax.name" key="tax.name"/>
            <Column title="Értékesítési Ár (Nettó)" dataIndex="netSellPrice" key="netSellPrice"/>
            <Column title="Értékesítési Ár (Bruttó)" dataIndex="grossSellPrice" key="grossSellPrice"/>
        </Table>
    );
};

export default PartnerInfoModal;