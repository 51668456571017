import React, {Component} from "react";
import {
    Affix,
    Button,
    Card, Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Icon,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Spin,
    Table
} from "antd";
import PartnerSelect from "../Components/PartnerSelect";
import SiteSelector from "../Components/SiteSelector";
import ProductSelectorModal from "../Components/ProductSelectorModal";
import PartnerDrawer from "../Components/PartnerDrawer";
import SalesOrderLineTable from "../Components/SalesOrderLineTable";
import {unsetNewlyCreatedPartner} from "../actions";
import {connect} from "react-redux";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import moment from "moment";
import BidModal from "../Components/BidModal";
import Descriptions from "antd/es/descriptions";
import EmailField from "../Components/EmailField";
import Tags from "../Components/Tags";
import {CalculateUtils} from "../Utils/CalculateUtils";
import Column from "antd/es/table/Column";
import PartnerInfoModal from "../Components/PartnerInfoModal";
import AddressUtil from "../Utils/AddressUtil";
import confirm from "antd/es/modal/confirm";
import QueryParams from "../Utils/QueryParams";
import Hotkeys from "react-hot-keys";
import ButtonGroup from "antd/es/button/button-group";
import ResponsibleSelector from "../Components/ResponsibleSelector";
import {resolveDocType, resolvePaymentType, resolveSalesOrderStatus} from "../Utils/Utils";

const {TextArea} = Input;
const {Option} = Select;

class SalesOrder extends Component {

    state = {
        loading: true,
        users: [],
        selectedUser: null,
        showBidModal: false,
        currentUser: null,
        salesOrderLines: [
            {
                name: "",
                netPurchasePrice: 0,
                grossPurchasePrice: "",
                profit: "",
                netProfit: "",
                grossProfit: "",
                netProfitPerUnit: "",
                grossProfitPerUnit: "",
                netSellPrice: "",
                grossSellPrice: "",
                qty: 1,
                uom: "db",
                taxId: 1,
                salesOrderLineExtras: [{value: ""}, {value: []}, {value: ""}],
                createdBy: "",
                modifiedBy: "",
                createdDate: "",
                updatedDate: "",
                isPoRequired: ""
            }
        ],
        selectedRowKeys: [],
        newSalesOrderId: "",
        partnerDrawerVisible: false,
        editId: "",
        selectedPartner: "",
        editObject:
            {
                id: null,
                documentStatus: "",
                salesOrderNo: "",
                siteTitle: null,
                salesOrderStatus: "",
                docType: null,
                paymentTypeId: null,
                deposit: null,
                deliveryTypeId: null,
                invoicePaymentDueDate: null,
                dueDate: null,
                invoiceCreateDate: null,
                invoiceDocType: null,
                responsible: null,
                documentRelations: []
            },
        taxList: [],
        docType: [],
        paymentType: [],
        deliveryType: [],
        status: ["BidRequest", "BidOffered", "BidAccepted", "Pending", "AwaitingPayment", "InPurchaseOrder", "OrderInStock", "Shipped", "CustomerNoticed", "Billable", "Closed", "Cancelled"],
        selectedStatus: null,
        productChooserVisible: false,
        paymentTypeNotCash: true,
        bids: [],
        visiblePartnerInfo: false,
        partner: {
            name: null,
            city: null,
            zipCode: null,
            address: null,
            vatNumber: null,
            phone: null,
            email: null,
            note: null
        },
        orders: [],
        foreignCurrency: false,
        openedCashRegisters: [],
        createInvoiceModal: false,
        stornoInvoiceModal: false,
        docNumbers: [],
        selectedDocNumber: null,
        selectedCashRegister: null,
        locked: false,
        invoiced: false,
        notInBidStatus: false,
        stornoVisible: false,
        closed: false,
        closedWithoutInvoice: false,
        fieldSetDisabled: false,
        preview: "",
        getDocumentLoading: false,
        references: [],
        history: []
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        console.log("params: ", params);

        if (params.id != null) {
            this.fetchData(params.id);
            this.getBids(params.id);
        } else {
            this.getLastSalesOrderNo(this.props.selectedSite.value);
        }

        console.log("productsForSale: ", this.props.productsForSale);
        let products = localStorage.getItem('productsForSale') ? JSON.parse(localStorage.getItem('productsForSale')) : [];
        if (Object.keys(products).length > 0) {
            for (let i = 0; i < products.length; i++) {
                this.selectProduct(products[i].productCode)
            }
        }

        this.getOpenedCashRegisters();

        this.setState({
            users: JSON.parse(localStorage.getItem('users')),
            docNumbers: JSON.parse(localStorage.getItem('docNumbers')),
            deliveryType: JSON.parse(localStorage.getItem('deliveryTypes')),
            paymentType: JSON.parse(localStorage.getItem('paymentTypes')),
            docType: JSON.parse(localStorage.getItem('docTypes')),
            taxList: JSON.parse(localStorage.getItem('taxValues'))
        })

        let path = this.props.match.path;
        if (path === "/app/new-bidOrder") {
            this.setState({editObject: {...this.state.editObject, salesOrderStatus: "BidRequest"}});
        } else if (path === "/app/new-salesOrder") {
            this.setState({editObject: {...this.state.editObject, salesOrderStatus: "BidAccepted"}});
        } else if (path === "/app/new-sales") {
            this.setState({
                editObject: {
                    ...this.state.editObject,
                    salesOrderStatus: "Billable",
                    docType: "CashRegisterSale"
                }
            });
        }
    }

    componentWillUnmount() {
        if (localStorage.getItem('productsForSale')) {
            localStorage.removeItem('productsForSale');
        }
        if (this.state.editObject.id != null) {
            API.post("salesOrder/unlock", {id: this.state.editObject.id, username: this.state.currentUser})
        }
    }

    //getHistory = (id) => {
        // API.get("getSalesOrderHistory/" + id)
        //     .then(res => {
        //         console.log("History: ", res.data);
        //         this.setState({history: res.data})
        //     })
    //}

    fetchData = (id) => {
        console.log("Fetching data...")
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "";
        API.get("salesOrder/getSalesOrder/" + id).then(res => {
            let {data} = res;
            if (data.type === "error") {
                NotificationUtil(data);
                setTimeout(() => {
                    this.props.history.goBack();
                }, 2500);
            } else {
                if (data.type === "warning") {
                    NotificationUtil(data);
                }
                console.log("salesOrder:", data);
                this.setState({
                    editObject: data.extraList[0],
                    salesOrderLines: data.extraList[0].salesOrderLines,
                    loading: false,
                    currentUser: user.username
                });
                if (data.exchangeRate != null) {
                    this.setState({foreignCurrency: true})
                }

                let closed = data.extraList[0].salesOrderStatus === "Closed" || data.extraList[0].salesOrderStatus === "Cancelled";
                let closedWithoutInvoice = closed && data.extraList[0].documentStatus === null;
                let locked = data.extraList[0].isLocked && data.extraList[0].lockedBy !== user.username;
                let invoiced = (data.extraList[0].documentStatus != null && data.extraList[0].documentStatus === "INVOICED") || (closed && data.extraList[0].docType === "Invoice") || (closed && data.extraList[0].docType === "CancelledInvoice");
                let notInBidStatus = data.extraList[0].salesOrderStatus !== "BidRequest";
                let stornoVisible = !closedWithoutInvoice && data.extraList[0].documentStatus !== "STORNO" && (closed && data.extraList[0].docType === "Invoice");
                let fieldSetDisabled = locked || invoiced || closed || closedWithoutInvoice;

                console.log("invoiced? ", (!this.state.invoiced));
                console.log("closed? ", (!this.state.closed));
                this.setState({
                    closed,
                    closedWithoutInvoice,
                    locked,
                    invoiced,
                    notInBidStatus,
                    stornoVisible,
                    fieldSetDisabled
                })
                if (locked) {
                    NotificationUtil({msg: "Dokumentum zárolva, csak megtekinthető!", type: "error"});
                } else {
                    API.post("/salesOrder/lock", {id: id, username: user.username});
                }

                this.getRefs(id);
                //this.getHistory(id);
            }
        });
    };

    getRefs = (id) => {
        API.get("salesOrder/getRefs/" + id)
            .then(res => {
                this.setState({references: res.data})
            })
    }

    getPurchaseOrder = (id) => {
        this.props.history.push('/app/edit-purchaseOrder/' + id)
    }

    getSalesOrderIdByRefNo = (refNo) => {
        API.get("salesOrder/getIdByRefNo/" + refNo)
            .then(res => {
                //TODO Fix this
                this.componentWillUnmount();
                window.location = "/app/edit-salesOrder/" + res.data;
                //this.props.history.push('/app/edit-salesOrder/' + res.data);
            })
    }

    getOpenedCashRegisters = () => {
        API.get("/cash-register/get-opened-cash-registers")
            .then(res => {
                this.setState({openedCashRegisters: res.data})
            })
    }

    getBids = (id) => {
        API.get("bid/getBids/" + id).then(res => {
            this.setState({
                bids: res.data.lines
            })
        });
    }

    newProductLine = () => {
        this.setState({productChooserVisible: true});
    };

    addLine = () => {
        let line = {
            name: "",
            netPurchasePrice: 0,
            grossPurchasePrice: "",
            profit: "",
            netProfit: "",
            grossProfit: "",
            netProfitPerUnit: "",
            grossProfitPerUnit: "",
            netSellPrice: "",
            grossSellPrice: "",
            qty: 1,
            uom: "db",
            taxId: 1,
            salesOrderLineExtras: [{value: ""}, {value: []}, {value: ""}],
            createdBy: "",
            modifiedBy: "",
            createdDate: "",
            updatedDate: ""
        };
        this.setState(prevState => ({salesOrderLines: [...prevState.salesOrderLines, line]}));
    };

    calculate = (e, type, index, form) => CalculateUtils(e, type, index, form, this.state.salesOrderLines, this.state.taxList, "salesOrderLines");

    getLastSalesOrderNo(sitePrefix) {
        API.get("salesOrder/getLastSalesOrderNo/" + sitePrefix).then(res => {
            let {data} = res;
            this.setState({newSalesOrderId: data.salesOrderNo, loading: false});
        });
    }

    changeSite = (e) => {
        this.getLastSalesOrderNo(e)
    };

    getPartnerInfo = (e, data) => {
        this.setState({selectedPartner: data}, () => this.partnerInfo(data.id));
    };

    partnerInfo = (id) => {
        if (id !== "null" && typeof id !== "undefined"
            && id !== null && id !== undefined) {
            API.get("/getPartner/" + id)
                .then(res => {
                    let partnerDetails = res.data;
                    this.setState({
                        partner: partnerDetails
                    })
                });
            API.get("/getSalesOrdersByPartnerId/" + id)
                .then(res => {
                    this.setState({
                        orders: res.data.lines
                    })
                });
        }
    };

    save = (generatePO) => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log(values);
            API.post("salesOrder/save", values).then(res => {
                form.resetFields();
                NotificationUtil(res.data);
                if (generatePO && res.data.type === "success") {
                    let id = res.data.extraList[0].id;
                    this.generatePO(id);
                } else if (res.data.type !== "success") {
                    NotificationUtil({msg: "Hiba történt a mentés közben!", type: "error"});
                }
                this.props.history.push('/app/salesOrder');
            }).catch(error => {
                console.log(error);
                NotificationUtil({msg: "Hiba történt!", type: "error"});
            });
        });
    };

    printBid = () => {
        const {form} = this.props;
        form.validateFields(err => {
            if (err) {
                return;
            }
            this.setState({showBidModal: true});
        });
    };

    getPDF = (id) => {
        API.get("/bid/generate-pdf/" + id, {responseType: 'blob'})
            .then(res => {
                const file = new Blob(
                    [res.data],
                    {type: 'application/pdf'});
                const url = URL.createObjectURL(file);
                const newWindow = window.open('url', '_blank');
                newWindow.title = "PDF Árajánlat";
                newWindow.document.write("<head><title>PDF Árajánlat</title></head>");
                newWindow.document.write('<iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src="' + url + '">');
                newWindow.focus();
            }).catch(error => {
            console.log(error);
        });
    };

    onOkBid = () => {
        this.onCancelBid();
        this.getBids(this.props.match.params.id)
    };

    onCancelBid = () => {
        this.setState({showBidModal: false})
    };

    generatePO = (id) => {
        API.post("purchaseOrder/generatePO/" + id)
            .then(res => {
                NotificationUtil(res.data);
            }).catch(error => {
            NotificationUtil({msg: "Hiba történt!" + error, type: "error"});
        });
    };

    newPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: ""
        });
    };

    editPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: this.state.selectedPartner.id
        });
    };

    partnerDrawerOnClose = () => {
        this.setState({
            partnerDrawerVisible: false,
            editId: ""
        });
    };

    onRowChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    getCheckboxProps = (record) => {
        return {
            disabled: record.index === 0 || this.state.closed,
            name: record.name,
        }
    };

    removeLine = (record, index, fields) => {
        let {salesOrderLines} = this.state;
        this.setState({salesOrderLines: salesOrderLines.filter(item => item !== record)});
        const {form} = this.props;
        form.resetFields(fields);
        form.setFieldsValue(salesOrderLines);
    };

    closeProductChooser = () => {
        this.setState({productChooserVisible: false,});
    };

    selectProduct = (e) => {
        this.setState({
            productChooserVisible: false,
        });
        let qp = new QueryParams();
        qp.setCustomParams({id: e})
        API.get("/product-by-productId/" + qp.getQueryParam()).then(res => {
            let {data} = res;
            console.log("product: ", data);
            let line = {
                productCode: data.productCode,
                name: data.name,
                qty: 1,
                uom: "db",
                netPurchasePrice: data.netPurchasePrice,
                status: "",
                created: "",
                updated: "",
                user: "",
                userUpdated: "",
                grossSellPrice: data.grossSellPrice
            };
            let line0 = this.state.salesOrderLines[0];
            if (line0.name === "") {
                let lines = this.state.salesOrderLines;
                lines.splice(0, 1);
                this.setState({salesOrderLines: lines})
            }
            this.setState(prevState => ({
                salesOrderLines: [...prevState.salesOrderLines, line]
            }));
        });
    };

    statusSelect(e) {
        this.setState({selectedStatus: e})
    };

    userSelect(e) {
        this.setState({selectedUser: e})
    };

    onPaymentTypeChange(e) {
        console.log(e);
        if (e === 1) {
            this.setState({
                paymentTypeNotCash: true
            })
        } else {
            this.setState({
                paymentTypeNotCash: false
            })
        }
    };

    disabledDate = (current) => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        return (current.valueOf() < currentDate);
    };

    partnerInfoModalVisible = () => {
        this.setState({visiblePartnerInfo: true})
    }

    handleInfoModalClose = () => {
        this.setState({visiblePartnerInfo: false})
    }

    createInvoiceModal = () => {
        const {form} = this.props;
        form.validateFields(err => {
            if (err) {
                return;
            }
            let createInvoiceModal = this.state.createInvoiceModal;
            this.setState({createInvoiceModal: !createInvoiceModal})
        });
    }

    stornoInvoiceModal = () => {
        const {form} = this.props;
        form.validateFields(err => {
            if (err) {
                return;
            }
            let stornoInvoiceModal = this.state.stornoInvoiceModal;
            this.setState({stornoInvoiceModal: !stornoInvoiceModal})
        });
    }

    onCashRegisterSelect = (e) => {
        this.setState({selectedCashRegister: e});
    }

    onDocNumberSelect = (e) => {
        this.setState({selectedDocNumber: e});
    }

    createInvoice = (preview) => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            // add chosen cr to values
            values.cashRegisterId = this.state.selectedCashRegister
            values.docNumber = this.state.selectedDocNumber == null ? this.state.docNumbers[0].prefix : this.state.selectedDocNumber
            console.log(values);
            let current = this;
            if (this.state.selectedPartner.vatNumber === null) {
                confirm({
                    title: "Hiányzó adószám!",
                    content: "A partnernek nincs megadva adószám, így nem fog adatszolgáltatás történni a NAV felé!",
                    okText: "Folytatás",
                    cancelText: "Adószám megadása",
                    onOk() {
                        current.setState({getDocumentLoading: true})
                        API.post("invoice/createInvoice/" + preview, values)
                            .then(res => {
                                if (preview) {
                                    current.openDocument(res.data);
                                } else {
                                    form.resetFields();
                                    NotificationUtil(res.data);
                                    current.props.history.push('/app/salesOrder');
                                }
                            }).catch(() => {
                            current.setState({getDocumentLoading: false})
                            NotificationUtil({msg: "Hiba történt!", type: "error"});
                        });
                    }
                })
            } else {
                this.setState({getDocumentLoading: true})
                API.post("invoice/createInvoice/" + preview, values)
                    .then(res => {
                        if (preview) {
                            this.openDocument(res.data);
                        } else {
                            form.resetFields();
                            NotificationUtil(res.data);
                            this.props.history.push('/app/salesOrder');
                        }
                    }).catch(() => {
                    this.setState({getDocumentLoading: true})
                    NotificationUtil({msg: "Hiba történt!", type: "error"});
                });
            }
        });
    }

    closeSalesOrder = () => {
        const current = this;
        confirm({
            title: "Biztosan le szeretné zárni az értékesítést?",
            content: "Lezárás után nem végezhető el semmilyen művelet, és nem visszavonható!",
            okText: "Lezárás",
            cancelText: "Mégsem",
            onOk() {
                API.post("salesOrder/closeSalesOrder/" + current.state.editObject.id)
                    .then(res => {
                        NotificationUtil(res.data);
                        current.props.history.push('/app/salesOrder');
                    }).catch(() => {
                    NotificationUtil({msg: "Hiba történt!", type: "error"});
                });
            }
        })
    }

    invoiceCancellation = () => {
        API.post("invoice/invoiceCancellation/" + this.state.editObject.id + "/" + this.state.selectedDocNumber).then(res => {
            NotificationUtil(res.data);
            this.props.history.push('/app/salesOrder');
        }).catch(() => {
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    }

    onCurrencyChange = (e) => {
        if (e !== "HUF") {
            this.setState({foreignCurrency: true});
        } else {
            this.setState({foreignCurrency: false});
        }
    }

    onKeyDown(keyName) {
        if (keyName === "shift+n") {
            this.addLine();
        } else if (keyName === "shift+enter") {
            this.newProductLine();
        }
    }

    getInvoice = () => {
        this.setState({getDocumentLoading: true})
        API.post("invoice/getDocument/" + this.state.editObject.invoiceNo)
            .then(res => {
                this.openDocument(res.data);
            }).catch(() => this.setState({getDocumentLoading: false}))
    }

    openDocument(data) {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], {type: 'application/pdf'});

        const url = URL.createObjectURL(file);
        const newWindow = window.open('url', '_blank');
        newWindow.title = "Számla";
        newWindow.document.write("<head><title>Számla</title></head>");
        newWindow.document.write('<iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src="' + url + '">');
        newWindow.focus();
        this.setState({getDocumentLoading: false})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onRowChange,
            getCheckboxProps: this.getCheckboxProps
        };
        let addressUtil = new AddressUtil();
        let addressLine = addressUtil.getAddressLine(this.state.selectedPartner);
        let netSum = this.state.salesOrderLines && this.state.salesOrderLines.reduce((a, v) => a + parseFloat(v.sumNetPrice), 0).toString();
        let taxSum = this.state.salesOrderLines && this.state.salesOrderLines.reduce((a, v) => a + (parseFloat(v.sumGrossPrice) - parseFloat(v.sumNetPrice)), 0).toString();
        let grossSum = this.state.salesOrderLines && this.state.salesOrderLines.reduce((a, v) => a + parseFloat(v.sumGrossPrice), 0).toString();

        let title = <h3>Értékesítés</h3>;
        if (this.state.editObject.docType === "CancelledInvoice" && this.state.closed)
            title = <h3 style={{color: "red"}}>Sztornó számla</h3>;

        if (this.state.editObject.documentStatus === "INVOICED")
            title = <h3>Számla</h3>;

        if (this.state.editObject.documentStatus === "STORNO")
            title = <h3 style={{color: "red"}}>Sztornózott számla</h3>;

        return (
            <div>
                <Hotkeys
                    keyName="shift+n,shift+enter"
                    onKeyDown={this.onKeyDown.bind(this)}
                    filter={event => true}
                >
                    <Spin spinning={this.state.loading}>
                        <Form onSubmit={this.handleSubmit} className="partner-form" layout="vertical">
                            <Row gutter={24}>
                                <Card title={title} bordered={true}>
                                    <Affix offsetTop={10}>
                                        <div style={{backgroundColor: "white"}}>
                                            <Row gutter={24}>
                                                <ButtonGroup>
                                                    {!this.state.invoiced &&
                                                    <Button disabled={this.state.locked} onClick={() => this.save(false)}
                                                            size={"large"} type="primary">Mentés</Button>
                                                    }

                                                    {!this.state.notInBidStatus &&
                                                    <span>
                                                    <Button type="primary" size={"large"}
                                                            disabled={this.state.locked}
                                                            onClick={e => this.printBid(e)}>
                                                        Árajánlat készítés
                                                    </Button>
                                                </span>
                                                    }

                                                    {!this.state.invoiced &&
                                                    <span>
                                                        <Button type="primary" size={"large"}
                                                                disabled={this.state.locked}
                                                                onClick={this.createInvoiceModal}>
                                                            Számla kiállítása
                                                        </Button>
                                                    </span>
                                                    }
                                                    {!this.state.invoiced &&
                                                    <span>
                                                        <Button type="primary" size={"large"}
                                                                loading={this.state.getDocumentLoading}
                                                                onClick={() => this.createInvoice("true")}>
                                                            Számla előnézet
                                                        </Button>
                                                    </span>
                                                    }
                                                    {(this.state.invoiced && !this.state.closedWithoutInvoice) &&
                                                    <span>
                                                        <Button type="primary" size={"large"}
                                                                loading={this.state.getDocumentLoading}
                                                                onClick={this.getInvoice}>
                                                            Nyomtatás
                                                        </Button>
                                                    </span>
                                                    }

                                                    {this.state.stornoVisible &&
                                                    <span>
                                                    <Button type="danger" size={"large"}
                                                            disabled={this.state.locked}
                                                            onClick={this.stornoInvoiceModal}>
                                                        Sztornózás
                                                    </Button>
                                                </span>
                                                    }

                                                    {(!this.state.closed && this.state.editObject.id) &&
                                                    <span>
                                                    <Button type="danger" size={"large"}
                                                            disabled={this.state.locked}
                                                            onClick={this.closeSalesOrder}>Lezárás</Button>
                                                </span>
                                                    }

                                                    <Button type="dashed" size={"large"} onClick={() => {
                                                        this.props.history.push('/app/salesOrder')
                                                    }}>Vissza</Button>
                                                </ButtonGroup>
                                            </Row>
                                        </div>
                                    </Affix>

                                    {this.state.locked &&
                                    <h2 style={{color: "red"}}>Dokumentum
                                        zárolva, {this.state.editObject.lockedBy} felhasználó épp szerkeszti!</h2>
                                    }

                                    {Object.keys(this.state.editObject.documentRelations).length > 0 &&
                                    <Table title={() => "Kapcsolódó dokumentumok"}
                                           dataSource={this.state.editObject.documentRelations} bordered={true}>
                                        <Column title="Azonosító" dataIndex="source" key="source"
                                                render={(documentNo) => (
                                                    <span>
                                                        {this.state.editObject.refNo === documentNo ? "Ez a számla" :
                                                            <a href="#salesOrderNo"
                                                               onDoubleClick={() => this.getSalesOrderIdByRefNo(documentNo)}>{documentNo}</a>}
                                                    </span>)}/>
                                        <Column title="Új dokumentum" dataIndex="destination" key="destination"
                                                render={(documentNo) => (
                                                    <span>
                                                        {this.state.editObject.refNo === documentNo ? "Ez a számla" :
                                                            <a href="#salesOrderNo"
                                                               onDoubleClick={() => this.getSalesOrderIdByRefNo(documentNo)}>{documentNo}</a>}
                                                    </span>)}/>
                                        <Column title="Művelet" dataIndex="type" key="type"
                                                render={(type) => (
                                                    <span>
                                                        {type === "INVOICE_CREATION" && "Létrehozás"}
                                                        {type === "INVOICE_STORNO" && "Sztornózás"}
                                                        {type === "INVOICE_MODIFICATION" && "Módosítás"}
                                                        {type === "INVOICE_FINALIZE" && "Végszámla"}
                                                    </span>)}/>
                                    </Table>}

                                    <Col span={12}>
                                        <fieldset disabled={this.state.fieldSetDisabled}>
                                            <Form.Item>
                                                {getFieldDecorator('id', {
                                                    rules: [{required: false}],
                                                    initialValue: this.state.editObject.id != null ? this.state.editObject.id : "",
                                                })(
                                                    <Input hidden={true}/>
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Értékesítési szám:" labelCol={{span: 6}}
                                                       wrapperCol={{span: 15}}>
                                                {getFieldDecorator("salesOrderNo", {
                                                    initialValue: this.state.editObject.salesOrderNo !== "" ? this.state.editObject.salesOrderNo : this.state.newSalesOrderId,
                                                    rules: [],
                                                })(
                                                    <Input disabled={true} style={{width: "80%"}}/>
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Referencia szám:" labelCol={{span: 6}}
                                                       wrapperCol={{span: 15}}>
                                                {getFieldDecorator("refNo", {
                                                    rules: [],
                                                    initialValue: this.state.editObject.refNo !== "" ? this.state.editObject.refNo : "",
                                                })(
                                                    <Input disabled={true} style={{width: "80%"}}/>
                                                )}
                                            </Form.Item>

                                            <PartnerSelect openDrawer={this.newPartner} editPartner={this.editPartner}
                                                           selectedPartnerId={this.state.editObject.partnersId}
                                                           form={this.props.form}
                                                           onSelectedPartner={this.getPartnerInfo}/>

                                            <Form.Item label="Státusz:" labelCol={{span: 6}} wrapperCol={{span: 15}}>
                                                {getFieldDecorator("salesOrderStatus", {
                                                    initialValue: this.state.editObject.salesOrderStatus != null ? this.state.editObject.salesOrderStatus : "",
                                                    rules: [],
                                                })(
                                                    <Select showSearch={true} onChange={e => this.statusSelect(e)}
                                                            style={{width: "80%"}}
                                                            filterOption={(inputValue, option) =>
                                                                option.props.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .includes(inputValue.toLowerCase())
                                                            }>
                                                        {this.state.status.map((d, index) => (
                                                            <Option key={d}
                                                                    value={d}>{index + 1} - {resolveSalesOrderStatus(d)}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="E-Mail küldése:" labelCol={{span: 6}}
                                                       wrapperCol={{span: 4}}>
                                                {getFieldDecorator("sendEmail", {
                                                    initialValue: false,
                                                    valuePropName: "checked"
                                                })(
                                                    <Checkbox/>
                                                )}
                                            </Form.Item>

                                            {this.state.selectedStatus === "BidAccepted" &&
                                            <Button type="primary" onClick={() => this.save(true)}>
                                                Beszerzés létrehozása és mentés
                                            </Button>
                                            }
                                            {this.props.match.path === "/app/new-salesOrder" &&
                                            <Button type="primary" onClick={() => this.save(true)}>
                                                Beszerzés létrehozása és mentés
                                            </Button>
                                            }
                                        </fieldset>
                                    </Col>
                                    <Col span={12}>
                                        <h3>Partner Infó <Button onClick={this.partnerInfoModalVisible}
                                                                 hidden={this.state.selectedPartner === ""}>Több
                                            infó</Button></h3>
                                        {this.state.selectedPartner !== "" &&
                                        <Descriptions bordered layout="vertical" border size="small">
                                            <Descriptions.Item
                                                label="Név">{this.state.selectedPartner.name}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="Telefonszám">{this.state.selectedPartner.phone}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="Cím">{addressLine}</Descriptions.Item>
                                            <Descriptions.Item label="Email cím"><EmailField
                                                email={this.state.selectedPartner.email}/> </Descriptions.Item>
                                            <Descriptions.Item label="Címkék"> <Tags
                                                tags={this.state.selectedPartner.tags ? this.state.selectedPartner.tags : []}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Megjegyzés">{this.state.selectedPartner.note}</Descriptions.Item>
                                        </Descriptions>
                                        }
                                        {Object.keys(this.state.references).length > 0 &&
                                        <Table dataSource={this.state.references} pagination={false}>
                                            <Column title="Referencia azonosító" dataIndex="targetOrderNo"
                                                    key="targetOrderNo"
                                                    render={(text, record) => (
                                                        <span><a href="#purchaseOrderNo"
                                                                 onDoubleClick={() => this.getPurchaseOrder(record.targetId)}>{text}</a></span>)}/>
                                            <Column title="Termék" dataIndex="targetOrderLineName"
                                                    key="targetOrderLineName"
                                                    render={(text, record) => (
                                                        record.targetOrderLineName.map(name =>
                                                            <Row>{name}</Row>)
                                                    )}/>
                                        </Table>
                                        }

                                    </Col>
                                </Card>
                            </Row>

                            <fieldset disabled={this.state.fieldSetDisabled}>
                                <Row style={{paddingTop: 10, paddingBottom: 10}}>
                                    <Col span={24}>
                                        <SalesOrderLineTable calculate={this.calculate} rowSelection={rowSelection}
                                                             type={'salesOrderLines'}
                                                             lines={this.state.salesOrderLines}
                                                             form={this.props.form}
                                                             getFieldDecorator={getFieldDecorator}
                                                             taxValues={this.state.taxList}
                                                             removeLine={this.removeLine}
                                                             editing={true}/>
                                    </Col>
                                </Row>

                                <Row style={{paddingBottom: 10}}>
                                    <Col span={18}>
                                        <Button type="primary" onClick={this.newProductLine}> <Icon
                                            type="plus-circle"/> Új termék (Shift+Enter)</Button>
                                        <Divider type="vertical"/>
                                        <Button type="primary" onClick={this.addLine}> <Icon type="plus-circle"/> Új
                                            sor (Shift+N)</Button>
                                    </Col>
                                    <Col span={6}>
                                        {!isNaN(parseFloat(netSum.toString())) &&
                                        <h3>Összesen
                                            nettó: {parseFloat(netSum).toFixed(2).toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')} Ft</h3>
                                        }
                                        {!isNaN(parseFloat(taxSum.toString())) &&
                                        <h3>Áfa: {parseFloat(taxSum).toFixed(2).toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')} Ft</h3>
                                        }
                                        {!isNaN(parseFloat(grossSum.toString())) &&
                                        <h2>Összesen
                                            bruttó: {parseFloat(grossSum).toFixed(2).toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')} Ft</h2>
                                        }
                                    </Col>
                                </Row>

                                {this.state.bids.length > 0 &&
                                <Row gutter={24}>
                                    <Card title="Kiadott árajánlatok">
                                        <Table dataSource={this.state.bids} pagination={false} rowKey="id">
                                            <Column title="Árajánlat azonosító" dataIndex="bidNo" key="bidNo"
                                                    render={(bidNo, record) => (
                                                        <span>
                                                        <a href="#bidNo"
                                                           onDoubleClick={() => this.getPDF(record.id)}>{bidNo}</a>
                                                        </span>)}/>

                                            <Column title="Létrehozva" dataIndex="date" key="date"/>
                                            <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>
                                        </Table>
                                    </Card>
                                </Row>
                                }

                                <Row gutter={24}>
                                    <Card>
                                        <Col span={12}>
                                            <h3>Dokumentum adatok</h3>
                                            <Divider type="horizontal"/>
                                            <Form.Item label="Típus:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                                {getFieldDecorator("docType", {
                                                    initialValue: this.state.editObject.docType != null ? this.state.editObject.docType : "CashRegisterSale",
                                                    rules: [{required: true, message: "Válasszon típust!"}],
                                                })(
                                                    <Select placeholder="Dokumentum típus" style={{width: "80%"}}>
                                                        {this.state.docType.map(d => (
                                                            <Option key={d} value={d}>{resolveDocType(d)}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Fizetési mód:" labelCol={{span: 3}}
                                                       wrapperCol={{span: 15}}>
                                                {getFieldDecorator("paymentType", {
                                                    initialValue: this.state.editObject.paymentType != null ? this.state.editObject.paymentType : "Cash",
                                                    rules: [],
                                                })(
                                                    <Select placeholder="Fizetési mód"
                                                            onChange={e => this.onPaymentTypeChange(e)}
                                                            style={{width: "80%"}} allowClear>
                                                        {this.state.paymentType.map(d => (
                                                            <Option key={d} value={d}>{resolvePaymentType(d)}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>

                                            <Form.Item label="Szállítási mód:" labelCol={{span: 3}}
                                                       wrapperCol={{span: 15}}>
                                                {getFieldDecorator("deliveryTypeId", {
                                                    initialValue: this.state.editObject.deliveryTypeId != null ? this.state.editObject.deliveryTypeId : 2,
                                                    rules: [],
                                                })(
                                                    <Select placeholder="Szállítási mód" style={{width: "80%"}}
                                                            allowClear>
                                                        {this.state.deliveryType.map(d => (
                                                            <Option key={d.id} value={d.id}>{d.name}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Pénznem:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                                {getFieldDecorator("currency", {
                                                    initialValue: this.state.editObject.currency != null ? this.state.editObject.currency : "HUF",
                                                    rules: [{required: true}],
                                                })(
                                                    <Select onChange={e => this.onCurrencyChange(e)}
                                                            style={{width: "80%"}}>
                                                        <Option key={"HUF"} value={"HUF"}>Magyar Forint (HUF)</Option>
                                                        <Option key={"USD"} value={"USD"}>Amerikai Dollár (USD)</Option>
                                                        <Option key={"GBP"} value={"GBP"}>Angol Font (GBP)</Option>
                                                        <Option key={"AUD"} value={"AUD"}>Ausztrál Dollár (AUD)</Option>
                                                        <Option key={"BGN"} value={"BGN"}>Bolgár Leva (BGN)</Option>
                                                        <Option key={"BRL"} value={"BRL"}>Brazil Reál (BRL)</Option>
                                                        <Option key={"CZK"} value={"CZK"}>Cseh Korona (CZK)</Option>
                                                        <Option key={"DKK"} value={"DKK"}>Dán Korona (DKK)</Option>
                                                        <Option key={"ZAR"} value={"ZAR"}>Dél-Afrikai Rand
                                                            (ZAR)</Option>
                                                        <Option key={"KRW"} value={"KRW"}>Dél-koreai Won (KRW)</Option>
                                                        <Option key={"EUR"} value={"EUR"}>Euro (EUR)</Option>
                                                        <Option key={"PHP"} value={"PHP"}>Fülöp-Szigeteki Peso
                                                            (PHP)</Option>
                                                        <Option key={"HKD"} value={"HKD"}>Hongkongi Dollár
                                                            (HKD)</Option>
                                                        <Option key={"HRK"} value={"HRK"}>Horvát Kuna (HRK)</Option>
                                                        <Option key={"INR"} value={"INR"}>Indiai Rupía (INR)</Option>
                                                        <Option key={"IDR"} value={"IDR"}>Indonéz Rupía (IDR)</Option>
                                                        <Option key={"ISK"} value={"ISK"}>Izlandi Korona (ISK)</Option>
                                                        <Option key={"ILS"} value={"ILS"}>Izraeli Sékel (ILS)</Option>
                                                        <Option key={"JPY"} value={"JPY"}>Japán Yen (JPY)</Option>
                                                        <Option key={"CAD"} value={"CAD"}>Kanadai Dollár (CAD)</Option>
                                                        <Option key={"CNY"} value={"CNY"}>Kínai Jüan (CNY)</Option>
                                                        <Option key={"PLN"} value={"PLN"}>Lengyel Zloty (PLN)</Option>
                                                        <Option key={"MYR"} value={"MYR"}>Malájziai Ringgit
                                                            (MYR)</Option>
                                                        <Option key={"MXN"} value={"MXN"}>Mexikói Peso (MXN)</Option>
                                                        <Option key={"NOK"} value={"NOK"}>Norvég Korona (NOK)</Option>
                                                        <Option key={"RUB"} value={"RUB"}>Orosz Rubel (RUB)</Option>
                                                        <Option key={"RON"} value={"RON"}>Román Lej (RON)</Option>
                                                        <Option key={"CHF"} value={"CHF"}>Svájci Frank (CHF)</Option>
                                                        <Option key={"SEK"} value={"SEK"}>Svéd Korona (SEK)</Option>
                                                        <Option key={"RSD"} value={"RSD"}>Szerb Dinár (RSD)</Option>
                                                        <Option key={"SGD"} value={"SGD"}>Szingapúri Dollár
                                                            (SGD)</Option>
                                                        <Option key={"THB"} value={"THB"}>Thai Baht (THB)</Option>
                                                        <Option key={"TRY"} value={"TRY"}>Török Líra (TRY)</Option>
                                                        <Option key={"UAH"} value={"UAH"}>Ukrán Hryvnia (UAH)</Option>
                                                        <Option key={"NZD"} value={"NZD"}>Új-Zélandi Dollár
                                                            (NZD)</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Árfolyam:"
                                                       style={this.state.foreignCurrency ? {} : {display: "none"}}
                                                       labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                                {getFieldDecorator("exchangeRate", {
                                                    initialValue: this.state.editObject.exchangeRate != null ? this.state.editObject.exchangeRate : 0,
                                                    rules: [{
                                                        required: this.state.foreignCurrency,
                                                        type: "integer",
                                                        message: "Árfolyam megadása kötelező!"
                                                    }]
                                                })(
                                                    <InputNumber style={{width: "80%"}}/>
                                                )}
                                            </Form.Item>

                                            <SiteSelector form={this.props.form} changeSite={this.changeSite}
                                                          selectedValue={this.state.editObject.siteTitle != null ? this.state.editObject.siteTitle : this.props.selectedSite.value}/>

                                            <Form.Item label="Előleg:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                                {getFieldDecorator("deposit", {
                                                    initialValue: this.state.editObject.deposit != null ? this.state.editObject.deposit : 0,
                                                    rules: [{
                                                        required: true,
                                                        type: "integer",
                                                        message: "Nem adott meg előleget!"
                                                    }],
                                                })(
                                                    <InputNumber style={{width: "80%"}}/>
                                                )}
                                            </Form.Item>

                                            <ResponsibleSelector users={this.state.users} form={this.props.form}
                                                                 responsible={this.state.editObject.responsible}
                                                                 userSelect={e => this.userSelect(e)}/>

                                        </Col>
                                        <Col span={12}>
                                            <h3>Számla adatok</h3>
                                            <Divider type="horizontal"/>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item label="Számla típusa:" wrapperCol={{span: 22}}>
                                                        {getFieldDecorator("invoiceDocType", {
                                                            rules: [],
                                                            initialValue: this.state.editObject.invoiceDocType != null ? this.state.editObject.invoiceDocType : 'PAPER',
                                                        })(
                                                            <Select>
                                                                <Option key={1} value={'PAPER'}>Papír alapú</Option>
                                                                <Option key={2} value={'ELECTRONIC'}>Elektronikus
                                                                    számla</Option>
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Teljesítési határidő:" wrapperCol={{span: 22}}>
                                                        {getFieldDecorator("dueDate", {
                                                            initialValue: this.state.editObject.dueDate != null ? moment(this.state.editObject.dueDate) : moment(),
                                                            rules: [],
                                                        })(
                                                            <DatePicker disabledDate={this.disabledDate}
                                                                        style={{width: "100%"}}/>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Fizetési határidő:" wrapperCol={{span: 22}}>
                                                        {getFieldDecorator("invoicePaymentDueDate", {
                                                            initialValue: this.state.editObject.invoicePaymentDueDate != null ? moment(this.state.editObject.invoicePaymentDueDate) : moment(),
                                                            rules: [],
                                                        })(
                                                            <DatePicker disabledDate={this.disabledDate}
                                                                        style={{width: "100%"}}/>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Számla kelte:" wrapperCol={{span: 22}}>
                                                        {getFieldDecorator("invoiceCreateDate", {
                                                            initialValue: this.state.editObject.invoiceCreateDate != null ? moment(this.state.editObject.invoiceCreateDate) : moment(),
                                                            rules: [],
                                                        })(
                                                            <DatePicker disabledDate={this.disabledDate}
                                                                        style={{width: "100%"}}/>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item label="Megjegyzés:">
                                                {getFieldDecorator("note", {
                                                    initialValue: this.state.editObject.note != null ? this.state.editObject.note : "",

                                                    rules: [{required: false}],
                                                })(
                                                    <TextArea rows={6} placeholder="Megjegyzés"/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Card>
                                </Row>
                            </fieldset>
                        </Form>

                        {Object.keys(this.state.history).length > 0 &&
                        <Card title="Előzmények">
                            <Table dataSource={this.state.history} pagination={false}
                                   expandedRowRender={record => (<ChangeList record={record}/>)}>
                                <Column title="Felhasználó"/>
                                <Column title="Dátum"/>
                            </Table>
                        </Card>
                        }
                    </Spin>
                    <BidModal calculate={this.calculate} salesOrderId={this.props.match.params.id}
                              showBidModal={this.state.showBidModal} salesOrderLines={this.state.salesOrderLines}
                              taxValues={this.state.taxList} onCancelBid={this.onCancelBid} onOkBid={this.onOkBid}
                              editObject={this.state.editObject} getFieldDecorator={getFieldDecorator}/>
                    <ProductSelectorModal onSelectClick={this.selectProduct} visible={this.state.productChooserVisible}
                                          close={this.closeProductChooser}/>
                    <PartnerDrawer visible={this.state.partnerDrawerVisible} onClose={this.partnerDrawerOnClose}
                                   editId={this.state.editId}/>
                    <PartnerInfoModal orders={this.state.orders} partner={this.state.partner}
                                      visibleInfoModal={this.state.visiblePartnerInfo}
                                      onClose={this.handleInfoModalClose}/>

                    <Modal
                        title={"Számla kiállítása"}
                        visible={this.state.createInvoiceModal}
                        onOk={() => this.createInvoice("false")}
                        okText={"Kiválasztás"}
                        cancelText={"Mégsem"}
                        onCancel={this.createInvoiceModal}>
                        <Card title="Melyik számlatömbről készüljön a számla?">
                            <Select allowClear style={{width: "100%"}} placeholder="Számlatömb kiválasztása"
                                    onChange={e => this.onDocNumberSelect(e)}>
                                {this.state.docNumbers && this.state.docNumbers.map(d => (
                                    <Option key={d.prefix} value={d.prefix}>{d.prefix}</Option>
                                ))}
                            </Select>
                        </Card>
                        <Card title="Melyik kasszába kerüljön?">
                            <Select allowClear style={{width: "100%"}} placeholder="Kassza kiválasztása"
                                    onChange={e => this.onCashRegisterSelect(e)}>
                                {this.state.openedCashRegisters.map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Card>
                    </Modal>
                    <Modal
                        title={"Számla sztornózása"}
                        visible={this.state.stornoInvoiceModal}
                        onOk={this.invoiceCancellation}
                        okText={"Kiválasztás"}
                        cancelText={"Mégsem"}
                        onCancel={this.stornoInvoiceModal}>
                        <Card title="Melyik számlatömbről készüljön a számla?">
                            <Select allowClear style={{width: "100%"}} placeholder="Számlatömb kiválasztása"
                                    onChange={e => this.onDocNumberSelect(e)}>
                                {this.state.docNumbers && this.state.docNumbers.map(d => (
                                    <Option key={d.prefix} value={d.prefix}>{d.prefix}</Option>
                                ))}
                            </Select>
                        </Card>
                        <Card title="Melyik kasszába kerüljön?">
                            {/*TODO Ha null, akkor ne történjen mozgatás*/}
                            <Select allowClear style={{width: "100%"}} placeholder="Kassza kiválasztása"
                                    onChange={e => this.onCashRegisterSelect(e)}>
                                {this.state.openedCashRegisters && this.state.openedCashRegisters.map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Card>
                    </Modal>
                </Hotkeys>
            </div>
        );
    }
}

const ChangeList = (props) => {
    return (
        <Table dataSource={props.record.historyDifferences} pagination={false}>
            <Column title="Mező" dataIndex="fieldName" key="fieldName"/>
            <Column title="Erről" dataIndex="from" key="from"/>
            <Column title="Erre" dataIndex="to" key="to"/>
        </Table>)
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
        newlyCreatedPartner: state.newlyCreatedPartner
    };
}

function mapDispatchToProps(dispatch) {
    return {
        unsetNewlyCreatedPartner: () => dispatch(unsetNewlyCreatedPartner())
    };
}

const connectedSalesOrder = connect(mapStateToProps, mapDispatchToProps)(SalesOrder);

export default Form.create({name: 'salesOrder_form'})(connectedSalesOrder);

