import React, {Component} from "react";
import {Table, Button, Icon, Card, Upload, message} from "antd";
import Column from "antd/es/table/Column";
import API from "../../Utils/API";

class DatabaseUpdates extends Component {

    componentDidMount() {
        this.getUpdates();
    }

    state = {
        updates: [],
        tableLoading: true,
        fileList: [],
        uploading: false
    }

    getUpdates = () => {
        API.get("/getUpdates")
            .then(res => {
                console.log(res.data);
                this.setState({updates: res.data, tableLoading: false})
            })
            .catch(error => {
                console.log(error);
                this.setState({tableLoading: false})
            })
    }

    handleUpload = () => {
        const {fileList} = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('file', file);
        });

        this.setState({
            uploading: true,
        });

        API.post("/importMarseus", formData)
            .then(() => {
                this.setState({fileList: [], uploading: false})
                message.success("Sikeres feltöltés!");
            }).catch(() => {
            message.error("Hiba történt!");
        }).finally(() => {
            this.setState({fileList: [], uploading: false});
            this.getUpdates();
        })
    };

    render() {
        const {uploading, fileList} = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };
        return (
            <div>
                <Card title="Adatbázis frissítések">
                    <div style={{marginBottom: 20, marginLeft: '30%', marginRight: '30%'}}>
                        <Upload {...props}>
                            <Button disabled={fileList.length === 1} type="primary">
                                <Icon type="upload"/> Marseus CSV Feltöltése
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={this.handleUpload}
                            hidden={fileList.length === 0}
                            loading={uploading}
                            style={{marginTop: 16}}
                        >
                            {uploading ? 'Feltöltés...' : 'Feltöltés'}
                        </Button>
                    </div>
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.updates}
                        pagination={false}
                        bordered={true}
                        rowKey={"id"}>
                        <Column title="ID" dataIndex="id"/>
                        <Column title="Típus" dataIndex="type"/>
                        <Column title="Beszállító" dataIndex="supplier"/>
                        <Column title="Termékek száma" dataIndex="productCount"/>
                        <Column title="Hibás termékek" dataIndex="errorCount"/>
                        <Column title="Kezdete" dataIndex="startTime"/>
                        <Column title="Vége" dataIndex="endTime"/>
                        <Column title="Sikeres?" dataIndex="success" render={(text) => {
                            if (text === true) {
                                return ("Igen");
                            } else {
                                return ("Nem");
                            }
                        }}/>
                        <Column title="Hibaüzenet" dataIndex="error"/>

                    </Table>
                </Card>
            </div>
        )
    }
}

export default DatabaseUpdates;