import {Form, Input, InputNumber, Popconfirm, Select, Switch, Table} from 'antd';
import React, {useState} from 'react';
import TextArea from "antd/es/input/TextArea";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {isBoolean} from "lodash/lang";

const {Column} = Table;
const {Option} = Select;

const SalesOrderLineTable = (props) => {
    const [selectedProfit, setSelectedProfit] = useState("netProfit")
    let {type} = props;
    let newMode = props.newMode != null ? props.newMode : false;
    let metaMode = props.metaMode != null ? props.metaMode : false;

    return (
        <Table size={"small"} dataSource={props.lines} bordered={true} rowKey="id"
               pagination={false}
               expandedRowRender={(record, index) => (<Details record={record} index={index} props={props}/>)}>

            {newMode &&
                <Column title="#" dataIndex="id" key="id" width={2}/>
            }

            <Column title="Megnevezés" dataIndex="name" key="name" render={(text, record, index) => {
                if (props.editing) {
                    return (
                        <span>
                    <Form.Item key={`${(type)}[${(index)}].productCode`} style={{display: "none"}}>
                        {props.getFieldDecorator(`${(type)}[${(index)}].productCode`, {
                            rules: [{required: false}],
                            initialValue: record.productCode != null ? record.productCode : ""
                        })(
                            <Input key={`${(type)}[${(index)}].productCode`} hidden={true}
                                   onChange={e => props.calculate(e, "productCode", index, props.form)}/>
                        )}
                    </Form.Item>
                    <Form.Item key={`${(type)}[${(index)}].id`} style={{display: "none"}}>
                    {props.getFieldDecorator(`${(type)}[${(index)}].id`, {
                        rules: [{required: false}],
                        initialValue: record.id != null ? record.id : "",
                    })(
                        <Input key={`${(type)}[${(index)}].id`} hidden={true}/>
                    )}
                    </Form.Item>
                    <Form.Item key={`${(type)}[${(index)}].name`} style={{marginBottom: "0px"}}>
                    {props.getFieldDecorator(`${(type)}[${(index)}].name`, {
                        rules: [{required: true, message: 'Töltse ki a megnevezést!'}],
                        initialValue: text !== "" ? text : ""
                    })(
                        <TextArea key={`${(type)}[${(index)}].name`} placeholder="Megnevezés"
                                  onChange={e => props.calculate(e, "name", index, props.form)}/>
                    )}
                    </Form.Item>
                </span>);
                } else {
                    return (text);
                }
            }}/>

            <Column title="Mennyiség" dataIndex="qty" key="qty" width={100} render={(text, record, index) => {
                if (props.editing) {
                    return (
                        <Form.Item key={`${(type)}[${(index)}].qty`} style={{marginBottom: "0px"}}>
                            {props.getFieldDecorator(`${(type)}[${(index)}].qty`, {
                                rules: [{required: true, type: "integer", message: "Nem mennyiséget adott meg!"}],
                                initialValue: text !== "" ? text : ""
                            })(
                                <InputNumber key={`${(type)}[${(index)}].qty`} style={{width: "100%"}}
                                             onChange={e => props.calculate(e, "qty", index, props.form)}/>
                            )}
                        </Form.Item>);
                } else {
                    return (text);
                }
            }}/>

            <Column title="Beszerzési nettó egységár" dataIndex="netPurchasePrice" key="netPurchasePrice" width={150}
                    render={(text, record, index) => {
                        if (props.editing) {
                            return (
                                <Form.Item key={`${(type)}[${(index)}].netPurchasePrice`} style={{marginBottom: "0px"}}>
                                    {props.getFieldDecorator(`${(type)}[${(index)}].netPurchasePrice`, {
                                        rules: [{required: true, type: "number", message: "Nem összeget adott meg!"}],
                                        initialValue: text !== "" ? text : ""
                                    })(
                                        <InputNumber key={`${(type)}[${(index)}].netPurchasePrice`}
                                                     style={{width: "100%"}}
                                                     step={0.1}
                                                     formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                     disabled={typeof record.productCode !== "undefined" && record.productCode !== ""}
                                                     onChange={e => props.calculate(e, "netPurchasePrice", index, props.form)}/>
                                    )}
                                </Form.Item>);
                        } else {
                            return (text);
                        }
                    }}/>
            <Column title="Beszerzési bruttó egységár" width={150}
                    render={(text, record) => {
                        return (
                            (record.netPurchasePrice * 1.27).toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')
                        )
                    }}/>
            <Column title="Nettó egységár" dataIndex="netSellPrice" key="netSellPrice" width={150}
                    render={(text, record, index) => {
                        return (
                            <Form.Item key={`${(type)}[${(index)}].netSellPrice`} style={{marginBottom: "0px"}}>
                                {props.getFieldDecorator(`${(type)}[${(index)}].netSellPrice`, {
                                    rules: [{type: "number", message: "Nem összeget adott meg!"}],
                                    initialValue: text !== "" ? text : ""
                                })(
                                    <InputNumber key={`${(type)}[${(index)}].netSellPriceInput`} style={{width: "100%"}}
                                                 step={0.1}
                                                 formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                 onChange={e => {
                                                     props.calculate(e, "netSellPrice", index, props.form)
                                                     let objectToChange = props.form.getFieldValue(type)
                                                     objectToChange[`${(index)}`].grossSellPrice = props.lines[`${(index)}`].grossSellPrice
                                                     let objectToSend = {}
                                                     objectToSend[`${(type)}`] = objectToChange
                                                     props.form.setFieldsValue(objectToSend)
                                                 }}/>,
                                )}
                            </Form.Item>);
                    }}/>
            <Column title="Bruttó egységár" dataIndex="grossSellPrice" key="grossSellPrice" width={150}
                    render={(text, record, index) => {
                        return (
                            <Form.Item key={`${(type)}[${(index)}].grossSellPrice`} style={{marginBottom: "0px"}}>
                                {props.getFieldDecorator(`${(type)}[${(index)}].grossSellPrice`, {
                                    rules: [{required: true, type: "number", message: "Nem összeget adott meg!"}],
                                    initialValue: text !== "" ? text : ""
                                })(
                                    <InputNumber key={`${(type)}[${(index)}].grossSellPriceInput`} style={{width: "100%"}}
                                                 step={0.1}
                                                 formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                 onChange={e => {
                                                     props.calculate(e, "grossSellPrice", index, props.form)
                                                     let objectToChange = props.form.getFieldValue(type)
                                                     objectToChange[`${(index)}`].netSellPrice = props.lines[`${(index)}`].netSellPrice
                                                     let objectToSend = {}
                                                     objectToSend[`${(type)}`] = objectToChange
                                                     props.form.setFieldsValue(objectToSend)
                                                 }}/>
                                )}
                            </Form.Item>);
                    }}/>

            <Column title="Bruttó ár" dataIndex="sumGrossPrice" key="sumGrossPrice" width={100}
                    render={(text) => {
                        return (
                            typeof text !== "undefined" &&
                            text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')
                        );
                    }}/>

            <Column title={<Select value={selectedProfit} style={{width: "180px"}}
                onChange={e => setSelectedProfit(e)}
            >
                <Option value="netProfit">Nettó haszon</Option>
                <Option value="grossProfit">Bruttó haszon</Option>
                <Option value="netProfitPerUnit">Nettó haszon / egység</Option>
                <Option value="grossProfitPerUnit">Bruttó haszon / egység</Option>
            </Select>}
                    dataIndex={selectedProfit} key={selectedProfit}
                    width={200}
                    render={(text) => {
                      return (
                          typeof text !== "undefined" &&
                              text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')
                      )
            }}/>

            <Column title="check" dataIndex="isPoRequired" key="isPoRequired" width={60}
                    render={(text, record, index) => {
                        return (
                            <Form.Item key={`${(type)}[${(index)}].isPoRequired`} initialValue>
                                {props.getFieldDecorator(`${(type)}[${(index)}].isPoRequired`, {
                               //     rules: [{required: false, type: "boolean"}],
                                    initialValue: isBoolean != null
                                })(
                                    <Switch key={`${(type)}[${(index)}].isPoRequired`} defaultChecked={true}
                                            onChange={e => props.calculate(e, "isPoRequired", index, props.form)}/>
                                )}
                            </Form.Item>);
                    }}/>


            <Column title="Törlés" key="operation" width={60} render={(text, record, index) => {
                return (

                    <div key={`[${(index)}].delete`}>
                        <Popconfirm
                            title="Biztos a törlésben? A törlés nem visszavonható!"
                            icon={<QuestionCircleOutlined color={"red"}/>}
                            onConfirm={() => props.removeLine(record,
                                index,
                                [`${(type)}[${(index)}].name`,
                                    `${(type)}[${(index)}].qty`,
                                    `${(type)}[${(index)}].netPurchasePrice`,
                                    `${(type)}[${(index)}].grossSellPrice`])}
                            okText="Igen"
                            cancelText="Nem"
                        >
                            <DeleteOutlined style={{fontSize: 25, color: "#1890ff"}}/>
                        </Popconfirm>
                    </div>
                );
            }}/>

            {(!newMode && metaMode) && [
                <Column title="Létrehozva" dataIndex="createdDate" key="createdDate"/>,
                <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>,
                <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>,
                <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>

            ]
            }

            {metaMode && [
                <Column title="Létrehozva" dataIndex="createdDate" key="createdDate"/>,
                <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>,
                <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>,
                <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>
            ]

            }
        </Table>);
};

const Details = (props) => {
    const {record} = props;
    const {index} = props;
    const type = props.props.type;
    console.log("propsdetails" ,props)
    console.log("extras: ", record.salesOrderLineExtras);

    return (
        <Table dataSource={[record]} pagination={false} rowKey="id">
            <Column title="Menny. Egység" dataIndex="uom" width={100} key="uom" render={text => {
                if (props.props.editing) {
                    return (
                        <Form.Item>
                            {props.props.getFieldDecorator(`${(type)}[${(index)}].uom`, {
                                rules: [{required: false, message: "Menny. egys. kötelező!", type: "string"}],
                                initialValue: text !== "" ? text : "",
                            })(
                                <Input style={{width: "100%"}}/>,
                            )}
                        </Form.Item>);
                } else {
                    return (text);
                }
            }}/>
            <Column title="Áfa" dataIndex="taxId" key="taxId" width={100} render={text => {
                if (props.props.editing) {
                    return (
                        <Form.Item>
                            {props.props.getFieldDecorator(`${(type)}[${(index)}].taxId`, {
                                rules: [{required: true, message: "Válasszon ÁFÁt!"}],
                                initialValue: typeof text !== "undefined" ? text : 1
                            })(
                                <Select style={{width: "100%"}}
                                        onChange={e => props.props.calculate(e, "tax", index, props.props.form)}>
                                    {props.props.taxValues.map(d => (
                                        <Option key={d.id} value={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>);
                } else {
                    return (text);
                }
            }}/>

            <Column title="Garancia" width={150} render={(text, record) =>
                <Form.Item>
                    {props.props.getFieldDecorator(`${(type)}[${(index)}].warrantyTime`, {
                        initialValue: record.warrantyTime
                    })(
                        <Input style={{width: "100%"}}/>
                    )}
                </Form.Item>}/>

            <Column title="Gyáriszámok" render={(text, record) =>
                <Form.Item>
                    {props.props.getFieldDecorator(`${(type)}[${(index)}].serialNumbers`, {
                        initialValue: record.serialNumbers != null ? record.serialNumbers : []
                    })(
                        <Select mode="tags"/>
                    )}
                </Form.Item>}/>

            <Column title="Megjegyzés" render={(text, record) =>
                <Form.Item>
                    {props.props.getFieldDecorator(`${(type)}[${(index)}].note`, {
                        initialValue: record.note
                    })(
                        <TextArea style={{width: "100%"}}/>
                    )}
                </Form.Item>}/>
        </Table>
    );
};

export default SalesOrderLineTable;
