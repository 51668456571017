import {Select, Button, Icon, Form, Row, Col} from "antd";
import React from 'react';
import API from "../Utils/API";
import {connect} from "react-redux";

const {Option} = Select;

function debounce(func, wait) {
    let timeout;
    return function () {
        const result = timeout
            ? Promise.reject(new Error("called during debounce period"))
            : Promise.resolve(func.apply(this, arguments));
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
        }, wait);
        return result;
    };
}

class PartnerSelect extends React.Component {

    find = (e) => {
        if (e.length > 2) {
            this.setState({loading: true});
            debounce(API.get("partnersList/" + e).then(res => {
                this.setState({partners: res.data, loading: false});
            }), 400);
        }
    };

    findById = (e) => {
        if (e !== "null" && typeof e !== "undefined"
            && e !== null && e !== undefined) {
            this.setState({loading: true});
            return debounce(API.get("/getPartner/" + e).then(res => {
                this.setState(prevState => ({
                    partners: [...prevState.partners, res.data],
                    selectedId: res.data.id,
                    partner: res.data
                }));
            }), 400);
        } else {
            this.setState(prevState => ({
                partners: [...prevState.partners],
                selectedId: "",
                partner: ""
            }));
        }
    };

    onSelect = (e) => {
        if (e !== "null" && e !== "undefined"
            && e !== null && e !== undefined) {
            API.get("/getPartner/" + e).then(res => {
                this.props.onSelectedPartner(this.state.selectedId, res.data);
                this.setState({
                    isSelected: true
                });
            });
        }
    };

    /*componentDidMount() {
        if (this.props.selectedPartnerId != null && this.props.selectedPartnerId !== "null"
            && this.props.selectedPartnerId !== undefined && this.props.selectedPartnerId !== "undefined") {
            this.findById(this.props.selectedPartnerId);
        } else {
            this.setState(prevState => ({
                partners:  [...prevState.partners],
                selectedId: "",
                partner : ""
            }));
        }
    }*/

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.newlyCreatedPartner !== this.props.newlyCreatedPartner) {
            console.log("this.props.newlyCP: ", this.props.newlyCreatedPartner);
            let newPartner = {
                id: this.props.newlyCreatedPartner.partnerId,
                name: this.props.newlyCreatedPartner.partnerName
            };
            this.setState(previousState => ({
                partners: [...previousState.partners, newPartner],
            }), () => {
                this.findById(this.props.newlyCreatedPartner.partnerId);
                this.props.form.setFieldsValue({partnersId: this.props.newlyCreatedPartner.partnerId});
                this.props.onSelectedPartner(this.props.selectedPartnerId, this.state.partner);
            });

        }
        if (prevProps.selectedPartnerId !== this.props.selectedPartnerId) {
            this.findById(this.props.selectedPartnerId);
        }
        if (prevState.partner !== this.state.partner) {
            this.props.onSelectedPartner(this.props.selectedPartnerId, this.state.partner);
        }
    }

    state = {
        loading: false,
        partners: [],
        selectedId: "",
        partner: "",
        isSelected: false
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Row>
                <Col span={20}>
                    <Form.Item labelCol={this.props.toDo ? {span: 4} : {span: 9}} wrapperCol={{span: 15}}
                               label="Partner:">
                        {getFieldDecorator("partnersId", {
                            rules: [this.props.toDo ? {required: false} : {
                                required: true,
                                message: 'Válasszon partnert!'
                            }],
                            initialValue: this.state.partner.id //this.state.partner.name //!= null && this.props.selectedId
                        })(
                            <Select
                                showSearch={true}
                                style={{width: '98%', position: 'relative'}}
                                placeholder="Kérem válasszon!"
                                allowClear={true}
                                onSearch={this.find}
                                onSelect={this.onSelect}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.partners &&
                                this.state.partners.map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))
                                }
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Button onClick={this.props.openDrawer} style={{left: '5px', position: 'relative'}}>
                        <Icon type="plus"/>
                    </Button>
                    <Button disabled={!this.state.isSelected} onClick={this.props.editPartner}
                            style={{left: '5px', position: 'relative'}}>
                        <Icon type="info-circle"/>
                    </Button>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        newlyCreatedPartner: state.newlyCreatedPartner
    };
}

export default connect(mapStateToProps)(PartnerSelect);
