import {
    Card,
    Col,
    Divider,
    Form,
    Input, InputNumber,
    Modal,
    Row, Select, Table
} from "antd";
import React, {Component} from "react";
import PartnerSelect from "./PartnerSelect";
import API from "../Utils/API";
import PartnerDrawer from "./PartnerDrawer";
import SiteSelector from "./SiteSelector";
import {connect} from "react-redux";

const {Column} = Table;
const { Option } = Select;

class BidModal extends Component {

    state = {
        selectedPartner: null,
        partnerDrawerVisible: false,
        editId: null,
        selectedRowKeys : [],
        newBidNo: null
    };

    print=()=> {
        const { form } = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log(values);
            let data = {
                "sites": values.sites,
                "deliveryTime" : values.deliveryTime,
                "partnersId" : values.partnersId,
                "salesOrderId" : this.props.salesOrderId,
                "currencyValid": values.currency,
                "bidSalesOrderLines" : this.props.salesOrderLines
            };
            console.log("Data: ",data);
            API.post("/bid/save-bid/",data).then(this.props.onOkBid)
        });
    };

    getLastBidNo(sitePrefix){
        API.get("bid/getBidNo/"+sitePrefix).then(res => {
            this.setState({newBidNo : res.data.bidNo, loading : false});
        });
    }

    changeSite = (e) => { this.getLastBidNo(e) };

    newPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId : ""
        });
    };

    getPartnerInfo = (e,data) => {
        this.setState({selectedPartner: data});
    };

    editPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId : this.state.selectedPartner.id
        });
    };

    partnerDrawerOnClose = () => {
        this.setState({
            partnerDrawerVisible: false,
            editId : ""
        });
    };

    onRowChange =  selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    getCheckboxProps = (record) => {
        return {
            disabled: record.index === 0,
            name: record.name,
        }
    };

    componentDidMount() {
        console.log("props.salesOrderId: ",this.props.salesOrderId);
        this.getLastBidNo(this.props.selectedSite.value);
    }

    render() {
        const { getFieldDecorator} = this.props.form;
        // const {selectedRowKeys} = this.state;
        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onRowChange,
        //     getCheckboxProps: this.getCheckboxProps
        // };
        // const hasSelected = selectedRowKeys.length > 0;
        return (
    <div>
            <Modal
                visible={this.props.showBidModal}
                onOk={this.print}
                okText="PDF generálása"
                cancelText="Mégse"
                onCancel={this.props.onCancelBid}
                closable={false}
                maskClosable={false}
                width="95%"
            >
                <Form>
                    <Row>
                        <Col span={12}>
                            <PartnerSelect openDrawer={this.newPartner} editPartner={this.editPartner} selectedPartnerId={this.props.editObject.partnersId} form={this.props.form} onSelectedPartner={this.getPartnerInfo}/>
                        </Col>
                        <Col span={12}>
                            <SiteSelector form={this.props.form} changeSite={this.changeSite} selectedValue={this.props.editObject.siteTitle != null ? this.props.editObject.siteTitle : this.props.selectedSite.value}/>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={10}>
                            <Form.Item label="Árajánlat azonosító:" labelCol={{ span: 10}} wrapperCol={{ span: 10 }} >
                                {getFieldDecorator("BidNo", {
                                    initialValue : this.state.newBidNo !== null ? this.state.newBidNo : "",
                                    rules: [],
                                })(
                                    <Input disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="Értékesítési szám:" labelCol={{ span: 10}} wrapperCol={{ span: 10 }} >
                                {getFieldDecorator("salesOrderNo", {
                                    initialValue : this.props.editObject.salesOrderNo !== "" ? this.props.editObject.salesOrderNo : this.state.newSalesOrderId,
                                    rules: [],
                                })(
                                    <Input disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} >
                            <Card title="Kalkuláció">
                            <Table size={"small"} dataSource={this.props.salesOrderLines} /*rowSelection={rowSelection}*/ bordered={true} pagination={false}>
                                <Column  title="Megnevezés" dataIndex="name" key="name" render={(text, record,index) => {
                                        return (
                                            <span>
                                                <Form.Item style={{display: "none"}}>
                                                    {getFieldDecorator(`salesOrderLines[${(index)}].productId`, {
                                                        rules: [{ required: false}],
                                                        initialValue : record.productId != null ? record.productId: ""
                                                    })(
                                                        <Input hidden={true} />
                                                    )}
                                                </Form.Item>
                                                <Form.Item style={{display: "none"}}>
                                                {getFieldDecorator(`salesOrderLines[${(index)}].id`, {
                                                    rules: [{ required: false}],
                                                    initialValue : record.id != null ? record.id: "",
                                                })(
                                                    <Input hidden={true} />
                                                )}
                                                </Form.Item>
                                                <Form.Item style={{marginBottom: "0px"}} >
                                                {getFieldDecorator(`salesOrderLines[${(index)}].name`, {
                                                    rules: [{ required: true, message: 'Töltse ki a megnevezést!' }],
                                                    initialValue : text !== "" ? text: ""
                                                })(
                                                    <Input disabled={ record.productId != null} placeholder="Megnevezés" />
                                                )}
                                                </Form.Item>
                                            </span>);
                                }}/>
                                <Column width={90} title="Mennyiség" dataIndex="qty" key="qty" render={(text, record,index) => {
                                    return (
                                        <Form.Item style={{marginBottom: "0px"}}>
                                            {getFieldDecorator(`salesOrderLines[${(index)}].qty`, {
                                                rules: [{type: "integer" ,message: "Nem mennyiséget adott meg!"  }],
                                                initialValue : text !== "" ? text: ""
                                            })(
                                                <InputNumber
                                                    formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                    style={{width: "100%"}}
                                                    onChange={e => this.props.calculate(e, "qty", index, this.props.form)}/>
                                            )}
                                        </Form.Item>);
                                }}/>
                                <Column width={90} title="Áfa" dataIndex="taxId" key="taxId" render={(text, record,index) => {
                                        return (
                                            <Form.Item style={{marginBottom: "0px", width: "70px"}}>
                                                {getFieldDecorator(`salesOrderLines[${(index)}].taxId`, {
                                                    rules: [{required: true, message: "Válasszon ÁFÁt!"}],
                                                    initialValue : text !== "" ? text: ""
                                                })(
                                                    <Select style={{ width: "100%"}} onChange={e => this.props.calculate(e, "tax", index, this.props.form)}>
                                                        {this.props.taxValues.map(d => (
                                                            <Option key={d.id} value={d.id}>{d.name}</Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>);
                                }}/>
                                <Column width={100} title="Beszerzési nettó egységár" dataIndex="netPurchasePrice" key="netPurchasePrice" render={(text, record,index) => {

                                    return (
                                        <Form.Item style={{marginBottom: "0px"}}>
                                            {getFieldDecorator(`salesOrderLines[${(index)}].netPurchasePrice`, {
                                                rules: [{type: "number" ,message: "Nem összeget adott meg!"  }],
                                                initialValue : text !== "" ? text: ""
                                            })(
                                                <InputNumber
                                                    step={0.1}
                                                    formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                    style={{width: "100%"}}
                                                    onChange={e => this.props.calculate(e, "netPurchasePrice", index, this.props.form)}/>
                                            )}
                                        </Form.Item>);
                                }}/>

                                <Column width={100} title="Nettó egységár" dataIndex="netSellPrice" key="netSellPrice" render={(text, record,index) => {
                                    return (
                                        <Form.Item style={{marginBottom: "0px"}}>
                                            {getFieldDecorator(`salesOrderLines[${(index)}].netSellPrice`, {
                                                rules: [{type: "number" ,message: "Nem összeget adott meg!"  }],
                                                initialValue : text !== "" ? text: ""
                                            })(
                                                <InputNumber
                                                    style={{width: "100%"}}
                                                    step={0.1}
                                                    formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                    onChange={e => this.props.calculate(e, "netSellPrice", index, this.props.form)}/>
                                            )}
                                        </Form.Item>);
                                }}/>
                                <Column width={100} title="Bruttó egységár" dataIndex="grossSellPrice" key="grossSellPrice" render={(text, record,index) => {
                                    return (
                                        <Form.Item style={{marginBottom: "0px"}}>
                                            {getFieldDecorator(`salesOrderLines[${(index)}].grossSellPrice`, {
                                                rules: [{type: "number" ,message: "Nem összeget adott meg!"  }],
                                                initialValue : text !== "" ? text: ""
                                            })(
                                                <InputNumber
                                                    style={{width: "100%"}}
                                                    step={0.1}
                                                    formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                                    onChange={e => this.props.calculate(e, "grossSellPrice", index, this.props.form)}/>
                                            )}
                                        </Form.Item>);
                                }}/>

                                <Column width={90} title="Nettó eladási ár" dataIndex="sumNetPrice" key="sumNetPrice" render={(text) => {
                                    return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '));

                                }}/>
                                <Column width={90} title="Bruttó eladási ár" dataIndex="sumGrossPrice" key="sumGrossPrice" render={(text) => {
                                    return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '));
                                }}/>
                                <Column width={90} title="Haszon" dataIndex="profit" key="profit" render={(text) => {
                                    return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '));
                                }}/>

                            </Table>
                            </Card>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col span={24} >
                            <Button type="primary" onClick={this.newProductLine}> <Icon type="plus-circle" /> Új termék</Button>
                            <Divider type="vertical"/>
                            <Button type="primary" onClick={this.addLine}> <Icon type="plus-circle" /> Új sor</Button>
                            <Divider type="vertical"/>
                            <Popconfirm
                                title="Biztos a törlésben? A törlés nem visszavonható!"
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={this.removeLine}
                                okText="Igen"
                                cancelText="Nem"
                            >
                                <Button type="danger" disabled={!hasSelected}> <Icon type="delete" /> Kijelölt(ek) törlése</Button>
                            </Popconfirm>

                        </Col>
                    </Row>
                    <Divider type="horizontal"/>*/}
                    <Row>
                        <Col span={6}>
                            <Form.Item label="Szállítási idő:" /*labelCol={{ span: 10}} wrapperCol={{ span: 15 }}*/ >
                                {getFieldDecorator("deliveryTime", {
                                    rules: [{required: true, message: "Válasszon szállítási időt!"}],
                                })(
                                    <Select>
                                        <Option key={1} value={'IN_STOCK'}>Raktarrol azonnal 1-5 munkanap</Option>
                                        <Option key={2} value={'ORDER1'}>Megrendelesre 5-7 munkanap</Option>
                                        <Option key={3} value={'ORDER2'}>Megrendelesre 7-14 munkanap</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col offset={2} span={12}>
                            <Form.Item label="Árfolyam:" /*labelCol={{ span: 12}} wrapperCol={{ span: 20 }}*/ >
                                {getFieldDecorator("currency", {
                                    rules: [{required: true, message: "Nem összeget adott meg!"  }],
                                })(
                                    <span>"Ajánlatunk <InputNumber/> Ft/1 Euró középárfolyamig érvényes"</span>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <PartnerDrawer visible={this.state.partnerDrawerVisible} onClose={this.partnerDrawerOnClose} editId={this.state.editId}  />
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite
    };
}
const connectedBidModal = connect(mapStateToProps)(BidModal);

export default Form.create({ name: 'bid_form' })(connectedBidModal);