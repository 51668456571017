import {Drawer, Button} from 'antd';
import React, {Component} from 'react';
import API from "../../Utils/API";
import NotificationUtil from "../../Utils/NotificationUtil";
import {CashRegisterForm} from "./CashRegisterForm";

class CashRegisterDrawer extends Component {

    save = (e) => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            let url = `/settings/save${values.type === "office" ? "Office" : ""}CashRegister?name=${values.name}&site=${values.sites}&note=${values.note}${this.props.editId !== "" ? `&editId=${this.props.editId}` : ""}`

            API.post(url)
                .then(res => {
                    form.resetFields();
                    this.props.onClose(e,true);
                    NotificationUtil(res.data);
                }).catch(error => {
                NotificationUtil({msg:"Hiba történt!" + error,type:"error"});
                this.props.onClose(e,true);
            });
        });
    };

    constructor(props){
        super(props);
        this.state = {
            cashRegister : {name : "", siteName: "", siteId: "", type: ""},
            title: ""
        };
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    open = () => {
        if(this.props.editId !== "") {
            API.get(`/settings/get${this.props.type === "office" ? "Office" : ""}CashRegister/` + this.props.editId).then(res => {
                this.setState({cashRegister: {...res.data, type: this.props.type}}, ()=>console.log("state.cr: ",this.state.cashRegister));
                this.setState({title: "Kassza szerkesztése: "+ res.data.name});
            });
        }else{
            this.setState({cashRegister: {name : "", siteName: "", siteId: "", note: "", type: "normal"}});
            this.setState({title: "Új kassza felvétele"});
        }
    };

    render() {
        return (
            <Drawer
                destroyOnClose={true}
                width={480}
                title={this.state.title}
                placement="right"
                closable={true}
                afterVisibleChange={this.open}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >

                <CashRegisterForm {...this.state.cashRegister} cashRegister={this.state.cashRegister} wrappedComponentRef={this.saveFormRef} initialName={this.state.cashRegister.name}
                                  isEdit={this.props.editId !== ""}
                />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.props.onClose} style={{marginRight: 8}}>
                        Mégsem
                    </Button>
                    <Button onClick={this.save} type="primary">
                        Mentés
                    </Button>
                </div>
            </Drawer>
        );
    }
}

export default CashRegisterDrawer;

