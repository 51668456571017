import React, {Component} from "react";
import {Modal} from "antd";
import moment from "moment";
import {LeaveItemForm} from "./LeaveItemForm";
import _ from 'lodash'
import NotificationUtil from "../Utils/NotificationUtil";
import API from "../Utils/API";

class LeaveFormModal extends Component {

    save = () => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ""
        const { form } = this.formRef.props
        form.validateFields((err, values) => {
            if (err) return;
            const data = {
                username: user.username,
                startDate: values.date[0].format('YYYY-MM-DD'),
                endDate: values.date[1].format('YYYY-MM-DD'),
                ..._.pick(values, ['name', 'type', 'notes'])
            }

            if (this.state.editId === "") {
                API.post("/leaves/saveLeave", data).then(res => {
                    NotificationUtil(res.data);
                    this.props.close()
                }).catch(res => {
                    NotificationUtil(res.data)
                    this.props.close()
                }).finally(
                    setTimeout(() => this.props.getLeaves(), 500)
                )
            } else {
                data['id'] = this.state.editId
                API.post(`/leaves/updateLeave/${this.state.editId}`, data).then(res => {
                    NotificationUtil(res.data)
                    this.setState({ editId: "" })
                    this.props.close()
                }).catch(res => {
                    NotificationUtil(res.data)
                    this.props.close()
                })
            }
            form.resetFields()
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            item: {
                date: null,
                name: "",
                type: "",
                notes: ""
            },
            employees: [],
            types: [],
            editId: "",
        }
    }

    saveFormRef = formRef => {
        this.formRef = formRef
    }

    open = (id) => {
        this.setState({ editId: id })
        if (id === "") {
            this.setState({
                title: "Új szabadság",
                item: {
                    date: [moment(this.props.startDate), moment(this.props.endDate)],
                    name: "",
                    type: "",
                    notes: ""
                },
            })
        } else {
            this.setState({
                title: "Szabadság módosítás",
                item: {
                    date: [moment(this.props.editEvent.startDate), moment(this.props.editEvent.endDate)],
                    name: this.props.editEvent.employee.id,
                    type: this.props.editEvent.type.id,
                    notes: this.props.editEvent.notes,
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.leaveTypes !== this.props.leaveTypes) {
            this.setState({
                types: this.props.leaveTypes
            })
        }
        if (prevProps.employees !== this.props.employees) {
            this.setState({
                employees: this.props.employees
            })
        }
    }

    render() {
        return (
            <Modal
                destroyOnClose={true}
                title={this.state.title}
                visible={this.props.visible}
                onCancel={this.props.close}
                cancelText={"Mégsem"}
                okText={"Mentés"}
                onOk={this.save}
                width={"80%"}
                maskClosable={false}
            >
                <LeaveItemForm
                    save={this.save}
                    {...this.state.item}
                    getBusinessDayCount={this.props.getBusinessDayCount}
                    types={this.state.types}
                    employees={this.state.employees}
                    wrappedComponentRef={this.saveFormRef}
                />
            </Modal>
        );
    }
}

export default LeaveFormModal;