import React from 'react';
import SwLayout from './Layouts/SwLayout';
import {LoginForm} from './Auth/Login';
import {BrowserRouter as Router, Route,Switch,Redirect} from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store/index";
import './App.css';
import {ConfigProvider} from "antd";
import huHU from "antd/lib/locale-provider/hu_HU";
const huHU_format = { ...huHU, DatePicker: {}};

function App() {

  return (

      <Provider store={store}>
          <ConfigProvider locale={huHU_format}>
          <div className="App">
              <Router>
                  <Switch>
                      <Route exact path="/">
                          <Redirect to="/login" />
                      </Route>
                      <Route path="/login" component={LoginForm} />
                      <Route path="/app" component={SwLayout} />
                  </Switch>
              </Router>
          </div>
          </ConfigProvider>
      </Provider>
  );
}

export default App;


