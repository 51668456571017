import {Drawer, Button} from 'antd';
import React, {Component} from 'react';
import API from "../../Utils/API";
import NotificationUtil from "../../Utils/NotificationUtil";
import QueryParams from "../../Utils/QueryParams";
import {WebshopForm} from "./WebshopForm";

class WebshopDrawer extends Component {

    save = (e) => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if(this.props.editId != null) {
                values = Object.assign({}, values, {
                    id: this.props.editId
                });
            }
            console.log(values);
            let qp = new QueryParams();
            qp.addPathParam(values.name).addPathParam(values.username).addPathParam(values.password).addPathParam(values.url);
            if (this.props.editId !== ""){
                qp.setCustomParams({"id":values.id});
            }
            console.log(qp);
            let id = 0;
            if (values.id[0] != null){
                id = values.id[0];
            }
            let data = {
                "name": values.name,
                "username": values.username,
                "password": values.password,
                "url": values.url,
                "id": id,
                "type": values.type
            }
            API.post("/settings/saveWebshop/",data)
                .then(res => {
                    form.resetFields();
                    this.props.onClose(e,true);
                    NotificationUtil(res.data);
                }).catch(error => {
                NotificationUtil({msg:"Hiba történt!" + error,type:"error"});
                this.props.onClose(e,true);
            });
        });
    };

    constructor(props){
        super(props);
        this.state = {
            webshop : {name : ""},
            title: ""
        };
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    open = () => {
        if(this.props.editId !== "") {
            API.get("/settings/getWebshop/" + this.props.editId).then(res => {
                this.setState({webshop: res.data});
                this.setState({title: "Webshop módosítása : "+ res.data.title});
            });
        }else{
            this.setState({webshop: {name : ""}});
            this.setState({title: "Új webshop felvétele"});
        }
    };

    render() {
        return (
            <Drawer
                destroyOnClose={true}
                width={480}
                title={this.state.title}
                placement="right"
                closable={true}
                afterVisibleChange={this.open}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >

                <WebshopForm {...this.state.webshop} wrappedComponentRef={this.saveFormRef} initialName={this.state.webshop.name}/>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.props.onClose} style={{marginRight: 8}}>
                        Mégsem
                    </Button>
                    <Button onClick={this.save} type="primary">
                        Mentés
                    </Button>
                </div>
            </Drawer>
        );
    }
}

export default WebshopDrawer;

