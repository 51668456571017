import React, {Component} from "react";
import {Tooltip, Button, Card, Col, DatePicker, message, Modal, Row, Select, Table} from "antd";
import API from "../Utils/API";
import CashRegisterModal from "../Components/CashRegisterModal";
import CashRegisterItemForm from "../Components/CashRegisterDrawer";
import locale from "antd/es/date-picker/locale/hu_HU";
import Empty from "antd/es/empty";
import moment from "moment";
import "moment/locale/hu";
import {connect} from "react-redux";
import QueryParams from "../Utils/QueryParams";
import NotificationUtil from "../Utils/NotificationUtil";

const {Option} = Select;
const dateValue = moment(new Date()).format("YYYY-MM-DD");

class CashRegistry extends Component {

    state = {
        isCashierOpen: false,
        tableLoading: true,
        visibleCROpen: false,
        visibleCRClose: false,
        visibleDrawer: false,
        visibleCRCardAndItems: false,
        visibleDifferenceLines: false,
        editId: null,
        items: [],
        cashRegisters: [],
        cashRegister: null,
        selectedLineId: null,
        sumOpen: null,
        sumClose: null,
        cashFlowIn: 0,
        itemsSum: 0,
        difference: 0,
        date: dateValue,
        site: this.props.selectedSite.value,
        showNotCorrectClose: false,
        saveNotCorrect: false,
        selectedCashRegisterOpenId: null,
        cashRegisterOpenIds: []
    };
    showModalCROpen = () => {
        this.setState({
            visibleCROpen: true,
        });
    };

    showModalCRClose = () => {
        this.setState({
            visibleCRClose: true,
        });
    };

    editClickDrawer = (e, id) => {
        this.setState({
            visibleDrawer: true,
            editId: id
        });
    };

    onCloseDrawer = (e, success) => {
        this.setState({
            visibleDrawer: false,
            editId: null
        });
        if (success) {
            this.handleTableChange();
        }
    };

    showDrawer = (e) => {
        this.setState({
            visibleDrawer: true,
        });
    };

    removeLine = (e, id) => {
        const current = this;
        Modal.confirm({
            title: 'Biztosan törölni szeretnéd ezt a tételt?',
            content: 'A törlés nem visszavonható!',
            cancelText: 'Mégse',
            okText: 'Törlés',
            okType: 'danger',
            onOk() {
                API.post("cash-register/remove-line/" + id).then(() => {
                    current.handleTableChange();
                })
            }
        })

    };

    getItemsAndDifference() {
        this.setState({tableLoading: true, items: []});
        let queryParams = new QueryParams();
        queryParams.addPathParam(this.state.cashRegister).addPathParam(this.state.date);
        if (this.state.selectedCashRegisterOpenId != null) {
            queryParams.setCustomParams({"cashRegisterOpenId": this.state.selectedCashRegisterOpenId});
        }

        API.get("cash-register/get-items/" + queryParams.getQueryParam())
            .then(res => {
                console.log("get-items: ", res.data);
                if (res.data.lines != null) {
                    let sum = 0;
                    res.data.lines.forEach((row) => {
                        sum += row.amount;
                    });
                    this.setState({
                        items: res.data.lines,
                        itemsSum: sum
                    }, () => {
                        if (this.state.sumClose !== null) {
                            console.log("this.state.sumClose:", this.state.sumClose);
                            this.setState({
                                visibleDifferenceLines: true,
                                cashFlowIn: (this.state.sumClose - this.state.sumOpen)
                            }, () => {
                                if (this.state.itemsSum !== this.state.cashFlowIn) {
                                    this.setState({
                                        difference: (this.state.cashFlowIn - this.state.itemsSum)
                                    })
                                } else {
                                    this.setState({
                                        visibleDifferenceLines: false
                                    })
                                }
                            })
                        }
                    })
                }
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    notCorrectSave = () => {
        let data = {
            cashRegisterOpenId: this.state.selectedCashRegisterOpenId,
            sumClose: this.state.sumClose
        }
        API.post("cash-register/close/", data)
            .then(() => {
                this.setState({
                    visibleCRClose: false,
                    showNotCorrectClose: false,
                    isCashierOpen: false
                }, async () => {
                    if (this.state.sumOpen === (this.state.sumClose - this.state.itemsSum)) {
                        this.handleTableChange();
                    }
                    this.getItemsAndDifference();
                });
            });
    };

    notCorrectNoSave = () => {
        this.setState({
            visibleCRClose: true,
            showNotCorrectClose: false
        });
        this.handleTableChange();
    };

    handleLastCROpen = () => {
        API.post("cash-register/open-last/" + this.state.cashRegister + "/" + this.state.date)
            .then(res => {
                this.handleTableChange();
                if (res.data.type === "success") {
                    message.success(res.data.msg, 5);
                } else {
                    message.error(res.data.msg, 5);
                }
            });
    };

    handleOkCROpen = (e, sum) => {
        this.setState({
            visibleCROpen: false
        });
        let openData = {
            "cashRegistersId": this.state.cashRegister,
            "openDate": this.state.date,
            "sumOpen": sum
        };
        API.post("cash-register/open/", openData)
            .then(() => {
                this.handleTableChange();
                NotificationUtil({msg: "Sikeres nyitás!", type: "success"});
            })
    };

    handleOkCRClose = async (e, sum) => {
        let difference = await this.getDifference(sum);
        this.getItemsAndDifference();
        if (difference !== 0) {
            this.setState({
                showNotCorrectClose: true,
                sumClose: sum
            });
        } else {
            if (this.state.selectedCashRegisterOpenId !== null) {
                let closeData = {
                    cashRegisterOpenId: this.state.selectedCashRegisterOpenId,
                    sumClose: sum,
                }
                API.post("cash-register/close/", closeData)
                    .then(() => {
                        this.setState({
                            visibleCRClose: false,
                            sumClose: sum,
                            isCashierOpen: false
                        }, () => this.handleTableChange());
                        NotificationUtil({msg: "Sikeres zárás!", type: "success"});
                    });
            } else {
                NotificationUtil({msg: "Sikertelen zárás!", type: "error"});
            }
        }
    };

    handleCancelCROpen = () => {
        this.setState({
            visibleCROpen: false,
        });
    };

    handleCancelCRClose = () => {
        this.setState({
            visibleCRClose: false,
        });
    };

    getCashRegisters() {
        this.setState({tableLoading: true, cashRegisters: []});
        API.get("cash-register/cash-registers/" + this.state.site)
            .then(res => {
                console.log(res.data);
                this.setState({cashRegisters: res.data});
            }).finally(() => {
            this.setState({tableLoading: false})
        })
    };

    handleTableChange() {
        if (this.state.date != null && this.state.cashRegister != null) {
            this.setState({
                sumOpen: null,
                sumClose: null,
                cashFlowIn: 0,
                difference: 0,
                itemsSum: 0,
                visibleDifferenceLines: false,
                isCashierOpen: false
            }, () => {
                this.getDataCROpenAndClose();
                this.getItemsAndDifference();
            });
        }
        if (!this.state.date) {
            this.setState({
                visibleCRCardAndItems: false,
                sumOpen: null,
                sumClose: null,
                cashFlowIn: 0,
                difference: 0,
                itemsSum: 0,
                visibleDifferenceLines: false,
                cashRegisterOpenIds: [],
                isCashierOpen: false
            })
        }
    };

    async getDifference(sum) {
        try {
            let qp = new QueryParams();
            qp.addPathParam(this.state.cashRegister).addPathParam(this.state.date).addPathParam(sum);
            if (this.state.selectedCashRegisterOpenId !== null) {
                qp.setCustomParams({"cashRegisterOpenId": this.state.selectedCashRegisterOpenId});
            }
            const res = await API.get("cash-register/get-difference/" + qp.getQueryParam());
            let data = await res.data;
            if (data === "") {
                data = 0;
            }
            return data;
        } catch (e) {
            console.error(e);
        }
    };

    setSelectedCROpenId() {
        if ((this.state.cashRegisterOpenIds.length > 1 && this.state.selectedCashRegisterOpenId === null)) {
            this.setState({selectedCashRegisterOpenId: this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1]});
        } else if (this.state.cashRegisterOpenIds.length === 1) {
            this.setState({selectedCashRegisterOpenId: this.state.cashRegisterOpenIds[0]});
        }
    };

    getDataCROpenAndClose() {
        this.setState({tableLoading: true, isCashierOpen: false});
        API.get("cash-register/getOpenAndClose/" + this.state.cashRegister + "/" + this.state.date)
            .then(res => {
                if (res.data != null && res.data.length > 0) {
                    res.data.forEach(row => {
                        if ((row.sumOpen != null) && row.sumClose === null) {
                            this.setState({isCashierOpen: true});
                        }
                    });
                    let ids = res.data.map(row => row.id);
                    this.setState({cashRegisterOpenIds: ids}, () => {
                        this.setSelectedCROpenId();
                    });
                    let sumOpen;
                    let sumClose;
                    if (this.state.selectedCashRegisterOpenId != null) {
                        let selectedOpen = res.data.find(x => x.id === this.state.selectedCashRegisterOpenId);
                        sumOpen = selectedOpen.sumOpen;
                        sumClose = selectedOpen.sumClose;
                    } else {
                        sumOpen = res.data[0].sumOpen;
                        sumClose = res.data[0].sumClose;
                    }
                    this.setState({
                        tableLoading: false,
                        sumOpen: sumOpen,
                        sumClose: sumClose
                    }, () => {
                        if (this.state.sumClose !== 0 || this.state.sumClose !== null) {
                            this.getItemsAndDifference();
                        }
                    });
                } else {
                    this.setState({tableLoading: false});
                }
            });
    };

    onChangeDate = (date, dateString) => {
        this.setState({
            date: dateString
        }, () => {
            if (date != null && this.state.cashRegister != null) {
                this.setState({
                    visibleCRCardAndItems: true,
                    selectedCashRegisterOpenId: null,
                    cashRegisterOpenIds: []
                });
                this.handleTableChange();
            } else {
                this.handleTableChange();
            }
        });
    };

    onCashRegisterChange = (value) => {
        this.setState({
            cashRegister: value
        }, () => {
            if (this.state.date != null && value != null) {
                this.setState({
                    visibleCRCardAndItems: true,
                    selectedCashRegisterOpenId: null,
                    cashRegisterOpenIds: []
                }, () => {
                    this.handleTableChange();
                });
            }
        })
    };

    disabledDate = (current) => {
        let customDate = new Date();
        return current && current > moment(customDate, "YYYY-mm-DD");
    };

    pastDate = () => {
        let now = new Date();
        return this.state.date >= moment(now).format("YYYY-MM-DD");
    }

    onchangeCashRegisterOpen = (e) => {
        this.setState({
            selectedCashRegisterOpenId: e
        }, () => {
            this.handleTableChange();
        });
    };

    componentDidMount() {
        this.getCashRegisters();
    }

    render() {
        const {Column, ColumnGroup} = Table;
        return (
            <div>
                <h1>Kassza</h1>
                <Row gutter={18} type="flex" justify="space-around">
                    <Col span={6}>
                        <DatePicker locale={locale} onChange={this.onChangeDate} defaultValue={moment()}
                                    allowClear={false} disabledDate={this.disabledDate}/>
                    </Col>
                    <Col span={6}>
                        {this.state.cashRegisterOpenIds.length >= 1 &&
                        <Select defaultValue={this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1]}
                                style={{width: '100%'}} onChange={this.onchangeCashRegisterOpen}>
                            {this.state.cashRegisterOpenIds.map(d => (
                                <Option key={d} value={d}>#{d}</Option>
                            ))}
                        </Select>
                        }
                    </Col>
                    <Col span={6} offset={0}>
                        <Select style={{width: '70%', position: 'relative', right: '5px'}}
                                onChange={this.onCashRegisterChange} placeholder={"Válasszon kasszát"}>
                            {this.state.cashRegisters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <br/>

                {!this.state.visibleCRCardAndItems &&
                <Empty description={"Válasszon dátumot és kasszát!"}/>
                }
                {this.state.visibleCRCardAndItems &&
                <Row>
                    <Col span={12}>
                        <Card style={{maxWidth: "40%"}}>
                            <h2>Választott kassza: {this.state.cashRegisters.find(cr => cr.id === this.state.cashRegister)?.name}</h2>
                            <h3>Nyitás összege: {this.state.sumOpen}</h3>
                            <h3>Zárás összege: {this.state.sumClose}</h3>

                            {this.state.visibleDifferenceLines &&
                            <h3>Nyitás-zárás különbsége: {this.state.cashFlowIn}</h3>
                            }
                            {this.state.visibleDifferenceLines &&
                            <h3>Tételek összege: {this.state.itemsSum}</h3>
                            }
                            {this.state.visibleDifferenceLines &&
                            <h2>Eltérés: {this.state.difference}</h2>
                            }
                            <Tooltip title={this.state.isCashierOpen ? "Nyitva van a kassza!" : ""}>
                                <Button type="primary" onClick={this.showModalCROpen}
                                        hidden={!this.pastDate() || (this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId)}
                                        disabled={this.state.isCashierOpen}>
                                    Nyitás
                                </Button>
                            </Tooltip>
                            <Button type="primary" onClick={this.showModalCRClose}
                                    hidden={!this.state.isCashierOpen || (this.state.sumOpen != null && this.state.sumClose != null)}>Zárás</Button>

                            <Button type="danger" onClick={this.showModalCRClose}
                                    hidden={(!this.state.visibleDifferenceLines) || (this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId)}>Zárás
                                Javítása</Button>
                            <Tooltip
                                title={!(this.state.isCashierOpen || !this.state.visibleDifferenceLines) ? "Hibás zárás esetén ez a gomb nem használható!" : ""}
                                placement={"bottom"}>
                                <Button type="default" onClick={this.handleLastCROpen}
                                        disabled={(this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId) || this.state.isCashierOpen || this.state.visibleDifferenceLines}
                                        hidden={!this.pastDate() || this.state.cashRegisterOpenIds.length < 1 || ((this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId) || this.state.isCashierOpen)}>Nyitás
                                    előző zárásból</Button>
                            </Tooltip>
                        </Card>
                    </Col>

                </Row>
                }

                {this.state.visibleCRCardAndItems &&
                <div>
                    <Table dataSource={this.state.items} bordered={true} loading={this.state.tableLoading} rowKey={"id"}
                           pagination={false}>
                        <ColumnGroup title="Tételek">
                            <Column title="#" dataIndex="id" key="id"/>
                            <Column title="Tétel megnevezése" dataIndex="name" key="name"/>
                            <Column title="Összeg" dataIndex="amount" key="amount" render={text => {
                                return (text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' '))
                            }}/>
                            <Column title="Megjegyzés" dataIndex="note" key="note"/>

                            <Column title="Szerkesztés" key="action"
                                    render={(text, record) => (
                                        <span>
                                            <Button type="primary"
                                                    disabled={(this.state.sumOpen === null && this.state.sumClose === null) || (this.state.sumOpen != null && this.state.sumClose != null
                                                        && this.state.difference === 0) || this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId}
                                                    onClick={e => this.editClickDrawer(e, record.id)}>Tétel szerkesztése</Button>
                                            <Button type="danger"
                                                    disabled={(this.state.sumOpen === null && this.state.sumClose === null) || (this.state.sumOpen != null && this.state.sumClose != null
                                                        && this.state.difference === 0) || this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId}
                                                    onClick={e => this.removeLine(e, record.id)}>Törlés</Button>
                                        </span>
                                    )}>
                            </Column>
                        </ColumnGroup>
                    </Table>

                    <Button type="primary" onClick={this.showDrawer}
                            disabled={(this.state.sumOpen === null && this.state.sumClose === null) || (this.state.sumOpen != null && this.state.sumClose != null
                                && this.state.difference === 0) || this.state.cashRegisterOpenIds[this.state.cashRegisterOpenIds.length - 1] > this.state.selectedCashRegisterOpenId}>Tétel
                        hozzáadása</Button>
                </div>
                }

                {this.state.showNotCorrectClose &&
                <Modal visible={this.state.showNotCorrectClose}
                       okText="Mentés" onOk={this.notCorrectSave}
                       cancelText="Mégse" onCancel={this.notCorrectNoSave}>
                    <h3>Nem egyezik a zárás! Szeretné mégis elmenteni?</h3>
                </Modal>
                }

                <CashRegisterItemForm visible={this.state.visibleDrawer}
                                      selectedCashRegisterOpenId={this.state.selectedCashRegisterOpenId}
                                      onClose={this.onCloseDrawer} editId={this.state.editId}
                                      cashRegisterId={this.state.cashRegister}/>
                <CashRegisterModal visibleCR={this.state.visibleCROpen} onOk={this.handleOkCROpen}
                                   onCancel={this.handleCancelCROpen}/>
                <CashRegisterModal visibleCR={this.state.visibleCRClose} onOk={this.handleOkCRClose}
                                   onCancel={this.handleCancelCRClose} cashRegisterId={this.state.cashRegister}
                                   date={this.state.date} itemsSum={this.state.itemsSum} sumOpen={this.state.sumOpen}
                                   cashFlowIn={this.state.cashFlowIn}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
    };
}

export default connect(mapStateToProps)(CashRegistry);
