import React, {Component} from 'react';
import {Layout, Menu, Row, Col, Dropdown, Divider, Checkbox, Badge, Icon} from 'antd';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import Partners from '../pages/Partners';
import Dashboard from '../pages/Dashboard';
import {connect} from "react-redux";
import PurchaseOrdersList from "../pages/PurchaseOrderList";
import PurchaseOrders from "../pages/PurchaseOrder";
import {
    setDeliveryTypes,
    setDocNumbers,
    setDocTypes,
    setMetaMode,
    setPaymentTypes,
    setTaxValues,
    setUsers
} from "../actions";
import Products from "../pages/Products";
import SalesOrderList from "../pages/SalesOrderList";
import Inventory from "../pages/Inventory";
import CashRegistry from "../pages/CashRegistry";
import RepairsList from "../pages/RepairsList";
import ProductList from "../pages/ProductList";
import SalesOrder from "../pages/SalesOrder";
import ProductPricing from "../pages/ProductPricing";
import MessageQueueTest from "../pages/MessageQueueTest";
import UserSettings from "../pages/Settings/UserSettings";
import SitesSettings from "../pages/Settings/SitesSettings";
import Repair from "../pages/Repair";
import CashRegisterSettings from "../pages/Settings/CashRegisterSettings";
import WebshopSettings from "../pages/Settings/WebshopSettings";
import ToDoList from "../pages/ToDoList";
import API from "../Utils/API";
import Profile from "../pages/Profile";
import DatabaseUpdates from "../pages/Settings/DatabaseUpdates";
import ProductDetails from "../pages/ProductDetails";
import InvoiceSettings from "../pages/Settings/InvoiceSettings";
import NotificationUtil from "../Utils/NotificationUtil";
import EmailTemplatesSettings from "../pages/Settings/EmailTemplatesSettings";
import {version} from "../../package.json";
import NonWorkingDayIndex from "../pages/Calendar/NonWorkingDayIndex";
import NonWorkingDayExport from "../pages/Calendar/NonWorkingDayExport";
import OfficeCashRegistry from "../pages/OfficeCashRegistry";
import CashRegistryExport from "../pages/CashRegistryExport";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

class SwLayout extends Component {

    state = {
        collapsed: false,
        user: {username: ""},
        todoCount: 0,
        intervalId: null
    };

    UNSAFE_componentWillMount() {
        let jwt = localStorage.getItem("jwtToken");
        if (typeof jwt === "undefined" || jwt === "undefined" || jwt == null) {
            this.logout();
        }
    }

    componentDidMount() {
        // this.authInvoicing();
        this.getTodoCount();
        this.getDeliveryTypes();
        this.getPaymentTypes();
        this.getTaxValues();
        this.getDocTypes();
        this.getUsers();

        let intervalId = setInterval(() => this.getTodoCount(), 15000);
        this.setState({intervalId})
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    getTodoCount() {
        API.get("/todo/getToDoCount")
            .then(res => {
                this.setState({todoCount: res.data})
            });
    }

    Submenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <a href="/app/profile">Profilom</a>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key="1">
                    <a href="#logout" onClick={this.logout}>Kilépés</a>
                </Menu.Item>
            </Menu>);
    };

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    logout = () => {
        localStorage.clear();
        this.props.history.push('/login');
    };

    authInvoicing = () => {
        API.get("invoice/auth")
            .then(() => {
                //Interceptor handles this
                this.getDocNumbers();
            }).catch(err =>
            NotificationUtil({msg: "Hiba történt: " + err, type: "error"}))
    }

    getDocNumbers = () => {
        API.get("invoice/getDocNumbers")
            .then(res => {
                if (typeof res.data.extraList !== "undefined"
                    && res.data.extraList !== "undefined"
                    && res.data.extraList != null) {
                    this.props.setDocNumbers(res.data.extraList)
                }
            });
    }

    getDeliveryTypes() {
        API.get("/getDeliveryTypes")
            .then(res => {
                if (typeof res.data !== "undefined"
                    && res.data !== "undefined"
                    && res.data != null) {
                    this.props.setDeliveryTypes(res.data)
                }
            })
    }

    getPaymentTypes() {
        API.get("/getPaymentTypes")
            .then(res => {
                if (typeof res.data !== "undefined"
                    && res.data !== "undefined"
                    && res.data != null) {
                    this.props.setPaymentTypes(res.data)
                }
            })
    };

    getTaxValues() {
        API.get("/tax/getTaxValues")
            .then(res => {
                if (typeof res.data !== "undefined"
                    && res.data !== "undefined"
                    && res.data != null) {
                    this.props.setTaxValues(res.data)
                }
            })
    };

    getDocTypes() {
        API.get("/getDocTypes")
            .then(res => {
                if (typeof res.data !== "undefined"
                    && res.data !== "undefined"
                    && res.data != null) {
                    this.props.setDocTypes(res.data);
                }
            })
    };

    getUsers() {
        API.get("/getUsers")
            .then(res => {
                if (typeof res.data !== "undefined"
                    && res.data !== "undefined"
                    && res.data != null) {
                    this.props.setUsers(res.data);
                }
                let sites = localStorage.getItem("sites");
                let users = localStorage.getItem("users");
                if (typeof sites === "undefined" || sites === "undefined" || sites == null
                    || typeof users === "undefined" || users === "undefined" || users == null) {
                    this.logout();
                }
            })
    };

    render() {
        return (
            <Router>
                <Layout style={{minHeight: '100vh'}}>
                    <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                        <div className="logo"/>
                        <Menu theme="dark" defaultSelectedKeys={[this.props.location.pathname]} mode="inline">
                            <Menu.Item key="/app/index">
                                <Icon type="dashboard"/>
                                <span>Irányítópult</span><Link to="/app/index"/>
                            </Menu.Item>
                            <Menu.Item key="/app/partners">
                                <Icon type="team"/>
                                <span>Partnerek</span><Link to="/app/partners"/>
                            </Menu.Item>
                            <Menu.Item key="/app/todo">
                                {this.state.todoCount > 0
                                    ? (<Icon type="bell" className={"todo_menu_today"}/>)
                                    : (<Icon type="check"/>)
                                }
                                <span>Teendők</span><Link to="/app/todo"/>
                            </Menu.Item>
                            <Menu.Item key="/app/salesOrder">
                                <Icon type="shop"/>
                                <span>Értékesítés</span><Link to="/app/salesOrder"/>
                            </Menu.Item>
                            <Menu.Item key="/app/purchaseOrders">
                                <Icon type="shopping-cart"/>
                                <span>Beszerzés</span><Link to="/app/purchaseOrders"/>
                            </Menu.Item>

                            <SubMenu
                                key="/app/products"
                                title={
                                    <span>
                                    <Icon type="shopping"/>
                                    <span>Termékek</span>
                                </span>
                                }
                            >
                                <Menu.Item key="/app/products-list">
                                    <Icon type="profile"/>
                                    <span>Termék lista</span><Link to="/app/products-list"/>
                                </Menu.Item>

                                <Menu.Item key="/app/products-pricing">
                                    <span>Termékek árazása</span><Link to="/app/products-pricing"/>
                                </Menu.Item>
                            </SubMenu>

                            {/*<Menu.Item key="/app/inventory">
                                <Icon type="interaction" />
                                <span>Készlet</span><Link to="/app/inventory"/>
                            </Menu.Item>*/}
                            <Menu.Item key="/app/cash-register">
                                <Icon type="dollar"/>
                                <span>Kassza</span><Link to="/app/cash-register"/>
                            </Menu.Item>
                            <Menu.Item key="/app/office-cash-register">
                                <Icon type="bank"/>
                                <span>Irodai kassza</span><Link to="/app/office-cash-register"/>
                            </Menu.Item>
                            <Menu.Item key="/app/repairsList">
                                <Icon type="tool"/>
                                <span>Szervíz</span><Link to="/app/repairsList"/>
                            </Menu.Item>
                            <Menu.Item key="/app/leaves">
                                <Icon type="coffee"/>
                                <span>Szabadságolás</span><Link to="/app/leaves/all"/>
                            </Menu.Item>

                            <SubMenu
                                key="/app/settings"
                                title={
                                    <span>
                                    <Icon type="setting"/>
                                    <span>Beállítások</span>
                                </span>
                                }
                            >
                                <Menu.Item key="/app/settings/invoice-settings">
                                    <Icon type="container"/>
                                    <span>Számlázó</span><Link to="/app/settings/invoice-settings"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/user-settings">
                                    <Icon type="user"/>
                                    <span>Felhasználók</span><Link to="/app/settings/user-settings"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/sites-settings">
                                    <Icon type="home"/>
                                    <span>Telephelyek</span><Link to="/app/settings/sites-settings"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/cash-register-settings">
                                    <Icon type="dollar"/>
                                    <span>Kasszák</span><Link to="/app/settings/cash-register-settings"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/webshops-settings">
                                    <Icon type="global"/>
                                    <span>Webshopok</span><Link to="/app/settings/webshops-settings"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/database-updates">
                                    <Icon type="global"/>
                                    <span>Adatbázis frissítések</span><Link to="/app/settings/database-updates"/>
                                </Menu.Item>
                                <Menu.Item key="/app/settings/email-templates">
                                    <Icon type="mail"/>
                                    <span>Email formátumok</span><Link to="/app/settings/email-templates"/>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header style={{background: '#fff', padding: 0}}>
                            <Row>
                                <Col span={1}>
                                    <Icon
                                        className="trigger"
                                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                        style={{cursor: 'pointer'}}
                                        onClick={this.toggle}
                                    />
                                </Col>
                                <Col span={8} style={{paddingLeft: '20px'}}>
                                    <b>Pc-Ház-Terv SW
                                        {process.env.NODE_ENV === "development" &&
                                            <font color="red">&nbsp; DEV</font>
                                        }
                                    </b>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>

                                    <span>
                                        Telephely: {this.props.selectedSite.title}
                                    </span>

                                    <Divider type="vertical" style={{background: "darkgray"}}/>

                                    <span>
                                        <Icon type="user"/>
                                        {this.props.user.username}
                                        ({this.props.user.fullName})
                                        {((this.state.todoCount !== "") && (this.state.todoCount != null)) &&
                                            <a href="/app/todo"><Badge
                                                style={{backgroundColor: "#ff6666", color: "white", marginLeft: 5}}
                                                count={this.state.todoCount}/></a>
                                        }
                                    </span>

                                </Col>

                                <Col span={3} style={{textAlign: "left"}}>
                                    <Divider type="vertical" style={{background: "darkgray"}}/>
                                    <Checkbox
                                        onChange={e => this.props.setMetaMode(e.target.checked)}
                                        checked={this.props.metaMode}> Expert
                                    </Checkbox>
                                    <Divider type="vertical" style={{background: "darkgray"}}/>
                                    <Dropdown overlay={this.Submenu} trigger={['click']}>
                                        <a className="ant-dropdown-link" href="#user">
                                            <Icon type="user"/> <Icon type="down"/>
                                        </a>
                                    </Dropdown>
                                </Col>
                            </Row>

                        </Header>

                        <Content style={{margin: '16px 16px', padding: 24, background: '#fff'}}>
                            <Route path="/app/index" component={Dashboard}/>
                            <Route path="/app/partners" component={Partners}/>
                            <Route path="/app/todo" component={ToDoList}/>
                            <Route path="/app/purchaseOrders" component={PurchaseOrdersList}/>
                            <Route path="/app/new-purchaseOrder" component={PurchaseOrders}/>
                            <Route path="/app/edit-purchaseOrder/:id" component={PurchaseOrders}/>
                            <Route path="/app/products" component={Products}/>
                            <Route path="/app/products-list" component={ProductList}/>
                            <Route path="/app/products-pricing" component={ProductPricing}/>
                            <Route path="/app/salesOrder" component={SalesOrderList}/>
                            <Route path="/app/new-salesOrder" component={SalesOrder}/>
                            <Route path="/app/new-bidOrder" component={SalesOrder}/>
                            <Route path="/app/new-sales" component={SalesOrder}/>
                            <Route path="/app/edit-salesOrder/:id" component={SalesOrder}/>
                            <Route path="/app/inventory" component={Inventory}/>
                            <Route path="/app/cash-register" component={CashRegistry}/>
                            <Route path="/app/office-cash-register" component={OfficeCashRegistry}/>
                            <Route path="/app/repairsList" component={RepairsList}/>
                            <Route path="/app/new-repair" component={Repair}/>
                            <Route path="/app/edit-repair/:id" component={Repair}/>
                            <Route path="/app/leaves/all" component={NonWorkingDayIndex}/>
                            <Route path="/app/leaves/export" component={NonWorkingDayExport}/>

                            <Route path="/app/settings/user-settings" component={UserSettings}/>
                            <Route path="/app/settings/sites-settings" component={SitesSettings}/>
                            <Route path="/app/settings/cash-register-settings" component={CashRegisterSettings}/>
                            <Route path="/app/settings/cash-register-export" component={CashRegistryExport}/>
                            <Route path="/app/settings/webshops-settings" component={WebshopSettings}/>
                            <Route path="/app/settings/database-updates" component={DatabaseUpdates}/>
                            <Route path="/app/settings/invoice-settings" component={InvoiceSettings}/>
                            <Route path="/app/settings/email-templates" component={EmailTemplatesSettings}/>

                            <Route path="/app/profile" component={Profile}/>

                            <Route path="/app/product" component={ProductDetails}/>

                            <Route path="/app/mq-test" component={MessageQueueTest}/>
                        </Content>
                        <Footer style={{textAlign: 'center'}}>Pc-Ház-Terv Bt. -- Verzió: {version} Béta</Footer>
                    </Layout>
                </Layout></Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
        metaMode: state.metaMode,
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setMetaMode: (metaMode) => dispatch(setMetaMode(metaMode)),
        setDocNumbers: docNumbers => dispatch(setDocNumbers(docNumbers)),
        setDeliveryTypes: deliveryTypes => dispatch(setDeliveryTypes(deliveryTypes)),
        setPaymentTypes: paymentTypes => dispatch(setPaymentTypes(paymentTypes)),
        setTaxValues: taxValues => dispatch(setTaxValues(taxValues)),
        setDocTypes: docTypes => dispatch(setDocTypes(docTypes)),
        setUsers: users => dispatch(setUsers(users))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwLayout);

