import {Drawer, Button} from 'antd';
import React, {Component} from 'react';
import PartnerForm from "./PartnerForm";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import {setNewlyCreatedPartner} from "../actions";
import {connect} from "react-redux";

import confirm from "antd/es/modal/confirm";

function mapDispatchToProps(dispatch) {
    return {
        setNewlyCreatedPartner: site => dispatch(setNewlyCreatedPartner(site))
    };
}


class PartnerDrawer extends Component {


    save = (e) => {
        const {form} = this.formRef.props;
        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            if (this.props.editId != null) {
                values = Object.assign({}, values, {
                    id: this.props.editId
                });
            }

            if (values.isSupplier === true) {
                values.partnerType = "SUPPLIER"
            } else {
                values.partnerType = "CUSTOMER"
            }
            let current = this;
            console.log(values);
            if (values.vatNumber === null || values.vatNumber === undefined) {
                confirm({
                    title: "Hiányzó adószám!",
                    content: "A partnernek nincs megadva adószám, így számla kiállításánál nem fog adatszolgáltatás történni a NAV felé!",
                    okText: "Folytatás",
                    cancelText: "Adószám megadása",
                    onOk() {
                        API.post("savePartner/", values)
                            .then(res => {
                                form.resetFields();
                                current.props.setNewlyCreatedPartner(res.data.extra);
                                current.props.onClose(e, true);
                                NotificationUtil(res.data);
                            }).catch(() => {
                            NotificationUtil({msg: "Hiba történt!", type: "error"});
                            current.props.onClose(e, true);
                        });
                    }
                })
            } else {
                API.post("savePartner/", values)
                    .then(res => {
                        form.resetFields();
                        this.props.setNewlyCreatedPartner(res.data.extra);
                        this.props.onClose(e, true);
                        NotificationUtil(res.data);
                    }).catch(() => {
                    NotificationUtil({msg: "Hiba történt!", type: "error"});
                    this.props.onClose(e, true);
                });
            }
        });
    };


    constructor(props) {
        super(props);
        this.state = {
            partner: {name: ""},
            title: "",
            supplier: false
        };
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    open = () => {
        if (this.props.editId !== '') {
            API.get("/getPartner/" + this.props.editId).then(res => {
                this.setState({partner: res.data});
                this.setState({title: "Partner módosítása : " + res.data.name});
                if (res.data.partnerType === "SUPPLIER") {
                    this.setState({supplier: true})
                } else {
                    this.setState({supplier: false})
                }
            });
        } else {
            this.setState({partner: {name: ""}});
            this.setState({title: "Új partner felvétele"});
        }
    };

    render() {
        return (
            <Drawer
                width={480}
                title={this.state.title}
                placement="right"
                closable={true}
                afterVisibleChange={this.open}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >

                <PartnerForm {...this.state.partner} wrappedComponentRef={this.saveFormRef}
                             initialName={this.state.partner.name} isSupplier={this.state.supplier}/>

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >

                    <Button onClick={this.props.onClose} style={{marginRight: 8}}>
                        Mégsem
                    </Button>
                    <Button onClick={this.save} type="primary">
                        Mentés
                    </Button>
                </div>
            </Drawer>
        );
    }
}

export default connect(null, mapDispatchToProps)(PartnerDrawer);

