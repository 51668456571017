import React, {Component} from "react";
import {Affix, Button, Card, Col, Divider, Row, Select, Table} from "antd";
import {connect} from "react-redux";
import API from "../Utils/API";
import SiteSelector from "../Components/SiteSelector";
import QueryParams from "../Utils/QueryParams";
import NotificationUtil from "../Utils/NotificationUtil";
import ToDoModal from "../Components/ToDoModal";
import moment from "moment";
import ResponsibleSelector from "../Components/ResponsibleSelector";

const {Column} = Table;
const { Option } = Select;

class ToDoList extends Component {

    componentDidMount() {
        this.getAllStatus();
        this.getUsers();
    }

    state = {
        tableLoading: false,
        users: [],
        selectedUser: null,
        toDo : [],
        status: [],
        selectedStatus : [],
        site: "all",
        pagination: {
            pageSizeOptions: ['50', '100', '150', '200'],
            showSizeChanger: true,
            current: 1,
            results: 1,
            total: 200,
            pageSize: 50
        },
        modalVisible: false,
        title: "",
        item: {},
    };

    getUsers() {
        this.setState({users: JSON.parse(localStorage.getItem('users'))})
    };

    editToDo = (id) => {
        API.get("/todo/get-item/" + id).then(res => {
            this.setState({item: res.data});
            this.setState({title: "Tétel módosítása: #"+ res.data.id});
        }).finally(()=>{this.setState({modalVisible: true})});
    };

    newToDo = () => {
        this.setState({item: []});
        this.setState({title: "Új tétel létrehozása"});
        this.setState({modalVisible: true})
    };

    handleOk = (e,values) => {
        this.setState({modalVisible: false})
        API.post("todo/save-item",values).then(res => {
            NotificationUtil(res.data);
            this.handleTableChange(this.state.pagination);
        }).catch(error => {
            NotificationUtil({msg:"Hiba történt: "+error,type:"error"});
        });
    }
    handleCancel = () => {
        this.setState({modalVisible: false})
    }

    getAllStatus() {
        API.get("/todo/getStatuses").then(res => {
            this.setState({
                selectedStatus: [1,2],
                status: res.data
            },()=>this.getToDoList())
        })
    };

    getToDoList() {
        this.setState({tableLoading: true ,toDo: []});
        let queryParams = new QueryParams();
        if (this.state.selectedUser == null) {
            queryParams.addPathParam(this.state.pagination.current + "&size=" + this.state.pagination.pageSize + "&site="
                + this.state.site + "&status=" + this.state.selectedStatus);
        } else {
            queryParams.addPathParam(this.state.pagination.current + "&size=" + this.state.pagination.pageSize + "&site="
                + this.state.site + "&status=" + this.state.selectedStatus + "&user=" + this.state.selectedUser)
        }

        API.get("/todo/getToDoList?page="+queryParams.getQueryParam())
            .then(res=> {
                if (res.data.lines != null) {
                    console.log(res.data.lines)
                    for (let i = 0; i < res.data.lines.length; i++) {
                        let createdDate = moment(res.data.lines[0].createdDate);
                        let date = createdDate.year() + "-" + (createdDate.month()+1) + "-" + createdDate.date() + " " + (createdDate.hour()+2) + ":" +createdDate.minute();
                        res.data.lines[i].createdDate = date;

                        let updatedDate = moment(res.data.lines[0].updatedDate);
                        date = updatedDate.year() + "-" + (updatedDate.month()+1) + "-" + updatedDate.date() + " " + (updatedDate.hour()+2) + ":" +updatedDate.minute();
                        res.data.lines[i].updatedDate = date;
                    }
                }
                const pagination = { ...this.state.pagination };
                pagination.total = res.data.totalElements;
                this.setState({toDo:res.data.lines, pagination:pagination})
            }).finally(()=>{
                this.setState({tableLoading: false })
        })
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
            sortedInfo : sorter
        },() => {
            this.getToDoList();
        });
    };

    changeSite = (e) => {
        this.setState({site: e ,pagination : { ...this.state.pagination, current: 0}}, () => {
            this.getToDoList();
        });
    };

    filterByUser = (e) => {
        this.setState({selectedUser: e ,pagination : { ...this.state.pagination, current: 0}}, () => {
            this.getToDoList();
        });
    };

    changeStatus = (text) => {
        this.setState({selectedStatus: text,
            pagination : { ...this.state.pagination, current: 0}
        }, () => {
            this.getToDoList();
        });
    };

    render() {
        return (
            <div>
                <Card title="Teendők">
                    <Affix offsetTop={10} >
                        <div style={{backgroundColor: "white"}}>
                            <Col>
                                <Row>
                                    <Button onClick={this.newToDo} type="primary">
                                        Új felvétel
                                    </Button>
                                    <Divider type="vertical" />
                                    <Button onClick={this.handleTableChange} type="primary">
                                        Frissítés
                                    </Button>
                                </Row>
                                <Divider type="horizontal" />
                            </Col>
                        </div>
                    </Affix>

                    <Row>
                        <Col span={5}><b>Státusz</b></Col>
                        <Col span={3} offset={1}><b>Telephely</b></Col>
                        <Col span={4} offset={1}><b>Felelős személy</b></Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                onChange={this.changeStatus}
                                value={this.state.selectedStatus}
                                placeholder="Státusz">
                                {this.state.status.map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col offset={1} span={3}>
                            <SiteSelector all={true} selected={"all"} onSelect={this.changeSite}/>
                        </Col>
                        <Col offset={1} span={4}>
                            <ResponsibleSelector users={this.state.users} filterByUser={e => this.filterByUser(e)} />
                        </Col>
                    </Row>

                    <Divider type="horizontal" />
                    <Col span={24}>
                        <Table onChange={this.handleTableChange} dataSource={this.state.toDo} bordered={true}
                               loading={this.state.tableLoading} rowKey={"id"} size={"small"} pagination={this.state.pagination}>

                            <Column title="#" dataIndex="id" key="id"/>
                            <Column title="Megnevezés" dataIndex="toDoDescription" key="toDoDescription"/>
                            <Column title="Partner" dataIndex="partnerName" key="partnerName"/>
                            <Column title="Státusz" dataIndex="toDoStatus" key="toDoStatus"/>
                            <Column title="Telephely" dataIndex="siteTitle" key="siteTitle"/>
                            <Column title="Felelős" dataIndex="responsibleName" key="responsibleName"/>
                            <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>
                            <Column title="Létrehozva" dataIndex="createdDate" key="createdDate"/>

                            {this.props.metaMode &&   <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/> }
                            {this.props.metaMode &&   <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/> }

                            <Column render={(record) => <span className="link" onClick={() => this.editToDo(record.id)}>Szerkesztés</span>}/>

                        </Table>
                    </Col>
                </Card>
                <ToDoModal handleTableChange={this.getToDoList} statuses={this.state.status} users={this.state.users} visible={this.state.modalVisible} onOk={this.handleOk} onCancel={this.handleCancel} item={this.state.item} title={this.state.title}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        metaMode: state.metaMode,
        selectedSite: state.selectedSite,
    };
}

export default connect(mapStateToProps)(ToDoList);