import React, {Component} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";

const {Option} = Select;

export const VariantModal = Form.create({name: 'form_in_modal'})(
    class extends Component {
        state = {}

        render() {
            const {form} = this.props;
            const {getFieldDecorator} = form;
            let footer = [
                <Button key={"back"} onClick={this.props.handleVariantModal}>Mégsem</Button>,
                <Button key={"submit"} type={"primary"} onClick={this.props.createVariant}>Mentés</Button>
            ];
            if (this.props.state.selectedVariant) {
                footer = [
                    <Button key={"back"} onClick={this.props.handleVariantModal}>Mégsem</Button>,
                    <Button key={"delete"} type={"danger"} onClick={this.props.deleteVariant}>Törlés</Button>,
                    <Button key={"submit"} type={"primary"} onClick={this.props.createVariant}>Mentés</Button>
                ]
            }
            const {current} = this.props;
            return (
                <Modal visible={this.props.state.variantModalVisible}
                       title={this.props.state.selectedVariant ? "Variáns szerkesztése" : "Új variáns felvétele"}
                       okText={"Hozzáadás"}
                       closable={false}
                       cancelText={"Mégsem"}
                       footer={footer}>
                    <Form>
                        <Form.Item label={'Méret'}>
                            {getFieldDecorator("size", {
                                rules: [{required: true}],
                                initialValue: this.props.state.selectedVariant ? this.props.state.selectedVariant.size : ""
                            })(
                                <Input name="size" placeholder="Méret"/>
                            )}
                        </Form.Item>
                        <Form.Item label={'Súly'}>
                            {getFieldDecorator("weight", {
                                rules: [{required: true}],
                                initialValue: this.props.state.selectedVariant ? this.props.state.selectedVariant.weight : ""
                            })(
                                <Input name="weight" placeholder="Súly"/>
                            )}
                        </Form.Item>
                        <Form.Item label={'Extra méretű'}>
                            {getFieldDecorator("specialSize", {
                                rules: [{required: true}],
                                initialValue: this.props.state.selectedVariant ? this.props.state.selectedVariant.specialSize : "0"
                            })(
                                <Select name="specialSize" placeholder="Extra méretű" style={{width: '100%'}}>
                                    <Option value="1">Igen</Option>
                                    <Option value="0">Nem</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <p>
                            <Button type="primary" onClick={current.handlePurchasePriceModal}><PlusOutlined/> Beszerzési ár hozzáadása</Button>
                        </p>
                        <p>
                            <Button type="primary" onClick={() => current.handleShopPriceModal({}, null)}><PlusOutlined/> Eladási ár hozzáadása</Button>
                        </p>

                        <Form.Item style={{hidden: true}}>
                            {getFieldDecorator("id", {
                                rules: [{required: false}],
                                initialValue: this.props.state.selectedVariant ? this.props.state.selectedVariant.id : null
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            )
        }
    })