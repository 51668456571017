import React, {Component} from "react";
import {Button, Card} from "antd";
import FullCalendar from "@fullcalendar/react";
import huLocale from "@fullcalendar/core/locales/hu"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import API from "../../Utils/API";
import moment from "moment";
import LeaveFormModal from "../../Components/LeaveFormModal";
import NotificationUtil from "../../Utils/NotificationUtil";
import LeaveInfoModal from "../../Components/LeaveInfoModal";

class NonWorkingDayIndex extends Component {

    calendarRef = React.createRef()
    formModalRef = React.createRef()

    componentDidMount() {
        this.getDates();
        this.getLeaveTypes();
        this.getEmployees();
        this.getLeaves();
    }

    state = {
        dates: [],
        events: [],
        backgroundEvents: [],
        canSelect: false,
        selectedDateInterval: [],
        formModalVisible: false,
        infoModalVisible: false,
        clickedEvent: null,
        leaveTypes: [],
        employees: [],
        leaves: [],
        businessDayCount: 0,
        editId: ""
    }

    getDates = () => {
        API.get("calendar/getDates/").then(res => {
            this.setState({
                dates: res.data,
                backgroundEvents: this.getBackgroundEvents(res.data)
            })
        })
    }

    getLeaveTypes = () => {
        API.get("leaveTypes/getTypes/").then(res => {
            this.setState({
                leaveTypes: res.data
            })
        })
    }

    getEmployees = () => {
        API.get("getUsers").then(res => {
            this.setState({
                employees: res.data
            })
        })
    }

    getLeaves = () => {
        API.get("leaves/getLeaves/").then(res => {
            this.setState({
                leaves: res.data,
                events: this.getLeaveEvents(res.data)
            })
        })
    }

    getBusinessDays = (startDateStr, endDateStr) => {
        const startDate = moment(startDateStr)
        const endDate = moment(endDateStr)
        let dates = []
        for (let currentDate = moment(startDate); currentDate.isSameOrBefore(endDate, 'day'); currentDate.add(1, 'd')) {
            dates.push(this.state.dates.find(date => date.date === currentDate.format('YYYY-MM-DD')))
        }
        if (dates.some(date => date == null)) return [];
        if (dates.every(date => date.type !== 2)) return [];

        while (dates[0].type !== 2) dates.shift()
        while (dates.at(-1).type !== 2) dates.pop()

        return dates.filter(date => date.type === 2);
    }

    getBusinessDayCount = (startDateStr, endDateStr) => {
        return this.getBusinessDays(startDateStr, endDateStr).length
    }

    setSelectedDateInterval = (dates) => {
        this.setState({
            selectedDateInterval: dates,
        })
    }

    getBackgroundEvents = (dates) => {
        return dates.map(day => ({
            date: day.date,
            title: day.name ? day.name : '',
            display: 'background',
            color: day.type === 2 ? '#97e589' : '#ff9f89',
        }))
    }

    getLeaveEvents = (leaves) => {
        return leaves.map(leave => ({
            id: leave.id,
            start: leave.startDate,
            end: moment(leave.endDate).add(1, 'd').format('YYYY-MM-DD'),
            title: leave.employee.fullName,
            color: leave.type.color,
            classNames: leave.type.id === 3 ? ['half-day'] : [],
        }))
    }

    onExportClick = () => {
        this.props.history.push('/app/leaves/export')
    }

    render() {
        return (
            <div>
                <Card title="Munkanapok">
                    <Button onClick={this.onExportClick} type="primary" style={{marginBottom: "1em"}}>Exportálás</Button>
                    <h4>Új szabadság felvételéhez jelöljön ki egy intervallumot!</h4>
                    <FullCalendar
                        ref={this.calendarRef}
                        locale={huLocale}
                        plugins={[ dayGridPlugin, interactionPlugin ]}
                        initialView="dayGridMonth"
                        events={this.state.events.concat(this.state.backgroundEvents)}
                        eventClick={this.onEventClick}
                        selectable={true}
                        select={this.onDateSelect}
                    />
                </Card>
                <LeaveFormModal
                    ref={this.formModalRef}
                    visible={this.state.formModalVisible}
                    close={this.onFormModalClose}
                    startDate={this.state.selectedDateInterval[0]?.date}
                    endDate={this.state.selectedDateInterval.at(-1)?.date}
                    getBusinessDayCount={this.getBusinessDayCount}
                    leaveTypes={this.state.leaveTypes}
                    employees={this.state.employees}
                    getLeaves={this.getLeaves}
                    editId={this.state.editId}
                    editEvent={this.state.clickedEvent}
                />
                <LeaveInfoModal
                    visible={this.state.infoModalVisible}
                    close={this.onInfoModalClose}
                    onEventEdit={this.onEventEdit}
                    onEventDelete={this.onEventDelete}
                    event={this.state.clickedEvent}
                />
            </div>
        )
    }

    onFormModalClose = () => {
        this.setState({ formModalVisible: false });
        this.calendarRef.current.getApi().unselect();
        this.getLeaves()
    }

    onInfoModalClose = () => {
        this.setState({
            infoModalVisible: false,
            clickedEvent: null,
        })
    }

    onEventClick = (e) => {
        if (e.event.id === null || e.event.id === '') return;
        const clickedEvent = this.state.leaves.find(event => event.id === parseInt(e.event.id))
        if (clickedEvent == null) return;

        this.setState({
            clickedEvent: clickedEvent,
            infoModalVisible: true,
        })
    }

    onEventEdit = (id) => {
        this.setState({ editId: id })
        this.formModalRef.current.open(id)
        this.setState({
            formModalVisible: true,
        })
    }

    onEventDelete = (id) => {
        API.post(`/leaves/deleteLeave/${id}`).then(res => {
            NotificationUtil(res.data)
        }).catch(() => {
            NotificationUtil({msg: "Hiba történt!", type: "error"})
        }).finally(
            setTimeout(() => this.getLeaves(), 500)
)
    }

    onDateSelect = (e) => {
        if (moment(e.startStr).isBefore(moment(), 'day')) {
            NotificationUtil({ msg: "Nem lehet múltbéli kezdődátumot kiválasztani!", type: "error"})
            this.calendarRef.current.getApi().unselect()
            return
        }

        const businessDays = this.getBusinessDays(e.startStr, moment(e.endStr).subtract(1, 'day').format('YYYY-MM-DD'))
        this.setSelectedDateInterval(businessDays)
        const businessDayCount = businessDays.length

        if (businessDayCount <= 0) {
            NotificationUtil({msg:"Nincs munkanap a kiválasztott intervallumban!",type:"error"});
            this.calendarRef.current.getApi().unselect()
            return
        }
        this.formModalRef.current.open("")
        this.setState({
            businessDayCount: businessDayCount,
            formModalVisible: true,
        })
    }
}

export default NonWorkingDayIndex