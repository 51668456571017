import React, {Component} from "react";
import {Icon} from "antd";
import API from "../Utils/API";
import { Card, Col, Row } from 'antd';

class Products extends Component {

    import = () =>{
        API.get("product-import-demo-elastic").then(res => {
            console.log(res);
        });
    };

    importMobilDig = () =>{
        API.get("product-import-demo1").then(res => {
            console.log(res);
        });
    };

    importDb = () =>{
        API.get("product-import-demo-db").then(res => {
            console.log(res);
        });
    };

    importMobilDigDb = () =>{
        API.get("product-import-demo-db-1").then(res => {
            console.log(res);
        });
    };

    goToProducts = () => {
        this.props.history.push('/app/products-list');
    };

    render() {
        return (
            <div>

                <Row>
                    <Col span={6}>
                        <Card hoverable style={{backgroundColor : "#4fd1c5"}}>
                            <h1 style={{color : "white"}}><Icon type="tag" />Termék árazás</h1>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card onClick={this.goToProducts} hoverable style={{backgroundColor : "#4299e1"}}>
                            <h1 style={{color : "white"}}><Icon type="profile" /> Termékek listázása</h1>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card hoverable style={{backgroundColor : "#38a169"}}>
                            <h1 style={{color : "white"}}><Icon type="search" /> Termék kereső</h1>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card hoverable style={{backgroundColor : "#4a5568"}}>
                            <h1 style={{color : "white"}}><Icon type="upload" /> Termék importálás</h1>
                        </Card>
                    </Col>
                </Row>

                {/*
                 <Button onClick={this.import}> Import Compmarket elastic</Button>
                 <Button onClick={this.importMobilDig}> Import mobildig</Button>

                <Button onClick={this.importDb}> Import Compmarket to DB</Button>
                <Button onClick={this.importMobilDigDb}> Import mobildig to DB</Button> */}
            </div>
        );
    }
}

export default Products;