import {
    Affix,
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Icon,
    Input,
    Menu,
    Modal,
    Popconfirm,
    Row,
    Table, Tooltip
} from 'antd';
import React, {Component} from 'react';
import API from "../Utils/API";
import PartnerDrawer from "../Components/PartnerDrawer";
import PurchaseOrderExpandedTable from "../Components/PurchaseOrderExpandedTable";
import SiteSelector from "../Components/SiteSelector";
import _ from 'lodash'
import {connect} from "react-redux";
import NotificationUtil from "../Utils/NotificationUtil";
import PurchaseOrderStateSelector from "../Components/PurchaseOrderStateSelector";
import {saveAs} from 'file-saver';
import {LockOutlined} from "@ant-design/icons";
import {resolvePurchaseOrderStatus} from "../Utils/Utils";

const {Column} = Table;

class PurchaseOrderList extends Component {

    componentDidMount() {
        this.getAllStatus();
        this.getStatusesData();
    }

    state = {
        showStatusModal: false,
        tableLoading: true,
        visible: false,
        editId: null,
        search: "",
        purchaseOrders: [{
            id: ""
        }],
        status: [],
        selectedStatus: [],
        selectedOrderStatus: "",
        selectedRowKeys: [],
        selectedRows: [],
        visibleStateChangeButton: false,
        site: "all",
        searchStr: "",
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            current: 0,
            results: 1,
            total: 200,
            pageSize: 10
        },
        currentMenuKey: 'all',
        statusesData: {pending: 0, ordered: 0, inStock: 0}
    };

    newPurchaseOrder = () => {
        this.props.history.push('/app/new-purchaseOrder');
    };

    reload = _.debounce(() => {
        this.getPurchaseOrders(this.state.searchStr);
    }, 500);

    editClick = (e, id) => {
        this.setState({
            visible: true,
            editId: id
        });
    };

    editPurchaseOrder = (id) => {
        this.props.history.push('/app/edit-purchaseOrder/' + id);
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
            if (selectedRowKeys > 0) {
                this.setState({
                    visibleStateChangeButton: true
                });
            } else {
                this.setState({
                    visibleStateChangeButton: false
                });
            }
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    onClose = (e, reload) => {
        this.setState({
            visible: false,
            editId: ""
        });
        if (reload) {
            this.setState({tableLoading: true});
            this.getPurchaseOrders(this.state.searchStr);
        }
    };

    getPurchaseOrders(search) {
        this.setState({tableLoading: true, purchaseOrders: []});
        let data = {
            page: this.state.pagination.current,
            size: this.state.pagination.pageSize,
            siteId: this.state.site,
            status: this.state.selectedStatus,
            search: search
        }
        API.post("/purchaseOrder/getPurchaseOrders", data)
            .then(res => {
                let purchaseOrders = res.data.lines;
                const pagination = {...this.state.pagination};
                pagination.total = res.data.totalElements;
                this.setState({
                    purchaseOrders: purchaseOrders,
                    pagination
                }, () => console.log(this.state.purchaseOrders));

            }).finally(() => {
            this.setState({tableLoading: false});
        });
    }

    download = (key) => {
        alert("Előkészítés..")
        API.get("/purchaseOrder/download/" + key, {responseType: 'blob'})
            .then(res => {
                const blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let date = new Date();
                let fileName = "rendelesek-" + date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + ".xls";
                saveAs(blob, fileName);
            }).catch(error => {
            console.log(error);
        });
    };

    handleTableChange = (pagination) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        if (typeof pagination.pageSize !== "undefined")
            pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        }, () => {
            this.getPurchaseOrders(this.state.searchStr);
        });
    };

    changeSite = (e) => {
        this.setState({site: e, pagination: {...this.state.pagination, current: 0}}, () => {
            this.handleTableChange(this.state.pagination);
            this.getStatusesData();
        });
    };

    searchCallback = _.debounce((text) => {
        this.search(text);
    }, 500);

    search = (text) => {
        this.setState({
            searchStr: text,
            pagination: {...this.state.pagination, current: 0}
        }, () => {
            this.getPurchaseOrders(this.state.searchStr);
        });
    };

    getStatusesData() {
        API.get("purchaseOrder/getStatusesData/" + this.state.site)
            .then(res => {
                this.setState({statusesData: res.data})
            });
    };

    changeOrderStatus = (e) => {
        this.setState({selectedOrderStatus: e});
    };

    saveOrderStatus = () => {
        let {selectedRows} = this.state;
        let selectedIds = selectedRows.map(row => {
            return row.id;
        });

        let data = {
            lines: selectedIds,
            status: this.state.selectedOrderStatus
        };
        API.post("purchaseOrder/updatePurchaseOrdersStatus", data).then(res => {
            NotificationUtil(res.data);
            this.getPurchaseOrders();
            this.getStatusesData();
            this.setState({
                showStatusModal: false
            });
        }).catch(error => {
            console.log(error);
            NotificationUtil({msg: "Hiba történt", type: "error"});
        });
    };

    removeOrder = () => {
        let {selectedRows} = this.state;
        let selectedIds = selectedRows.map(row => {
            return row.id;
        });
        API.post("purchaseOrder/removeOrder/" + selectedIds).then(res => {
            NotificationUtil(res.data);
            this.getPurchaseOrders();
        }).catch(() => {
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    };

    showStatus = () => {
        this.setState({showStatusModal: true})
    };

    hideStatus = () => {
        this.setState({showStatusModal: false})
    };

    getAllStatus() {
        API.get("purchaseOrder/getAllStatus").then(res => {
            this.setState({
                selectedStatus: res.data,
                status: res.data
            }, () => {
                this.getPurchaseOrders(this.state.searchStr);
            });
        });
    };

    handleMenuClick = (e) => {
        let setPaggination = this.state.pagination;
        setPaggination.current = 1;
        this.setState({pagination: setPaggination});

        let selectedStatus = [];
        if (e.key === "all") {
            this.state.status.forEach(d => selectedStatus.push(d));
        } else {
            selectedStatus.push(e.key);
        }
        this.setState({currentMenuKey: e.key, selectedStatus}, () => this.getPurchaseOrders(this.state.searchStr));
    }

    render() {
        const {selectedRowKeys} = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Card title="Beszerzések">
                    <Affix offsetTop={10}>
                        <div style={{backgroundColor: "white"}}>
                            <Row /*type="flex" justify="space-around"*/>
                                <Col offset={1}>
                                    <Button onClick={e => this.newPurchaseOrder(e)} type="primary">
                                        Új felvétel
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Button onClick={this.handleTableChange} type="primary">
                                        Frissítés
                                    </Button>
                                    <Divider type="vertical"/>
                                    <Dropdown overlay={(
                                        <Menu>
                                            <Menu.Item key={1} onClick={() => this.download(1)}>
                                                Utolsó 1 hónap
                                            </Menu.Item>
                                            <Menu.Item key={2} onClick={() => this.download(2)}>
                                                Utolsó 6 hónap
                                            </Menu.Item>
                                            <Menu.Item key={3} onClick={() => this.download(3)}>
                                                Utolsó 1 év
                                            </Menu.Item>
                                            <Menu.Item key={4} onClick={() => this.download(4)}>
                                                Összes
                                            </Menu.Item>
                                        </Menu>
                                    )}>
                                        <Button type="primary">Excel export</Button>
                                    </Dropdown>
                                    <Divider type="vertical"/>
                                    <Button disabled={!hasSelected} onClick={this.showStatus} type="primary"
                                            style={{background: "green"}}>
                                        Státusz módosítása
                                    </Button>
                                    <Modal
                                        onOk={e => this.saveOrderStatus(e)} okText="Mentés"
                                        onCancel={this.hideStatus} cancelText="Mégsem"
                                        visible={this.state.showStatusModal}
                                        title="Kijelölt(ek) státuszának módosítása">
                                        <PurchaseOrderStateSelector changeStatus={this.changeOrderStatus}/>
                                    </Modal>
                                    <Divider type="vertical"/>
                                    <Popconfirm
                                        title={"Biztos a törlésben? A törlés nem visszavonható!"}
                                        icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
                                        onConfirm={this.removeOrder}
                                        okText="Igen"
                                        cancelText="Nem"
                                        disabled={!hasSelected}
                                    >
                                        <Button disabled={!hasSelected} type="danger">Rendelés törlése</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>

                            <Divider type="horizontal"/>

                            <Menu onClick={this.handleMenuClick} selectedKeys={[this.state.currentMenuKey]} mode="horizontal">
                                <Menu.Item key="all" style={{width: '20%'}}><b>Összes</b></Menu.Item>
                                <Menu.Item key="Pending" style={{width: '20%'}}><b>Függőben </b>({this.state.statusesData.pending})</Menu.Item>
                                <Menu.Item key="Ordered" style={{width: '20%'}}><b>Megrendelve </b>({this.state.statusesData.ordered})</Menu.Item>
                                <Menu.Item key="InStock" style={{width: '20%'}}><b>Készleten </b>({this.state.statusesData.inStock})</Menu.Item>
                                <Menu.Item key="Done" style={{width: '20%'}}><b>Lezárt</b></Menu.Item>
                            </Menu>
                            <Divider type="horizontal"/>

                        </div>
                    </Affix>
                    <Row>
                        {/*<Col span={5}><b>Rendelés státusza</b></Col>*/}
                        <Col span={5} />
                        <Col offset={1} span={3}><b>Telephely</b></Col>
                    </Row>
                    <Row>
                        {/*<Col span={5}>*/}
                        {/*    <Select*/}
                        {/*        mode="multiple"*/}
                        {/*        style={{width: '100%'}}*/}
                        {/*        onChange={this.changeStatus}*/}
                        {/*        value={this.state.selectedStatus}*/}
                        {/*        placeholder="Státusz">*/}
                        {/*        {this.state.status.map(d => (*/}
                        {/*            <Option key={d.id} value={d.id}>{d.name}</Option>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</Col>*/}
                        <Col span={5}>
                            <Input disabled={false} placeholder="Keresés" onChange={(e) => {
                                this.searchCallback(e.target.value)
                            }}/>
                        </Col>
                        <Col offset={1} span={3}>
                            <SiteSelector all={true} selected={"all"} onSelect={this.changeSite}/>
                        </Col>
                    </Row>

                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={24}>
                            <Table onChange={this.handleTableChange} dataSource={this.state.purchaseOrders}
                                   bordered={true} rowSelection={this.rowSelection}
                                   loading={this.state.tableLoading} expandedRowRender={PurchaseOrderExpandedTable}
                                   rowKey={"id"} pagination={this.state.pagination}>

                                <Column title="Rendelési Szám" dataIndex="purchaseOrderNo" key="purchaseOrderNo"
                                        render={(purchaseOrderNo, record) => (<span>
                                <span className="link" onDoubleClick={() => this.editPurchaseOrder(record.id)}>{purchaseOrderNo} {record.isLocked ?
                                    <Tooltip title={record.lockedBy + " felhasználó szerkeszti"}><LockOutlined
                                        hidden={!record.isLocked}/></Tooltip> : ""}</span>
                            </span>)}/>

                                <Column title="Partner" dataIndex="partnersName" key="partnersName"
                                        render={(partnerName, record) => (<span>
                                <a href="#partner" onClick={e => this.editClick(e, record.partnersId)}>{partnerName}</a>
                            </span>)}/>

                                <Column title="Állapot" dataIndex="purchaseOrderStatus" key="purchaseOrderStatus" render={text => {
                                    return (resolvePurchaseOrderStatus(text))
                                }}/>
                                <Column title="Összes eladási ár" dataIndex="sumSellPrice" key="sumSellPrice"
                                        render={text => {
                                            return (
                                                typeof text !== "undefined" &&
                                                text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')
                                            )
                                        }}/>
                                <Column title="Hova" dataIndex="siteTitle" key="siteTitle"/>
                                <Column title="Várh. beérk." dataIndex="expectedArrival" key="expectedArrival"/>
                                <Column title="Határidő" dataIndex="dueDate" key="dueDate"/>

                                {this.props.metaMode &&
                                <Column title="Létrehozta" dataIndex="createdBy" key="createdBy"/>}
                                {this.props.metaMode &&
                                <Column title="Módosítva" dataIndex="updatedDate" key="updatedDate"/>}
                                {this.props.metaMode &&
                                <Column title="Módosította" dataIndex="modifiedBy" key="modifiedBy"/>}

                                <Column title="Megjegyzés" dataIndex="note" key="note"/>

                            </Table>
                        </Col>
                    </Row>
                </Card>
                <PartnerDrawer visible={this.state.visible} onClose={this.onClose} editId={this.state.editId}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        metaMode: state.metaMode,
    };
}

export default connect(mapStateToProps)(PurchaseOrderList);
