import React, {Component} from "react";
import {
    Affix,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Icon,
    Input,
    InputNumber,
    Row,
    Select,
    Spin
} from "antd";
import PartnerSelect from "../Components/PartnerSelect";
import SiteSelector from "../Components/SiteSelector";
import ProductSelectorModal from "../Components/ProductSelectorModal";
import PartnerDrawer from "../Components/PartnerDrawer";
import {unsetNewlyCreatedPartner} from "../actions";
import {connect} from "react-redux";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import Descriptions from "antd/es/descriptions";
import EmailField from "../Components/EmailField";
import Tags from "../Components/Tags";
import PartnerInfoModal from "../Components/PartnerInfoModal";
import {CalculateUtils} from "../Utils/CalculateUtils";
import QueryParams from "../Utils/QueryParams";
import RepairLineTable from "../Components/RepairLineTable";
import ResponsibleSelector from "../Components/ResponsibleSelector";
import SalesOrderLineTable from "../Components/SalesOrderLineTable";
import {resolveRepairStatus} from "../Utils/Utils";
import RadioButton from "antd/es/radio/radioButton";
import RadioGroup from "antd/es/radio/group";
import ButtonGroup from "antd/es/button/button-group";

const {TextArea} = Input;
const {Option} = Select;

class Repair extends Component {

    state = {
        loading: true,
        selectedRowKeys: [],
        newRepairNo: "",
        partnerDrawerVisible: false,
        editId: "",
        selectedPartner: "",
        edit: false,
        currentUser: null,
        lock: false,
        editObject: {
            id: null,
            partnersId: null,
            repairNo: null,
            siteTitle: null,
            deposit: null,
            repairStatus: null,
            isLocked: null,
            lockedBy: null
        },
        repairLines: [{
            id: "",
            productName: "",
            accessories: "",
            productCondition: "",
            problemDescription: "",
            note: ""
        }],
        status: ["TakenOver", "BidOffered", "BidAccepted", "WaitingForParts", "Pending", "Done", "CustomerNoticed", "Closed"],
        users: [],
        selectedUser: null,
        selectedStatus: ["TakenOver"],
        productChooserVisible: false,
        repairProductLines: [{id: "", name: "", productId: "", qty: "", uom: ""}],
        visiblePartnerInfo: false,
        partner: {
            name: null,
            city: null,
            zipCode: null,
            address: null,
            vatNumber: null,
            phone: null,
            email: null,
            note: null
        },
        orders: [],
        taxList: [],
    };

    componentDidMount() {
        this.getUsers();
        const {match: {params}} = this.props;
        console.log("params id",params)
        console.log("props",this.props)

        if (params.id != null) {
            this.fetchData(params.id);
            this.getTaxValues();
        } else {
            this.getLastRepairNo(this.props.selectedSite.value);
        }
    }
    componentWillUnmount() {
        if (this.state.editObject.id) {
            API.post("repair/unlock", {id: this.state.editObject.id, username: this.state.currentUser})
        }
    }

    fetchData = (id) => {
        console.log(id)
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "";
        API.get("repair/getRepair/" + id).then(res => {
            console.log(res.data);
            this.setState({
                editObject: res.data,
                edit: true,
                loading: false,
                currentUser: user.username,
                repairLines: res.data.repairLines,
                repairProductLines: res.data.repairProductLines
            });
            if (this.state.editObject.isLocked && this.state.currentUser !== this.state.editObject.lockedBy) {
                this.setState({lock: true});
                NotificationUtil({msg: "Munkalap zárolva, csak megtekinthető!", type: "error"});
            }
            if (res.data.repairStatus === "Closed") {
                this.setState({closed: true, lock: true})
            }
        });
        API.post("/repair/lock", {id: id, username: user.username})
    };

    getUsers() {
        this.setState({users: JSON.parse(localStorage.getItem('users'))})
    };

    getLastRepairNo(sitePrefix) {
        API.get("repair/getLastRepairNo/" + sitePrefix).then(res => {
            let {data} = res;
            this.setState({newRepairNo: data.repairNo, loading: false}, () => console.log(this.state.newRepairNo));
        });
    }

    getPartnerInfo = (e, data) => {
        this.setState({selectedPartner: data}, () => this.partnerInfo(data.id));
    };

    partnerInfo = (id) => {
        API.get("/getPartner/" + id)
            .then(res => {
                let partnerDetails = res.data;
                this.setState({
                    partner: partnerDetails
                })
            });
        API.get("/getSalesOrdersByPartnerId/" + id)
            .then(res => {
                this.setState({
                    orders: res.data.lines
                })
            });
    };

    save = (callback) => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            values.refNo = null;
            console.log("saving this: ", values);
            API.post("repair/saveRepair", values).then(res => {
                form.resetFields();
                NotificationUtil(res.data);
                if (callback == null) {
                    this.props.history.push('/app/repairsList');
                }
                if (typeof callback === "function") {
                    callback();
                }
            }).catch(error => {
                NotificationUtil({msg: "Hiba történt: " + error, type: "error"});
            });
        });
    };

    newPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: ""
        });
    };

    editPartner = () => {
        this.setState({
            partnerDrawerVisible: true,
            editId: this.state.selectedPartner.id
        });
    };

    partnerDrawerOnClose = () => {
        this.setState({
            partnerDrawerVisible: false,
            editId: ""
        });
    };

    onRowChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    getCheckboxProps = (record) => {
        return {
            disabled: record.index === 0 || this.state.closed,
            name: record.name,
        }
    };

    getRepairLines(id) {
        API.get("repair/getRepairLines/" + id).then(res => {
            console.log("res data",res);
            this.setState({
                repairLines: res.data.lines,
            });
        }).catch(() => {NotificationUtil({msg: "Hiba van!", type: "error"})})
    };

    addRepairLine = () => {
        let line = {
            id: null,
            productName: "",
            accessories: "",
            problemDescription: "",
            productCondition: "",
            note: ""
        };
        this.setState(prevState => ({
            repairLines: [...prevState.repairLines, line]
        }));
    };

    getTaxValues() {
        API.get("/tax/getTaxValues")
            .then(res => {
                this.setState({
                    taxList: res.data
                })
            })
    };

    calculate = (e, type, index, form) => CalculateUtils(e, type, index, form, this.state.repairProductLines, this.state.taxList, "repairProductLines");

    statusSelect(e) {
        this.setState({selectedStatus: e})
    };

    userSelect(e) {
        this.setState({selectedUser: e})
    };

    removeLine = (record, index, fields) => {
        let {repairProductLines} = this.state;
        console.log(repairProductLines);

        this.setState({repairProductLines: repairProductLines.filter(item => item !== record)});
        const {form} = this.props;
        form.resetFields(fields);
        form.setFieldsValue(repairProductLines);
    };

    newProductLine = () => {
        this.setState({
            productChooserVisible: true,
        });
    };

    closeProductChooser = () => {
        this.setState({
            productChooserVisible: false,
        });
    };

    selectProduct = (e) => {
        this.setState({
            productChooserVisible: false,
        });
        let qp = new QueryParams();
        qp.setCustomParams({id: e})
        API.get("/product-by-productId/" + qp.getQueryParam()).then(res => {
            let {data} = res;
            let line = {
                productCode: data.productCode,
                name: data.name,
                qty: 1,
                uom: "db",
                netPurchasePrice: data.netPurchasePrice
            };
            this.setState(prevState => ({
                repairProductLines: [...prevState.repairProductLines, line]
            }), () => {
                console.log("repairProductLines: ", this.state.repairProductLines);
            });
            console.log(data);
        });
    };

    generateSO = () => {
        let id = this.state.editObject.id;
        API.post("salesOrder/generateSO/" + id)
            .then(res => {
                NotificationUtil(res.data);
            }).catch(error => {
            NotificationUtil({msg: "Hiba történt!" + error, type: "error"});
        });
        this.save(null);
    };

    makeWorksheet = () => {
        //TODO Test: Save before generating !
        const {match: {params}} = this.props;
        API.get("/repair/generateWorksheet/" + params.id, {responseType: 'blob'})
            .then(res => {
                const file = new Blob(
                    [res.data],
                    {type: 'application/pdf'});
                const url = URL.createObjectURL(file);
                const newWindow = window.open('url', '_blank');
                newWindow.title = "SZERVIZ ÁTVÉTELI JEGYZŐKÖNYV";
                newWindow.document.write("<head><title>SZERVIZ ÁTVÉTELI JEGYZŐKÖNYV</title></head>");
                newWindow.document.write('<iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src="' + url + '">');
                newWindow.focus();
            }).catch(error => {
            console.log(error);
        });
    }

    addLine = () => {
        let line = {id: "", name: "", productId: "", qty: "", uom: ""};
        this.setState(prevState => ({repairProductLines: [...prevState.repairProductLines, line]}));
    };
    partnerInfoModalVisible = () => {
        this.setState({visiblePartnerInfo: true})
    }
    handleInfoModalClose = () => {
        this.setState({visiblePartnerInfo: false})
    }

    removeRepairLine = (record, index, fields) => {
        let {repairLines} = this.state;
        console.log(repairLines);

        this.setState({repairLines: repairLines.filter(item => item !== record)});
        const {form} = this.props;
        form.resetFields(fields);
        form.setFieldsValue(repairLines);
        // const repairLines = this.state.repairLines.filter(item => item.productName !== record.productName);
        // this.setState({repairLines: repairLines});
    };

    onRepairLineChange = (e, index, name) => {
        let repairLines = this.state.repairLines;
        let line = repairLines[index];
        line[name] = e.target.value;
        repairLines[index] = line;
        this.setState({repairLines});
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onRowChange,
            getCheckboxProps: this.getCheckboxProps
        };
        const hasSelected = selectedRowKeys.length > 0;
        let disableInvoice = true;
        if (this.state.editObject.id != null && Object.keys(this.state.editObject.repairProductLines).length !== 0) {
            disableInvoice = false;
        }
        let disableWorksheet = true;
        if (this.state.editObject.id != null) {
            disableWorksheet = false;
        }
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Form onSubmit={this.handleSubmit} className="repair-form" layout="vertical">
                        <Row gutter={24}>
                            <h2>Szerviz munkalap</h2>
                            {(this.state.editObject.isLocked && this.state.editObject.lockedBy !== this.state.currentUser) &&
                            <div>
                                {!this.state.closed &&
                                <h2 style={{color: "red"}}>Dokumentum
                                    zárolva, {this.state.editObject.lockedBy} felhasználó épp szerkeszti!</h2>
                                }
                            </div>
                            }
                            {this.state.closed &&
                            <h2 style={{color: "red"}}>Szerviz munkalap lezárva, csak megtekinthető!</h2>
                            }


                            <Card>
                                <Affix offsetTop={10}>
                                    <div style={{backgroundColor: "white"}}>
                                        <Row>
                                            <ButtonGroup disabled={this.state.lock}>
                                                <Button onClick={() => this.save(null)} size={"large"}
                                                        type="primary">Mentés</Button>

                                                {this.state.editObject.id != null &&
                                                <Button onClick={this.generateSO} disabled={disableInvoice} size={"large"}
                                                        type="primary">Számla készítése</Button>
                                                }

                                                <Button onClick={() => this.save(this.makeWorksheet)} size={"large"}
                                                        disabled={disableWorksheet}
                                                        type="primary">Munkalap készítése</Button>

                                                <Button type="dashed" size={"large"} onClick={() => {
                                                    this.props.history.push('/app/repairsList')}}>Vissza</Button>
                                            </ButtonGroup>
                                        </Row>
                                    </div>
                                </Affix>
                                <Row type="flex" justify="center" align="middle" style={{marginTop: 10}}>
                                    <Form.Item>
                                        {getFieldDecorator('repairType', {
                                            rules: [{required: true}],
                                            initialValue: this.state.editObject.repairType != null ? this.state.editObject.repairType : "Repair",
                                        })(
                                            <RadioGroup buttonStyle={"solid"}>
                                                <RadioButton value={"Repair"}>Javítás</RadioButton>
                                                <RadioButton value={"Guarantee"}>Garancia</RadioButton>
                                            </RadioGroup>
                                        )}
                                    </Form.Item>
                                </Row>
                                <Col span={12}>
                                    <Form.Item>
                                        {getFieldDecorator('id', {
                                            rules: [{required: false}],
                                            initialValue: this.state.editObject.id != null ? this.state.editObject.id : "",
                                        })(
                                            <Input hidden={true}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Javítási szám:" labelCol={{span: 6}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("repairNo", {
                                            initialValue: this.state.editObject.repairNo !== null ? this.state.editObject.repairNo : this.state.newRepairNo,
                                            rules: [],
                                        })(
                                            <Input disabled={true} style={{width: "80%"}}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Referencia szám:" labelCol={{span: 6}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("refNo", {
                                            rules: [],
                                            initialValue: this.state.editObject.refNo !== null ? this.state.editObject.refNo : "",
                                        })(
                                            <Input disabled={true} style={{width: "80%"}}/>
                                        )}
                                    </Form.Item>
                                    <PartnerSelect openDrawer={this.newPartner} editPartner={this.editPartner}
                                                   selectedPartnerId={this.state.editObject.partnersId}
                                                   form={this.props.form} onSelectedPartner={this.getPartnerInfo}/>
                                </Col>

                                <Col span={12}>
                                    <h3>Partner Infó <Button onClick={this.partnerInfoModalVisible}
                                                             hidden={this.state.selectedPartner === ""}>Több
                                        infó</Button></h3>
                                    {this.state.selectedPartner !== "" &&
                                    <Descriptions bordered layout="vertical" border size="small">
                                        <Descriptions.Item
                                            label="Név">{this.state.selectedPartner.name}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Telefonszám">{this.state.selectedPartner.phone}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Cím">{this.state.selectedPartner.city + ' ' + this.state.selectedPartner.address}</Descriptions.Item>
                                        <Descriptions.Item label="Email cím"><EmailField
                                            email={this.state.selectedPartner.email}/> </Descriptions.Item>
                                        <Descriptions.Item label="Címkék"> <Tags
                                            tags={this.state.selectedPartner.tags ? this.state.selectedPartner.tags : []}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label="Megjegyzés">{this.state.selectedPartner.note}</Descriptions.Item>
                                    </Descriptions>
                                    }
                                </Col>
                            </Card>
                        </Row>
                        <fieldset disabled={this.state.closed}>
                            <Card>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <SiteSelector form={this.props.form} changeSite={this.changeSite}
                                                      selectedValue={this.state.editObject.siteTitle != null ? this.state.editObject.siteTitle : this.props.selectedSite.value}/>
                                        <Form.Item label="Előleg:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                            {getFieldDecorator("deposit", {
                                                initialValue: this.state.editObject.deposit != null ? this.state.editObject.deposit : 0,
                                            })(
                                                <InputNumber style={{width: "80%"}}/>
                                            )}
                                        </Form.Item>

                                        <ResponsibleSelector users={this.state.users} form={this.props.form}
                                                             responsible={this.state.editObject.responsible}
                                                             userSelect={e => this.userSelect(e)}/>

                                        <Form.Item label="Státusz:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                                            {getFieldDecorator("repairStatus", {
                                                initialValue: this.state.editObject.repairStatus
                                            })(
                                                <Select style={{width: "80%"}} onChange={e => this.statusSelect(e)}>
                                                    {this.state.status.map(d => (
                                                        <Option key={d} value={d}>{resolveRepairStatus(d)}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Megjegyzés:" labelCol={{span: 4}} wrapperCol={{span: 15}}>
                                            {getFieldDecorator("note", {
                                                initialValue: this.state.editObject.note != null ? this.state.editObject.note : "",
                                            })(
                                                <TextArea rows={6}/>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Várható javítási költség:" labelCol={{span: 3}}
                                                   wrapperCol={{span: 15}}>
                                            {getFieldDecorator("expectedPriceOut", {
                                                initialValue: this.state.editObject.expectedPriceOut
                                            })(
                                                <InputNumber style={{width: "80%"}}/>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Várható javítási idő:" labelCol={{span: 3}}
                                                   wrapperCol={{span: 15}}>
                                            {getFieldDecorator("expectedRepairTime", {
                                                initialValue: this.state.editObject.expectedRepairTime
                                            })(
                                                <Input style={{width: "80%"}}/>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Várható bekerülési költség:" labelCol={{span: 3}}
                                                   wrapperCol={{span: 15}}>
                                            {getFieldDecorator("expectedPriceIn", {
                                                initialValue: this.state.editObject.expectedPriceIn
                                            })(
                                                <InputNumber style={{width: "80%"}}/>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>

                            <Row>
                                <Card title="Átvett termék">
                                    <Col span={24}>
                                        <RepairLineTable edit={this.state.edit} getFieldDecorator={getFieldDecorator}
                                                         repairLines={this.state.repairLines}
                                                         rowSelection={rowSelection} removeLine={this.removeRepairLine}
                                                         onChange={this.onRepairLineChange}
                                                         reloadTable={this.fetchData}/>
                                    </Col>
                                    {!this.state.editObject.id &&
                                    <Col span={24}>
                                        <Button type="primary" onClick={this.addRepairLine}> <Icon
                                            type="plus-circle"/> Új sor</Button>
                                        <Button type="danger" onClick={this.removeRepairLine} disabled={!hasSelected}>
                                            <Icon
                                                type="delete"/> Kijelölt(ek) törlése</Button>
                                    </Col>
                                    }

                                </Card>
                            </Row>

                            {this.state.editObject.id &&
                            <Row style={{marginTop: "50px"}}>
                                <Col span={24}>
                                    <Card title="Kiadott tételek, beszerelt alkatrészek">
                                        <SalesOrderLineTable calculate={this.calculate} rowSelection={rowSelection}
                                                             type={'repairProductLines'}
                                                             lines={this.state.repairProductLines}
                                                             form={this.props.form}
                                                             getFieldDecorator={getFieldDecorator}
                                                             taxValues={this.state.taxList}
                                                             removeLine={this.removeLine}
                                                             editing={true}/>
                                    </Card>
                                </Col>
                            </Row>
                            }
                            {this.state.editObject.id &&
                            <Row>
                                {!this.state.lock &&
                                <Col span={24}>
                                    <Button type="primary" onClick={this.addLine}> <Icon type="plus-circle"/> Új
                                        sor</Button>
                                    <Divider type="vertical"/>
                                    <Button type="primary" onClick={this.newProductLine}> <Icon type="plus-circle"/> Új
                                        termék</Button>
                                    {/*<Divider type="vertical"/>*/}
                                    {/*<Popconfirm*/}
                                    {/*    title="Biztos a törlésben? A törlés nem visszavonható!"*/}
                                    {/*    icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}*/}
                                    {/*    onConfirm={this.removeLine}*/}
                                    {/*    okText="Igen"*/}
                                    {/*    cancelText="Nem"*/}
                                    {/*>*/}
                                    {/*    <Button type="danger" disabled={!hasSelected}> <Icon*/}
                                    {/*        type="delete"/> Kijelölt(ek)*/}
                                    {/*        törlése</Button>*/}
                                    {/*</Popconfirm>*/}
                                </Col>
                                }
                            </Row>
                            }
                        </fieldset>
                    </Form>
                </Spin>
                <ProductSelectorModal inRepair={true} onSelectClick={this.selectProduct}
                                      visible={this.state.productChooserVisible} close={this.closeProductChooser}/>
                <PartnerDrawer visible={this.state.partnerDrawerVisible} onClose={this.partnerDrawerOnClose}
                               editId={this.state.editId}/>
                <PartnerInfoModal orders={this.state.orders} partner={this.state.partner}
                                  visibleInfoModal={this.state.visiblePartnerInfo} onClose={this.handleInfoModalClose}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
        newlyCreatedPartner: state.newlyCreatedPartner
    };
}

function mapDispatchToProps(dispatch) {
    return {
        unsetNewlyCreatedPartner: () => dispatch(unsetNewlyCreatedPartner())
    };
}

const connectedRepair = connect(mapStateToProps, mapDispatchToProps)(Repair);

export default Form.create({name: 'repair_form'})(connectedRepair);

