import React, { Component } from 'react';
import {Button, Form, Input, List} from "antd";
import {connect} from "react-redux";
import API from "../Utils/API";
import QueryParams from "../Utils/QueryParams";

class MessageQueueTest extends Component {

    sendMsg = () =>{
        this.props.form.validateFields((err, values) => {
            let queryParams = new QueryParams();
            queryParams.setCustomParams(values);
            API.get("send-msg"+queryParams.getQueryParam()).then(() => {
                console.log("Message sent");
            })
        });
    };

    state = {
        messages : []
    };

    componentDidMount() {
        this.timer = setInterval(()=> this.getMsg(), 1000);
    }

    componentWillUnmount() {
        this.timer = null; // here...
    }

    getMsg = () =>{
            API.get("get-msg").then(res => {
                this.setState({messages : res.data});
            })
    };

    render(){
        const { getFieldDecorator} = this.props.form;
        return(
            <div>
                <Form.Item label="productId">
                    {getFieldDecorator('productId', {

                    })(
                        <Input/>
                        ,
                    )}
                </Form.Item>

                <Form.Item label="siteId">
                    {getFieldDecorator('siteId', {
                    })(
                        <Input/>
                        ,
                    )}
                </Form.Item>
                <Button onClick={this.sendMsg}>Send msg</Button>

                <List
                    header={<div>Rendelések</div>}
                    bordered
                    dataSource={this.state.messages}
                    renderItem={item => (
                        <List.Item>
                            {item.name} - {item.address}
                        </List.Item>
                    )}
                />
            </div>

        );
    }
}
function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite
    };
}

const connectedMqTest = connect(mapStateToProps)(MessageQueueTest);

export default Form.create({ name: 'mq_form' })(connectedMqTest);


