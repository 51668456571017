import {Button, Divider, Input, Modal, Table, Row} from "antd";
import React, {Component} from "react";
import API from "../Utils/API";
import QueryParams from "../Utils/QueryParams";

const {Column} = Table;

class ProductSelectorModal extends Component {

    state = {
        data: [],
        name: [],
        barcode: "",
        loading: false,
        selectedProductId: "",
        manufacturers: [],
        suppliers: [],
        categories: [],
        selectedManufacturers: "",
        selectedSuppliers: "",
        selectedCategories: "",
        selectedRowKeys: "",
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            current: 0,
            results: 0,
            total: 0,
            pageSize: 0
        },
        filteredInfo: null

    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({selectedProductId: selectedRows[0].productCode}, () => console.log("selected: " + this.state.selectedProductId))
        },
        type: 'radio'
    };

    onRowChange = (selectedRowKeys) => {
        this.setState({
            selectedRowKeys
        });
    };

    handleTableChange = (pagination, filters) => {
        const pager = {...this.state.pagination};
        let selectedManufacturers = {...this.state.selectedManufacturers};
        let selectedSuppliers = {...this.state.selectedSuppliers};
        let selectedCategories = {...this.state.selectedCategories};

        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({filteredInfo: filters})

        if (typeof filters.category !== 'undefined') {
            selectedCategories = filters.category;
            this.setState({selectedCategories});
        }

        if (typeof filters.supplier !== 'undefined') {
            selectedSuppliers = filters.supplier;
            this.setState({selectedSuppliers});
        }

        if (typeof filters.manufacturer !== 'undefined') {
            selectedManufacturers = filters.manufacturer;
            this.setState({selectedManufacturers});
        }

        this.setState({pagination: pager}, () => {
            this.search();
        });

    };


    search = () => {
        this.setState({loading: true});
        let qp = new QueryParams();
        if (this.state.barcode !== "") {
            qp.setCustomParams({"barcode": this.state.barcode});
        } else if (this.state.name) {
            qp.setCustomParams({"name": this.state.name})
        }
        /*API.get("/product-find/"+qp.getQueryParam())
            .then(res =>{
               console.log(res.data);
               this.setState({
                   data: res.data.lines
               })
            });*/
        API.get("/search/" + qp.getQueryParam())
            .then(res => {
                console.log(res.data);
                let manufacturers = [];
                //TODO Fix this
                /*for (let i = 0; i < res.data.lines.length; i++) {
                    if (!manufacturers.includes(res.data.lines[i].manufacturer)) {
                        manufacturers.push(res.data.lines[i].manufacturer);
                    }
                }*/
                this.setState({
                    data: res.data.lines,
                    manufacturers: manufacturers
                })
            });


        /*        API.get("/product-find-demo?page="+this.state.pagination.current+"&size="+this.state.pagination.pageSize+"&name="+this.state.name+"&barcode="+this.state.barcode
                    +"&categories="+this.state.selectedCategories+"&suppliers="+this.state.selectedSuppliers+"&manufacturers="+this.state.selectedManufacturers
                ).then(res => {
                    let {data} = res;
                    const pagination = { ...this.state.pagination };
                    pagination.total = res.data.totalElements;
                    this.setState({ data : data.lines , pagination});

                    if(data.filters != null) {
                        if (data.filters.categories != null) {
                            let categories = data.filters.categories.map((result => {
                                return {text: result, value: result}
                            }));
                            this.setState({categories});
                        }
                        if (data.filters.manufacturers != null) {
                            let manufacturers = data.filters.manufacturers.map((result => {
                                return {text: result, value: result}
                            }));
                            this.setState({manufacturers});
                        }
                        if (data.filters.suppliers != null) {
                            let suppliers = data.filters.suppliers.map((result => {
                                return {text: result, value: result}
                            }));
                            this.setState({suppliers});
                        }
                    }

                });*/
        this.setState({loading: false});
    };

    setField = (fieldName, e) => {
        if (fieldName === "name") {
            this.setState({barcode: "", categories: [], manufacturers: [], suppliers: []});
        } else {
            this.setState({name: "", categories: [], manufacturers: [], suppliers: []});
        }

        this.setState({[fieldName]: e});
    };

    selectProduct = () => {
        this.props.onSelectClick(this.state.selectedProductId);

        ///Resetting modal
        this.setState({name: "", data: [], barcode: "", selectedProductId: ""});
    };

    render() {
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onRowChange
        };
        let {filteredInfo, manufacturers} = this.state;
        filteredInfo = filteredInfo || [];
        manufacturers = manufacturers || [];
        let manufacturerFilter = manufacturers.map(manufacturer => (
            {text: manufacturer, value: manufacturer}
        ))
        return (
            <Modal
                title="Termék választó"
                rowSelection={rowSelection}
                visible={this.props.visible}
                onCancel={this.props.close}
                okButtonProps={{disabled: this.state.selectedProductId === ""}}
                onOk={this.selectProduct}
                okText={"Kiválaszt"}
                cancelText={"Mégsem"}
                width={"80%"}
            >
                <p><Input placeholder="Vonalkód" autoFocus={true} value={this.state.barcode} onPressEnter={this.search}
                          onChange={e => {
                              this.setField("barcode", e.target.value)
                          }}/></p>
                <Divider type={"horizontal"}/>

                <p><Input style={{width: "100%"}} value={this.state.name} onPressEnter={this.search} placeholder="Név"
                          onChange={e => {
                              this.setField("name", e.target.value)
                          }}/></p>

                <Row>
                    <Button type="primary" onClick={this.search}>Keresés</Button>
                    <Divider type={"vertical"}/>
                    <Button type="primary" onClick={this.selectProduct}
                            disabled={this.state.selectedProductId === ""}>Kiválaszt</Button>

                </Row>
                <Table dataSource={this.state.data} rowKey={"productCode"} rowSelection={this.rowSelection}
                       pagination={this.state.pagination}
                       onChange={this.handleTableChange}
                       expandedRowRender={record => <div dangerouslySetInnerHTML={{__html: record.description}}
                                                         style={{margin: 0}}/>}
                       loading={this.state.loading}>
                    <Column title="#" dataIndex="productCode" key="productCode"/>
                    <Column title="Termék Név" dataIndex="name" key="name"/>
                    <Column title="Kategória" dataIndex="category" key="category" filters={this.state.categories}
                            onFilter={(value, record) => record.category.indexOf(value) === 0}/>
                    {!this.props.inRepair &&
                    <Column title="Nettó be. ár" dataIndex="netPurchasePrice" key="netPurchasePrice"
                            sorter={(a, b) => a.netPurchasePrice - b.netPurchasePrice}/>}
                    {!this.props.inRepair &&
                    <Column
                        title="Gyártó"
                        dataIndex="manufacturer"
                        key="manufacturer"
                        filters={manufacturerFilter}
                        filteredValue={filteredInfo.manufacturer || null}
                        onFilter={(value, record) => record.manufacturer.includes(value)}/>}
                    {!this.props.inRepair &&
                    <Column title="Beszállító" dataIndex="supplier" key="supplier" filters={this.state.suppliers}
                            onFilter={(value, record) => record.supplier.indexOf(value) === 0}/>}
                </Table>

            </Modal>);
    }

}

export default ProductSelectorModal;

