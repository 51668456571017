import {Tag} from "antd";
import React from "react";

const Tags = ({tags}) => (
    <span>
      {tags.map(tag => (
          <Tag color="blue" key={tag}>
              {tag}
          </Tag>
      ))}
    </span>
);

export default Tags;