import React from "react";
import {Input, Row, Col, Button, Icon, Divider, Form} from "antd";

const FormItem = Form.Item;

class PriceRangeList extends React.Component {

    state = {
        priceRanges: [{
            id: 1,
            from: "",
            to: "",
            value: "",
            toStatus: "",
            fromStatus: "success",
        }]
    };

    componentDidMount() {
        if (typeof this.props.initialValues !== "undefined") {
            this.setState({priceRanges: this.props.initialValues})
        }
    }

    addPriceRange = () => {
        console.log("this.state.priceRanges:", this.state.priceRanges);
        let lastItem = this.state.priceRanges[this.state.priceRanges.length - 1];
        let line = {
            id: lastItem.id + 1,
            from: "",
            to: "",
            value: "",
            toStatus: "",
            fromStatus: "",
        };

        if (lastItem.to > 0) {
            line.from = lastItem.to;
        }

        this.setState(prevState => ({
            priceRanges: [...prevState.priceRanges, line]
        }), () => {
            this.props.updateParentComponent(this.state.priceRanges);
        });

    };

    removePriceRange = (e, id) => {
        if (this.state.priceRanges.length > 1) {
            let {priceRanges} = this.state;

            let filteredPriceRanges = priceRanges.filter(value => value.id  !== id);

            this.setState({priceRanges: filteredPriceRanges}, () => {
                this.props.updateParentComponent(this.state.priceRanges);
            });
        }
    };

    onBlur = (e, id, type) => {
        let filteredPriceRanges = this.state.priceRanges.filter(function (value, index, arr) {
            if (id === value.id) {
                if (type === "to") {
                    if ((value.to !== "") && (e.target.value <= value.from)) {
                        value.toStatus = "error";
                    } else {
                        value.toStatus = "success";
                    }
                }
            }

            if (typeof arr[index - 1] !== 'undefined') {

                if (value.from < arr[index - 1].to) {
                    value.fromStatus = "error";
                } else {
                    value.fromStatus = "success";
                }
            }

            return value;
        });

        this.setState({priceRanges: filteredPriceRanges});
    };

    changeVal = (e, id, type) => {
        let filteredPriceRanges = this.state.priceRanges.filter(function (value) {

            if (!isNaN(e.target.value)) {
                if (value.id === id) {
                    value[type] = parseInt(e.target.value);
                }
            }

            return value;
        });
        this.setState({priceRanges: filteredPriceRanges}, () => {
            this.props.updateParentComponent(this.state.priceRanges);
        });
    };


    render() {
        return (
            <div>
                {this.state.priceRanges.map((d, key) => (
                    <div key={key}>
                        <Row>
                            <Col span={8}>

                                <FormItem
                                    validateStatus={this.state.priceRanges[key].fromStatus}
                                    hasFeedback
                                >
                                    <Input placeholder={"Tól"} value={d.from} onBlur={e => this.onBlur(e, d.id, "from")}
                                           onChange={e => this.changeVal(e, d.id, 'from')}/>
                                </FormItem>

                            </Col>

                            <Col span={8}>

                                <FormItem
                                    validateStatus={this.state.priceRanges[key].toStatus}
                                    hasFeedback
                                >
                                    <Input placeholder={"Ig"} value={d.to} onBlur={e => this.onBlur(e, d.id, "to")}
                                           onChange={e => this.changeVal(e, d.id, 'to')}/>
                                </FormItem>

                            </Col>

                            <Col span={7}>
                                <FormItem>
                                    <Input placeholder={"Összeg"} value={d.value}
                                           onChange={e => this.changeVal(e, d.id, 'value')}/>
                                </FormItem>
                            </Col>

                            {key > 0 &&
                            <Col span={1}>
                                <Button type={"danger"} onClick={e => this.removePriceRange(e, d.id)}>X</Button>
                            </Col>
                            }
                        </Row>
                        <Divider type="horizontal"/>
                    </div>
                ))}
                <Button type="primary" onClick={this.addPriceRange}> <Icon type="plus-circle"/> Új sor</Button>
            </div>
        )
    }
}

export default PriceRangeList;