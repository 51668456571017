import React, {Component} from "react";
import {Button, Descriptions, Modal} from "antd";

class LeaveInfoModal extends Component {

    onEditClick = () => {
        this.props.close()
        this.props.onEventEdit(this.props.event.id)
    }

    onDeleteClick = () => {
        this.props.close()
        this.props.onEventDelete(this.props.event.id);
    }

    render() {
        return (
            <Modal
                title="Szabadság infó"
                visible={this.props.visible}
                onCancel={this.props.close}
                onOk={this.props.close}
                footer={[
                    <Button key={"editButton"} onClick={this.onEditClick}>
                        Szerkesztés
                    </Button>,
                    <Button key={"deleteButton"} type="danger" onClick={this.onDeleteClick}>Törlés</Button>,
                    <Button key={"closeButton"} type="primary" onClick={this.props.close}>
                        Bezárás
                    </Button>
                ]}
            >
                <h2>Szabadság adatai: </h2>
                <Descriptions>
                    <Descriptions.Item label="Név">{this.props.event?.employee.fullName}</Descriptions.Item>
                    <Descriptions.Item label="Típus">{this.props.event?.type.name}</Descriptions.Item>
                    <Descriptions.Item label="Intervallum">{this.props.event?.startDate} - {this.props.event?.endDate}</Descriptions.Item>
                    {this.props.event?.notes &&
                        <Descriptions.Item label="Megjegyzés">{this.props.event?.notes}</Descriptions.Item>
                    }

                </Descriptions>
            </Modal>
        )
    }
}

export default LeaveInfoModal;