import React, {Component} from 'react';
import {Statistic, Row, Card, Col, Calendar, PageHeader} from "antd";
import {connect} from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer'
import {XAxis, YAxis, HorizontalGridLines, LineSeries, XYPlot} from 'react-vis';
import API from "../Utils/API";
import nevnapKiir from './../Utils/nevnap';

class Dashboard extends Component {

    componentDidMount() {
        this.getOpenedPurchaseOrders();
        this.getOpenedRepairs();
        this.getClosedSalesOrders();
        this.getSOSumPerWeek();
        this.countSOPerDays();
        this.sumSOPerDays();
        this.nevnapString = {__html: nevnapKiir()};
    }

    state = {
        openedPO: 0,
        openedRep: 0,
        closedSO: 0,
        soSum: 0,
        countSOPerDays: [0, 0, 0, 0, 0, 0, 0],
        sumSOPerDays: [0, 0, 0, 0, 0, 0, 0]
    };

    getOpenedPurchaseOrders() {
        API.get("dashboard/getOpenedPurchaseOrders").then(res => {
            this.setState({openedPO: res.data})
        })
    };

    getOpenedRepairs() {
        if (this.props.selectedSite)
            API.get("dashboard/getStatusesData/" + this.props.selectedSite.value).then(res => {
                this.setState({openedRep: res.data[0] + res.data[1]})
            })
    };

    getClosedSalesOrders() {
        API.get("dashboard/getClosedSalesOrders").then(res => {
            this.setState({closedSO: res.data})
        })
    };

    getSOSumPerWeek() {
        API.get("dashboard/getSOSumPerWeek").then(res => {
            this.setState({soSum: res.data})
        })
    };

    countSOPerDays() {
        API.get("dashboard/countSOPerDays").then(res => {
            this.setState({countSOPerDays: res.data}, () => console.log(this.state.countSOPerDays))
        })
    };

    sumSOPerDays() {
        API.get("dashboard/sumSOPerDays").then(res => {
            this.setState({sumSOPerDays: res.data}, () => console.log(this.state.sumSOPerDays))
        })
    };

    render() {

        return (
            <div>
                <PageHeader style={{textAlign: "left"}} title={"Üdvözlünk " + this.props.user.fullName + "!"}/>

                <div style={{textAlign: "left", fontSize: '16px'}}>

                    <div dangerouslySetInnerHTML={this.nevnapString}/>
                </div>

                <Row gutter={16}>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Nyitott beszerzések száma" value={this.state.openedPO}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Nyitott szervízek" value={this.state.openedRep}/>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card>
                            <Statistic title="Lezárt Értékesítések száma" value={this.state.closedSO}/>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card>
                            <Statistic title="E-Heti Értékesítések összege" value={this.state.soSum + " Ft"}/>
                        </Card>
                    </Col>

                </Row>
                <Row gutter={20} style={{minHeight: "400px"}}>

                    <Col span={12}>
                        <h1>E-Heti Értékesítések száma</h1>
                        <AutoSizer>
                            {({width}) => (
                                <XYPlot margin={{left: 30}} xType="ordinal" height={350} width={width - 32}>
                                    <HorizontalGridLines/>
                                    <LineSeries
                                        data={[
                                            {x: "Hétfő", y: this.state.countSOPerDays[0]},
                                            {x: "Kedd", y: this.state.countSOPerDays[1]},
                                            {x: "Szerda", y: this.state.countSOPerDays[2]},
                                            {x: "Csütörtök", y: this.state.countSOPerDays[3]},
                                            {x: "Péntek", y: this.state.countSOPerDays[4]},
                                            {x: "Szombat", y: this.state.countSOPerDays[5]},
                                            {x: "Vasárnap", y: this.state.countSOPerDays[6]},
                                        ]}/>
                                    <XAxis/>
                                    <YAxis/>
                                </XYPlot>
                            )}
                        </AutoSizer>
                    </Col>

                    <Col span={12}>
                        <h1>E-Heti Értékesítések összege</h1>
                        <AutoSizer>
                            {({width}) => (
                                <XYPlot margin={{left: 60}} xType="ordinal" height={350} width={width - 32}>
                                    <HorizontalGridLines/>
                                    <LineSeries
                                        data={[
                                            {x: "Hétfő", y: this.state.sumSOPerDays[0]},
                                            {x: "Kedd", y: this.state.sumSOPerDays[1]},
                                            {x: "Szerda", y: this.state.sumSOPerDays[2]},
                                            {x: "Csütörtök", y: this.state.sumSOPerDays[3]},
                                            {x: "Péntek", y: this.state.sumSOPerDays[4]},
                                            {x: "Szombat", y: this.state.sumSOPerDays[5]},
                                            {x: "Vasárnap", y: this.state.sumSOPerDays[6]},
                                        ]}/>
                                    <XAxis/>
                                    <YAxis/>
                                </XYPlot>
                            )}
                        </AutoSizer>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h1>Naptár</h1>
                        <Calendar/>
                    </Col>
                </Row>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
        user: state.user
    };
}

export default connect(mapStateToProps)(Dashboard);




