function InitArray(...args) {
    this.length = args.length
    for (let i = 0; i < this.length; i++)
        this[i + 1] = args[i]
}

function havinev(ev, ho, nap) {
    let napok;
    if (ho === 1) {
        napok = new InitArray("Újév, Fruzsina", "Ábel", "Genovéva, Benjámin", "Titusz, Leona",
            "Simon", "Boldizsár", "Attila, Ramóna", "Gyöngyvér", "Marcell",
            "Melánia", "Ágota", "Ernő", "Veronika", "Bódog", "Lóránt, Loránd",
            "Gusztáv", "Antal, Antónia", "Piroska", "Sára, Márió", "Fábián, Sebestyén",
            "Ágnes", "Vince, Artúr", "Zelma, Rajmund", "Timót", "Pál", "Vanda, Paula",
            "Angelika", "Károly, Karola", "Adél", "Martina, Gerda", "Marcella", "Ignác");
    }
    if (ho === 2)
        if ((ev % 4) === 1) {
            napok = new InitArray("Ignác", "Karolina, Aida", "Balázs", "Ráhel, Csenge", "Ágota, Ingrid",
                "Dorottya, Dóra", "Tódor, Rómeó", "Aranka", "Abigél, Alex", "Elvira",
                "Bertold, Marietta", "Lívia, Lídia", "Ella, Linda", "Bálint, Valentin",
                "Kolos, Georgina", "Julianna, Lilla", "Donát", "Bernadett", "Zsuzsanna",
                "Aladár, Álmos", "Eleonóra", "Gerzson", "Alfréd",
                "Mátyás", "Géza", "Edina", "Ákos, Bátor", "Elemér", "Albin");
        } else {
            napok = new InitArray("Ignác", "Karolina, Aida", "Balázs", "Ráhel, Csenge", "Ágota, Ingrid",
                "Dorottya, Dóra", "Tódor, Rómeó", "Aranka", "Abigél, Alex", "Elvira",
                "Bertold, Marietta", "Lívia, Lídia", "Ella, Linda", "Bálint, Valentin",
                "Kolos, Georgina", "Julianna, Lilla", "Donát", "Bernadett", "Zsuzsanna",
                "Aladár, Álmos", "Eleonóra", "Gerzson", "Alfréd",
                "Szőkőnap", "Mátyás", "Géza", "Edina", "Ákos, Bátor", "Elemér", "Albin");
        }
    if (ho === 3) {
        napok = new InitArray("Albin", "Lujza", "Kornélia", "Kázmér", "Adorján, Adrián", "Leonóra, Inez",
            "Tamás", "Nemz.Nőnap, Zoltán", "Franciska, Fanni", "Ildikó", "Szilárd",
            "Gergely", "Krisztián, Ajtony", "Matild", "Nemzeti Ünnep, Kristóf",
            "Henrietta", "Gertrúd, Patrik", "Sándor, Ede", "József, Bánk", "Klaudia",
            "Benedek", "Beáta, Izolda", "Emőke", "Gábor, Karina", "Irén, Irisz",
            "Emánuel", "Hajnalka", "Gedeon, Johanna", "Auguszta", "Zalán", "Árpád", "Hugó");
    }
    if (ho === 4) {
        napok = new InitArray("Hugó", "Áron", "Buda, Richárd", "Izidor", "Vince", "Vilmos, Bíborka",
            "Herman", "Dénes", "Erhard", "Zsolt", "Leó, Szaniszló", "Gyula", "Ida",
            "Tibor", "Anasztázia, Tas", "Csongor", "Rudolf", "Andrea, Ilma", "Emma",
            "Tivadar", "Konrád", "Csilla, Noémi", "Béla", "György", "Márk", "Ervin",
            "Zita", "Valéria", "Péter", "Katalin, Kitti", "Munka Ünnepe, Fülöp, Jakab");
    }
    if (ho === 5) {
        napok = new InitArray("Munka Ünnepe , Fülöp, Jakab", "Zsigmond", "Tímea, Irma", "Mónika, Flórián",
            "Györgyi", "Ivett, Frida", "Gizella", "Mihály", "Gergely", "Ármin, Pálma",
            "Ferenc", "Pongrác", "Szervác, Imola", "Bonifác", "Zsófia, Szonja",
            "Mózes, Botond", "Paszkál", "Erik, Alexandra", "Ivó, Milán",
            "Bernát, Felícia", "Konstantin", "Júlia, Rita", "Dezső", "Eszter, Eliza",
            "Orbán", "Fülöp, Evelin", "Hella", "Emil, Csanád", "Magdolna",
            "Janka, Zsanett", "Angéla, Petronella", "Tünde");
    }
    if (ho === 6) {
        napok = new InitArray("Tünde", "Kármen, Anita", "Klotild", "Bulcsú", "Fatime", "Norbert, Cintia",
            "Róbert", "Medárd", "Félix", "Margit, Gréta", "Barnabás", "Villő",
            "Antal, Anett", "Vazul", "Jolán, Vid", "Jusztin", "Laura, Alida",
            "Arnold, Levente", "Gyárfás", "Rafael", "Alajos, Leila", "Paulina",
            "Zoltán", "Iván", "Vilmos", "János, Pál", "László", "Levente, Irén",
            "Péter, Pál", "Pál", "Tihamér");
    }
    if (ho === 7) {
        napok = new InitArray("Tihamér, Annamária", "Ottó", "Kornél, Soma", "Ulrik", "Emese, Sarolta",
            "Csaba", "Appolónia", "Ellák", "Lukrécia", "Amália", "Nóra, Lili",
            "Izabella, Dalma", "Jenő", "Őrs, Stella", "Henrik, Roland", "Valter",
            "Endre, Elek", "Frigyes", "Emília", "Illés", "Dániel, Daniella",
            "Magdolna", "Lenke", "Kinga, Kincső", "Kristóf, Jakab", "Anna, Anikó",
            "Olga, Liliána", "Szabolcs", "Márta, Flóra", "Judit, Xénia", "Oszkár", "Boglárka");
    }
    if (ho === 8) {
        napok = new InitArray("Boglárka", "Lehel", "Hermina", "Domonkos, Dominika", "Krisztina",
            "Berta, Bettina", "Ibolya", "László", "Emőd", "Lörinc",
            "Zsuzsanna, Tiborc", "Klára", "Ipoly", "Marcell", "Mária", "Ábrahám",
            "Jácint", "Ilona", "Huba", "Alkotmány Ünnepe, István", "Sámuel, Hajna",
            "Menyhért, Mirjam", "Bence", "Bertalan", "Lajos, Patrícia", "Izsó",
            "Gáspár", "Ágoston", "Beatrix, Erna", "Rózsa", "Erika, Bella", "Egyed, Egon");
    }
    if (ho === 9) {
        napok = new InitArray("Egyed, Egon", "Rebeka, Dorina", "Hilda", "Rozália", "Viktor, Lőrinc",
            "Zakariás", "Regina", "Mária, Adrienn", "Ádám", "Nikolett, Hunor",
            "Teodóra", "Mária", "Kornél", "Szeréna, Roxána", "Enikő, Melitta", "Edit",
            "Zsófia", "Diána", "Vilhelmina", "Friderika", "Máté, Mirella", "Móric",
            "Tekla", "Gellért, Mercédesz", "Eufrozina, Kende", "Jusztina", "Adalbert",
            "Vencel", "Mihály", "Jeromos", "Malvin");
    }
    if (ho === 10) {
        napok = new InitArray("Malvin", "Petra", "Helga", "Ferenc", "Aurél", "Brúnó, Renáta", "Amália",
            "Koppány", "Dénes", "Gedeon", "Brigitta", "Miksa", "Kálmán, Ede", "Helén",
            "Teréz", "Gál", "Hedvig", "Lukács", "Nándor", "Vendel", "Orsolya", "Előd",
            "Köztársaság kikiált., Gyöngyi", "Salamon", "Blanka, Bianka", "Dömötör",
            "Szabina", "Simon, Szimonetta", "Nárcisz", "Alfonz", "Farkas", "Marianna");
    }
    if (ho === 11) {
        napok = new InitArray("Marianna", "Achilles", "Győző", "Károly", "Imre", "Lénárd", "Rezső",
            "Zsombor", "Tivadar", "Réka", "Márton", "Jónás, Renátó", "Szilvia",
            "Aliz", "Albert, Lipót", "Ödön", "Hortenzia, Gergő", "Jenő", "Erzsébet",
            "Jolán", "Olivér", "Cecília", "Kelemen, Klementina", "Emma", "Katalin",
            "Virág", "Virgil", "Stefánia", "Taksony", "András, Andor", "Elza");
    }
    if (ho === 12) {
        napok = new InitArray("Elza", "Melinda, Vivien", "Ferenc, Olívia", "Borbála, Barbara", "Vilma",
            "Miklós", "Ambrus", "Mária", "Natália", "Judit", "Árpád", "Gabriella",
            "Luca, Otília", "Szilárda", "Valér", "Etelka, Aletta", "Lázár, Olimpia",
            "Auguszta", "Viola", "Teofil", "Tamás", "Zéno", "Viktória", "Ádám, Éva",
            "Karácsony, Eugénia", "Karácsony, István", "János", "Kamilla",
            "Tamás, Tamara", "Dávid", "Szilveszter", "Újév, Fruzsina");
    }
    return napok[nap];
}

function honev(ho) {
    const month = new InitArray("január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december");
    return month[ho];
}

function napnev(szam) {
    const napok = new InitArray("vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat", "vasárnap");
    return napok[szam];
}

/* Dátum lekérdezése és az adatok kiírása */
export default function nevnapKiir() {
    const ido = new Date();
    let ev = ido.getYear();
    if (ev < 2000) ev = ev + 1900;
    const ho = ido.getMonth() + 1;
    const nap = ido.getDate();

    return ev + '. ' + honev(ho) + ' ' + nap + '. ' + napnev(ido.getDay() + 1) + ', Ma ' + havinev(ev, ho, nap) + ', holnap ' + havinev(ev, ho, nap + 1) + ' ünnepli a névnapját.';
}