import React, {Component} from "react";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import {Button, Card, Input, Modal, Table} from "antd";
import Column from "antd/es/table/Column";
import {LockOutlined} from "@ant-design/icons";

class DocNumberList extends Component {

    componentDidMount() {
        this.getDocNumbers()
    }

    state = {
        tableLoading: true,
        docNumbers: [],
        createDocNumberModal: false,
        newDocNumber: ""
    }

    getDocNumbers = () => {
        API.get("invoice/getDocNumbers")
            .then(res => {
                this.setState({docNumbers: res.data.extraList, tableLoading: false})
            }).catch(() => {
            this.setState({tableLoading: false})
            NotificationUtil({msg: "Hiba történt!", type: "error"});
        });
    }

    createNewDocNumber = () => {
        console.log(this.state.newDocNumber)
        API.post("invoice/createDocNumber/" + this.state.newDocNumber)
            .then(res => {
                NotificationUtil(res.data);
                this.setState({createDocNumberModal: false, newDocNumber: ""})
                this.getDocNumbers();
            })
    }

    render() {
        return (
            <>
                <Card title="Számlatömbök">
                    <Button onClick={() => this.setState({createDocNumberModal: true})} type="primary">
                        Új számlatömb felvétele
                    </Button>
                    <Table dataSource={this.state.docNumbers} bordered={true}
                           loading={this.state.tableLoading} rowKey={"id"} size={"small"}>

                        <Column title="#" width={130} dataIndex="id" key="id"/>
                        <Column title="Prefix" dataIndex="prefix" key="prefix"/>
                        <Column title="Lezárás" width={130} key="close" render={() => {
                            return (
                                //TODO DISABLE if closed
                                <Button onClick={() => {
                                    console.log("clicked")
                                }}>
                                    <LockOutlined/> Lezárás</Button>)

                        }}/>
                    </Table>
                </Card>
                <Modal
                    title={"Számlatömb létrehozása"}
                    visible={this.state.createDocNumberModal}
                    onOk={this.createNewDocNumber}
                    okButtonProps={{disabled: this.state.newDocNumber === ""}}
                    okText={"Mentés"}
                    cancelText={"Mégsem"}
                    onCancel={() => this.setState({createDocNumberModal: false})}>
                    <Card>
                        <span>Prefix: </span>
                        <Input defaultValue={this.state.newDocNumber} onChange={e => this.setState({newDocNumber: e.target.value})}/>
                    </Card>
                </Modal>
            </>
        )
    }
}

export default DocNumberList;