import React, {Component} from 'react';
import API from "../../Utils/API";
import {Button, Card, Col, Divider, Radio, Row, Table} from "antd";
import Column from "antd/es/table/Column";
import CashRegisterDrawer from "./CashRegisterDrawer";

class CashRegisterSettings extends Component {

    componentDidMount() {
        this.getCashRegisters();
    }

    state = {
        showCashRegisterDrawer : false,
        selectedRowKeys : [],
        tableLoading: false,
        cashRegisters: [],
        officeCashRegisters: [],
        editId: "",
        selectedType: "normal",
    };

    onSelectChange = selectedRowKeys => {
        this.setState({
            selectedRowKeys: selectedRowKeys
        })
    }

    getCashRegisters(){
        this.setState({tableLoading: true});
        API.get("settings/getCashRegisters")
            .then(res => {
                this.setState({ cashRegisters : res.data.lines.map(line => { return { ...line, key: line.id } })},()=>{console.log(this.state.cashRegisters)});
                API.get("settings/getOfficeCashRegisters")
                    .then(res => {
                        this.setState({ officeCashRegisters: res.data.lines.map(line => { return { ...line, key: line.id } }) }, () => console.log(this.state.officeCashRegisters))
                    })
            }).finally(() => {
            this.setState({tableLoading: false, selectedRowKeys: []})
        })
    };

    handleTableChange(){
        this.getCashRegisters();
    }

    addCashRegister=()=> {  this.setState({showCashRegisterDrawer:true, editId: ""}) };

    onCloseCashRegister=()=> { this.setState({showCashRegisterDrawer:false}); this.handleTableChange()};

    editCashRegister = () => {
        let selectedId = this.state.selectedRowKeys
        this.setState({showCashRegisterDrawer:true, editId: selectedId})
    };

    exportRegister = () => {
        this.props.history.push("/app/settings/cash-register-export")
    }

    onTypeChange = e => {
        this.setState({
            selectedType: e.target.value,
        })
        this.handleTableChange()
    }

    render() {
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: this.onSelectChange,
            type: "radio"
        }
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                <Card title="Kasszák">
                    <Row>
                        <Col offset={1}>
                            <Button onClick={e=>this.addCashRegister(e)} type="primary">
                                Új felvétel
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={this.editCashRegister} disabled={!hasSelected} type="primary">
                                Kassza szerkesztése
                            </Button>
                            <Divider type="vertical"/>
                            <Button type="primary" onClick={this.exportRegister}>
                                Export
                            </Button>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Radio.Group value={this.state.selectedType} onChange={this.onTypeChange}>
                        <Radio.Button value="normal">Bolti</Radio.Button>
                        <Radio.Button value="office">Irodai</Radio.Button>
                    </Radio.Group>
                    <Divider type="horizontal"/>
                    <Table
                        loading={this.state.tableLoading}
                        dataSource={this.state.selectedType === "normal" ? this.state.cashRegisters : this.state.officeCashRegisters}
                        pagination={false}
                        bordered={true}
                        rowSelection={rowSelection}
                    >
                        <Column title="ID" dataIndex="id" key="id"/>
                        <Column title="Megnevezés" dataIndex="name" key="name"/>
                        <Column title="Telephely" dataIndex="siteName" key="siteName"/>
                        <Column title="Megjegyzés" dataIndex="note" key="note"/>
                    </Table>
                </Card>
                <CashRegisterDrawer visible={this.state.showCashRegisterDrawer} onClose={this.onCloseCashRegister} editId={this.state.editId} type={this.state.selectedType} />
            </div>
        )
    }
}
export default CashRegisterSettings;