import React, {Component} from "react";
import {Button, Card, DatePicker, Divider, Form, Select} from "antd";
import API from "../../Utils/API";
import moment from "moment";
import _ from "lodash";
const { RangePicker } = DatePicker
const { Option } = Select


const LeaveExportForm = Form.create({
    name: 'leave_export',
    onFieldsChange: (props, fields, allFields) => {
        props.handleChange(allFields.date.value, allFields.name.value)
    },
})(
    class extends Component {

        render() {
            const { getFieldDecorator } = this.props.form
            return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label="Intervallum">
                            {getFieldDecorator('date', {})(
                                <RangePicker
                                    defaultPickerValue={[moment().subtract(1, 'months'), moment()]}
                                    ranges={{
                                        Ma: [moment(), moment()],
                                        'Előző hónap': [moment().startOf('month').subtract(1, 'months'), moment().endOf('month').subtract(1, 'months')],
                                        'E hónap': [moment().startOf('month'), moment().endOf('month')]
                                    }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Név">
                            {getFieldDecorator('name', {})(
                                <Select allowClear={true} showSearch={true} filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                    {this.props.employees.map((employee) => (
                                        <Option key={employee.id} value={employee.id}>{employee.fullName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </div>

            );
        }
    }
)
class NonWorkingDayExport extends Component {

    state = {
        leaveTypes: [],
        employees: [],
        leaves: [],
        filter: {
            date: null,
            name: null,
            type: null
        },
        filteredLeaves: [],
    }

    componentDidMount() {
        this.getEmployees()
        this.getLeaves()
    }

    getEmployees = () => {
        API.get("getUsers").then(res => {
            this.setState({
                employees: res.data
            })
        })
    }

    getLeaves = () => {
        API.get("leaves/getLeaves").then(res => {
            this.setState({
                leaves: res.data,
                filteredLeaves: _.groupBy(res.data, e => e.employee.id),
            })
        })
    }

    filterLeaves = (dates, employee) => {
        let filteredLeaves = this.state.leaves
        if (employee !== undefined) {
            filteredLeaves = filteredLeaves.filter(e => e.employee.id === employee)
        }

        if (dates !== undefined && dates.length !== 0) {
            const startDate = dates[0]
            const endDate = dates[1]
            filteredLeaves = filteredLeaves.filter(e => startDate.isSameOrBefore(moment(e.endDate)) && endDate.isSameOrAfter(moment(e.startDate)))
        }
        this.setState({
            filteredLeaves: _.groupBy(filteredLeaves, e => e.employee.id)
        })
    }

    handleChange = () => {
        const { form } = this.formRef.props
        form.validateFields((err, values) => {
            if (err) return
            this.filterLeaves(values.date[0], values.date[1], values.name)
        })
    }

    saveFormRef = formRef => {
        this.formRef = formRef
    }

    render() {
        return (
            <div>
                <Card title="Szabadságolás export">
                    <Button onClick={() => this.props.history.push('/app/leaves/all')} style={{marginBottom: "2em"}}>Vissza</Button>
                    <LeaveExportForm
                        handleChange={this.filterLeaves}
                        {...this.state.filter}
                        employees={this.state.employees}
                        wrappedComponentRef={this.saveFormRef}
                    />
                    <Divider type="horizontal" />
                    {this.state.filteredLeaves.length === 0 ? (
                        <h4>Itten az output</h4>
                    ) : (
                        <div style={{ marginLeft: "16em", marginRight: "16em" }}>
                            {_.values(this.state.filteredLeaves).sort((a, b) => {
                                const aName = a[0].employee.fullName
                                const bName = b[0].employee.fullName
                                if (aName < bName) return -1
                                if (aName > bName) return 1
                                return 0
                            }).map((employeeLeaves) => (
                                <div key={`${employeeLeaves[0].employee.fullName}_div`} style={{ marginBottom: "2em" }}>
                                    <h2
                                        key={employeeLeaves[0].employee.fullName}
                                        style={{ textAlign: "left" }}
                                    >{employeeLeaves[0].employee.fullName}</h2>
                                        {employeeLeaves.sort((a, b) => {
                                            if (a.startDate < b.startDate) return -1
                                            if (a.startDate > b.startDate) return 1
                                            return 0
                                        }).map((leave) => (
                                            <div key={leave.id}>
                                                <p style={{ display: "inline"}}>{leave.startDate} - {leave.endDate}</p>
                                                <Divider type={"vertical"} style={{ display: "inline"}}></Divider>
                                                <p style={{ display: "inline"}}>{leave.type.name}</p>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    )}
                </Card>
            </div>
        );
    }
}

export default NonWorkingDayExport