import {Form, Input, Modal} from "antd";
import React, {Component} from "react";
import TextArea from "antd/es/input/TextArea";

export const EmailTemplateModal = Form.create({name: 'email_template_modal'})(
    class extends Component {

        render() {
            const {visible, onCancel, onSave, form} = this.props;
            const {getFieldDecorator} = form;
            return (
                <Modal
                    visible={visible}
                    title="Sablon szerkesztése"
                    okText="Mentés"
                    cancelText="Mégsem"
                    onCancel={onCancel}
                    onOk={onSave}>
                    <Form>
                        <Form.Item label={'Tárgy'}>
                            {getFieldDecorator("subject", {
                                rules: [{required: true, message: "Tárgy megadása kötelező!"}],
                                initialValue: this.props.selectedTemplate ? this.props.selectedTemplate.subject : ""
                            })(<Input/>)}
                        </Form.Item>
                        <Form.Item label={'Tartalom'}>
                            {getFieldDecorator("body", {
                                rules: [{required: true, message: "Tartalom megadása kötelező!"}],
                                initialValue: this.props.selectedTemplate ? this.props.selectedTemplate.body : ""
                            })(<TextArea rows={6}/>)}
                        </Form.Item>
                        <p>{"${target.name} - a vevő neve"}</p>
                        <Form.Item style={{hidden: true}}>
                            {getFieldDecorator('id', {
                                initialValue: this.props.selectedTemplate ? this.props.selectedTemplate.id : "",
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>
                        <Form.Item style={{hidden: true}}>
                            {getFieldDecorator('active', {
                                initialValue: this.props.selectedTemplate ? this.props.selectedTemplate.active : true,
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>
                        <Form.Item style={{hidden: true}}>
                            {getFieldDecorator('event', {
                                initialValue: this.props.selectedTemplate ? this.props.selectedTemplate.event : "",
                            })(
                                <Input hidden={true}/>
                            )}
                        </Form.Item>
                    </Form>

                </Modal>
            )
        }
    });