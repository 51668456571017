export const SET_SELECTED_SITE = "SET_SELECTED_SITE";
export const SET_NEWLY_CREATED_PARTNER = "SET_NEWLY_CREATED_PARTNER";
export const UNSET_NEWLY_CREATED_PARTNER = "UNSET_NEWLY_CREATED_PARTNER";
export const SET_META_MODE = "SET_META_MODE";
export const SET_USER = "SET_USER";
export const SET_PRODUCTS_FOR_SALE = "SET_PRODUCTS_FOR_SALE";
export const SET_DOC_NUMBERS = "SET_DOC_NUMBERS";
export const SET_DELIVERY_TYPES = "SET_DELIVERY_TYPES";
export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";
export const SET_TAX_VALUES = "SET_TAX_VALUES";
export const SET_DOC_TYPES = "SET_DOC_TYPES";
export const SET_USERS = "SET_USERS";
export const SET_SITES = "SET_SITES";