import React, {Component} from "react";
import {Form, Input} from "antd";
import InputNumber from "antd/es/input-number";
const { TextArea } = Input;

export const CashRegisterItemForm = Form.create({name: 'cash_register_item'})(
    class extends Component {
        constructor(props) {
            super(props);
            this.focus[1] = React.createRef();
            this.focus[2] = React.createRef();
        }

        focus = (e, number) => this.focus[number].current.focus();

        render() {
            const { getFieldDecorator } = this.props.form;
            return (
                <Form layout={'vertical'} className="cashregister-item-form">
                    {getFieldDecorator('id', {
                        initialValue: this.props.id,
                    })(
                        <Input type={"hidden"}/>
                    )}
                    <Form.Item label="Megnevezés">
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Kérjük adja meg a tétel nevét!' }],
                            initialValue: this.props.name,
                        })(
                            <Input autoFocus={true} onPressEnter={(e) => this.focus(e,1)} />,
                        )}
                    </Form.Item>
                    <Form.Item label="Összeg">
                        {getFieldDecorator('amount', {
                            rules: [{ required: true, message: 'Kérjük adja meg az összeget!' }],
                            initialValue: this.props.amount,
                        })(
                            <InputNumber formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                         ref={this.focus['1']} onPressEnter={(e) => this.focus(e, 2)}/>,
                        )}
                    </Form.Item>
                    <Form.Item label="Megjegyzés">
                        {getFieldDecorator('note', {
                            rules: [{ required: false}],
                            initialValue: this.props.note,
                        })(
                            <TextArea ref={this.focus['2']} rows={4}/>,
                        )}
                    </Form.Item>
                </Form>
            )
        }
    }
)

// class CashRegisterItemForm extends Component {
//
//     constructor(props) {
//         super(props);
//         this.focus[1] = React.createRef();
//         this.focus[2] = React.createRef();
//     }
//
//     focus = (e,number) =>{ this.focus[number].current.focus(); };
//
//     render() {
//         const { getFieldDecorator} = this.props.form;
//         return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="cashregister-item-form">
//
//             {getFieldDecorator('id',)(
//                 <Input type={"hidden"}/>,
//             )}
//             <Form.Item label="Megnevezés" >
//
//                 {getFieldDecorator('name', {
//                     rules: [{ required: true, message: 'Kérjük adja meg a tétel nevét!' }],
//                 })(
//                     <Input autoFocus={true} onPressEnter={(e) => this.focus(e,1)} />,
//                 )}
//             </Form.Item>
//             <Form.Item label="Összeg">
//                 {getFieldDecorator('amount', {
//                     rules: [{ required: true, message: 'Kérjük adja meg az összeget!' }],
//                 })(
//                     <InputNumber formatter={value => `${value}`.replace(/(?=(\d{3})+(?!\d))/g, ' ')}
//                                  ref={this.focus['1']} onPressEnter={(e) => this.focus(e, 2)}/>,
//                 )}
//             </Form.Item>
//             <Form.Item label="Megjegyzés">
//                 {getFieldDecorator('note', {
//                     rules: [{ required: false}],
//                 })(
//                     <TextArea ref={this.focus['2']} rows={4}/>,
//                 )}
//             </Form.Item>
//         </Form>)
//     }
// }
//
// export default Form.create({ name: 'normal_login' ,  mapPropsToFields(props) {
//
//         return {
//             id: Form.createFormField({
//                 ...props.id,
//                 value: props.id,
//             }),
//             name: Form.createFormField({
//                 ...props.name,
//                 value: props.name,
//             }),
//             amount: Form.createFormField({
//                 ...props.amount,
//                 value: props.amount,
//             }),
//             note: Form.createFormField({
//                 ...props.note,
//                 value: props.note,
//             }),
//         };
//     },})(CashRegisterItemForm);