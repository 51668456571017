import {Typography,Icon} from 'antd';
import React, {Component} from 'react';

const { Text } = Typography;

class EmailField extends Component {

    render() {
        return (
            <span><Text copyable>{this.props.email}</Text> <a  rel="noopener noreferrer" target="_blank" href={'mailto:' + this.props.email}> <Icon type="mail"/> </a> </span>)
    }
}

export default EmailField;