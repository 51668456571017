import React, {Component} from "react";
import {Button, Card, Col, Divider, Dropdown, Input, Menu, Row, Select, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import API from "../Utils/API";
import SiteSelector from "../Components/SiteSelector";
import RepairListExpandedTable from "../Components/RepairListExpandedTable";
import {saveAs} from 'file-saver';
import {LockOutlined} from "@ant-design/icons";
import ResponsibleSelector from "../Components/ResponsibleSelector";
import _ from "lodash";
import {resolveRepairStatus, resolveRepairType} from "../Utils/Utils";

const {Option} = Select;

class RepairsList extends Component {

    componentDidMount() {
        this.getUsers();
        this.getStatusesData();
    }

    state = {
        selectedRowKeys: [],
        selectedRows: [],
        tableLoading: false,
        repairs: [],
        users: [],
        selectedUser: null,
        status: ["TakenOver", "BidOffered", "BidAccepted", "WaitingForParts", "Pending", "Done", "CustomerNoticed", "Closed"],
        selectedStatus: ["TakenOver", "BidOffered", "BidAccepted", "WaitingForParts", "Pending", "Done", "CustomerNoticed", "Closed"],
        search: "",
        site: "all",
        takenOver: null,
        inProgress: null,
        done: null,
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            current: 1,
            results: 1,
            total: 200,
            pageSize: 10
        },
        currentMenuKey: 'all',
        selectedType: "all"
    };

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows
            });
        }
    };

    handleMenuClick = (e) => {
        let setPaggination = this.state.pagination;
        setPaggination.current = 1;
        this.setState({pagination: setPaggination});

        let selectedStatus = [];
        switch (e.key) {
            case 'all':
                this.state.status.forEach(d => selectedStatus.push(d));
                break;
            case 'takenOver':
                selectedStatus.push('TakenOver');
                break;
            case 'inProgress':
                selectedStatus.push('BidOffered', 'BidAccepted', 'Pending', 'WaitingForParts');
                break;
            case 'done':
                selectedStatus.push('CustomerNoticed', 'Done');
                break;
            case 'closed':
                selectedStatus.push('Closed');
                break;
            default:
                break;
        }
        this.setState({currentMenuKey: e.key, selectedStatus}, () => this.getRepairs());
    }

    getUsers() {
        this.setState({users: JSON.parse(localStorage.getItem('users'))})
    };

    getStatusesData() {
        API.get("repair/getStatusesData/" + this.state.site)
            .then(res => {
                this.setState({
                    takenOver: res.data[0],
                    inProgress: res.data[1],
                    done: res.data[2]
                }, () => this.getRepairs(this.state.search));
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
            sortedInfo: sorter
        }, () => {
            this.getRepairs(this.state.search);
            this.getStatusesData();
        });
    };

    getRepairs(search) {
        this.setState({tableLoading: true, repairs: [], search: search});
        let data = {
            page: this.state.pagination.current,
            size: this.state.pagination.pageSize,
            siteId: this.state.site,
            status: this.state.selectedStatus,
            type: [this.state.selectedType],
            search: search,
            userId: this.state.selectedUser
        }
        API.post("/repair/getRepairs", data)
            .then(res => {
                let repair = res.data.lines;
                const pagination = {...this.state.pagination};
                pagination.total = res.data.totalElements;
                this.setState({repairs: repair, pagination}, () => console.log(this.state.repairs));
            }).finally(() => {
            this.setState({tableLoading: false});
        });
    };

    searchCallback = _.debounce((text) => {
        this.getRepairs(text);
    }, 500);

    changeSite = (e) => {
        this.setState({
            site: e
        }, () => {
            this.handleTableChange(this.state.pagination);
        });
    };

    changeType = (e) => {
        this.setState({
            selectedType: e
        }, () => {
            this.handleTableChange(this.state.pagination);
        });
    };

    filterByUser = (e) => {
        this.setState({
            selectedUser: e
        }, () => {
            this.handleTableChange(this.state.pagination);
        });
    };

    editRepair = (id) => {
        this.props.history.push('/app/edit-repair/' + id);
    };

    newRepair = () => {
        this.props.history.push('/app/new-repair');
    };

    download = (key) => {
        alert("Előkészítés..")
        API.get("/repair/download/" + key, {responseType: 'blob'})
            .then(res => {
                const blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let date = new Date();
                let fileName = "repairs-" + date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + ".xls";
                saveAs(blob, fileName);
            }).catch(error => {
            console.log(error);
        });
    };

    render() {
        return (
            <div>
                <Card title="Szerviz">
                    <Row type="flex" justify="space-around">
                        <Col span={14}>
                            <Button onClick={e => this.newRepair(e)} type="primary">
                                Új felvétel
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={() => this.handleTableChange(this.state.pagination)} type="primary">
                                Frissítés
                            </Button>
                            <Divider type="vertical"/>
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item key={1} onClick={() => this.download(1)}>
                                        Utolsó 1 hónap
                                    </Menu.Item>
                                    <Menu.Item key={2} onClick={() => this.download(2)}>
                                        Utolsó 6 hónap
                                    </Menu.Item>
                                    <Menu.Item key={3} onClick={() => this.download(3)}>
                                        Utolsó 1 év
                                    </Menu.Item>
                                    <Menu.Item key={4} onClick={() => this.download(4)}>
                                        Összes
                                    </Menu.Item>
                                </Menu>
                            )}>
                                <Button type="primary">Excel export</Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Menu onClick={this.handleMenuClick} selectedKeys={[this.state.currentMenuKey]} mode="horizontal">
                        <Menu.Item key="all" style={{width: '20%'}}><b>Összes</b></Menu.Item>
                        <Menu.Item key="takenOver"
                                   style={{width: '20%'}}><b>Átvett </b>({this.state.takenOver})</Menu.Item>
                        <Menu.Item key="inProgress" style={{width: '20%'}}><b>Folyamatban </b>({this.state.inProgress})</Menu.Item>
                        <Menu.Item key="done" style={{width: '20%'}}><b>Elkészült </b>({this.state.done})</Menu.Item>
                        <Menu.Item key="closed" style={{width: '20%'}}><b>Lezárt</b></Menu.Item>
                    </Menu>
                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={5}/>
                        <Col span={3} offset={1}><b>Típus</b></Col>
                        <Col span={3} offset={1}><b>Telephely</b></Col>
                        <Col span={4} offset={1}><b>Felelős személy</b></Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Input onChange={e => {
                                this.searchCallback(e.target.value)
                            }} placeholder="Keresés"/>
                        </Col>
                        <Col offset={1} span={3}>
                            <Select style={{width: "80%"}} onSelect={e => this.changeType(e)} defaultValue={"all"}>
                                <Option key="Repair">Javítás</Option>
                                <Option key="Guarantee">Garancia</Option>
                                <Option key="all">Összes</Option>
                            </Select>
                        </Col>
                        <Col offset={1} span={3}>
                            <SiteSelector all={true} selected={"all"} onSelect={this.changeSite}/>
                        </Col>
                        <Col offset={1} span={4}>
                            <ResponsibleSelector users={this.state.users} filterByUser={e => this.filterByUser(e)}/>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>

                    <Row>
                        <Col>
                            <Table dataSource={this.state.repairs} rowKey="id" pagination={this.state.pagination}
                                   bordered={true} onChange={this.handleTableChange}
                                   expandedRowRender={RepairListExpandedTable}>
                                <Column title="Azonosító" dataIndex="repairNo" key="repairNo" width={130}
                                        render={(repairNo, record) => (
                                            <span className="link" onDoubleClick={() => this.editRepair(record.id)}>{repairNo} {record.isLocked ?
                                                <Tooltip title={record.lockedBy + " felhasználó szerkeszti"}><LockOutlined
                                                    hidden={!record.isLocked}/></Tooltip> : ""}</span>
                                        )}/>
                                <Column title="Tulajdonos" dataIndex="partnersName" key="partnersName" width={160}/>
                                <Column title="Telephely" dataIndex="siteTitle" key="siteTitle" width={130}/>
                                <Column title="Státusz" dataIndex="repairStatus" key="repairStatus" width={150}
                                        render={text => {
                                            return (resolveRepairStatus(text))
                                        }}/>
                                <Column title="Hiba leírása" dataIndex="repairLines[0].problemDescription"
                                        key="repairLines[0].problemDescription"/>
                                <Column title="Átvéve" dataIndex="createdDate" key="createdDate" width={130}/>
                                <Column title="Típus" dataIndex="repairType" key="repairType" width={150}
                                        render={text => {
                                            return (resolveRepairType(text))
                                        }}/>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default RepairsList;
