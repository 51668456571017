import {Card, Form, Icon, Input, Button, Row, Col, Alert} from 'antd';
import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import axios from 'axios';
import API from "../Utils/API";
import {connect} from "react-redux";
import {setSelectedSite, setSites, setUser} from "../actions/index";
import SiteUtils from "../Utils/SiteUtils";
import SiteSelector from "../pages/SiteSelector";


function mapDispatchToProps(dispatch) {
    return {
        setSelectedSite: site => dispatch(setSelectedSite(site)),
        setUser: user => dispatch(setUser(user)),
        setSites: sites => dispatch(setSites(sites))
    };
}

class Login extends Component {

    state = {
        formVisible: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                 axios.post(process.env.REACT_APP_AUTH_BASE_URL, values).then(res => {
                    const token = res.data.token;
                    localStorage.setItem("jwtToken", token);

                    this.getUser();


                }).catch(error => {
                        if (error.response != null) {
                            let msg;
                            if (error.response.data === "INVALID_CREDENTIALS") {
                                msg = "Hibás felhasználónév vagy jelszó!";
                            } else if (error.response.data === "USER_DISABLED") {
                                msg = "Letiltott felhasználó!";
                            } else {
                                msg = "Hiba történt a szerverre való bejelentkezéskor! Eredeti hiba: " + error.response.data;
                            }
                            ReactDOM.render(<Alert
                                message={msg}
                                type="error"
                            />, document.getElementById("alert"));

                        }
                    }
                )
            }
        });
    };

    getUser = () => {
        API.get("user/me").then(res => {
            this.props.setUser(res.data);
            this.props.setSites(res.data.sites);
            console.log("user/me: ", JSON.stringify(res.data.sites));
            if (res.data.sites.length >= 1) {

                this.setState({formVisible: true});

                ReactDOM.render(<SiteSelector
                    sites={res.data.sites}
                    history={this.props.history}
                    setSelectedSite={this.props.setSelectedSite}
                />, document.getElementById("site-selector"));

            }  else if (res.data.sites.length === 1) {
                console.log(res.data.sites[0]);
                SiteUtils.getSiteTitleByKey(res.data.sites[0].nameKey).then(response => {
                    console.log("getSiteTitleByKey: ", response.data);
                    let newSelectedSite = {
                        id: response.data.id,
                        title: response.data.title,
                        value: response.data.nameKey
                    };
                    this.props.setSelectedSite(newSelectedSite);
                });
                this.props.history.push('/app/index');
            }else {
                ReactDOM.render(<Alert
                    message={'Nincs elérhető telephely!'}
                    type="error"
                />, document.getElementById("alert"));
            }
        }).catch(error => {
                if (error.response != null) {
                    let msg = "";
                    if (error.response.data === "INVALID_CREDENTIALS") {
                        msg = "Hibás felhasználónév vagy jelszó!";
                    } else if (error.response.data === "USER_DISABLED") {
                        msg = "Letiltott felhasználó!";
                    } else {
                        msg = "Hiba történt a szerverre való bejelentkezéskor! Eredeti hiba: " + error.response.data;
                    }
                    ReactDOM.render(<Alert
                        message={msg}
                        type="error"
                    />, document.getElementById("alert"));

                }
            }
        );
    }

    componentDidMount() {
        //TODO: check expired JWT
        if (localStorage.getItem("jwtToken") != null) {
            this.props.history.push('/app/index');
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div style={{background: "#f0f2f5", paddingTop: "15%", position: "fixed", height: "100%", width: "100%"}}>
                <Row type="flex" justify="center" align="middle">
                    <Col span={6} stye={{paddingTop: "320px"}}>
                        <Card title="Pc-Ház SW" style={{width: 420}}>
                            <div id="alert">

                            </div>

                            <div id="site-selector">

                            </div>

                            <Form onSubmit={this.handleSubmit} hidden={this.state.formVisible} className="login-form">
                                <Form.Item>
                                    {getFieldDecorator('username', {
                                        rules: [{required: true, message: 'Kérjük töltse ki a felhasználó nevet!'}],
                                    })(
                                        <Input
                                            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                            placeholder="Felhasználónév"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [{required: true, message: 'Kérjük töltse ki a jelszót!'}],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                            type="password"
                                            placeholder="Jelszó"
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Belépés
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const AuthForm = Form.create({name: 'normal_login'})(Login);
export const LoginForm = connect(null, mapDispatchToProps)(AuthForm);