import React, {Component} from "react";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";
import {Button, Drawer} from "antd";
import {CashRegisterItemForm} from "./CashRegisterItemForm";

class CashRegisterDrawer extends Component {


    save = (e) => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) { return; }
            values.cashRegisterId = this.props.cashRegisterId;
            if (this.props.selectedCashRegisterOpenId != null)
            {
                values.selectedCashRegisterOpenId = this.props.selectedCashRegisterOpenId;
            }


            API.post("cash-register/save-item",values).then(res => {
                form.resetFields();
                if(res.data.type === "success"){
                    this.props.onClose(e,true);
                }
                NotificationUtil(res.data);
            }).catch(() => {
                NotificationUtil({msg:"Hiba történt!",type:"error"});
                this.props.onClose(e,false);
            });
        });
    };

    constructor(props){
        super(props);
        this.state = {
            item : {name: ""},
            title: ""
        };
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    open = () => {
        if(this.props.editId !== null) {
            API.get("/cash-register/get-item/" + this.props.editId).then(res => {
                this.setState({item: res.data});
                this.setState({title: "Tétel módosítása : "+ res.data.name});
            });
        }else{
            this.setState({item: {name : ""}});
            this.setState({title: "Új tétel létrehozása"});
        }
    };

    render() {
        return (
            <Drawer
                width={480}
                title={this.state.title}
                placement="right"
                closable={true}
                afterVisibleChange={this.open}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >
                <CashRegisterItemForm save={this.save} {...this.state.item} wrappedComponentRef={this.saveFormRef} />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.props.onClose} style={{marginRight: 8}}>
                        Mégsem
                    </Button>
                    <Button onClick={this.save} type="primary">
                        Mentés
                    </Button>
                </div>
            </Drawer>
        );
    }
}

export default CashRegisterDrawer;

