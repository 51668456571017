import {Form, Input, Select} from 'antd';
import React, {Component} from 'react';
import API from "../../Utils/API";

const { Option } = Select;

export const UserForm = Form.create({name: 'normal_user'})(
    class extends Component {
        state = {
            showPostalData : false,
            nameChecking : "",
            nameCheckingText: "",
            tags: []
        };

        componentDidMount() {
            API.get("settings/getAllRole").then(res => {
                this.setState({tags:res.data},()=>console.log(this.state.tags))
            });
        }

        render() {
            const { getFieldDecorator} = this.props.form;
            return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="user-form">

                <Form.Item label="Teljes név">
                    {getFieldDecorator('fullName', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a nevet!'}],
                        initialValue: this.props.fullName,
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <Form.Item label="Felhasználónév">
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Kérjük töltse ki a felhasználónevet!' }],
                        initialValue: this.props.username,
                    })(
                        <Input/>,
                    )}
                </Form.Item>

                <Form.Item label="Jelszó">
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Kérjük adjon meg egy jelszót!' }],
                        initialValue: this.props.password,
                    })(
                        <Input type="password"/>,
                    )}
                </Form.Item>

                <Form.Item label="Címkék">
                    {getFieldDecorator('tags', {
                        rules: [{ required: true, message: 'Kérjük adjon meg jogosultságot!'}],
                        initialValue: this.props.tags,
                    })(
                        <Select mode="multiple" style={{ width: '100%' }} >
                            { this.state.tags.map((d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))) }
                        </Select>
                    )}
                </Form.Item>
            </Form>)
        }
    }
)