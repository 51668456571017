import React, {Component} from "react";
import {Button, Form, Input, Row, Col, Spin} from "antd";
import Card from "antd/es/card";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";

class NavSettings extends Component {

    componentDidMount() {
        this.getNavSettings();
    }

    state = {
        navSettingsLoading: false,
        navConnectionState: false,
        navUser: "",
        navPassword: "",
        signKey: "",
        exchangeKey: "",
        taxNumber: ""
    }

    getNavSettings = () => {
        API.get("invoice/getNavSettings")
            .then(res => {
                if (Object.keys(res.data.extraList[0]).length !== 0) {
                    let data = {
                        navUser: res.data.extraList[0].navUser,
                        navPassword: res.data.extraList[0].navPassword,
                        exchangeKey: res.data.extraList[0].exchangeKey,
                        signKey: res.data.extraList[0].signKey,
                        taxNumber: res.data.extraList[0].taxNumber
                    }
                    API.post("invoice/testNavSettings", data)
                        .then(response => {
                            if (response.data.type === "success") {
                                this.setState({navConnectionState: true});
                            } else {
                                this.setState({navConnectionState: false});
                            }
                        })

                    let decodedUser = Buffer.from(res.data.extraList[0].navUser, 'base64').toString('utf-8');
                    let decodedPassword = Buffer.from(res.data.extraList[0].navPassword, 'base64').toString('utf-8');
                    let decodedSignKey = Buffer.from(res.data.extraList[0].signKey, 'base64').toString('utf-8');
                    let decodedExchangeKey = Buffer.from(res.data.extraList[0].exchangeKey, 'base64').toString('utf-8');
                    let decodedTaxNumber = Buffer.from(res.data.extraList[0].taxNumber, 'base64').toString('utf-8');

                    this.setState({
                        navUser: decodedUser,
                        navPassword: decodedPassword,
                        signKey: decodedSignKey,
                        exchangeKey: decodedExchangeKey,
                        taxNumber: decodedTaxNumber
                    })
                }
            })
            .finally(() => {
                this.setState({navSettingsLoading: false});
            })
    }

    handleFormSubmit = () => {
        const {form} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log(values);

            this.setState({navSettingsLoading: true});
            let encodedNavUser = new Buffer(values.navUser).toString('base64');
            let encodedNavPassword = new Buffer(values.navPassword).toString('base64');
            let encodedSignKey = new Buffer(values.signKey).toString('base64');
            let encodedExchangeKey = new Buffer(values.exchangeKey).toString('base64');
            let encodedTaxNumber = new Buffer(values.taxNumber).toString('base64');

            let data = {
                navUser: encodedNavUser,
                navPassword: encodedNavPassword,
                exchangeKey: encodedExchangeKey,
                signKey: encodedSignKey,
                taxNumber: encodedTaxNumber
            }

            API.post("invoice/saveNavSettings", data)
                .then(res => {
                    if (res.data.type === "success") {
                        NotificationUtil({msg: res.data.msg, type: "success"});
                    } else {
                        NotificationUtil({msg: res.data.msg, type: "error"});
                    }
                }).finally(() => {
                this.setState({navSettingsLoading: false});
            })
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Spin spinning={this.state.navSettingsLoading}>
                    <Card title="Számlázó bejelentése a NAV felé" style={{width: "100%"}}>
                        <p>További információ a <a target="_blank"
                                                   rel="noreferrer noopener"
                                                   href="https://app.mobil-szamla.hu">https://app.mobil-szamla.hu</a> oldalon.
                        </p>
                    </Card>
                    <Card title="NAV adatkapcsolat beállítások" style={{width: "100%"}}>
                        <Row align="center">
                            <Col span={2}>
                                <h3>Státusz: </h3>
                            </Col>
                            <Col span={2}>
                                {this.state.navConnectionState &&
                                <h3 style={{color: "green"}}>Aktív</h3>
                                }
                                {!this.state.navConnectionState &&
                                <h3 style={{color: "red"}}>Inaktív</h3>
                                }

                            </Col>
                        </Row>
                        <Form>
                            <Form.Item label="NAV Felhasználónév"
                                       rules={[{required: true, message: 'Kérjük töltse ki a felhasználó nevet!'}]}
                                       labelCol={{span: 8}} wrapperCol={{span: 6}}>
                                {getFieldDecorator("navUser", {
                                    initialValue: this.state.navUser,
                                    rules: [],
                                })(
                                    <Input placeholder="Felhasználónév"/>
                                )}
                            </Form.Item>
                            <Form.Item label="NAV Jelszó"
                                       rules={[{required: true, message: 'Kérjük töltse ki a jelszót!'}]}
                                       labelCol={{span: 8}} wrapperCol={{span: 6}}>
                                {getFieldDecorator("navPassword", {
                                    initialValue: this.state.navPassword,
                                    rules: [],
                                })(
                                    <Input placeholder="Jelszó" type="password"/>
                                )}
                            </Form.Item>
                            <Form.Item label="NAV Aláírókulcs"
                                       rules={[{required: true, message: 'Kérjük töltse ki az aláírókulcsot!'}]}
                                       labelCol={{span: 8}} wrapperCol={{span: 6}}>
                                {getFieldDecorator("signKey", {
                                    initialValue: this.state.signKey,
                                    rules: [],
                                })(
                                    <Input placeholder="Aláírókulcs"/>
                                )}
                            </Form.Item>
                            <Form.Item label="NAV Cserekulcs"
                                       rules={[{required: true, message: 'Kérjük töltse ki a cserekulcsot!'}]}
                                       labelCol={{span: 8}} wrapperCol={{span: 6}}>
                                {getFieldDecorator("exchangeKey", {
                                    initialValue: this.state.exchangeKey,
                                    rules: [],
                                })(
                                    <Input placeholder="Cserekulcs"/>
                                )}
                            </Form.Item>
                            <Form.Item label="Adóazonosító jel"
                                       rules={[{required: true, message: 'Kérjük töltse ki az adóazonosítót!'}]}
                                       labelCol={{span: 8}} wrapperCol={{span: 6}}>
                                {getFieldDecorator("taxNumber", {
                                    initialValue: this.state.taxNumber,
                                    rules: [],
                                })(
                                    <Input placeholder="Adószám első 8 karaktere"/>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={this.handleFormSubmit} type="primary">Ellenőrzés és Mentés</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default Form.create({name: 'nav_settings_form'})(NavSettings);
