import React, {Component} from "react";
import {Button, Drawer} from "antd";
import {CashRegisterItemForm} from "./CashRegisterItemForm";
import API from "../Utils/API";
import NotificationUtil from "../Utils/NotificationUtil";

class OfficeCashRegisterDrawer extends Component {

    save = () => {
        const { form } = this.formRef.props
        form.validateFields((err, values) => {
            if (err) return;
            values.cashRegisterId = this.props.cashRegisterId

            API.post("/office-cash-register/save-item", values)
                .then(res => {
                    form.resetFields()
                    if (res.data.type === "success") {
                        this.props.onClose(true)
                    }
                    NotificationUtil(res.data)
                }).catch(res => {
                    NotificationUtil({msg: "Hiba történt!", type: "error"})
                this.props.onClose(false)
            })


        })
    }

    constructor(props) {
        super(props);
        this.state = {
            item: {name: ""},
            title: "",
        }
    }

    saveFormRef = formRef => {
        this.formRef = formRef
    }

    open = (id) => {
        this.setState({editId: id})
        if (id === "") {
            this.setState({
                title: "Új tétel létrehozása",
                item: {name: ""}
            })
        } else {
            API.get(`/office-cash-register/get-item/${id}`)
                .then(res => {
                    this.setState({
                        item: res.data,
                        title: `Tétel módosítása: ${res.data.name}`
                    })
                })

        }
    }

    render() {
        return (
            <Drawer
                width={480}
                title={this.state.title}
                placement="right"
                closable={true}
                onClose={() => this.props.onClose(false)}
                visible={this.props.visible}
            >
                <CashRegisterItemForm save={this.save} {...this.state.item} wrappedComponentRef={this.saveFormRef} />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={() => this.props.onClose(false)} style={{marginRight: 8}}>
                        Mégsem
                    </Button>
                    <Button onClick={this.save} type="primary">
                        Mentés
                    </Button>
                </div>
            </Drawer>
        )
    }
}

export default OfficeCashRegisterDrawer;