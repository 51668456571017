import {Button, Card, Checkbox, Col, Form, Row, Select, Table, Tooltip} from "antd";
import React, {Component} from "react";
import API from "../Utils/API";
import QueryParams from "../Utils/QueryParams";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import ResizableAntdTable from 'resizable-antd-table';
import {connect} from "react-redux";
import {setProductsForSale} from "../actions";
import {EditOutlined} from "@ant-design/icons";

const {Column} = Table;

const {Option} = Select;

class ProductList extends Component {

    state = {
        tableLoading: true,
        products: [],
        manufacturers: [],
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            position: "both",
            current: 0,
            results: 0,
            total: 0,
            pageSize: 10
        },
        filteredInfo: null,
        sortedInfo: null,
        suppliers: [],
        selectedSupplier: [],
        categories: [],
        selectedCategory: [],
        selectedManufacturers: [],
        pricedOnly: false,
        searchStr: "",
        searchInputs: [{input: ""}],
        selectedForSale: []
    };

    handleTableChange = (pagination, filters, sorter) => {
        console.log("filters: ", filters)
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        if (this.state.pricedOnly) {
            this.setState({
                pagination: pager,
                filteredInfo: filters,
                sortedInfo: sorter
            }, () => this.getPricedOnly());
        } else {
            this.setState({
                pagination: pager,
                filteredInfo: filters,
                sortedInfo: sorter
            }, () => this.getProducts());
        }
    };

    clearFilters = () => {
        console.log("value: ", document.getElementById('input0').value);
        document.getElementById('input0').value = '';
        console.log("after clear: ", document.getElementById('input0').value);
        this.setState({filteredInfo: null, selectedCategory: [], selectedSupplier: [], searchInputs: [{input: ""}]},
            () => (this.getProducts()))
    }

    onSearchInputChange(e, index) {
        let searchInputs = this.state.searchInputs;
        searchInputs[index] = e.target.value;
        this.setState({searchInputs})
    }

    addSearchLine = () => {
        let line = {input: ""};
        this.setState(prevState => ({searchInputs: [...prevState.searchInputs, line]}));
    }

    deleteLine = (e, record) => {
        console.log("delete me: ", record);
        const searchInputs = this.state.searchInputs.filter(item => item !== record);
        this.setState({searchInputs: searchInputs});
    }

    componentDidMount() {
        this.getProducts();
        this.getSuppliers();
        //this.getManufacturers();
    }

    getManufacturers() {
        API.get("/getManufacturers")
            .then(res => {
                this.setState({manufacturers: res.data})
            })
    }

    getCategories() {
        API.post("/getCategoriesBySupplier", this.state.selectedSupplier)
            .then(res => {
                console.log("categories: ", res.data);
                this.setState({categories: res.data})
            })
    }

    getSuppliers() {
        API.get("/getSupplierList")
            .then(res => {
                let suppliers = res.data;
                console.log(suppliers);
                this.setState({suppliers: suppliers});
            });
    };

    getProducts = () => {
        console.log("sorter: ", this.state.sortedInfo);
        this.setState({tableLoading: true, products: []});
        let data;
        let searchInputs = [];
        console.log("this.state.searchInputs:", this.state.searchInputs);
        if (this.state.searchInputs[0].input !== "")
            for (let i = 0; i < this.state.searchInputs.length; i++) {
                searchInputs[i] = this.state.searchInputs[i];
                console.log(this.state.searchInputs[i]);
                console.log("searchInputs[i]: ", searchInputs[i]);
            }
        console.log(searchInputs)
        if (this.state.sortedInfo) {
            data = {
                "search": searchInputs,
                "suppliers": this.state.selectedSupplier,
                "manufacturers": this.state.selectedManufacturers,
                "categories": this.state.selectedCategory,
                "sorter": {
                    "columnName": this.state.sortedInfo.columnKey,
                    "order": this.state.sortedInfo.order
                }
            }
        } else if (this.state.searchInputs[0].input !== "") {
            data = {
                "search": searchInputs,
                "suppliers": this.state.selectedSupplier,
                "manufacturers": this.state.selectedManufacturers,
                "categories": this.state.selectedCategory
            }
        } else {
            data = {
                "suppliers": this.state.selectedSupplier,
                "manufacturers": this.state.selectedManufacturers,
                "categories": this.state.selectedCategory
            }
        }
        console.log("data:", data);
        API.post("/products" +
            "?page=" + this.state.pagination.current +
            "&size=" + this.state.pagination.pageSize, data)
            .then(res => {
                let products = res.data.lines;
                console.log('products are : ', products);
                const pagination = {...this.state.pagination};
                pagination.total = res.data.totalElements;
                this.setState({products: products, pagination});
            }).finally(() => {
            this.setState({tableLoading: false});
        });
    }

    changeSupplier = (e) => {
        this.setState({
            selectedSupplier: e,
            pagination: {...this.state.pagination, current: 0}
        }, () => {
            console.log(this.state.selectedSupplier);
            this.getCategories();
            this.getProducts();
        });
    }

    changeCategory = (e) => {
        this.setState({
            selectedCategory: e,
            pagination: {...this.state.pagination, current: 0}
        }, () => {
            this.getProducts();
        })
    }

    onManufacturerChange = (value) => {
        console.log("manufacturer: ", value);
        this.setState({selectedManufacturers: value});
        this.getProducts();
    }

    handlePricedOnlyCheckbox(e) {
        this.setState({pricedOnly: e.target.checked})
        if (e.target.checked) {
            this.getPricedOnly();
        } else {
            this.getProducts();
        }
    }

    getPricedOnly = () => {
        let qp = new QueryParams();
        qp.setPaginationParams(this.state.pagination.current, this.state.pagination.pageSize)
        API.get("productPrice/getPricedProducts" + qp.getQueryParam())
            .then(res => {
                console.log(res.data);
                const pager = {
                    total: res.data.totalElements
                }
                this.setState({products: res.data.lines, pagination: pager})
            })
    }

    sorter = () => {
        console.log("sorter clicked");
    }

    onProductSelected = (e, record) => {
        console.log(record);
        let selectedForSale = this.state.selectedForSale;
        selectedForSale.push(record);
        this.setState({selectedForSale})
    }

    editProduct = (record) => {
        this.props.history.push({
            pathname: '/app/product',
            state: {detail: record}
        });
    }

    createSalesOrder = () => {
        console.log("ProductList -> setProductsForSale:", this.state.selectedForSale)
        this.props.setProductsForSale(this.state.selectedForSale);
        this.props.history.push('/app/new-sales');
    }

    createNewProduct = () => {
        this.props.history.push('/app/product');
    }

    inStock = (stock) => {
        return stock.toString().toLowerCase() === "raktáron" ||
            stock.toString().toLowerCase() === "készleten" ||
            parseFloat(stock) > 0;
    }

    render() {
        let {filteredInfo, sortedInfo, manufacturers} = this.state;
        filteredInfo = filteredInfo || {};
        sortedInfo = sortedInfo || {};
        manufacturers = manufacturers || [];
        let filters;
        if (Object.keys(manufacturers) > 0) {
            filters = manufacturers.map(manufacturer => (
                {text: manufacturer, value: manufacturer}
            ))
        }


        const columns = [
            {
                title: 'Gyártói cikkszám',
                dataIndex: 'productCode',
                key: 'productCode',
                sorter: (a, b) => {
                    a = a.productCode || '';
                    b = b.productCode || '';
                    a.localeCompare(b);
                },
                sortOrder: sortedInfo.columnKey === 'productCode' && sortedInfo.order,
            },
            {
                title: 'Gyártó',
                dataIndex: 'manufacturer',
                key: 'manufacturer',
                filters: filters,
                filteredValue: filteredInfo.manufacturer || null,
                onFilter: (value, record) => record.manufacturer.includes(value),
                sorter: (a, b) => {
                    a = a.manufacturer || '';
                    b = b.manufacturer || '';
                    a.localeCompare(b);
                },
                sortOrder: sortedInfo.columnKey === 'manufacturer' && sortedInfo.order,
                width: '10%',
            },
            {
                title: 'Rögzítve',
                dataIndex: 'createdDate',
                key: 'createdDate',
                filters: filters,
                filteredValue: filteredInfo.createdDate || null,
                onFilter: (value, record) => record.createdDate.includes(value),
                sorter: (a, b) => {
                    return a > b;
                    // a = a.manufacturer || '';
                    // b = b.manufacturer || '';
                    // a.localeCompare(b);
                },
                sortOrder: sortedInfo.columnKey === 'createdDate' && sortedInfo.order,
                width: '10%',
            },
            {
                title: 'Megnevezés',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    a = a.name || '';
                    b = b.name || '';
                    a.localeCompare(b)
                },
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                width: '50%',
            },
            {
                title: 'Beszerzési ár',
                key: 'price',
                sorter: (a, b) => a.purchasePrices[0] - (b.purchasePrices[0].price),
                sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
                render: (record) => (
                    (record.purchasePrices && Object.keys(record.purchasePrices).length > 0) ?
                        record.lowestPrice = record.purchasePrices.reduce((min, p) => (p.price < min && this.inStock(p.internalStock)) ? p.price : min, record.purchasePrices[0].price)
                        : "-"
                )
            },
            {
                title: 'Értékesítés',
                key: 'add',
                render: (record) => <span className="link" onClick={e => this.onProductSelected(e, record)}>Hozzáad</span>
            },
            {
                title: 'Szerk.',
                key: 'edit',
                render: (record) => <Button type="primary"
                                            onClick={() => this.editProduct(record)}><EditOutlined/></Button>
            }
        ]
        return (

            <div>
                <Row>
                    <Col span={4}>
                        <Tooltip title="Hamarosan">
                            <Button type="primary" onClick={this.createNewProduct}>Új termék
                                felvétele</Button>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Checkbox onChange={(e) => this.handlePricedOnlyCheckbox(e)}>Csak webshopos termékek</Checkbox>
                    </Col>
                </Row>
                <Row style={{paddingTop: '15px'}}>
                    <Col span={3}>
                        <Select
                            mode="multiple"
                            style={{width: '120%'}}
                            onChange={this.changeSupplier}
                            placeholder="Beszállítók">
                            {this.state.suppliers.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col offset={1} span={3}>
                        <Select
                            mode="multiple"
                            style={{width: '200%'}}
                            dropdownStyle={{minWidth: "50%", maxWidth: "auto"}}
                            onChange={this.changeCategory}
                            placeholder="Kategória">
                            {this.state.categories.map(d => (
                                <Option key={d.supplier.name - d.name} value={d.name}>{d.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={2} offset={4}>
                        <Button type="danger" onClick={this.clearFilters}>Visszaállítás</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={5} style={{paddingTop: 50}}>
                        <Table dataSource={this.state.searchInputs} bordered={false} pagination={false}
                               showHeader={false}>
                            <Column render={(text, record, index) => {
                                return (
                                    <input
                                        id={`input${index}`}
                                        onKeyDown={event => {
                                            if (event.keyCode === 13) this.getProducts()
                                        }}
                                        onChange={e => this.onSearchInputChange(e, index)}
                                        placeholder="Keresés"/>
                                )
                            }}/>
                            <Column render={(text, record, index) => {
                                if (index !== 0)
                                    return (
                                        <DeleteOutlined style={{fontSize: 18, color: "darkred"}}
                                                        onClick={e => this.deleteLine(e, record)}/>
                                    )
                            }}/>
                        </Table>
                    </Col>
                    <Col span={2} style={{paddingTop: 70}}>
                        <Button type="primary" onClick={this.getProducts}>Keresés</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={2}>
                        <Button type="link" onClick={this.addSearchLine}>Keresési feltétel hozzáadása</Button>
                    </Col>
                </Row>
                {Object.keys(this.state.selectedForSale).length > 0 &&
                <Row style={{paddingTop: 40}}>
                    {this.state.selectedForSale.map((d, index) =>
                        <p key={index}>{d.productCode}: {d.name} <DeleteOutlined onClick={() => {
                            let lines = this.state.selectedForSale;
                            lines.splice(index, 1);
                            this.setState({selectedForSale: lines})
                        }} style={{color: "red"}}/></p>
                    )}
                    <Button onClick={e => this.createSalesOrder(e)}>Értékesítés létrehozása</Button>
                </Row>
                }
                <ResizableAntdTable
                    columns={columns}
                    onChange={this.handleTableChange}
                    dataSource={this.state.products}
                    bordered
                    loading={this.state.tableLoading}
                    rowKey={"productCode"}
                    expandedRowRender={record => (<PriceSubTable record={record}/>)}
                    pagination={this.state.pagination}>
                    {/*<Column title="Gyártói cikkszám" dataIndex="productCode" key="productCode"/>
                    <Column title="Gyártó" dataIndex="manufacturer" key="manufacturer"
                            filters={[
                                {text: 'Canon', value: 'Canon'},
                            ]}
                            filteredValue={filteredInfo.manufacturer || null}
                            onFilter={(value, record) => record.name.includes(value)}
                            ellipsis={true}
                    />
                    <Column title="Megnevezés" dataIndex="name" key="name"
                            sorter={(a, b) => a.name.length - b.name.length}
                            sortOrder={sortedInfo.columnKey === 'name' && sortedInfo.order}
                    />
                    <Column title="Vonalkód" dataIndex="barcode" key="barcode"/>
                    {/*<Column title="Kategória" dataIndex="category" key="category.name"/>

                    <Column title="Beszállítói cikkszám" dataIndex="supplierProductCode" key="supplierProductCode"/>


                    <Column title="Sorszám ellenőrzés" render={(serialCheck, record) => (
                        <Checkbox
                            checked={serialCheck}
                            disabled={true}>
                        </Checkbox>
                    )} dataIndex="serialCheck" key="serialCheck"/>

                    <Column title="Beszállító" dataIndex="supplier" key="supplier"/>
                    <Column title="Utoljára módosítva" dataIndex ="lastUpdated" key="lastUpdated"/>
                    */}

                    {/*<Column title="Besz. ár" render={(record) => (
                        <span>{record.purchasePrices.length > 0 ?
                            record.purchasePrices.reduce((min, p) => p.price < min ? p.price : min, record.purchasePrices[0].price)
                            : "-"} </span>
                    )}/>
                    <Column title="Garancia" dataIndex="warranty" key="warranty"/>*/}
                </ResizableAntdTable>
            </div>
        )
    }
}

const PriceSubTable = (props) => {
    console.log("props: ", props.record)
    return (
        <div>
            {props.record.variants &&
            <Card title="Variánsok">
                <Table rowKey={"supplier"} dataSource={props.record.variants} pagination={false}
                       expandedRowRender={record => <PriceSubTable record={record}/>}>
                    <Column title="Méret" dataIndex="size" key="size"/>
                    <Column title="Súly" dataIndex="weight" key="weight"/>
                    <Column title="Nagy méretű" dataIndex="specialSize" key="specialSize"/>
                    <Column title="Beszerzési Ár (Nettó)" dataIndex="price" key="price"/>
                </Table>
            </Card>
            }
            {props.record.purchasePrices &&
            <Card title="Beszerzési árak">
                <Table rowKey={"supplier"} dataSource={props.record.purchasePrices} pagination={false}>
                    <Column title="Beszállító" dataIndex="supplier" key="supplier"/>
                    <Column title="Beszállítói termékazonosító" dataIndex="supplierProductCode"
                            key="supplierProductCode"/>
                    <Column title="Kategória" dataIndex="supplierCategory" key="supplierCategory"/>
                    <Column title="Beszerzési Ár (Nettó)" dataIndex="price" key="price"/>
                    <Column title="Belső készlet" dataIndex="internalStock" key="internalStock"/>
                </Table>
            </Card>
            }
            {props.record.shopPrices &&
            <Card title="Eladási árak">
                <Table rowKey={"shopName"} dataSource={props.record.shopPrices} pagination={false}>
                    <Column title="Webshop" dataIndex="shopName" key="shopName"/>
                    <Column title="Értékesítési Ár (Bruttó)" dataIndex="price" key="price"/>
                    <Column title="Kedvezmény %" dataIndex="discount" key="discount"/>
                    <Column title="ÁFA" dataIndex="tax" key="tax"/>
                </Table>
            </Card>
            }
            {(props.record.description || props.record.imageLinks) &&
            <Card title="Termék adatok">
                <h4>Garancia: {props.record.warranty}</h4>
                {props.record.imageLinks &&
                props.record.imageLinks.map((d, index) => {
                    return (
                        d.type === "LINK" &&
                        <h4 key={index}>Kép: <a href={d.value} rel="noreferrer noopener" target="_blank">{d.value}</a></h4>
                    )
                })}
                <h4>Leírás:
                    <div dangerouslySetInnerHTML={{__html: props.record.description}}/>
                </h4>
            </Card>
            }
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setProductsForSale: products => dispatch(setProductsForSale(products))
    };
}

const connectedProductList = connect(null, mapDispatchToProps)(ProductList);
export default Form.create({name: 'productList_form'})(connectedProductList);

