import React, {Component} from "react";
import {Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select, Table} from "antd";
import moment from "moment";
import API from "../Utils/API";
import QueryParams from "../Utils/QueryParams";
import Column from "antd/es/table/Column";
import {saveAs} from 'file-saver';

const { RangePicker } = DatePicker
const { Option } = Select

const CashRegisterExportForm = Form.create({
    name: 'cash_register_export',
})(
    class extends Component {

        render() {
            const { getFieldDecorator } = this.props.form
            return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label="Kassza">
                            {getFieldDecorator('cashRegister', {
                                rules: [{ required: true, message: 'Kérjük adjon meg egy kasszát!' }]
                            })(
                                <Select>
                                    {this.props.cashRegisters.map((cr) => (
                                        <Option key={cr.id} value={cr.id}>{cr.name} - {cr.siteName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Intervallum">
                            {getFieldDecorator('date', {
                                rules: [{ required: true, message: "Kérjük válasszon egy intervallumot!" }],
                                initialValue: [moment().startOf('month'), moment().endOf('month')]
                            })(
                                <RangePicker
                                    ranges={{
                                        'Ma': [moment(), moment()],
                                        'Előző hónap': [moment().startOf('month').subtract(1, 'months'), moment().endOf('month').subtract(1, 'months')],
                                        'E hónap': [moment().startOf('month'), moment().endOf('month')],
                                        'Idén': [moment().startOf('year'), moment().endOf('year')]
                                    }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Létrehozó">
                            {getFieldDecorator('createdBy', {})(
                                <Select allowClear={true} showSearch={true} filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                    {this.props.users.map((user) => (
                                        <Option key={user.id} value={user.id}>{user.fullName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Keresés tétel címben">
                            {getFieldDecorator('name', {})(
                                <Input/>
                            )}
                        </Form.Item>
                        <Form.Item label="Keresés megjegyzésben">
                            {getFieldDecorator('note', {})(
                                <Input/>
                            )}
                        </Form.Item>

                    </Form>
                </div>
            );
        }
    }
)

class CashRegistryExport extends Component {

    state = {
        cashRegisters: [],
        users: [],
        exportData: [],
    }

    componentDidMount() {
        this.getCashRegisters()
        this.getUsers()
    }

    getCashRegisters() {
        API.get("settings/getCashRegisters")
            .then(res => {
                this.setState({ cashRegisters: res.data.lines }, () => console.log(this.state.cashRegisters))
            })
    }

    getUsers() {
        API.get("getUsers")
            .then(res => {
                this.setState({ users: res.data }, () => console.log(this.state.users))
            })
    }

    createLink = (values) => {
        const { cashRegister, date, createdBy, name, note } = values
        const fromDate = date[0].format("YYYY-MM-DD")
        const toDate = date[1].format("YYYY-MM-DD")
        let qp = new QueryParams();

        if (createdBy) qp.setCustomParams({ "createdBy": this.state.users.find(u => u.id === createdBy).username })
        if (name) qp.setCustomParams({ "name": name })
        if (note) qp.setCustomParams({ "note": note })

        return `${cashRegister}/${fromDate}/${toDate}` + qp.getQueryParam()
    }

    save = () => {
        const { form } = this.formRef.props
        form.validateFields((err, values) => {
            if (err) return;

            API.get(`cash-register/get-cash-movements/${this.createLink(values)}`).then(res => {
                console.log(res.data)
                this.setState({ exportData: res.data.lines.map(line => {
                        return {...line, key: line.id}
                    }) })
            })
        })

    }

    download = () => {
        const { form } = this.formRef.props
        form.validateFields((err, values) => {
            if (err) return;

            API.get(`/cashMovement/download/${this.createLink(values)}`, {responseType: 'blob'})
                .then(res => {
                    const blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    let fileName = "cash-movements-export.xls";
                    saveAs(blob, fileName);
            }).catch(error => {
                console.log(error)
            })

        })
    };

    saveFormRef = formRef => {
        this.formRef = formRef
    }

    render() {
        return (
            <div>
                <Card title="Kassza export">
                    <Button onClick={() => this.props.history.push('/app/settings/cash-register-settings')} style={{marginBottom: "2em"}}>Vissza</Button>
                    <CashRegisterExportForm
                        wrappedComponentRef={this.saveFormRef}
                        cashRegisters={this.state.cashRegisters}
                        users={this.state.users}
                    />
                    <Row>
                        <Button onClick={this.save} type="primary">
                            Lekérdezés
                        </Button>
                        <Divider type="vertical"/>
                        <Button onClick={this.download} type="primary">
                            Excel export
                        </Button>
                    </Row>
                </Card>
                <Divider type="horizontal"/>
                <Table
                    dataSource={this.state.exportData}
                    pagination={false}
                    bordered={true}
                >
                    <Column title="ID" dataIndex="id" key="id"/>
                    <Column title="Létrehozó" dataIndex="createdBy" key="createdBy"/>
                    <Column title="Dátum" dataIndex="createdDate" key="createdDate" render={text => moment(text).format("YYYY-MM-DD")}/>
                    <Column title="Összeg" dataIndex="amount" key="amount" render={text => (
                        <span style={{color: text.toString().at(0) === '-' ? 'red': ''}}>{text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')}</span>
                    )}/>
                    <Column title="Tétel neve" dataIndex="name" key="name"/>
                    <Column title="Megjegyzés" dataIndex="note" key="note"/>
                </Table>
            </div>
        );
    }
}

export default CashRegistryExport