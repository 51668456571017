import {Form, Input, Switch, Select} from 'antd';
import React, {Component} from 'react';
import API from "../Utils/API";
import Checkbox from "antd/es/checkbox";

const { TextArea } = Input;
const { Option } = Select;

class PartnerForm extends Component {

    state = {
        showPostalData : false,
        nameChecking : "",
        nameCheckingText: "",
    };

    availableTags = [];
    countries = [];

    componentDidMount() {
        API.get("tags").then(res => {
           res.data.map((tag) => {
              return  this.availableTags.push(<Option key={tag}>{tag}</Option>);
           })
        });
        this.getCountries();
    }

    getCountries = () => {
        API.get("getCountries")
            .then(res => {
                res.data.map((d) => {
                    return  this.countries.push(<Option key={d.code} value={d.code}>{d.name}</Option>);
                })
            })
    }

    checkName = () => {
        if(this.props.form.getFieldValue("name").trim() !== this.props.initialName.trim()) {
            this.setState({nameChecking: "validating"});
            API.get("checkName/" + this.props.form.getFieldValue("name")).then(res => {
                if (res.data.type === "error") {
                    this.setState({nameChecking: "error"});
                    this.setState({nameCheckingText: "Ez a név már létezik!"});
                } else {
                    this.setState({nameChecking: "success"});
                    this.setState({nameCheckingText: ""});
                }
            });
        }else{
            this.setState({nameChecking: "success"});
            this.setState({nameCheckingText: ""});
        }
    };

    anotherPostalData= () => {
        this.setState({
            showPostalData: !this.state.showPostalData,
        });
    };

    render() {
        const { getFieldDecorator} = this.props.form;
        return ( <Form onSubmit={this.handleSubmit} layout={'vertical'} className="partner-form">

            <Form.Item label="Típus">
            {getFieldDecorator('invoicePartnerType', {
                rules: [{required: true, message: 'Kérjük válasszon!'}],
            })(
                <Select>
                    <Option key={"PRIVATE_PERSON"}>Magánszemély</Option>
                    <Option key={"DOMESTIC"}>Belföldi cég</Option>
                    <Option key={"OTHER"}>Egyéb</Option>
                </Select>
            )}
            </Form.Item>

            <Form.Item label="Név" /*hasFeedback validateStatus={this.state.nameChecking}
                       help={this.state.nameCheckingText}*/>
                {getFieldDecorator('name', {
                    rules: [{required: true, message: 'Kérjük töltse ki a nevet!'}],
                })(
                    <Input /*onBlur={this.checkName}*/ />,
                )}
            </Form.Item>
            <Form.Item label="Telefonszám">
                {getFieldDecorator('phone', {
                    rules: [{required: true, message: 'Kérjük töltse ki a telefonszámot!'}],
                })(
                    <Input />,
                )}
            </Form.Item>
            <Form.Item label="E-mail cím">
                {getFieldDecorator('email', {
                    rules: [{ type: "email", message : "Nem email címet adtak meg!" },{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Ország">
                {getFieldDecorator('country', {
                    rules: [{required: false}],
                })(
                    <Select
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.countries}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Irányítószám">
                {getFieldDecorator('zipCode', {
                    rules: [{required: false}],
                })(
                    <Input/>,
                )}
            </Form.Item>

            <Form.Item label="Város">
                {getFieldDecorator('city', {
                    rules: [{required: false}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Közterület megnevezése">
                {getFieldDecorator('address', {
                    rules: [{required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Közterület típusa">
                {getFieldDecorator('addressType', {
                    rules: [{required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Házszám">
                {getFieldDecorator('addressNumber', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Épület">
                {getFieldDecorator('addressBuilding', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Lépcsőház">
                {getFieldDecorator('addressStaircase', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Emelet">
                {getFieldDecorator('addressFloor', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Ajtó">
                {getFieldDecorator('addressDoor', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Hrsz.">
                {getFieldDecorator('addressLotNumber', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Adószám">
                {getFieldDecorator('vatNumber', {
                    rules: [{ required: false,}],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Más a postázási cím?">
                <Switch checked={this.state.showPostalData} onChange={this.anotherPostalData} />
            </Form.Item>

            <Form.Item label="Postázási név" style={this.state.showPostalData ? {} :{display:"none"}} >
                {getFieldDecorator('postalName', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>

            <Form.Item label="Postázási ország" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalCountry', {
                    rules: [{ required: false }],
                })(
                    <Select
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.countries}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Postázási irányítószám" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalCode', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási város" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalCity', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási közterület címe" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalAddress', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási közterület típusa" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalAddressType', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási házszám" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalNumber', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási épület" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalBuilding', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási lépcsőház" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalStaircase', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási emelet" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalFloor', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási ajtó" style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalDoor', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>
            <Form.Item label="Postázási hrsz." style={this.state.showPostalData ? {} :{display:"none"}}>
                {getFieldDecorator('postalLotNumber', {
                    rules: [{ required: false }],
                })(
                    <Input/>,
                )}
            </Form.Item>

            <Form.Item label="Beszállító?" >
                {getFieldDecorator('isSupplier', {
                    valuePropName: 'checked',
                    initialValue: this.props.isSupplier,
                })(<Checkbox />)}
            </Form.Item>

            <Form.Item label="Címkék">
                {getFieldDecorator('tags', {
                    rules: [{ required: false}],
                })(
                    <Select mode="tags" style={{ width: '100%' }} >
                        { this.availableTags }
                    </Select>
                )}
            </Form.Item>

            <Form.Item label="Megjegyzés">
                {getFieldDecorator('note', {
                    rules: [{ required: false}],
                })(
                    <TextArea rows={4} />,
                )}
            </Form.Item>
        </Form>)
    }
}

export default Form.create({ name: 'normal_login' ,  mapPropsToFields(props) {

        return {
            id: Form.createFormField({
                ...props.id,
                value: props.id,
            }),
            invoicePartnerType: Form.createFormField({
                ...props.invoicePartnerType,
                value: props.invoicePartnerType != null ? props.invoicePartnerType : "PRIVATE_PERSON",
            }),
            name: Form.createFormField({
                ...props.name,
                value: props.name,
            }),
            phone: Form.createFormField({
                ...props.phone,
                value: props.phone,
            }),
            email: Form.createFormField({
                ...props.email,
                value: props.email,
            }),
            country: Form.createFormField({
                ...props.country,
                value: props.country,
            }),
            zipCode: Form.createFormField({
                ...props.zipCode,
                value: props.zipCode,
            }),
            city: Form.createFormField({
                ...props.city,
                value: props.city,
            }),
            address: Form.createFormField({
                ...props.address,
                value: props.address,
            }),
            addressType: Form.createFormField({
                ...props.addressType,
                value: props.addressType,
            }),
            addressNumber: Form.createFormField({
                ...props.addressNumber,
                value: props.addressNumber,
            }),
            addressBuilding: Form.createFormField({
                ...props.addressBuilding,
                value: props.addressBuilding,
            }),
            addressStaircase: Form.createFormField({
                ...props.addressStaircase,
                value: props.addressStaircase,
            }),
            addressFloor: Form.createFormField({
                ...props.addressFloor,
                value: props.addressFloor,
            }),
            addressDoor: Form.createFormField({
                ...props.addressDoor,
                value: props.addressDoor,
            }),
            addressLotNumber: Form.createFormField({
                ...props.addressLotNumber,
                value: props.addressLotNumber,
            }),
            vatNumber: Form.createFormField({
                ...props.vatNumber,
                value: props.vatNumber,
            }),

            postalName: Form.createFormField({
                ...props.postalName,
                value: props.postalName,
            }),
            postalCountry: Form.createFormField({
                ...props.postalCountry,
                value: props.postalCountry,
            }),
            postalCode: Form.createFormField({
                ...props.postalCode,
                value: props.postalCode,
            }),
            postalCity: Form.createFormField({
                ...props.postalCity,
                value: props.postalCity,
            }),
            postalAddress: Form.createFormField({
                ...props.postalAddress,
                value: props.postalAddress,
            }),
            postalAddressType: Form.createFormField({
                ...props.postalAddressType,
                value: props.postalAddressType,
            }),
            postalNumber: Form.createFormField({
                ...props.postalNumber,
                value: props.postalNumber,
            }),
            postalBuilding: Form.createFormField({
                ...props.postalBuilding,
                value: props.postalBuilding,
            }),
            postalStaircase: Form.createFormField({
                ...props.postalStaircase,
                value: props.postalStaircase,
            }),
            postalFloor: Form.createFormField({
                ...props.postalFloor,
                value: props.postalFloor,
            }),
            postalDoor: Form.createFormField({
                ...props.postalDoor,
                value: props.postalDoor,
            }),
            postalLotNumber: Form.createFormField({
                ...props.postalLotNumber,
                value: props.postalLotNumber,
            }),

            isSupplier: Form.createFormField({
                ...props.isSupplier,
                value: props.isSupplier
            }),
            tags: Form.createFormField({
                ...props.tags,
                value: props.tags,
            }),
            note: Form.createFormField({
                ...props.note,
                value: props.note,
            })
        };
    },})(PartnerForm);