import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, Modal, Row, Select, Table} from "antd";
import API from "../Utils/API";
import Empty from "antd/es/empty";
import OfficeCashRegisterDrawer from "../Components/OfficeCashRegisterDrawer";
import moment from "moment";

const {Option} = Select;
const {Column, ColumnGroup} = Table;

class OfficeCashRegistry extends Component {

    formDrawerRef = React.createRef()

    state = {
        selectedCashRegister: null,
        selectedCashRegisterId: 0,
        tableLoading: true,
        cashRegisters: [],
        site: this.props.selectedSite.value,
        items: [],
        itemsSum: 0,
        visibleDrawer: false,
        editId: ""
    }

    getCashRegisters() {
        this.setState({tableLoading: true, cashRegisters: []})
        API.get("office-cash-register/cash-registers/" + this.state.site)
            .then(res => {
                this.setState({cashRegisters: res.data})
            }).finally(() => {
                this.setState({tableLoading: false})
        })
    }

    getItems() {
        this.setState({tableLoading: true, items: []})
        if (this.state.selectedCashRegisterId != null) {
            API.get(`office-cash-register/get-items/${this.state.selectedCashRegisterId}`)
                .then(res => {
                    console.log("get-items: ", res.data)
                    if (res.data.lines != null) {
                        const sum = res.data.lines.reduce((acc, cur) => acc + cur.amount, 0)
                        this.setState({
                            items: res.data.lines,
                            itemsSum: sum
                        })
                    }
                })

        }
    }

    onCashRegisterChange = (value) => {
        this.setState({
            selectedCashRegisterId: value,
            selectedCashRegister: this.state.cashRegisters.find(cr => cr.id === value),
        }, () => this.getItems())
    }

    onAddDrawer = () => {
        this.formDrawerRef.current.open("")
        this.setState({
            visibleDrawer: true,
        })
    }

    onEditDrawer = (id) => {
        this.formDrawerRef.current.open(id)
        this.setState({
            visibleDrawer: true,
        })
    }

    onCloseDrawer = (success) => {
        this.setState({
            visibleDrawer: false,
            editId: ""
        })
        if (success) {
            this.getItems();
        }
    }

    removeLine = (id) => {
        const current = this
        Modal.confirm({
            title: 'Biztosan törölni szeretnéd ezt a tételt?',
            content: 'A törlés nem visszavonható!',
            cancelText: 'Mégse',
            okText: 'Törlés',
            okType: 'danger',
            onOk() {
                API.post("office-cash-register/remove-line/" + id).then(() => {
                    current.getItems();
                })
            }
        })

    }

    componentDidMount() {
        this.getCashRegisters();
    }

    render() {
        return (
            <div>
                <h1>Irodai kassza</h1>
                <Row type="flex" justify="end">
                    <Col span={6}>
                        <Select style={{width: '70%', position: 'relative', right: '5px'}}
                                onChange={this.onCashRegisterChange} placeholder="Válasszon kasszát">
                            {this.state.cashRegisters.map(c => (
                                <Option key={c.id} value={c.id}>{c.name}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <br/>
                {!this.state.selectedCashRegister
                    ? <Empty description="Válasszon kasszát!"/>
                    : (
                        <div>
                            <Row>
                                <Col span={12}>
                                    <Card style={{maxWidth: "40%"}}>
                                        <h2>Választott kassza: {this.state.selectedCashRegister.name}</h2>
                                        {this.state.items &&
                                            <h3 style={{color: this.state.itemsSum.toString().at(0) === '-' ? 'red': ''}}>Tételek összege: {this.state.itemsSum}</h3>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                            <Table dataSource={this.state.items} bordered={true} pagination={false} rowKey="id">
                                <ColumnGroup title="Tételek">
                                    <Column title="#" dataIndex="id" key="id"/>
                                    <Column title="Tétel megnevezése" dataIndex="name" key="name"/>
                                    <Column title="Hozzáadás dátuma" dataIndex="createdDate" key="createdDate" render={text => {
                                        return moment(text.toString()).format("YYYY.MM.DD. HH:mm")
                                    }}/>
                                    <Column title="Összeg" dataIndex="amount" key="amount" render={text => {
                                        return (<span style={{color: text.toString().at(0) === '-' ? 'red' : ''}}>
                                            {text.toString().replace(/(?=(\d{3})+(?!\d))/g, ' ')}
                                        </span>)
                                    }}/>
                                    <Column title="Megjegyzés" dataIndex="note" key="note"/>
                                    <Column title="Szerkesztés" key="action"
                                            render={(text, record) => (
                                                <span>
                                            <Button type="primary" onClick={() => this.onEditDrawer(record.id)}>Tétel szerkesztése</Button>
                                            <Button type="danger" onClick={() => this.removeLine(record.id)}>Törlés</Button>
                                        </span>
                                            )}>
                                    </Column>
                                </ColumnGroup>
                            </Table>
                            <Button type="primary" onClick={this.onAddDrawer}>
                                Tétel hozzáadása
                            </Button>
                        </div>
                    )}
                <OfficeCashRegisterDrawer ref={this.formDrawerRef}
                                          visible={this.state.visibleDrawer}
                                          onClose={this.onCloseDrawer}
                                          cashRegisterId={this.state.selectedCashRegisterId}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedSite: state.selectedSite,
    };
}

export default connect(mapStateToProps)(OfficeCashRegistry);