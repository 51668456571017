import React, {Component} from "react";
import {Form, Select} from "antd";

const {Option} = Select;

class ResponsibleSelector extends Component {

    render() {
        let component = "";
        if (this.props.form) {
            const {getFieldDecorator} = this.props.form;
            component = <Form.Item label="Felelős:" labelCol={{span: 3}} wrapperCol={{span: 15}}>
                {getFieldDecorator("responsible", {
                    initialValue: this.props.responsible != null ? this.props.responsible : ""
                })(
                    <Select onChange={this.props.userSelect} style={{width: "80%"}}
                            allowClear>
                        {this.props.users.map(d => (
                            <Option key={d.id} value={d.id}>{d.fullName}</Option>
                        ))}
                    </Select>
                )}
            </Form.Item>;
        } else {
            component = <Select style={{width: '100%'}} onChange={this.props.filterByUser} placeholder="Felelős"
                                allowClear>
                {this.props.users.map(d => (
                    <Option key={d.id} value={d.id}>{d.fullName}</Option>
                ))}
            </Select>;
        }

        return component;
    }
}
export default ResponsibleSelector;