import {Table, Divider, Button, Card, Input, Row, Col, Checkbox} from 'antd';
import React, {Component} from 'react';
import API from "../Utils/API";
import EmailField from "../Components/EmailField";
import PartnerDrawer from "../Components/PartnerDrawer";
import Tags from "../Components/Tags";
import _ from "lodash";
import PartnerInfoModal from "../Components/PartnerInfoModal";

const {Column} = Table;

class Partners extends Component {

    editClick = (e, id) => {
        this.setState({
            visible: true,
            editId: id
        });
    };

    partnerInfo = (e, id) => {
        API.get("/getPartner/" + id)
            .then(res => {
                let partnerDetails = res.data;
                this.setState({
                    partner: partnerDetails
                })
            });
        API.get("/getSalesOrdersByPartnerId/" + id)
            .then(res => {
                this.setState({
                    orders: res.data.lines
                });

            });
        this.setState({
            visibleInfo: true
        });
    };

    handleInfoModalClose = () => {
        this.setState({visibleInfo: false})
    }

    handleTableChange = (pagination) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        }, () => {
            this.getPartners("");
        });

    };


    onClose = (e, reload) => {
        this.setState({
            visible: false,
            editId: ""
        });
        if (reload) {
            this.setState({tableLoading: true});
            this.getPartners(this.state.search);
        }
    };

    state = {
        tableLoading: true,
        visible: false,
        editId: null,
        infoId: null,
        visibleInfo: false,
        partners: [],
        orders: [],
        checkBoxChecked: false,
        partner: {
            name: null,
            city: null,
            zipCode: null,
            address: null,
            vatNumber: null,
            phone: null,
            email: null,
            note: null
        },
        search: "",
        pagination: {
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            current: 1,
            results: 1,
            total: 200,
            pageSize: 10
        }
    };

    searchCallback = _.debounce((text) => {
        this.search(text);
    }, 500);

    search(search) {
        this.setState({search: search, pagination: {...this.state.pagination, current: 0}}, () => {
            this.getPartners();
        });
    };

    getPartners() {
        this.setState({tableLoading: true, partners: []});
        let url;
        if (this.state.search !== "") {
            //Search with pagination
            url = "partners/?s=" + this.state.search + "&page=" + this.state.pagination.current + "&size=" + this.state.pagination.pageSize;
        } else {
            //Add pagination
            url = "partners/?page=" + this.state.pagination.current + "&size=" + this.state.pagination.pageSize;
        }

        if (!this.state.checkBoxChecked) {
            API.get(url)
                .then(res => {
                    let partners = res.data.lines;
                    const pagination = {...this.state.pagination};
                    pagination.total = res.data.totalElements;
                    this.setState({partners: partners, pagination});
                }).finally(() => {
                this.setState({tableLoading: false});
            });
        } else {
            API.get("getSuppliers")
                .then(res => {
                    this.setState({partners: res.data.lines}, () => console.log(this.state.partners));
                }).finally(() => {
                this.setState({tableLoading: false});
            });
        }

    }

    getSuppliersOnly = (e) => {
        this.setState({checkBoxChecked: e.target.checked}, () => this.getPartners());
    };


    componentDidMount() {
        this.getPartners();
    }

    render() {
        return (
            <div>
                <Card title="Partnerek" extra={[
                    <Row key={1}>
                        <Col span={10}>
                            <Checkbox onChange={(e) => this.getSuppliersOnly(e)}>Csak beszállítók</Checkbox>
                        </Col>
                        <Col span={10}>
                            <Input placeholder="Keresés" onChange={(e) => {
                                this.searchCallback(e.target.value)
                            }}/>
                        </Col>
                        <Col span={2}>
                            <Button key="1" onClick={e => this.editClick(e, '')} type="primary">
                                Új felvétel
                            </Button>
                        </Col>
                    </Row>
                ]}>

                    <Table dataSource={this.state.partners} bordered={true} onChange={this.handleTableChange}
                           loading={this.state.tableLoading} rowKey={"id"} pagination={this.state.pagination}>

                        <Column title="Név" dataIndex="name" key="name"/>
                        <Column title="Telefonszám" dataIndex="phone" key="phone"/>
                        <Column title="Város" dataIndex="city" key="city"/>
                        <Column title="Cím" dataIndex="address" key="address"/>
                        <Column title="Email cím" dataIndex="email" key="email"
                                render={email => (<EmailField email={email}/>)}/>
                        <Column title="Megjegyzés" dataIndex="note" key="note"/>
                        <Column
                            title="Címkék"
                            dataIndex="tags"
                            key="tags"
                            render={tags => (<Tags tags={tags}/>)}
                        />

                        <Column
                            title="Műveletek"
                            key="action"
                            render={(text, record) => (
                                <span>
                                <Button type="primary" onClick={e => this.editClick(e, record.id)}>Szerkesztés</Button>
                                <Divider type="vertical"/>
                                <Button type="dashed" onClick={e => this.partnerInfo(e, record.id)}>Info</Button>
                            </span>
                            )}
                        />

                    </Table>
                </Card>
                <PartnerDrawer visible={this.state.visible} onClose={this.onClose} editId={this.state.editId}/>

                <PartnerInfoModal orders={this.state.orders} partner={this.state.partner}
                                  visibleInfoModal={this.state.visibleInfo} onClose={this.handleInfoModalClose}/>
            </div>
        );
    }
}

export default Partners;

