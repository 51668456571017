import React, {Component} from "react";
import {Button, Col, Form, Input, Row, Spin} from "antd";
import Card from "antd/es/card";
import NotificationUtil from "../Utils/NotificationUtil";
import API from "../Utils/API";

class Profile extends Component {

    componentDidMount() {
        this.getUser();
    }

    state = {
        loading: false,
        editObject: [],
    };

    getUser = () => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "";
        this.setState({editObject: user})
    };

    save = () => {
        const {form} = this.props;
        form.validateFields(['fullName', 'username', 'currentPassword'], (err, values) => {
            if (err) {
                return;
            }
            API.post("/changeUserDetails", values).then(res => {
                form.resetFields();
                NotificationUtil(res.data);
                if (res.data.type === "success") {
                    localStorage.clear();
                    this.props.history.push('/login');
                }
            }).catch(error => {
                NotificationUtil({msg: "Hiba történt: " + error, type: "error"});
            });
        });
    };
    changePassword = () => {
        const {form} = this.props;
        form.validateFields(['password', 'newPassword', 'newPasswordConfirmation'], (err, values) => {
            if (err) {
                return;
            }
            if (values.newPassword === values.newPasswordConfirmation) {
                let data = {
                    username: this.state.editObject.username,
                    password: values.password,
                    newPassword: values.newPassword
                }
                API.post("/changeUserPassword", data).then(res => {
                    form.resetFields();
                    NotificationUtil(res.data);
                    if (res.data.type === "success") {
                        localStorage.clear();
                        this.props.history.push('/login');
                    }
                }).catch(error => {
                    NotificationUtil({msg: "Hiba történt: " + error, type: "error"});
                });
            } else {
                NotificationUtil({msg: "A megadott jelszavak nem egyeznek!", type: "error"});
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Spin spinning={this.state.loading}>
                        <Col span={12}>
                            <Card title="Profil szerkesztése" style={{width: "90%"}}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Item label="Teljes név:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("fullName", {
                                            initialValue: this.state.editObject.fullName !== null ? this.state.editObject.fullName : "",
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                        })(
                                            <Input/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Felhasználónév:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("username", {
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                            initialValue: this.state.editObject.username !== null ? this.state.editObject.username : "",
                                        })(
                                            <Input/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Jelszó:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("currentPassword", {
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                        })(
                                            <Input type="password"/>
                                        )}
                                    </Form.Item>
                                    <Button type="primary" onClick={this.save}>Mentés</Button>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Jelszó változtatás" style={{width: "90%"}}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Item label="Jelenlegi jelszó:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("password", {
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                        })(
                                            <Input type="password"/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Új jelszó:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("newPassword", {
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                        })(
                                            <Input type="password"/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Új jelszó újra:" labelCol={{span: 5}} wrapperCol={{span: 15}}>
                                        {getFieldDecorator("newPasswordConfirmation", {
                                            rules: [{required: true, message: "A mező kitöltése kötelező!"}],
                                        })(
                                            <Input type="password"/>
                                        )}
                                    </Form.Item>
                                    <Button type="primary" onClick={this.changePassword}>Jelszó megváltozatása</Button>
                                </Form>
                            </Card>
                        </Col>
                    </Spin>
                </Row>
            </div>
        );
    }
}

export default Form.create({name: 'user_form'})(Profile);