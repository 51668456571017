export function localstorageToBool(pre) {
    return localStorage.getItem(pre) === 'true';
}

export function resolveSalesOrderStatus(status) {
    if (status == null || status === "") return "";
    switch (status) {
        case "BidRequest": return "Árajánlat kérés"
        case "BidOffered": return "Árajánlat kiadva"
        case "BidAccepted": return "Árajánlat elfogadva"
        case "Pending": return "Függőben"
        case "AwaitingPayment": return "Átutalásra vár"
        case "InPurchaseOrder": return "Beszerzés alatt"
        case "OrderInStock": return "Rendelés készleten"
        case "Shipped": return "Szállítás alatt"
        case "CustomerNoticed": return "Ügyfél értesítve"
        case "Billable": return "Számlázható"
        case "Closed": return "Lezárt"
        case "Cancelled": return "Törölve"
        default: return "";
    }
}

export function resolvePaymentType(type) {
    if (type == null || type === "") return "";
    switch (type) {
        case "Cash": return "Készpénz"
        case "Card": return "Bankkártya"
        case "BankWire": return "Átutalás"
        case "Check": return "Csekk"
        case "CashOnDelivery": return "Utánvét"
        case "PayPal": return "PayPal"
        case "Other": return "Egyéb"
        default: return "";
    }
}

export function resolveDocType(type) {
    if (type == null || type === "") return "";
    switch (type) {
        case "Bid": return "Árajánlat";
        case "Order": return "Megrendelés";
        case "CashRegisterSale": return "Pénztárgépes eladás";
        case "Invoice": return "Számla";
        case "CancelledInvoice": return "Sztornó számla";
        case "DeliveryNote": return "Szállító";
        default: return "";
    }
}

export function resolvePurchaseOrderStatus(status) {
    if (status == null || status === "") return "";
    switch (status) {
        case "Pending": return "Függőben";
        case "Ordered": return "Megrendelve";
        case "InStock": return "Készleten";
        case "Done": return "Lezárt";
        default: return "";
    }
}

export function resolveRepairStatus(status) {
    if (status == null || status === "") return "";
    switch (status) {
        case "TakenOver": return "Átvéve";
        case "BidOffered": return "Árajánlat kiadva";
        case "BidAccepted": return "Árajánlat elfogadva";
        case "WaitingForParts": return "Alkatrészre vár";
        case "Pending": return "Függőben";
        case "InProgress": return "Folyamatban";
        case "Done": return "Kész";
        case "CustomerNoticed": return "Ügyfél értesítve";
        case "Closed": return "Lezárt";
        default: return "";
    }
}

export function resolveRepairType(type) {
    if (type == null || type === "") return "";
    switch (type) {
        case "Repair": return "Javítás";
        case "Guarantee": return "Garancia";
        default: return "";
    }
}