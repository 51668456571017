import axios from "axios";
import {notification, Modal, Collapse, Button} from 'antd';
import React from 'react';

const {Panel} = Collapse;


const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

API.interceptors.request.use(request => {

    let token = localStorage.getItem('jwtToken');

    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`
    }

    if (request.url !== "user/me" && request.url !== "authenticate" && !request.url.includes("getSiteByValue")) {
        let site = localStorage.getItem('site');
        if (site) {
            const parsed = JSON.parse(site);
            request.headers.common['site'] = ` ${parsed.id}`
        } else {
            localStorage.clear();
            return (window.location.href = '/login')
        }
    }

    let invoicingToken = localStorage.getItem("invoicingToken");
    if (invoicingToken) {
        request.headers.common['invoicingToken'] = invoicingToken;
    }

    return request;
});

// Response interceptor
API.interceptors.response.use(response => {
    if (response.headers.invoicingtoken) {
        localStorage.setItem("invoicingToken", response.headers.invoicingtoken);
    }
    return response
}, error => {

    if (error.response == null) {
        notification.error({
            key: 'server_error',
            message: 'Hiba történt a szerverre való kapcsolódás közben!',
            description: (<span>Próbálja újra, vagy vegye fel a kapcsolatot a rendszergazdával!</span>),
        });
        return Promise.reject(error);
    }
    const {status} = error.response;

    if (status === 401) {
        notification.error({
            key: 'user_error',
            message: 'Felhasználói azonosítási hiba történt!',
            description: (<span>Kérjük lépjen be újra!</span>),
        });
        localStorage.clear();
        return (window.location.href = '/login')

    }

    if (status >= 500) {
        notification.error({
            key: 'other_error',
            message: 'Hiba történt!',
            description: (<span>Valami hiba van a kérésben! <Button type={"href"}
                                                                    onClick={() => showOtherInformation(error.response)}>Egyéb infó</Button></span>)
        });
    }


    return Promise.reject(error);
});

function showOtherInformation(content) {
    Modal.info({
        title: 'Szerver válasza:',
        width: "80%",
        content: (
            <div>
                <code><h2>Üzenet: </h2>{content.data.message}</code>
                <Collapse>
                    <Panel header="Trace" key="1">
                        <code>{content.data.trace}</code>
                    </Panel>
                </Collapse>
            </div>
        ),
    });
}


export default API;
